// src/components/TemplateManager.js

export const templates = [
  {
    id: 'boss_favorite',
    name: 'Boss Favorite',
    thumbnail: '/boss_favorite.png',
    variants: [
      {
        id: 'boss_favorite_blue',
        name: 'Blue',
        thumbnail: '/nice_slides_blue.png',
      },
      {
        id: 'boss_favorite_red',
        name: 'Christmas Red',
        thumbnail: '/nice_slides_red.png',
      },
      {
        id: 'boss_favorite_orange',
        name: 'Rustic Amber',
        thumbnail: '/nice_slides_orange.png',
      },
      {
        id: 'boss_favorite_violet',
        name: 'Gentle Violet',
        thumbnail: '/boss_favorite_violet.png',
      },
    ],
  },
  {
    id: 'pro_slides',
    name: 'Pro Slides',
    thumbnail: '/pro_slides.png',
    variants: [
      {
        id: 'pro_slides_dark',
        name: 'Dark',
        thumbnail: '/pro_slides_dark.png',
      },
      {
        id: 'pro_slides_light',
        name: 'Light',
        thumbnail: '/pro_slides_light.png',
      },
    ],
  },
  // Add more templates here
];

export const getTemplateById = (id) => {
  return templates.find((template) => template.id === id);
};

export const getTemplateVariants = (templateId) => {
  const template = getTemplateById(templateId);
  return template ? template.variants : [];
};

export default {
  templates,
  getTemplateById,
  getTemplateVariants,
};
