import React from "react";
import { Select, MenuItem } from "@mui/material";

const LanguageSelectShort = ({ language, setLanguage }) => {
  return (
    <Select
      displayEmpty
      renderValue={(value) => (value !== "" ? value : "Select output language")}
      fullWidth
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      sx={{ mb: 1.5 }}
    >
      <MenuItem value="English (American)">English (American)</MenuItem>
      <MenuItem value="English (British)">English (British)</MenuItem>
    </Select>
  );
};

export default LanguageSelectShort;
