import React from 'react';
import TagIcon from '@mui/icons-material/Tag';
import { alpha } from '@mui/material';

const socialMediaPostGenerator = {
  title: 'Social Media Post Generator - News',
  subtitle: 'Transform news into captivating, shareable social media posts.',
  targetRoute: '/social-media-post-generator-news',
  color: '#5A96E3',
  bgcolor: alpha('#5A96E3', 0.1),
  icon: <TagIcon fontSize="medium" style={{ color: '#5A96E3' }} />,
  tags: [],
};

export default socialMediaPostGenerator;
