import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Container from "../../../Container";

const HeadlineSubtextTeaching = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          align={"center"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Empowering Education: Next-Generation AI Tools for Teachers
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{ fontWeight: 400 }}
          align={"center"}
        >
          Unleash the potential of AI to create distinctive, personalized
          teaching resources. Just upload images of your syllabus, and our range
          of AI tools spring into action, generating a variety of study aids
          based on your content.
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "stretched", sm: "flex-start" }}
          justifyContent={"center"}
          marginTop={4}
        ></Box>
      </Box>
    </Container>
  );
};

export default HeadlineSubtextTeaching;
