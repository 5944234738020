// client/src/firebaseConfig.js

// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';

// import {
//   getAuth,
//   setPersistence,
//   browserSessionPersistence,
//   GoogleAuthProvider,
// } from 'firebase/auth';

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyBmU0H4NSfehH-qyWvggzZcU0ck0TT_Umk',
//   authDomain: 'wisdomscribe.ai',
//   // authDomain: 'wisdom-scribe-429116.firebaseapp.com',
//   projectId: 'wisdom-scribe-429116',
//   storageBucket: 'wisdom-scribe-429116.appspot.com',
//   messagingSenderId: '123565986852',
//   appId: '1:123565986852:web:4a19fa7848e7d88658b4b1',
//   measurementId: 'G-H4C5PLKEXT',
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// const auth = getAuth(app);
// const googleProvider = new GoogleAuthProvider();

// // Set session persistence
// setPersistence(auth, browserSessionPersistence);

// export { auth, googleProvider, analytics };

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBmU0H4NSfehH-qyWvggzZcU0ck0TT_Umk',
  authDomain: 'wisdomscribe.ai',
  // authDomain: 'wisdom-scribe-429116.firebaseapp.com',
  projectId: 'wisdom-scribe-429116',
  storageBucket: 'wisdom-scribe-429116.appspot.com',
  messagingSenderId: '123565986852',
  appId: '1:123565986852:web:4a19fa7848e7d88658b4b1',
  measurementId: 'G-H4C5PLKEXT',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Set local persistence
setPersistence(auth, browserLocalPersistence);

export { auth, googleProvider, analytics };
