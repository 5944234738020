// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
// import { useNavigate } from 'react-router-dom';
// import instance from '../../helpers/axiosInstance';

// import { useParams } from 'react-router-dom';
// import {
//   Box,
//   Typography,
//   Container,
//   Card,
//   CardContent,
//   IconButton,
//   TextField,
//   Button,
// } from '@mui/material';
// import { styled } from '@mui/system';
// import DescriptionIcon from '@mui/icons-material/Description';
// import DoneIcon from '@mui/icons-material/Done';

// import { authState } from '../../atoms';

// const SyllabusTitle = styled(Typography)(({ theme }) => ({
//   marginBottom: '0.5rem',
//   fontWeight: 'bold',
//   flexGrow: 1,
// }));

// const SyllabusContent = styled(Typography)(({ theme }) => ({
//   whiteSpace: 'pre-line',
// }));

// const SyllabusDetailCardContent = styled(CardContent)(({ theme }) => ({
//   p: 2,
//   display: 'flex',
//   flexDirection: 'column',
// }));

// const SyllabusDetailBox = styled(Box)(({ theme }) => ({
//   position: 'relative',
// }));

// const CopyButton = styled(IconButton)(({ theme }) => ({}));

// const SyllabusDetailScreen = () => {
//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const setAuth = useSetRecoilState(authState);
//   const { isAuthenticated, accessToken, refreshToken, tokenExpiry } = auth;

//   const [syllabusItem, setSyllabusItem] = useState(null);
//   const { id } = useParams();
//   const [copied, setCopied] = useState(false);
//   const [editing, setEditing] = useState(false);
//   const [editedContent, setEditedContent] = useState('');

//   const handleEdit = () => {
//     setEditing(true);
//     setEditedContent(syllabusItem.content);
//   };

//   const handleSave = async () => {
//     if (!isAuthenticated || !accessToken) {
//       console.error('Authentication required or access token missing.');
//       return;
//     }

//     try {
//       const response = await instance.put(
//         `/api/auth/syllabus/${id}`,
//         {
//           content: editedContent,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );
//       setSyllabusItem(response.data);
//       setEditing(false);
//     } catch (error) {
//       console.error(
//         'Error updating syllabus:',
//         error.response ? error.response.data : error.message
//       );
//     }
//   };

//   const fetchSyllabusItem = useCallback(async () => {
//     if (!isAuthenticated || !accessToken) {
//       console.error('Authentication required or access token missing.');
//       return;
//     }

//     try {
//       const response = await instance.get(`/api/auth/syllabus/${id}`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setSyllabusItem(response.data);
//     } catch (error) {
//       console.error(
//         'Error fetching syllabus:',
//         error.response ? error.response.data : error.message
//       );
//     }
//   }, [id, isAuthenticated, accessToken]);

//   useEffect(() => {
//     fetchSyllabusItem();
//   }, [fetchSyllabusItem]);

//   const handleCopyContent = () => {
//     if (syllabusItem && syllabusItem.content) {
//       try {
//         const textContent = syllabusItem.content
//           .replace(/<br\/>/g, '\n')
//           .replace(/<[^>]+>/g, '');
//         navigator.clipboard.writeText('\n' + textContent + '\n');
//         setCopied(true);
//         setTimeout(() => setCopied(false), 5000);
//       } catch (error) {
//         console.error('Error copying content:', error);
//       }
//     }
//   };

//   return (
//     <Container maxWidth="md">
//       {syllabusItem ? (
//         <SyllabusDetailBox sx={{ p: 4 }}>
//           <Card>
//             <SyllabusDetailCardContent>
//               <Box
//                 display="flex"
//                 justifyContent="space-between"
//                 alignItems="flex-start"
//               >
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   justifyContent="center"
//                 >
//                   <SyllabusTitle variant="h6">
//                     {syllabusItem.title}
//                   </SyllabusTitle>
//                 </Box>

//                 <Box
//                   display="flex"
//                   flexDirection="row"
//                   alignItems="center"
//                   justifyContent="flex-end"
//                 >
//                   <CopyButton onClick={handleCopyContent} disableRipple>
//                     {copied ? (
//                       <>
//                         <DoneIcon />
//                         <Typography variant="body2" component="span">
//                           Copied
//                         </Typography>
//                       </>
//                     ) : (
//                       <>
//                         <DescriptionIcon />
//                         <Typography variant="body2" component="span">
//                           Copy text
//                         </Typography>
//                       </>
//                     )}
//                   </CopyButton>
//                   {!editing ? (
//                     <Button onClick={handleEdit}>Edit</Button>
//                   ) : (
//                     <Button onClick={handleSave}>Save</Button>
//                   )}
//                 </Box>
//               </Box>

//               <Typography variant="body2" fontWeight={500}>
//                 Standard: {syllabusItem.standard}
//               </Typography>

//               <Typography variant="body2" fontWeight={500}>
//                 Subject: {syllabusItem.subject}
//               </Typography>

//               <Typography variant="body2" fontWeight={500}>
//                 Chapter Number: {syllabusItem.chapterNumber}
//               </Typography>

//               {editing ? (
//                 <TextField
//                   multiline
//                   fullWidth
//                   value={editedContent}
//                   onChange={(e) => setEditedContent(e.target.value)}
//                 />
//               ) : (
//                 <SyllabusContent
//                   variant="body2"
//                   dangerouslySetInnerHTML={{ __html: syllabusItem.content }}
//                 />
//               )}
//             </SyllabusDetailCardContent>
//           </Card>
//         </SyllabusDetailBox>
//       ) : (
//         <Box sx={{ border: '1px solid #ccc', p: 2 }}>
//           <Typography
//             variant="h5"
//             component="h2"
//             textAlign="center"
//             gutterBottom
//           >
//             Loading...
//           </Typography>
//         </Box>
//       )}
//     </Container>
//   );
// };

// export default SyllabusDetailScreen;

import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { authState } from '../../atoms';

const SyllabusTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
  flexGrow: 1,
}));

const SyllabusContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const SyllabusDetailCardContent = styled(CardContent)(({ theme }) => ({
  p: 2,
  display: 'flex',
  flexDirection: 'column',
}));

const SyllabusDetailBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CopyButton = styled(IconButton)(({ theme }) => ({}));

const SyllabusDetailScreen = () => {
  const navigate = useNavigate();

  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;
  const [syllabusItem, setSyllabusItem] = useState(null);
  const { id } = useParams();
  console.log('SyllabusDetailScreen - Received ID:', id);

  const [copied, setCopied] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const handleEdit = () => {
    setEditing(true);
    setEditedContent(syllabusItem.text);
    setIsPublic(syllabusItem.isPublic);
  };

  const handleSave = async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      console.log('Sending edit request with isPublic:', isPublic);

      const response = await instance.put(
        `/api/syllabus/edit-syllabus/${id}`,
        {
          text: editedContent,
          className: syllabusItem.className,
          subject: syllabusItem.subject,
          chapter: syllabusItem.chapter,
          title: syllabusItem.title,
          isPublic: isPublic,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log('Edit response:', response.data);
      setSyllabusItem({
        ...syllabusItem,
        text: editedContent,
        isPublic: isPublic,
      });
      setEditing(false);
    } catch (error) {
      console.error('Error updating syllabus item:', error);
    }
  };

  const handleDelete = async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      await instance.delete(`/api/syllabus/delete-syllabus/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      // Redirect to the syllabus list after successful deletion
      navigate('/my-syllabus');
    } catch (error) {
      console.error('Error deleting syllabus item:', error);
    }
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const fetchSyllabusItem = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    if (!id || id === 'undefined') {
      console.error('Invalid entry ID:', id);
      setSyllabusItem(null);
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      console.log('Fetching syllabus item with ID:', id);

      const response = await instance.get(`/api/syllabus/user-syllabus/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      console.log('Fetched syllabus item:', response.data);
      setSyllabusItem(response.data);
      setIsPublic(response.data.isPublic);
    } catch (error) {
      console.error('Error fetching syllabus item:', error);
      console.error('Error response:', error.response?.data);
      setSyllabusItem(null);
    }
  }, [id, isAuthenticated]);

  useEffect(() => {
    fetchSyllabusItem();
  }, [fetchSyllabusItem]);

  const handleCopyContent = () => {
    if (syllabusItem && syllabusItem.text) {
      try {
        const textContent = syllabusItem.text
          .replace(/<br\/>/g, '\n')
          .replace(/<[^>]+>/g, '');
        navigator.clipboard.writeText('\n' + textContent + '\n');
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (error) {
        console.error('Error copying content:', error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      {syllabusItem ? (
        <SyllabusDetailBox sx={{ p: 4 }}>
          <Card>
            <SyllabusDetailCardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h6" fontWeight={700}>
                    {syllabusItem.title}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <CopyButton onClick={handleCopyContent} disableRipple>
                    {copied ? (
                      <>
                        <DoneIcon />
                        <Typography variant="body2" component="span">
                          Copied
                        </Typography>
                      </>
                    ) : (
                      <>
                        <DescriptionIcon />
                        <Typography variant="body2" component="span">
                          Copy text
                        </Typography>
                      </>
                    )}
                  </CopyButton>
                  {!editing ? (
                    <>
                      <Button onClick={handleEdit}>Edit</Button>
                      <IconButton
                        onClick={handleDeleteDialogOpen}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Button onClick={handleSave}>Save</Button>
                  )}
                </Box>
              </Box>

              <SyllabusTitle variant="body2">
                Class: {syllabusItem.className}
              </SyllabusTitle>
              <Typography variant="body2" fontWeight={500}>
                Subject: {syllabusItem.subject}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                Chapter: {syllabusItem.chapter}
              </Typography>

              {editing ? (
                <>
                  <TextField
                    multiline
                    fullWidth
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                      />
                    }
                    label="Make public"
                  />
                </>
              ) : (
                <>
                  <SyllabusContent
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: syllabusItem.text }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Status: {syllabusItem.isPublic ? 'Public' : 'Private'}
                  </Typography>
                </>
              )}
            </SyllabusDetailCardContent>
          </Card>
        </SyllabusDetailBox>
      ) : (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Loading...
          </Typography>
        </Box>
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Syllabus Item?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this syllabus item? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SyllabusDetailScreen;
