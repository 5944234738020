// import React, { useState, useEffect } from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
// import instance from '../../helpers/axiosInstance';

// import {
//   Box,
//   Container,
//   Typography,
//   TextField,
//   FormControl,
//   Grid,
//   Pagination,
//   Select,
//   MenuItem,
//   InputLabel,
// } from '@mui/material';
// import filterAndRenderSyllabus from './filterAndRenderSyllabus';

// import { authState } from '../../atoms';

// const UserSyllabusScreen = (props) => {
//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const setAuth = useSetRecoilState(authState);
//   const { isAuthenticated, accessToken } = auth;

//   const [userSyllabus, setUserSyllabus] = useState([]);
//   const [search, setSearch] = useState('');
//   const [standardFilter, setStandardFilter] = useState('');
//   const [subjectFilter, setSubjectFilter] = useState('');
//   const [chapterNumberFilter, setChapterNumberFilter] = useState('');
//   const [page, setPage] = useState(1);

//   const itemsPerPage = 10;

//   const handleSearchChange = (event) => {
//     setSearch(event.target.value);
//   };

//   const handleStandardFilterChange = (event) => {
//     setStandardFilter(event.target.value);
//   };

//   const handleSubjectFilterChange = (event) => {
//     setSubjectFilter(event.target.value);
//   };

//   const handleChapterNumberFilterChange = (event) => {
//     setChapterNumberFilter(event.target.value);
//   };

//   const handlePageChange = (event, value) => {
//     setPage(value);
//   };

//   // Fetch user syllabus data on mount
//   useEffect(() => {
//     const fetchUserSyllabus = async () => {
//       if (!isAuthenticated || !accessToken) {
//         console.error('Authentication required or access token missing.');
//         return;
//       }

//       try {
//         const response = await instance.get('/api/auth/syllabus', {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         setUserSyllabus(response.data);
//       } catch (error) {
//         console.error('Error fetching user syllabus:', error);
//       }
//     };
//     fetchUserSyllabus();
//   }, [isAuthenticated, accessToken]);

//   // Handle click on "View full content" button
//   const handleViewFullContent = (syllabusItemId) => {
//     window.open(`/syllabus/${syllabusItemId}`, '_blank');
//   };

//   // Render user syllabus items using filterAndRenderSyllabus function
//   const renderedSyllabus = filterAndRenderSyllabus(
//     userSyllabus,
//     search,
//     standardFilter,
//     subjectFilter,
//     chapterNumberFilter,
//     handleViewFullContent
//   );

//   return (
//     <Container sx={{ padding: 0 }}>
//       <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
//         <Typography
//           variant="h5"
//           component="h3"
//           textAlign="center"
//           gutterBottom
//           mb={4}
//         >
//           Your Syllabus
//         </Typography>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Search"
//               variant="outlined"
//               fullWidth
//               value={search}
//               onChange={handleSearchChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <FormControl fullWidth variant="outlined">
//               <InputLabel id="standard-label">Standard</InputLabel>
//               <Select
//                 labelId="standard-label"
//                 id="standard-select"
//                 value={standardFilter}
//                 onChange={handleStandardFilterChange}
//                 label="Standard"
//               >
//                 <MenuItem value={'10'}>10</MenuItem>
//                 <MenuItem value={'6'}>6</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <FormControl fullWidth variant="outlined">
//               <InputLabel id="subject-label">Subject</InputLabel>
//               <Select
//                 labelId="subject-label"
//                 id="subject-select"
//                 value={subjectFilter}
//                 onChange={handleSubjectFilterChange}
//                 label="Subject"
//               >
//                 <MenuItem value={'Accounting'}>Accounting</MenuItem>
//                 <MenuItem value={'Advertising'}>Advertising</MenuItem>
//                 <MenuItem value={'Aerospace Engineering'}>
//                   Aerospace Engineering
//                 </MenuItem>
//                 <MenuItem value={'Agricultural Studies'}>
//                   Agricultural Studies
//                 </MenuItem>
//                 <MenuItem value={'Anatomy'}>Anatomy</MenuItem>
//                 <MenuItem value={'Anthropology'}>Anthropology</MenuItem>
//                 <MenuItem value={'Archaeology'}>Archaeology</MenuItem>
//                 <MenuItem value={'Astronomy'}>Astronomy</MenuItem>
//                 <MenuItem value={'Auditing'}>Auditing</MenuItem>
//                 <MenuItem value={'Biochemistry'}>Biochemistry</MenuItem>
//                 <MenuItem value={'Biology'}>Biology</MenuItem>
//                 <MenuItem value={'Broadcasting'}>Broadcasting</MenuItem>
//                 <MenuItem value={'Business Administration'}>
//                   Business Administration
//                 </MenuItem>
//                 <MenuItem value={'Business Law'}>Business Law</MenuItem>
//                 <MenuItem value={'Business Studies'}>Business Studies</MenuItem>
//                 <MenuItem value={'Chemical Engineering'}>
//                   Chemical Engineering
//                 </MenuItem>
//                 <MenuItem value={'Chemistry'}>Chemistry</MenuItem>
//                 <MenuItem value={'Civics'}>Civics</MenuItem>
//                 <MenuItem value={'Civil Engineering'}>
//                   Civil Engineering
//                 </MenuItem>
//                 <MenuItem value={'Computer Science'}>Computer Science</MenuItem>
//                 <MenuItem value={'Creative Writing'}>Creative Writing</MenuItem>
//                 <MenuItem value={'Cultural Studies'}>Cultural Studies</MenuItem>
//                 <MenuItem value={'Design and Technology'}>
//                   Design and Technology
//                 </MenuItem>
//                 <MenuItem value={'Digital Media'}>Digital Media</MenuItem>
//                 <MenuItem value={'Economics'}>Economics</MenuItem>
//                 <MenuItem value={'Electrical Engineering'}>
//                   Electrical Engineering
//                 </MenuItem>
//                 <MenuItem value={'Engineering'}>Engineering</MenuItem>
//                 <MenuItem value={'English Language'}>English Language</MenuItem>
//                 <MenuItem value={'English Literature'}>
//                   English Literature
//                 </MenuItem>
//                 <MenuItem value={'Entrepreneurship'}>Entrepreneurship</MenuItem>
//                 <MenuItem value={'Environmental Engineering'}>
//                   Environmental Engineering
//                 </MenuItem>
//                 <MenuItem value={'Environmental Studies'}>
//                   Environmental Studies
//                 </MenuItem>
//                 <MenuItem value={'Ethics'}>Ethics</MenuItem>
//                 <MenuItem value={'Film Studies'}>Film Studies</MenuItem>
//                 <MenuItem value={'Finance'}>Finance</MenuItem>
//                 <MenuItem value={'Forensic Science'}>Forensic Science</MenuItem>
//                 <MenuItem value={'Geography'}>Geography</MenuItem>
//                 <MenuItem value={'Geology'}>Geology</MenuItem>
//                 <MenuItem value={'Global Studies'}>Global Studies</MenuItem>
//                 <MenuItem value={'Health Education'}>Health Education</MenuItem>
//                 <MenuItem value={'History'}>History</MenuItem>
//                 <MenuItem value={'Home Economics'}>Home Economics</MenuItem>
//                 <MenuItem value={'Human Resource Management'}>
//                   Human Resource Management
//                 </MenuItem>
//                 <MenuItem value={'Journalism'}>Journalism</MenuItem>
//                 <MenuItem value={'Legal Studies'}>Legal Studies</MenuItem>
//                 <MenuItem value={'Linguistics'}>Linguistics</MenuItem>
//                 <MenuItem value={'Marine Biology'}>Marine Biology</MenuItem>
//                 <MenuItem value={'Marketing'}>Marketing</MenuItem>
//                 <MenuItem value={'Mass Communication'}>
//                   Mass Communication
//                 </MenuItem>
//                 <MenuItem value={'Mathematics I'}>Mathematics I</MenuItem>
//                 <MenuItem value={'Mathematics II'}>Mathematics II</MenuItem>
//                 <MenuItem value={'Mechanical Engineering'}>
//                   Mechanical Engineering
//                 </MenuItem>
//                 <MenuItem value={'Media Ethics'}>Media Ethics</MenuItem>
//                 <MenuItem value={'Media Production'}>Media Production</MenuItem>
//                 <MenuItem value={'Media Studies'}>Media Studies</MenuItem>
//                 <MenuItem value={'Medicine'}>Medicine</MenuItem>
//                 <MenuItem value={'Meteorology'}>Meteorology</MenuItem>
//                 <MenuItem value={'Microbiology'}>Microbiology</MenuItem>
//                 <MenuItem value={'Military Science'}>Military Science</MenuItem>
//                 <MenuItem value={'Nutrition and Food Science'}>
//                   Nutrition and Food Science
//                 </MenuItem>
//                 <MenuItem value={'Obstetrics and Gynecology'}>
//                   Obstetrics and Gynecology
//                 </MenuItem>
//                 <MenuItem value={'Pathology'}>Pathology</MenuItem>
//                 <MenuItem value={'Pediatrics'}>Pediatrics</MenuItem>
//                 <MenuItem value={'Personal Finance'}>Personal Finance</MenuItem>
//                 <MenuItem value={'Pharmacology'}>Pharmacology</MenuItem>
//                 <MenuItem value={'Philosophy'}>Philosophy</MenuItem>
//                 <MenuItem value={'Physics'}>Physics</MenuItem>
//                 <MenuItem value={'Physiology'}>Physiology</MenuItem>
//                 <MenuItem value={'Political Science'}>
//                   Political Science
//                 </MenuItem>
//                 <MenuItem value={'Psychology'}>Psychology</MenuItem>
//                 <MenuItem value={'Public Relations'}>Public Relations</MenuItem>
//                 <MenuItem value={'Religious Education'}>
//                   Religious Education
//                 </MenuItem>
//                 <MenuItem value={'Robotics'}>Robotics</MenuItem>
//                 <MenuItem value={'Science'}>Science</MenuItem>
//                 <MenuItem value={'Social Studies'}>Social Studies</MenuItem>
//                 <MenuItem value={'Sociology'}>Sociology</MenuItem>
//                 <MenuItem value={'Sports Science'}>Sports Science</MenuItem>
//                 <MenuItem value={'Surgery'}>Surgery</MenuItem>
//                 <MenuItem value={'Taxation'}>Taxation</MenuItem>
//                 <MenuItem value={'Technology'}>Technology</MenuItem>
//                 <MenuItem value={'Visual Communication'}>
//                   Visual Communication
//                 </MenuItem>
//                 <MenuItem value={'World Religions'}>World Religions</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <FormControl fullWidth variant="outlined">
//               <InputLabel id="chapter-number-label">Chapter Number</InputLabel>
//               <Select
//                 labelId="chapter-number-label"
//                 id="chapter-number-select"
//                 value={chapterNumberFilter}
//                 onChange={handleChapterNumberFilterChange}
//                 label="Chapter Number"
//               >
//                 <MenuItem value={'1'}>1</MenuItem>
//                 <MenuItem value={'2'}>2</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} justifyContent="center" mt={3}>
//           <Grid item xs={12}>
//             {renderedSyllabus.slice(
//               (page - 1) * itemsPerPage,
//               page * itemsPerPage
//             )}
//             <Box display="flex" justifyContent="center" mt={4}>
//               <Pagination
//                 count={Math.ceil(renderedSyllabus.length / itemsPerPage)}
//                 page={page}
//                 onChange={handlePageChange}
//                 color="primary"
//                 size="large"
//                 shape="rounded"
//               />
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </Container>
//   );
// };

// export default UserSyllabusScreen;

import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  Grid,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import filterAndRenderSyllabus from './filterAndRenderSyllabus';
import { authState } from '../../atoms';

const UserSyllabusScreen = (props) => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated, accessToken } = auth;

  const [userSyllabus, setUserSyllabus] = useState([]);
  const [search, setSearch] = useState('');
  const [standardFilter, setStandardFilter] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [chapterNumberFilter, setChapterNumberFilter] = useState('');
  const [page, setPage] = useState(1);
  const [standards, setStandards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapterNumbers, setChapterNumbers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const itemsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleStandardFilterChange = (event) => {
    setStandardFilter(event.target.value);
    // Filter subjects based on selected standard
    const filteredSubjects = userSyllabus
      .filter((item) => item.className === event.target.value)
      .map((item) => item.subject);
    setSubjects([...new Set(filteredSubjects)]);
  };

  const handleSubjectFilterChange = (event) => {
    setSubjectFilter(event.target.value);
    // Filter chapter numbers based on selected standard and subject
    const filteredChapterNumbers = userSyllabus
      .filter(
        (item) =>
          item.className === standardFilter &&
          item.subject === event.target.value
      )
      .map((item) => item.chapter);
    setChapterNumbers([...new Set(filteredChapterNumbers)]);
  };

  const handleChapterNumberFilterChange = (event) => {
    setChapterNumberFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemSelect = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleBulkDelete = async () => {
    if (!isAuthenticated || !accessToken) {
      console.error('Authentication required or access token missing.');
      return;
    }

    try {
      await instance.post(
        '/api/syllabus/bulk-delete-syllabus', // Update this URL
        {
          ids: selectedItems,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Refresh the syllabus after deletion
      const response = await instance.get('/api/syllabus/user-syllabus', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setUserSyllabus(response.data.entries);
      setSelectedItems([]);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error performing bulk delete:', error);
    }
  };

  // Fetch user syllabus data on mount
  useEffect(() => {
    const fetchUserSyllabus = async () => {
      if (!isAuthenticated || !accessToken) {
        console.error('Authentication required or access token missing.');
        return;
      }

      try {
        const response = await instance.get('/api/syllabus/user-syllabus', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log('Fetched syllabus entries:', response.data.entries);

        setUserSyllabus(response.data.entries);

        const uniqueStandards = [
          ...new Set(response.data.entries.map((item) => item.className)),
        ];
        setStandards(uniqueStandards);
      } catch (error) {
        console.error('Error fetching user syllabus:', error);
      }
    };

    fetchUserSyllabus();
  }, [isAuthenticated, accessToken]);

  const handleViewFullContent = (syllabusItemId) => {
    console.log('Attempting to view full content for ID:', syllabusItemId);
    if (syllabusItemId) {
      console.log('Opening URL:', `/syllabus/${syllabusItemId}`);
      window.open(`/syllabus/${syllabusItemId}`, '_blank');
    } else {
      console.error('Invalid syllabus item ID');
    }
  };

  const renderedSyllabus = filterAndRenderSyllabus(
    userSyllabus,
    search,
    standardFilter,
    subjectFilter,
    chapterNumberFilter,
    handleViewFullContent,
    handleItemSelect,
    selectedItems
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your Syllabus
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="standard-label">Standard</InputLabel>
              <Select
                labelId="standard-label"
                id="standard-select"
                value={standardFilter}
                onChange={handleStandardFilterChange}
                label="Standard"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {standards.map((standard, index) => (
                  <MenuItem key={index} value={standard}>
                    {standard}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="subject-label">Subject</InputLabel>
              <Select
                labelId="subject-label"
                id="subject-select"
                value={subjectFilter}
                onChange={handleSubjectFilterChange}
                label="Subject"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {subjects.map((subject, index) => (
                  <MenuItem key={index} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="chapter-number-label">Chapter Number</InputLabel>
              <Select
                labelId="chapter-number-label"
                id="chapter-number-select"
                value={chapterNumberFilter}
                onChange={handleChapterNumberFilterChange}
                label="Chapter Number"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {chapterNumbers.map((chapter, index) => (
                  <MenuItem key={index} value={chapter}>
                    {chapter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" mt={3}>
          <Grid item xs={12}>
            {selectedItems.length > 0 && (
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Selected ({selectedItems.length})
                </Button>
              </Box>
            )}
            {renderedSyllabus.slice(
              (page - 1) * itemsPerPage,
              page * itemsPerPage
            )}
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(renderedSyllabus.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                shape="rounded"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirm Bulk Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedItems.length} selected
            items? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleBulkDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserSyllabusScreen;
