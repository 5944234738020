import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import { authState } from '../../atoms';

const HistoryTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
  flexGrow: 1,
}));

const HistoryContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const HistoryDetailCardContent = styled(CardContent)(({ theme }) => ({
  p: 2,
  display: 'flex',
  flexDirection: 'column',
}));

const HistoryDetailBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CopyButton = styled(IconButton)(({ theme }) => ({}));

const HistoryDetailScreen = () => {
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;
  const [historyItem, setHistoryItem] = useState(null);
  const { id } = useParams();
  const [copied, setCopied] = useState(false);

  const fetchHistoryItem = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.get(`/api/auth/history/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      setHistoryItem(response.data);
    } catch (error) {
      console.error('Error fetching history item:', error);
    }
  }, [id, isAuthenticated]);

  useEffect(() => {
    fetchHistoryItem();
  }, [fetchHistoryItem]);

  const handleCopyContent = () => {
    if (historyItem && historyItem.content) {
      try {
        const textContent = historyItem.content
          .replace(/<br\/>/g, '\n')
          .replace(/<[^>]+>/g, '');
        navigator.clipboard.writeText('\n' + textContent + '\n');
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (error) {
        console.error('Error copying content:', error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      {historyItem ? (
        <HistoryDetailBox sx={{ p: 4 }}>
          <Card>
            <HistoryDetailCardContent>
              <Box display="flex" alignItems="center">
                <HistoryTitle variant="h6">
                  {new Date(historyItem.createdAt).toLocaleString()}
                </HistoryTitle>
                <CopyButton onClick={handleCopyContent} disableRipple>
                  {copied ? (
                    <>
                      <DoneIcon />
                      <Typography variant="body2" component="span">
                        Copied
                      </Typography>
                    </>
                  ) : (
                    <>
                      <DescriptionIcon />
                      <Typography variant="body2" component="span">
                        Copy text
                      </Typography>
                    </>
                  )}
                </CopyButton>
              </Box>
              <HistoryContent
                variant="body1"
                dangerouslySetInnerHTML={{ __html: historyItem.content }}
              />
            </HistoryDetailCardContent>
          </Card>
        </HistoryDetailBox>
      ) : (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Loading...
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default HistoryDetailScreen;
