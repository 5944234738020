import React from "react";
import { colors } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { alpha } from "@mui/material";

const revisionNotes = {
  title: "Revision Notes Generator",
  subtitle: "Generate concise revision notes from selected chapters.",
  targetRoute: "/revision-notes",
  color: colors.blue[700],
  bgcolor: alpha(colors.blue[700], 0.1),
  icon: <FormatListBulletedIcon fontSize="medium" />,
  tags: [],
};

export default revisionNotes;
