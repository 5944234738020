import createImageGeneratorTemplate from "../../ImageGeneratorTemplate";

const createMidjourneyImage = createImageGeneratorTemplate({
  name: "createmidjourneyimage",
  endpoint: "/api/imageGeneration/create-midjourney-image",
  aiText: "createImage",
  aiTextHandler: "createImageResponderHandler",
  aiTextPlainText: "createImageResponderPlainText",
  title: "Create Image",
  subText:
    "Easily generate custom images using advanced AI models. Select your preferred tool, customize options, and create stunning visuals in seconds.",
  label: "Generated Image",
  placeholderText: "Enter image description…",
  buttonText: "Create Image",
  aiPlaceholder: "Images will appear here",
});

export default createMidjourneyImage;
