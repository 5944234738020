import React from "react";
import { colors } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { alpha } from "@mui/material";

const simpleQuestionPaperMaker = {
  title: "Simple Question Paper Maker",
  subtitle: "Generate basic exams from chosen chapters.",
  targetRoute: "/simple-question-paper-maker",
  color: colors.green[800],
  bgcolor: alpha(colors.green[800], 0.1),
  icon: <ArticleIcon fontSize="medium" />,
  tags: [],
};

export default simpleQuestionPaperMaker;
