import React from "react";
import Box from "@mui/material/Box";

import Container from "../../../components/Container";
import { Gallery, Headline, Story } from "./components";

const AboutUs = () => {
  return (
    <Box>
      <Container>
        <Headline />
      </Container>
      <Container paddingY={"0 !important"}>
        <Gallery />
      </Container>

      <Container>
        <Story />
      </Container>
    </Box>
  );
};

export default AboutUs;
