import createTeachingTemplate from "../../TeachingTemplate";

const interactiveActivityDesigner = createTeachingTemplate({
  name: "interactiveactivitydesigner",
  endpoint: "/api/openai/interactive-activity-designer",
  aiText: "interactiveActivityDesigner",
  aiTextHandler: "interactiveActivityDesignerHandler",
  aiTextPlainText: "interactiveActivityDesignerPlainText",
  title: "Interactive Activity Designer",
  subText:
    'Spice up your classroom! Input the class, subject, and the chapters from your syllabus, and this tool will design hands-on activities to enhance learning. Make sure your syllabus is uploaded for activities tailored to your curriculum. Example: "Grade 11, Physics, Design an activity for 2-3 chapters."',
  label: "Engaging Activity",
  placeholderText: "Any instructions?",
  buttonText: "Design Activity",
  aiPlaceholder: "Engaging activity will appear here",
});

export default interactiveActivityDesigner;
