import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriter = createLanguageKeywordTopicTemplate({
  name: "blogwriter",
  endpoint: "/api/openai/blog-writer",
  aiText: "blogWriter",
  aiTextHandler: "blogWriterHandler",
  aiTextPlainText: "blogWriterPlainText",
  title: "Blog Writer",
  subText:
    "Transform your ideas into powerful blog posts using our AI Blog Writer. Enter your topic, main keyword (maximum 10 words), and the output language, and watch as our tool crafts a unique, attention-grabbing blog post. From personal blogs to professional websites, our tool is designed to cater to a wide array of writing needs.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriter;
