import createSummaryNewsTemplate from "../../SummaryNewsTemplate";

const newsSummaryTool = createSummaryNewsTemplate({
  name: "newssummarytool",
  endpoint: "/api/openai/news-summary-tool",
  aiText: "newsSummaryTool",
  aiTextHandler: "newsSummaryToolHandler",
  aiTextPlainText: "newsSummaryToolPlainText",
  title: "News Summary Tool",
  subText:
    "Create brief, engaging summaries of longer news articles or segments with our News Summary Tool. Simply provide your topic, word count, and news information, and it will generate summaries fit for any news genre.",
  label: "News Summary",
  placeholderText: "Enter News Topic to Summarize Here",
  buttonText: "Generate Summary",
  aiPlaceholder: "News summary will appear here",
});

export default newsSummaryTool;
