import React from "react";
import { colors } from "@mui/material";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import { alpha } from "@mui/material";

const transcriptionFromSpeech = {
  title: "Transcription From Speech",
  subtitle: "Transcribe any speech into clear, multilingual text.",
  targetRoute: "/transcription-from-speech",
  color: colors.purple[500],
  bgcolor: alpha(colors.purple[500], 0.1),
  icon: <VoiceChatIcon fontSize="medium" />,
  tags: [],
};

export default transcriptionFromSpeech;
