// import React from 'react';
// import {
//   Box,
//   TextField,
//   Button,
//   CircularProgress,
//   Alert,
//   Grid,
//   MenuItem,
//   Typography,
// } from '@mui/material';

// const SlideContentRenderer = ({
//   slide,
//   index,
//   fullContent,
//   setFullContent,
//   unsplashQuery,
//   setUnsplashQuery,
//   handleUnsplashSearch,
//   handleImageUpload,
//   handleImageSelect,
//   isSearching,
//   error,
//   unsplashResults,
// }) => {
//   switch (slide.layout) {
//     case 'title':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Title"
//             value={slide.content.title || ''}
//             onChange={(e) => {
//               const words = e.target.value.split(' ');
//               const limitedWords = words.slice(0, 10).join(' ');
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.title = limitedWords;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             helperText={`${slide.content.title?.split(' ').length || 0}/10 words`}
//           />
//           <TextField
//             fullWidth
//             label="Subtitle"
//             value={slide.content.subtitle || ''}
//             onChange={(e) => {
//               const words = e.target.value.split(' ');
//               const limitedWords = words.slice(0, 15).join(' ');
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.subtitle = limitedWords;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             helperText={`${slide.content.subtitle?.split(' ').length || 0}/15 words`}
//           />
//           <input
//             accept="image/*"
//             style={{ display: 'none' }}
//             id={`raised-button-file-${index}`}
//             type="file"
//             onChange={(e) => handleImageUpload(e, index)}
//           />
//           <label htmlFor={`raised-button-file-${index}`}>
//             <Button variant="contained" component="span">
//               Upload Title Slide Image
//             </Button>
//           </label>
//           {slide.content.image_url && (
//             <img
//               src={slide.content.image_url}
//               alt="Title slide preview"
//               style={{
//                 maxWidth: '200px',
//                 maxHeight: '200px',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//           )}
//         </Box>
//       );
//     case 'bullet_points':
//       return (
//         <TextField
//           key={index}
//           fullWidth
//           label="Bullet Points"
//           value={slide.content.points ? slide.content.points.join('\n') : ''}
//           onChange={(e) => {
//             const newContent = { ...fullContent };
//             newContent.slides[index].content.points =
//               e.target.value.split('\n');
//             setFullContent(newContent);
//           }}
//           margin="normal"
//           multiline
//           rows={4}
//         />
//       );
//     case 'image_and_text':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Text Content"
//             value={slide.content.text || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.text = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             multiline
//             rows={4}
//           />
//           {slide.content.image_url && (
//             <img
//               src={slide.content.image_url}
//               alt="Slide preview"
//               style={{
//                 maxWidth: '200px',
//                 maxHeight: '200px',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//           )}
//           <TextField
//             fullWidth
//             label="Unsplash Search"
//             value={unsplashQuery}
//             onChange={(e) => setUnsplashQuery(e.target.value)}
//             margin="normal"
//           />
//           <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
//             <Button
//               variant="contained"
//               onClick={() => handleUnsplashSearch(index)}
//               disabled={isSearching}
//             >
//               {isSearching ? <CircularProgress size={24} /> : 'Search Unsplash'}
//             </Button>
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id={`raised-button-file-${index}`}
//               type="file"
//               onChange={(e) => handleImageUpload(e, index)}
//             />
//             <label htmlFor={`raised-button-file-${index}`}>
//               <Button variant="contained" component="span">
//                 Upload Image
//               </Button>
//             </label>
//           </Box>
//           {error && (
//             <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
//               {error}
//             </Alert>
//           )}
//           <Grid container spacing={2} style={{ marginTop: '10px' }}>
//             {unsplashResults.map((image) => (
//               <Grid item xs={3} key={image.id}>
//                 <img
//                   src={image.thumb || ''}
//                   alt={image.description || 'Unsplash image'}
//                   style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
//                   onClick={() => handleImageSelect(image.url, index)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       );
//     case 'chart':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Chart Title"
//             value={slide.content.title || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.title = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             select
//             label="Chart Type"
//             value={slide.content.chart_type || 'bar'}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.chart_type = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           >
//             <MenuItem value="bar">Bar</MenuItem>
//             <MenuItem value="line">Line</MenuItem>
//             <MenuItem value="pie">Pie</MenuItem>
//           </TextField>
//           {/* Add more fields for chart data input */}
//         </Box>
//       );
//     case 'two_column':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Left Column"
//             value={slide.content.left_column || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.left_column = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             multiline
//             rows={4}
//           />
//           <TextField
//             fullWidth
//             label="Right Column"
//             value={slide.content.right_column || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.right_column = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             multiline
//             rows={4}
//           />
//         </Box>
//       );

//     case 'agenda':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Title"
//             value={slide.content.title || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.title = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             label="Bullet Points"
//             value={
//               slide.content.bullet_points
//                 ? slide.content.bullet_points.join('\n')
//                 : ''
//             }
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.bullet_points =
//                 e.target.value.split('\n');
//               setFullContent(newContent);
//             }}
//             margin="normal"
//             multiline
//             rows={4}
//           />
//           {slide.content.image_url && (
//             <img
//               src={slide.content.image_url}
//               alt="Slide preview"
//               style={{
//                 maxWidth: '200px',
//                 maxHeight: '200px',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//           )}
//           <TextField
//             fullWidth
//             label="Unsplash Search"
//             value={unsplashQuery}
//             onChange={(e) => setUnsplashQuery(e.target.value)}
//             margin="normal"
//           />
//           <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
//             <Button
//               variant="contained"
//               onClick={() => handleUnsplashSearch(index)}
//               disabled={isSearching}
//             >
//               {isSearching ? <CircularProgress size={24} /> : 'Search Unsplash'}
//             </Button>
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id={`raised-button-file-${index}`}
//               type="file"
//               onChange={(e) => handleImageUpload(e, index)}
//             />
//             <label htmlFor={`raised-button-file-${index}`}>
//               <Button variant="contained" component="span">
//                 Upload Image
//               </Button>
//             </label>
//           </Box>
//           {error && (
//             <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
//               {error}
//             </Alert>
//           )}
//           <Grid container spacing={2} style={{ marginTop: '10px' }}>
//             {unsplashResults.map((image) => (
//               <Grid item xs={3} key={image.id}>
//                 <img
//                   src={image.thumb || ''}
//                   alt={image.description || 'Unsplash image'}
//                   style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
//                   onClick={() => handleImageSelect(image.url, index)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       );
//     case 'section_header':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Section Title"
//             value={slide.content.title || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.title = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           />
//           {slide.content.image_url && (
//             <img
//               src={slide.content.image_url}
//               alt="Slide preview"
//               style={{
//                 maxWidth: '200px',
//                 maxHeight: '200px',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//           )}
//           <TextField
//             fullWidth
//             label="Unsplash Search"
//             value={unsplashQuery}
//             onChange={(e) => setUnsplashQuery(e.target.value)}
//             margin="normal"
//           />
//           <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
//             <Button
//               variant="contained"
//               onClick={() => handleUnsplashSearch(index)}
//               disabled={isSearching}
//             >
//               {isSearching ? <CircularProgress size={24} /> : 'Search Unsplash'}
//             </Button>
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id={`raised-button-file-${index}`}
//               type="file"
//               onChange={(e) => handleImageUpload(e, index)}
//             />
//             <label htmlFor={`raised-button-file-${index}`}>
//               <Button variant="contained" component="span">
//                 Upload Image
//               </Button>
//             </label>
//           </Box>
//           {error && (
//             <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
//               {error}
//             </Alert>
//           )}
//           <Grid container spacing={2} style={{ marginTop: '10px' }}>
//             {unsplashResults.map((image) => (
//               <Grid item xs={3} key={image.id}>
//                 <img
//                   src={image.thumb || ''}
//                   alt={image.description || 'Unsplash image'}
//                   style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
//                   onClick={() => handleImageSelect(image.url, index)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       );
//     case 'section_header_subtitle_image':
//       return (
//         <Box key={index}>
//           <TextField
//             fullWidth
//             label="Section Title"
//             value={slide.content.title || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.title = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             label="Subtitle"
//             value={slide.content.subtitle || ''}
//             onChange={(e) => {
//               const newContent = { ...fullContent };
//               newContent.slides[index].content.subtitle = e.target.value;
//               setFullContent(newContent);
//             }}
//             margin="normal"
//           />
//           {slide.content.image_url && (
//             <img
//               src={slide.content.image_url}
//               alt="Slide preview"
//               style={{
//                 maxWidth: '200px',
//                 maxHeight: '200px',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//           )}
//           <TextField
//             fullWidth
//             label="Unsplash Search"
//             value={unsplashQuery}
//             onChange={(e) => setUnsplashQuery(e.target.value)}
//             margin="normal"
//           />
//           <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
//             <Button
//               variant="contained"
//               onClick={() => handleUnsplashSearch(index)}
//               disabled={isSearching}
//             >
//               {isSearching ? <CircularProgress size={24} /> : 'Search Unsplash'}
//             </Button>
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id={`raised-button-file-${index}`}
//               type="file"
//               onChange={(e) => handleImageUpload(e, index)}
//             />
//             <label htmlFor={`raised-button-file-${index}`}>
//               <Button variant="contained" component="span">
//                 Upload Image
//               </Button>
//             </label>
//           </Box>
//           {error && (
//             <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
//               {error}
//             </Alert>
//           )}
//           <Grid container spacing={2} style={{ marginTop: '10px' }}>
//             {unsplashResults.map((image) => (
//               <Grid item xs={3} key={image.id}>
//                 <img
//                   src={image.thumb || ''}
//                   alt={image.description || 'Unsplash image'}
//                   style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
//                   onClick={() => handleImageSelect(image.url, index)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       );
//     // ... (other existing cases)
//     default:
//       return (
//         <Typography key={index} color="error">
//           Unsupported slide type: {slide.layout}
//         </Typography>
//       );
//   }
// };

// export default SlideContentRenderer;

import React from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';

const SlideContentRenderer = ({
  slide,
  index,
  fullContent,
  setFullContent,
  unsplashQuery,
  setUnsplashQuery,
  handleUnsplashSearch,
  handleImageUpload,
  handleImageSelect,
  isSearching,
  error,
  unsplashResults,
}) => {
  if (!slide || !slide.content) {
    return <Typography>No content available for this slide.</Typography>;
  }

  const updateSlideContent = (field, value) => {
    const newContent = { ...fullContent };
    newContent.slides[index].content[field] = value;
    setFullContent(newContent);
  };

  const renderTextField = (
    label,
    field,
    multiline = false,
    rows = 1,
    helperText = ''
  ) => (
    <TextField
      fullWidth
      label={label}
      value={slide.content[field] || ''}
      onChange={(e) => {
        let value = e.target.value;
        if (field === 'title') {
          value = value.split(' ').slice(0, 10).join(' ');
        } else if (field === 'subtitle') {
          value = value.split(' ').slice(0, 15).join(' ');
        }
        updateSlideContent(field, value);
      }}
      margin="normal"
      multiline={multiline}
      rows={rows}
      helperText={helperText}
    />
  );

  const renderImageUpload = () => (
    <Box>
      {slide.content.image_url && (
        <img
          src={slide.content.image_url}
          alt="Slide preview"
          style={{
            maxWidth: '200px',
            maxHeight: '200px',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
      )}
      <TextField
        fullWidth
        label="Unsplash Search"
        value={unsplashQuery}
        onChange={(e) => setUnsplashQuery(e.target.value)}
        margin="normal"
      />
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button
          variant="contained"
          onClick={() => handleUnsplashSearch(index)}
          disabled={isSearching}
        >
          {isSearching ? <CircularProgress size={24} /> : 'Search Unsplash'}
        </Button>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id={`raised-button-file-${index}`}
          type="file"
          onChange={(e) => handleImageUpload(e, index)}
        />
        <label htmlFor={`raised-button-file-${index}`}>
          <Button variant="contained" component="span">
            Upload Image
          </Button>
        </label>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        {unsplashResults && unsplashResults.length > 0 ? (
          unsplashResults.map((image) => (
            <Grid item xs={3} key={image.id}>
              <img
                src={image.thumb || ''}
                alt={image.description || 'Unsplash image'}
                style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                onClick={() => handleImageSelect(image.url, index)}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>
              No images found. Try a different search term.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  switch (slide.layout) {
    case 'title':
      return (
        <Box>
          {renderTextField(
            'Title',
            'title',
            false,
            1,
            `${slide.content.title?.split(' ').length || 0}/10 words`
          )}
          {renderTextField(
            'Subtitle',
            'subtitle',
            false,
            1,
            `${slide.content.subtitle?.split(' ').length || 0}/15 words`
          )}
          {renderImageUpload()}
        </Box>
      );
    case 'bullet_points':
      return renderTextField('Bullet Points', 'bullet_points', true, 4);
    case 'image_and_text':
      return (
        <Box>
          {renderTextField('Text Content', 'main_text', true, 4)}
          {renderImageUpload()}
        </Box>
      );
    case 'chart':
      return (
        <Box>
          {renderTextField('Chart Title', 'title')}
          <TextField
            fullWidth
            select
            label="Chart Type"
            value={slide.content.chart_type || 'bar'}
            onChange={(e) => updateSlideContent('chart_type', e.target.value)}
            margin="normal"
          >
            <MenuItem value="bar">Bar</MenuItem>
            <MenuItem value="line">Line</MenuItem>
            <MenuItem value="pie">Pie</MenuItem>
          </TextField>
        </Box>
      );
    case 'two_column':
      return (
        <Box>
          {renderTextField('Left Column', 'left_column', true, 4)}
          {renderTextField('Right Column', 'right_column', true, 4)}
        </Box>
      );
    case 'agenda':
      return (
        <Box>
          {renderTextField('Title', 'title')}
          {renderTextField('Bullet Points', 'bullet_points', true, 4)}
          {renderImageUpload()}
        </Box>
      );
    case 'section_header':
      return (
        <Box>
          {renderTextField('Section Title', 'title')}
          {renderImageUpload()}
        </Box>
      );
    case 'section_header_subtitle_image':
      return (
        <Box>
          {renderTextField('Section Title', 'title')}
          {renderTextField('Subtitle', 'subtitle')}
          {renderImageUpload()}
        </Box>
      );
    default:
      return (
        <Typography color="error">
          Unsupported slide type: {slide.layout}
        </Typography>
      );
  }
};

export default SlideContentRenderer;
