import React from "react";
import { colors } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { alpha } from "@mui/material";

const blogContentPlanner = {
  title: "Blog Content Planner",
  subtitle:
    "Create SEO-tailored, clickbait-style blog content calendars for effective keyword ranking.",
  targetRoute: "/blog-content-planner",
  color: colors.blue[800],
  bgcolor: alpha(colors.blue[800], 0.1),
  icon: <ListAltIcon fontSize="medium" />,
  tags: [],
};

export default blogContentPlanner;
