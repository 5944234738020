// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Grid,
//   Typography,
//   useTheme,
//   useMediaQuery,
//   TextField,
//   Button,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
// import { authState } from '../../atoms';
// import axiosInstance from '../../helpers/axiosInstance';
// import { getAuth } from 'firebase/auth';
// import emailVerificationImage from '../../assets/images/emailVerification.svg';

// const EmailVerification = () => {
//   const [verificationCode, setVerificationCode] = useState('');
//   const [message, setMessage] = useState('');
//   const [isError, setIsError] = useState(false);
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'));
//   const auth = useRecoilValue(authState);
//   const setAuthState = useSetRecoilState(authState);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!auth.user) {
//       navigate('/login', { replace: true });
//     } else if (auth.user.emailVerified) {
//       navigate('/dashboard', { replace: true });
//     }
//   }, [auth, navigate]);

//   const handleVerify = async () => {
//     try {
//       const response = await axiosInstance.post('/api/auth/verify-email', {
//         verificationCode,
//       });
//       setMessage(response.data.message);
//       setIsError(false);

//       // Refresh the Firebase user
//       const firebaseAuth = getAuth();
//       await firebaseAuth.currentUser.reload();

//       // Update Recoil state
//       setAuthState((prevState) => ({
//         ...prevState,
//         user: {
//           ...prevState.user,
//           emailVerified: true,
//         },
//         isAuthenticated: true,
//       }));

//       // Navigate after a short delay
//       setTimeout(() => navigate('/dashboard', { replace: true }), 2000);
//     } catch (error) {
//       console.error('Verification error:', error);
//       setMessage(error.response?.data?.error || 'Verification failed');
//       setIsError(true);
//     }
//   };

//   return (
//     <Box
//       px={1}
//       width="100%"
//       height="100vh"
//       mx="auto"
//       position="relative"
//       zIndex={2}
//     >
//       <Grid
//         container
//         spacing={1}
//         justifyContent="center"
//         alignItems="center"
//         height="100%"
//       >
//         {isMd && (
//           <Grid item container justifyContent={'center'} xs={12} md={6}>
//             <Box height={1} width={1} maxWidth={500}>
//               <Box
//                 component={'img'}
//                 src={emailVerificationImage}
//                 width={1}
//                 height={1}
//                 sx={{
//                   filter:
//                     theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
//                 }}
//               />
//             </Box>
//           </Grid>
//         )}
//         <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
//           <Box mt={3} mb={1} textAlign="center">
//             <Typography variant="h4" color="text.primary">
//               Email Verification
//             </Typography>
//             <Typography variant="body1" color="text.secondary">
//               <br />
//               Welcome to Wisdom Scribe! Before you can continue, please verify
//               your email address. We've sent a verification code to your email.
//               Enter this code below to complete your sign-up process and grant
//               you access to all features.
//               <br />
//               <br />
//               If you do not see the email, be sure to check your
//               <span
//                 style={{
//                   fontWeight: 'bold',
//                   color: theme.palette.primary.main,
//                 }}
//               >
//                 {' '}
//                 spam folder{' '}
//               </span>
//               as well. If you encounter any issues, please contact us by
//               clicking the chatbot for assistance. We're excited to have you on
//               board!
//             </Typography>
//             <TextField
//               label="Verification Code"
//               value={verificationCode}
//               onChange={(e) => setVerificationCode(e.target.value)}
//               fullWidth
//               margin="normal"
//             />
//             <Button variant="contained" color="primary" onClick={handleVerify}>
//               Verify
//             </Button>
//             {message && (
//               <Typography variant="body2" color={isError ? 'error' : 'success'}>
//                 {message}
//               </Typography>
//             )}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default EmailVerification;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  CircularProgress,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState, currentDashboardState } from '../../atoms';
import axiosInstance from '../../helpers/axiosInstance';
import { getAuth } from 'firebase/auth';
import emailVerificationImage from '../../assets/images/emailVerification.svg';

const EmailVerification = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const auth = useRecoilValue(authState);
  const setAuthState = useSetRecoilState(authState);
  const navigate = useNavigate();
  const setCurrentDashboard = useSetRecoilState(currentDashboardState);

  useEffect(() => {
    if (!auth.user) {
      navigate('/login', { replace: true });
    } else if (auth.user.emailVerified) {
      navigate('/dashboard', { replace: true });
    }
  }, [auth, navigate]);

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  const getRoleDashboard = (role) => {
    switch (role) {
      case 'journalist':
        return '/my-journalist-dashboard';
      case 'educator':
        return '/my-teaching-dashboard';
      case 'business':
        return '/my-business-dashboard';
      case 'digital-marketer':
        return '/my-digital-marketing-dashboard';
      case 'writer':
        return '/my-writing-dashboard';
      case 'user':
      default:
        return '/dashboard';
    }
  };

  useEffect(() => {
    if (!auth.user) {
      navigate('/login', { replace: true });
    } else if (auth.user.emailVerified) {
      const userRole = localStorage.getItem('userRole');
      const dashboardRoute = getRoleDashboard(userRole);
      navigate(dashboardRoute, { replace: true });
    }
  }, [auth, navigate]);

  const handleVerify = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/api/auth/verify-email', {
        verificationCode,
      });
      setMessage(response.data.message);
      setIsError(false);

      // Refresh the Firebase user
      const firebaseAuth = getAuth();
      await firebaseAuth.currentUser.reload();

      // Get user role and determine the appropriate dashboard
      const userRole = localStorage.getItem('userRole');
      const dashboardRoute = getRoleDashboard(userRole);

      // Update Recoil state
      setAuthState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          emailVerified: true,
        },
        isAuthenticated: true,
      }));

      // Set the current dashboard state
      setCurrentDashboard(dashboardRoute);
      localStorage.setItem('currentDashboard', dashboardRoute);

      // Navigate after a short delay
      setTimeout(() => navigate(dashboardRoute, { replace: true }), 2000);
    } catch (error) {
      console.error('Verification error:', error);
      setMessage(error.response?.data?.error || 'Verification failed');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResendDisabled(true);
    setResendCountdown(60);
    try {
      const response = await axiosInstance.post(
        '/api/auth/resend-verification-email',
        {
          email: auth.user.email, // Send the user's email
        }
      );
      setMessage(response.data.message);
      setIsError(false);
    } catch (error) {
      console.error('Resend verification error:', error);
      setMessage(
        error.response?.data?.error || 'Failed to resend verification code'
      );
      setIsError(true);
    }
  };

  const openIntercomHelp = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd && (
          <Grid item container justifyContent={'center'} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={'img'}
                src={emailVerificationImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary">
              Email Verification
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <br />
              Welcome to Wisdom Scribe! Before you can continue, please verify
              your email address. We've sent a verification code to your email.
              Enter this code below to complete your sign-up process and grant
              you access to all features.
              <br />
              <br />
              If you do not see the email, be sure to check your
              <span
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }}
              >
                {' '}
                spam folder{' '}
              </span>
              as well.
            </Typography>
            <TextField
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerify}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Verifying...' : 'Verify'}
            </Button>
            {message && (
              <Typography variant="body2" color={isError ? 'error' : 'success'}>
                {message}
              </Typography>
            )}
            <Box mt={2}>
              <Button
                variant="text"
                onClick={handleResendCode}
                disabled={resendDisabled}
              >
                {resendDisabled
                  ? `Resend Code (${resendCountdown}s)`
                  : 'Resend Verification Code'}
              </Button>
            </Box>
            <Box mt={2}>
              <Link
                component="button"
                variant="body2"
                onClick={openIntercomHelp}
                color="primary"
              >
                Not receiving your verification code? Get help
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailVerification;
