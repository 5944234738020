import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { authState } from '../atoms'; // Adjust the path according to your project structure

const initializeAuthState = () => {
  const savedAuthState = localStorage.getItem('authState');
  if (savedAuthState) {
    return JSON.parse(savedAuthState);
  }
  return {
    user: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    tokenExpiry: null,
  };
};

const useInitializeAuth = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedAuthState = initializeAuthState();
    setAuth(savedAuthState);
    setLoading(false); // Set loading to false after initializing auth state
  }, [setAuth]);

  return loading;
};

export default useInitializeAuth;
