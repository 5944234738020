import createFaqToneKnowledgeInfoNoKeywordTemplate from "../../FaqToneKnowledgeInfoNoKeywordTemplate";

const faqCreator = createFaqToneKnowledgeInfoNoKeywordTemplate({
  name: "faqcreator",
  endpoint: "/api/openai/faq-creator",
  aiText: "faqCreator",
  aiTextHandler: "faqCreatorHandler",
  aiTextPlainText: "faqCreatorPlainText",
  title: "FAQ Creator",
  subText:
    "Create comprehensive FAQs with our tool. Input your product or service details, and it will generate a list of potential questions and answers, boosting customer understanding and reducing support inquiries.",
  label: "FAQ",
  placeholderText: "Enter your product or service details…",
  infoText: "Input any additional information (optional)",
  buttonText: "Create FAQ",
  aiPlaceholder: "Your FAQ list will appear here",
});

export default faqCreator;
