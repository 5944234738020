import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const RegisterDialogComponent = ({ open, handleClose, navigate }) => {
  const theme = useTheme();

  const handleLogin = () => {
    handleClose(); // Close the dialog
    navigate("/login"); // Navigate to the /login route
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Registration Required
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Please register to continue</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary" variant="outlined">
            Login
          </Button>
          <Button
            onClick={() => navigate("/register")}
            color="primary"
            variant="contained"
          >
            Register
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default RegisterDialogComponent;
