// client/src/components/screens/KnowledgeBaseUpload.js

import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { authState } from '../../atoms';
import axiosInstance from '../../helpers/axiosInstance';

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function KnowledgeBaseUpload() {
  const [text, setText] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [itemName, setItemName] = useState('');
  const [files, setFiles] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const companyNameError =
    companyName &&
    (countWords(companyName) < 1 || countWords(companyName) > 15);
  const itemNameError =
    itemName && (countWords(itemName) < 1 || countWords(itemName) > 15);
  const textError = text && (countWords(text) < 10 || countWords(text) > 20000);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(
      (file) =>
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
    );
    setFiles(validFiles);
  };

  const uploadKnowledgeBase = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadSuccess(false);
    setUploadError('');

    const formData = new FormData();
    formData.append('company_name', companyName);
    formData.append('item_name', itemName);
    formData.append('is_public', isPublic);

    if (text) {
      formData.append('text', text);
    }

    if (files.length > 0) {
      files.forEach((file, index) => {
        console.log(`Appending file ${index}:`, file.name);
        formData.append('files', file);
      });
    } else {
      console.log('No files to upload');
    }

    try {
      console.log('Sending request to:', '/api/knowledgebase/upload-knowledge');

      const response = await axiosInstance.post(
        '/api/knowledgebase/upload-knowledge',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUploadSuccess(true);
      console.log('Upload successful:', response.data);
    } catch (error) {
      console.error('Upload failed:', error);
      console.error('Error response:', error.response?.data);
      setUploadError(
        error.response?.data?.error ||
          'Failed to upload data. Please try again.'
      );
    } finally {
      setIsUploading(false);
      setText('');
      setCompanyName('');
      setItemName('');
      setFiles([]);
      setIsPublic(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          margin: { xs: '2rem', sm: '8rem' },
          mt: 12,
          maxWidth: 800,
          mx: 'auto',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
          Authentication Required
        </Typography>
        <Typography color="text.secondary">
          Please log in to upload your Knowledge Base.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={uploadKnowledgeBase}
      sx={{
        margin: { xs: '2rem', sm: '8rem' },
        mt: 12,
        maxWidth: 800,
        mx: 'auto',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        Upload your Knowledge Base
      </Typography>
      <Typography color="text.secondary">
        Fill out the form and press "Upload".
      </Typography>

      {uploadSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Upload successful!
        </Alert>
      )}
      {uploadError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {uploadError}
        </Alert>
      )}
      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            error={companyNameError}
            helperText={
              companyNameError ? 'Company Name must be 1 to 15 words' : ''
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            error={itemNameError}
            helperText={itemNameError ? 'Item Name must be 1 to 15 words' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            label="Input your text here"
            value={text}
            onChange={(e) => setText(e.target.value)}
            error={textError}
            helperText={textError ? 'Text must be 10 to 20000 words' : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <input
            accept=".pdf,.png,.jpg,.jpeg"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span">
              Upload PDF or Images
            </Button>
          </label>
          {files.length > 0 && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {files.length} file(s) selected
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                name="isPublic"
              />
            }
            label="Make public"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={
              isUploading ||
              (textError && files.length === 0) ||
              companyNameError ||
              itemNameError ||
              (!text && files.length === 0) ||
              !companyName ||
              !itemName
            }
            sx={{ height: '56px' }}
          >
            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default KnowledgeBaseUpload;
