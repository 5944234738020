import createQuestionPaperGeneratorTemplate from "../../QuestionPaperGeneratorTemplate";

const simpleQuestionPaperMaker = createQuestionPaperGeneratorTemplate({
  name: "simplequestionpapermaker",
  endpoint: "/api/openai/simple-question-paper-maker",
  aiText: "simpleQuestionPaperMaker",
  aiTextHandler: "simpleQuestionPaperMakerHandler",
  aiTextPlainText: "simpleQuestionPaperMakerPlainText",
  title: "Simple Question Paper Maker",
  subText:
    "Make a simple question paper with this tool. Input your class (standard), subject, and chapter numbers. This tool will generate a simple question paper for you based on the content you provided in your syllabus.",
  label: "Question Paper",
  placeholderText: "Do you have any specific topics in mind?",
  buttonText: "Compile Question Paper",
  aiPlaceholder: "Question Paper will appear here",
});

export default simpleQuestionPaperMaker;
