import createNewsHeadlineTemplate from "../../NewsHeadlineTemplate";

const headlineGeneratorBroadcast = createNewsHeadlineTemplate({
  name: "headlinegeneratorbroadcast",
  endpoint: "/api/openai/headline-generator-broadcast",
  aiText: "headlineGeneratorBroadcast",
  aiTextHandler: "headlineGeneratorBroadcastHandler",
  aiTextPlainText: "headlineGeneratorBroadcastPlainText",
  title: "Headline Generator - Broadcast",
  subText:
    "Need compelling headlines for your channel? With our Broadcast Headline Generator, enter your topic, word count, and news information and see it churn out attention-grabbing headlines, ideal for any news genre.",
  label: "Headlines",
  placeholderText: "Enter Main News Here",
  buttonText: "Generate Headline",
  aiPlaceholder: "Headline will appear here",
});

export default headlineGeneratorBroadcast;
