import React from "react";
import { colors } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { alpha } from "@mui/material";

const headlineIdeasWriter = {
  title: "Headline Ideas Writer",
  subtitle:
    "Generate attention-grabbing, keyword-rich headlines that compel clicks and drive engagement.",
  targetRoute: "/headline-ideas-writer",
  color: colors.red[300],
  bgcolor: alpha(colors.red[300], 0.1),
  icon: <TableRowsIcon fontSize="medium" />,
  tags: [],
};

export default headlineIdeasWriter;
