import createQuestionPaperGeneratorTemplate from "../../QuestionPaperGeneratorTemplate";

const imageToBook = createQuestionPaperGeneratorTemplate({
  name: "imagetobook",
  endpoint: "/api/openai/image-to-book",
  aiText: "imageToBook",
  aiTextHandler: "imageToBookHandler",
  aiTextPlainText: "imageToBookPlainText",
  title: "Image to Book Converter",
  subText: "Convert a book into simpler language.",
  label: "Simplified Book",
  placeholderText: "Enter Any Other Instructions Here",
  buttonText: "Write Book",
  aiPlaceholder: "Book will appear here",
});

export default imageToBook;
