import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../../Container';
import { authState } from '../../../../atoms';

import {
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  outlineBasedBlogWriter,
  introductionsWriter,
} from '../../cards/ContentWriters';

const features = [
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  blogOutlineBuilder,
  outlineBasedBlogWriter,
  introductionsWriter,
];

const BlogWritingTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);

  const handleToolClick = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
        minHeight: '100vh', // Ensure full viewport height
      }}
    >
      <Container sx={{ py: 10, px: { xs: 2, sm: 4, md: 6 } }}>
        <Typography variant="h5" gutterBottom fontWeight="500" mb={4}>
          Blog Writing Tools
        </Typography>
        <Grid container spacing={4} justifyContent="left">
          {features.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard item={item} onClick={handleToolClick} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default BlogWritingTools;
