import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriterStorytelling = createLanguageKeywordTopicTemplate({
  name: "blogwriterstorytelling",
  endpoint: "/api/openai/blog-writer-storytelling",
  aiText: "blogWriterStorytelling",
  aiTextHandler: "blogWriterStorytellingHandler",
  aiTextPlainText: "blogWriterStorytellingHandlerPlainText",
  title: "Storytelling Blog",
  subText:
    "Whether you're a travel blogger sharing adventures or a lifestyle coach sharing personal experiences, Storytelling Blog Writer will help you to tell your story in the most engaging way possible. Input a brief like 'Climbing Mount Everest' and let the tool weave a captivating narrative.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterStorytelling;
