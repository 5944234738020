import React from "react";
import { colors } from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { alpha } from "@mui/material";

const faqCreator = {
  title: "FAQ Creator",
  subtitle:
    "Develop comprehensive, user-friendly FAQ sections to improve customer experience and provide valuable support.",
  targetRoute: "/faq-creator",
  color: colors.cyan["A700"],
  bgcolor: alpha(colors.cyan["A700"], 0.1),
  icon: <LiveHelpIcon fontSize="medium" />,
  tags: [],
};

export default faqCreator;
