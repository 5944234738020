import createPressReleaseNewsTemplate from "../../PressReleaseNewsTemplate";

const pressReleaseRewriterDigital = createPressReleaseNewsTemplate({
  name: "pressreleaserewriterdigital",
  endpoint: "/api/openai/press-release-rewriter-digital",
  aiText: "pressReleaseRewriterDigital",
  aiTextHandler: "pressReleaseRewriterDigitalHandler",
  aiTextPlainText: "pressReleaseRewriterDigitalPlainText",
  title: "Press Release Rewriter - Digital",
  subText:
    "Let AI do the mundane tasks for you! Paste the press release, and the number of words required, and receive a reworded version, keeping the essential information intact, optimized for the digital audience.",
  label: "Rewritten Press Release",
  placeholderText: "Paste Press Release Here",
  buttonText: "Rewrite for Digital",
  aiPlaceholder: "Rewritten press release will appear here",
});

export default pressReleaseRewriterDigital;
