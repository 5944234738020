import React from "react";
import { colors } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { alpha } from "@mui/material";

const relatedTopicsResearch = {
  title: "Related Topics Research",
  subtitle:
    "Discover SEO-optimized, unique sub-topics to add depth to your main content.",
  targetRoute: "/related-topics-research",
  color: colors.green[900],
  bgcolor: alpha(colors.green[900], 0.1),
  icon: <ZoomInIcon fontSize="medium" />,
  tags: [],
};

export default relatedTopicsResearch;
