// import React, { useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { motion } from 'framer-motion';
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   CircularProgress,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Grid,
//   MenuItem,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Typography,
//   Alert,
// } from '@mui/material';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../../../atoms';
// import axiosInstance from '../../../../helpers/axiosInstance';
// import { CreditCard, FileText, AlertTriangle } from 'lucide-react';

// const MotionBox = motion(Box);

// const BillingDashboard = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//   const [subscription, setSubscription] = useState(null);
//   const [invoices, setInvoices] = useState([]);
//   const [availablePlans, setAvailablePlans] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const [selectedPlan, setSelectedPlan] = useState('');
//   const [feedbackMessage, setFeedbackMessage] = useState(null);

//   const auth = useRecoilValue(authState);
//   const { isAuthenticated } = auth;

//   useEffect(() => {
//     if (isAuthenticated) {
//       fetchData();
//     }
//   }, [isAuthenticated]);

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const [subRes, invRes, planRes] = await Promise.all([
//         axiosInstance.get('/api/razorpay/subscription'),
//         axiosInstance.get('/api/razorpay/invoices'),
//         axiosInstance.get('/api/razorpay/available-plans'), // Changed this line
//       ]);
//       setSubscription(subRes.data);
//       setInvoices(invRes.data);
//       setAvailablePlans(planRes.data);
//       setSelectedPlan(subRes.data.planId);
//     } catch (err) {
//       setError('Failed to load billing information');
//       console.error(err);
//     }
//     setLoading(false);
//   };

//   const handlePlanChange = async () => {
//     setLoading(true);
//     setFeedbackMessage(null);
//     try {
//       const response = await axiosInstance.post(
//         '/api/razorpay/subscription/change',
//         {
//           newPlanId: selectedPlan,
//         }
//       );
//       setFeedbackMessage({
//         type: 'success',
//         message: response.data.message,
//         details: response.data.details,
//       });
//       await fetchData(); // Refresh the data
//     } catch (err) {
//       setFeedbackMessage({
//         type: 'error',
//         message: err.response?.data?.error || 'Failed to change plan',
//         details: err.response?.data?.details,
//       });
//       console.error(err);
//     }
//     setLoading(false);
//   };

//   const handleCancelSubscription = async () => {
//     setLoading(true);
//     setFeedbackMessage(null);
//     try {
//       const response = await axiosInstance.post(
//         '/api/razorpay/subscription/cancel'
//       );
//       setFeedbackMessage({
//         type: 'success',
//         message: response.data.message,
//         details: response.data.details,
//       });
//       await fetchData(); // Refresh the data
//     } catch (err) {
//       setFeedbackMessage({
//         type: 'error',
//         message: err.response?.data?.error || 'Failed to cancel subscription',
//         details: err.response?.data?.details,
//       });
//       console.error(err);
//     }
//     setLoading(false);
//     setOpenCancelDialog(false);
//   };

//   const handleResumeSubscription = async () => {
//     setLoading(true);
//     setFeedbackMessage(null);
//     try {
//       const response = await axiosInstance.post(
//         '/api/razorpay/subscription/resume'
//       );
//       setFeedbackMessage({
//         type: 'success',
//         message: response.data.message,
//         details: response.data.details,
//       });
//       await fetchData(); // Refresh the data
//     } catch (err) {
//       setFeedbackMessage({
//         type: 'error',
//         message: err.response?.data?.error || 'Failed to resume subscription',
//         details: err.response?.data?.details,
//       });
//     }
//     setLoading(false);
//   };

//   const handleCancelScheduledChange = async () => {
//     setLoading(true);
//     setFeedbackMessage(null);
//     try {
//       const response = await axiosInstance.post(
//         '/api/razorpay/subscription/cancel-scheduled-change'
//       );
//       setFeedbackMessage({
//         type: 'success',
//         message: response.data.message,
//         details: response.data.details,
//       });
//       await fetchData(); // Refresh the data
//     } catch (err) {
//       setFeedbackMessage({
//         type: 'error',
//         message:
//           err.response?.data?.error || 'Failed to cancel scheduled change',
//         details: err.response?.data?.details,
//       });
//     }
//     setLoading(false);
//   };

//   if (loading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <Alert severity="error">{error}</Alert>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       sx={{
//         minHeight: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         bgcolor: theme.palette.background.default,
//       }}
//     >
//       <Box
//         sx={{
//           position: 'relative',
//           backgroundColor: theme.palette.alternate.main,
//           backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
//           paddingTop: 13,
//           marginBottom: 3,
//         }}
//       >
//         <Container>
//           <Typography
//             variant="h3"
//             gutterBottom
//             align={'center'}
//             sx={{
//               fontWeight: 700,
//               color: theme.palette.primary.main,
//             }}
//           >
//             Billing Dashboard
//           </Typography>
//         </Container>
//       </Box>

//       <Container>
//         <Grid container spacing={4} mb={5}>
//           <Grid item xs={12} md={6}>
//             <MotionBox
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.5 }}
//             >
//               <Card variant="outlined" sx={{ mb: 4 }}>
//                 <CardContent>
//                   <Typography
//                     variant="h5"
//                     gutterBottom
//                     fontWeight="bold"
//                     color="text.primary"
//                   >
//                     Current Subscription
//                   </Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={6}>
//                       <Typography>
//                         <strong>Plan:</strong> {subscription.planName}
//                       </Typography>
//                       <Typography
//                         color={subscription.isCancelled ? 'error' : 'inherit'}
//                       >
//                         <strong>Status:</strong>{' '}
//                         {subscription.isCancelled
//                           ? 'Cancelled (Active until billing cycle end)'
//                           : subscription.status}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography>
//                         <strong>Next billing:</strong>{' '}
//                         {new Date(
//                           subscription.currentPeriodEnd
//                         ).toLocaleDateString()}
//                       </Typography>
//                       <Typography>
//                         <strong>Amount:</strong> {subscription.amount}{' '}
//                         {subscription.currency}
//                       </Typography>
//                     </Grid>
//                     {subscription.scheduledPlanId && (
//                       <Grid item xs={12}>
//                         <Typography color="info.main">
//                           <strong>Scheduled Change:</strong> Plan will change on{' '}
//                           {new Date(
//                             subscription.scheduledChangeDate
//                           ).toLocaleDateString()}
//                         </Typography>
//                       </Grid>
//                     )}
//                   </Grid>
//                 </CardContent>
//               </Card>

//               {feedbackMessage && (
//                 <Alert severity={feedbackMessage.type} sx={{ mt: 2, mb: 2 }}>
//                   <Typography>{feedbackMessage.message}</Typography>
//                   {feedbackMessage.details && (
//                     <Typography variant="body2">
//                       {feedbackMessage.details.effectiveDate &&
//                         `New plan will be effective from: ${new Date(feedbackMessage.details.effectiveDate).toLocaleDateString()}`}
//                       {feedbackMessage.details.endDate &&
//                         `Subscription will end on: ${new Date(feedbackMessage.details.endDate).toLocaleDateString()}`}
//                     </Typography>
//                   )}
//                 </Alert>
//               )}

//               <Card variant="outlined">
//                 <CardContent>
//                   <Typography
//                     variant="h5"
//                     gutterBottom
//                     fontWeight="bold"
//                     color="text.primary"
//                   >
//                     Manage Plan
//                   </Typography>
//                   {subscription.isCancelled ? (
//                     <>
//                       <Typography color="error" gutterBottom>
//                         Your subscription has been cancelled and will end on{' '}
//                         {new Date(
//                           subscription.currentPeriodEnd
//                         ).toLocaleDateString()}
//                         .
//                       </Typography>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={handleResumeSubscription}
//                         startIcon={<CreditCard size={20} />}
//                       >
//                         Resume Subscription
//                       </Button>
//                     </>
//                   ) : subscription.scheduledPlanId ? (
//                     <>
//                       <Typography gutterBottom>
//                         You have a scheduled plan change. Your plan will change
//                         on{' '}
//                         {new Date(
//                           subscription.scheduledChangeDate
//                         ).toLocaleDateString()}
//                         .
//                       </Typography>
//                       <Button
//                         variant="contained"
//                         color="secondary"
//                         onClick={handleCancelScheduledChange}
//                         startIcon={<AlertTriangle size={20} />}
//                       >
//                         Cancel Scheduled Change
//                       </Button>
//                     </>
//                   ) : (
//                     <>
//                       <Grid container spacing={2} alignItems="center">
//                         <Grid item xs={12} md={8}>
//                           <Select
//                             value={selectedPlan}
//                             onChange={(e) => setSelectedPlan(e.target.value)}
//                             fullWidth
//                           >
//                             {availablePlans.map((plan) => (
//                               <MenuItem key={plan.id} value={plan.id}>
//                                 {plan.name} - {plan.amount} {plan.currency} /{' '}
//                                 {plan.interval}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </Grid>
//                         <Grid item xs={12} md={4}>
//                           <Button
//                             variant="contained"
//                             onClick={handlePlanChange}
//                             disabled={selectedPlan === subscription.planId}
//                             fullWidth
//                             startIcon={<CreditCard size={20} />}
//                           >
//                             Change Plan
//                           </Button>
//                         </Grid>
//                       </Grid>
//                       <Box sx={{ mt: 2 }}>
//                         <Button
//                           variant="outlined"
//                           color="secondary"
//                           onClick={() => setOpenCancelDialog(true)}
//                           startIcon={<AlertTriangle size={20} />}
//                         >
//                           Cancel Subscription
//                         </Button>
//                       </Box>
//                     </>
//                   )}
//                 </CardContent>
//               </Card>
//             </MotionBox>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <MotionBox
//               initial={{ opacity: 0, x: 20 }}
//               animate={{ opacity: 1, x: 0 }}
//               transition={{ duration: 0.5, delay: 0.2 }}
//             >
//               <Card variant="outlined">
//                 <CardContent>
//                   <Typography
//                     variant="h5"
//                     gutterBottom
//                     fontWeight="bold"
//                     color="text.primary"
//                   >
//                     Invoice History
//                   </Typography>
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Date</TableCell>
//                         <TableCell>Amount</TableCell>
//                         <TableCell>Status</TableCell>
//                         <TableCell>Action</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {invoices.map((invoice) => (
//                         <TableRow key={invoice.id}>
//                           <TableCell>
//                             {new Date(invoice.date).toLocaleDateString()}
//                           </TableCell>
//                           <TableCell>
//                             {invoice.amount} {invoice.currency}
//                           </TableCell>
//                           <TableCell>{invoice.status}</TableCell>
//                           <TableCell>
//                             <Button
//                               size="small"
//                               onClick={() =>
//                                 window.open(invoice.invoiceUrl, '_blank')
//                               }
//                               startIcon={<FileText size={16} />}
//                             >
//                               View
//                             </Button>
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </CardContent>
//               </Card>
//             </MotionBox>
//           </Grid>
//         </Grid>
//       </Container>

//       <Dialog
//         open={openCancelDialog}
//         onClose={() => setOpenCancelDialog(false)}
//       >
//         <DialogTitle>Cancel Subscription</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Are you sure you want to cancel your subscription? You will lose
//             access to premium features at the end of your current billing
//             period.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenCancelDialog(false)}>
//             No, Keep My Subscription
//           </Button>
//           <Button onClick={handleCancelSubscription} color="secondary">
//             Yes, Cancel Subscription
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default BillingDashboard;

//client/src/components/screens/Account/Billing/RazorpayPortal.js

import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../../atoms';
import axiosInstance from '../../../../helpers/axiosInstance';
import { CreditCard, FileText, AlertTriangle, RefreshCw } from 'lucide-react';

const MotionBox = motion(Box);

const BillingDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [subscription, setSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [subRes, invRes, planRes] = await Promise.all([
        axiosInstance.get('/api/razorpay/subscription'),
        axiosInstance.get('/api/razorpay/invoices'),
        axiosInstance.get('/api/razorpay/available-plans'),
      ]);
      setSubscription(subRes.data);
      setInvoices(invRes.data);
      setAvailablePlans(planRes.data);
      setSelectedPlan(subRes.data.planId);
    } catch (err) {
      setError('Failed to load billing information');
      console.error(err);
    }
    setLoading(false);
  };

  const handlePlanChange = async () => {
    setLoading(true);
    setFeedbackMessage(null);
    try {
      const response = await axiosInstance.post(
        '/api/razorpay/subscription/change',
        {
          newPlanId: selectedPlan,
        }
      );
      setFeedbackMessage({
        type: 'success',
        message: response.data.message,
        details: response.data.details,
      });
      await fetchData();
    } catch (err) {
      setFeedbackMessage({
        type: 'error',
        message: err.response?.data?.error || 'Failed to change plan',
        details: err.response?.data?.details,
      });
      console.error(err);
    }
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    setFeedbackMessage(null);
    try {
      const response = await axiosInstance.post(
        '/api/razorpay/subscription/cancel'
      );
      setFeedbackMessage({
        type: 'success',
        message: response.data.message,
        details: response.data.details,
      });
      await fetchData();
    } catch (err) {
      setFeedbackMessage({
        type: 'error',
        message: err.response?.data?.error || 'Failed to cancel subscription',
        details: err.response?.data?.details,
      });
      console.error(err);
    }
    setLoading(false);
    setOpenCancelDialog(false);
  };

  const handleResumeSubscription = async () => {
    setLoading(true);
    setFeedbackMessage(null);
    try {
      const response = await axiosInstance.post(
        '/api/razorpay/subscription/resume'
      );
      setFeedbackMessage({
        type: 'success',
        message: response.data.message,
        details: response.data.details,
      });
      await fetchData();
    } catch (err) {
      setFeedbackMessage({
        type: 'error',
        message: err.response?.data?.error || 'Failed to resume subscription',
        details: err.response?.data?.details,
      });
    }
    setLoading(false);
  };

  const handleCancelScheduledChange = async () => {
    setLoading(true);
    setFeedbackMessage(null);
    try {
      const response = await axiosInstance.post(
        '/api/razorpay/subscription/cancel-scheduled-change'
      );
      setFeedbackMessage({
        type: 'success',
        message: response.data.message,
        details: response.data.details,
      });
      await fetchData();
    } catch (err) {
      setFeedbackMessage({
        type: 'error',
        message:
          err.response?.data?.error || 'Failed to cancel scheduled change',
        details: err.response?.data?.details,
      });
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          paddingTop: 13,
          marginBottom: 3,
        }}
      >
        <Container>
          <Typography
            variant="h3"
            gutterBottom
            align={'center'}
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
          >
            Billing Dashboard
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={4} mb={5}>
          <Grid item xs={12} md={6}>
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card variant="outlined" sx={{ mb: 4 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Current Subscription
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Plan:</strong> {subscription.planName}
                      </Typography>
                      <Typography
                        color={subscription.isCancelled ? 'error' : 'inherit'}
                      >
                        <strong>Status:</strong>{' '}
                        {subscription.isCancelled
                          ? 'Cancelled (Active until billing cycle end)'
                          : subscription.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Next billing:</strong>{' '}
                        {new Date(
                          subscription.currentPeriodEnd
                        ).toLocaleDateString()}
                      </Typography>
                      <Typography>
                        <strong>Amount:</strong> {subscription.amount}{' '}
                        {subscription.currency}
                      </Typography>
                    </Grid>
                    {subscription.scheduledPlanId && (
                      <Grid item xs={12}>
                        <Typography color="info.main">
                          <strong>Scheduled Change:</strong> Plan will change on{' '}
                          {new Date(
                            subscription.scheduledChangeDate
                          ).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>

              {feedbackMessage && (
                <Alert severity={feedbackMessage.type} sx={{ mt: 2, mb: 2 }}>
                  <Typography>{feedbackMessage.message}</Typography>
                  {feedbackMessage.details && (
                    <Typography variant="body2">
                      {feedbackMessage.details.effectiveDate &&
                        `New plan will be effective from: ${new Date(feedbackMessage.details.effectiveDate).toLocaleDateString()}`}
                      {feedbackMessage.details.endDate &&
                        `Subscription will end on: ${new Date(feedbackMessage.details.endDate).toLocaleDateString()}`}
                    </Typography>
                  )}
                </Alert>
              )}

              <Card variant="outlined">
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Manage Plan
                  </Typography>
                  {subscription.isCancelled ? (
                    <>
                      <Typography color="error" gutterBottom>
                        Your subscription has been cancelled and will end on{' '}
                        {new Date(
                          subscription.currentPeriodEnd
                        ).toLocaleDateString()}
                        .
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResumeSubscription}
                        startIcon={<RefreshCw size={20} />}
                      >
                        Resume Subscription
                      </Button>
                    </>
                  ) : subscription.scheduledPlanId ? (
                    <>
                      <Typography gutterBottom>
                        You have a scheduled plan change. Your plan will change
                        on{' '}
                        {new Date(
                          subscription.scheduledChangeDate
                        ).toLocaleDateString()}
                        .
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancelScheduledChange}
                        startIcon={<AlertTriangle size={20} />}
                      >
                        Cancel Scheduled Change
                      </Button>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={8}>
                          <Select
                            value={selectedPlan}
                            onChange={(e) => setSelectedPlan(e.target.value)}
                            fullWidth
                          >
                            {availablePlans.map((plan) => (
                              <MenuItem key={plan.id} value={plan.id}>
                                {plan.name} - {plan.amount} {plan.currency} /{' '}
                                {plan.interval}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button
                            variant="contained"
                            onClick={handlePlanChange}
                            disabled={selectedPlan === subscription.planId}
                            fullWidth
                            startIcon={<CreditCard size={20} />}
                          >
                            Change Plan
                          </Button>
                        </Grid>
                      </Grid>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setOpenCancelDialog(true)}
                          startIcon={<AlertTriangle size={20} />}
                        >
                          Cancel Subscription
                        </Button>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </MotionBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MotionBox
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Invoice History
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <TableCell>
                            {new Date(invoice.date).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {invoice.amount} {invoice.currency}
                          </TableCell>
                          <TableCell>{invoice.status}</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() =>
                                window.open(invoice.invoiceUrl, '_blank')
                              }
                              startIcon={<FileText size={16} />}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </MotionBox>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your subscription? You will lose
            access to premium features at the end of your current billing
            period.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>
            No, Keep My Subscription
          </Button>
          <Button onClick={handleCancelSubscription} color="secondary">
            Yes, Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BillingDashboard;
