import React from "react";
import { colors } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import { alpha } from "@mui/material";

const newsReportDigital = {
  title: "News Report - Digital",
  subtitle: "Turn inputs into SEO-optimized digital news content",
  targetRoute: "/news-report-digital",
  color: colors.deepPurple[700],
  bgcolor: alpha(colors.deepPurple[700], 0.1),
  icon: <WebIcon fontSize="medium" />,
  tags: [],
};

export default newsReportDigital;
