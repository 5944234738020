// //client/src/components/screens/TemplateSelector.js

// import React from 'react';
// import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

// const templates = [
//   {
//     id: 'nice_slides',
//     name: 'Nice Slides',
//     thumbnail: '/nice_slides.png',
//   },
//   // Add more templates here
// ];

// function TemplateSelector({ onSelect, selectedTemplate }) {
//   return (
//     <Grid container spacing={2}>
//       {templates.map((template) => (
//         <Grid item xs={4} key={template.id}>
//           <Card
//             onClick={() => onSelect(template.id)}
//             sx={{
//               border:
//                 selectedTemplate === template.id ? '2px solid blue' : 'none',
//             }}
//           >
//             <CardMedia
//               component="img"
//               height="140"
//               image={template.thumbnail}
//               alt={template.name}
//             />
//             <CardContent>
//               <Typography variant="body2">{template.name}</Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// export default TemplateSelector;

// src/components/screens/TemplateSelector.js

import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { templates } from './TemplateManager';

function TemplateSelector({ onSelect, selectedTemplate }) {
  return (
    <Grid container spacing={2}>
      {templates.map((template) => (
        <Grid item xs={4} key={template.id}>
          <Card
            onClick={() => onSelect(template.id)}
            sx={{
              border:
                selectedTemplate === template.id ? '2px solid blue' : 'none',
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={template.thumbnail}
              alt={template.name}
            />
            <CardContent>
              <Typography variant="body2">{template.name}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default TemplateSelector;
