// client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/journalistMock.js
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HistoryIcon from '@mui/icons-material/History';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ArticleIcon from '@mui/icons-material/Article';
import WebIcon from '@mui/icons-material/Web';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const writerMock = [
  {
    groupTitle: 'AI Tools for Writers',
    id: 'tools',
    pages: [
      {
        title: 'All Writing Tools',
        path: '/my-writing-dashboard',
        icon: <EditNoteIcon />,
      },
      {
        title: 'Blog Writing',
        path: '/writer/blog-writing-tools',
        icon: <ArticleIcon />,
      },
      {
        title: 'Content Optimization',
        path: '/writer/content-optimization-tools',
        icon: <ManageSearchIcon />,
      },
      {
        title: 'Specialized Content',
        path: '/writer/specialized-content-tools',
        icon: <WebIcon />,
      },
      {
        title: 'Creative Writing',
        path: '/writer/creative-writing-tools',
        icon: <TipsAndUpdatesIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Documents',
    id: 'documents',
    pages: [
      {
        title: 'History',
        path: '/history',
        icon: <HistoryIcon />,
      },
      {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />,
      },
      {
        title: 'Playlist',
        path: '/playlist',
        icon: <LibraryMusicIcon />,
      },
      {
        title: 'Knowledge Base',
        path: '/my-knowledge-base',
        icon: <TextSnippetIcon />,
      },
      {
        title: 'Tone of Voice',
        path: '/my-tone-of-voice',
        icon: <GraphicEqIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Account',
    id: 'account',
    pages: [
      {
        title: 'General Settings',
        path: '/user-profile',
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: 'Change Password',
        path: '/update-password',
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: 'Billing Portal',
        path: '/billing-portal',
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Support',
    id: 'support',
    pages: [
      {
        title: 'Support',
        path: '#',
        icon: <EmailOutlinedIcon />,
      },
      {
        title: 'Announcements',
        path: '#',
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

export default writerMock;
