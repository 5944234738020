// src/api/intercom.js
import instance from '../helpers/axiosInstance';

export const getUserHMAC = async (email) => {
  try {
    const response = await instance.post('/api/intercom/generate-hmac', {
      email,
    });
    return response.data.hmac;
  } catch (error) {
    console.error('Error generating HMAC:', error);
    throw error;
  }
};
