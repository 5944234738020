// src/api/knowledgeBaseApi.js

import { getAuth } from 'firebase/auth';
import instance from '../helpers/axiosInstance';

export const fetchCompanyNames = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    try {
      const idToken = await user.getIdToken();
      const { data } = await instance.get('/api/knowledgebase/company-names', {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return data.companyNames;
    } catch (error) {
      console.error('Error fetching company names:', error);
      throw error;
    }
  }
  return [];
};

export const fetchItemNames = async (selectedCompany) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (selectedCompany && user) {
    try {
      const idToken = await user.getIdToken();
      const { data } = await instance.get(
        `/api/knowledgebase/item-names?companyName=${selectedCompany}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
      return data.itemNames;
    } catch (error) {
      console.error('Error fetching item names:', error);
      throw error;
    }
  }
  return [];
};
