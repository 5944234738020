import createQuestionPaperGeneratorTemplate from "../../QuestionPaperGeneratorTemplate";

const mixedQuestionPaperMaker = createQuestionPaperGeneratorTemplate({
  name: "mixedquestionpapermaker",
  endpoint: "/api/openai/mixed-question-paper-maker",
  aiText: "mixedQuestionPaperMaker",
  aiTextHandler: "mixedQuestionPaperMakerHandler",
  aiTextPlainText: "mixedQuestionPaperMakerPlainText",
  title: "Mixed Question Paper Maker",
  subText:
    'Cater to all learners. Select the class, subject, and the chapters from your syllabus, and this tool will generate a well-balanced question paper with a mix of difficulty levels. Make sure your syllabus is uploaded for accurate results. Example: "Grade 10, Mathematics, Chapter 8-15.“',
  label: "Mixed Difficulty Question Paper",
  placeholderText: "Point out specific concepts to be emphasised",
  buttonText: "Compile Question Paper",
  aiPlaceholder: "Question Paper with answers will appear here",
});

export default mixedQuestionPaperMaker;
