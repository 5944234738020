import React from "react";
import { colors } from "@mui/material";
import TvIcon from "@mui/icons-material/Tv";
import { alpha } from "@mui/material";

const newsReportBroadcast = {
  title: "News Report - Broadcast",
  subtitle: "Translate raw news into dynamic broadcast scripts.",
  targetRoute: "/news-report-broadcast",
  color: colors.orange[500],
  bgcolor: alpha(colors.orange[500], 0.1),
  icon: <TvIcon fontSize="medium" />,
  tags: [],
};

export default newsReportBroadcast;
