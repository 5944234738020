import React from "react";
import { colors } from "@mui/material";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import { alpha } from "@mui/material";

const headlineGeneratorPrint = {
  title: "Headline Generator - Print",
  subtitle: "Craft catchy headlines for news articles.",
  targetRoute: "/headline-generator-print",
  color: colors.indigo[900],
  bgcolor: alpha(colors.indigo[900], 0.1),
  icon: <ViewStreamIcon fontSize="medium" />,
  tags: [],
};

export default headlineGeneratorPrint;
