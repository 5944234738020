import createLongTextMoreLanguageTemplate from "../../LongTextMoreLanguageTemplate";

const analogyMaker = createLongTextMoreLanguageTemplate({
  name: "analogymaker",
  endpoint: "/api/openai/analogy-maker",
  aiText: "analogyMaker",
  aiTextHandler: "analogyMakerHandler",
  aiTextPlainText: "analogyMakerPlainText",
  title: "Analogy Maker",
  subText:
    "Struggling to simplify complex ideas? Our tool works like your personal analogy-maker, converting tricky concepts into easy-to-understand comparisons. Just input the difficult idea, and voila, it gives you a clear, engaging analogy, akin to turning jargon into plain tea-time conversation. Your communication, made simple and compelling.",
  label: "Analogy",
  placeholderText: "Enter a topic",
  buttonText: "Create Analogy",
  aiPlaceholder: "Your analogy will appear here",
});

export default analogyMaker;
