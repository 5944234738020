import createTopicLanguageTemplate from "../../TopicLanguageTemplate";

const simpleKeywordResearchTool = createTopicLanguageTemplate({
  name: "simplekeywordresearchtool",
  endpoint: "/api/openai/simple-keyword-research-tool",
  aiText: "simpleKeywordResearchTool",
  aiTextHandler: "simpleKeywordResearchToolHandler",
  aiTextPlainText: "simpleKeywordResearchToolPlainText",
  title: "Simple Keyword Research Tool",
  subText:
    "This is an easy-to-use solution for SEO keyword research. Enter your topic and get 10 potential keywords, analyzed for effectiveness and relevance. The tool offers options for considering competition, volume, and keyword funnel stages. It's a straightforward way to improve your content creation and boost your website's SEO performance.",
  label: "Keywords",
  placeholderText: "Enter your main topic…",
  buttonText: "Find Keywords",
  aiPlaceholder: "Keywords will be displayed here",
});

export default simpleKeywordResearchTool;
