import React from "react";
import { colors } from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { alpha } from "@mui/material";

const summaryFromSpeech = {
  title: "Summary From Speech",
  subtitle: "Condense lengthy audio into short summaries.",
  targetRoute: "/summary-from-speech",
  color: colors.green[700],
  bgcolor: alpha(colors.green[700], 0.1),
  icon: <SummarizeIcon fontSize="medium" />,
  tags: [],
};

export default summaryFromSpeech;
