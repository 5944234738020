import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Story = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography fontWeight={700} variant={"h5"}>
            Birth of Innovation: The Genesis of Wisdom Scribe
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography component={"p"} color={"text.secondary"} fontWeight={400}>
            Born from the innovative spirit of Wisdom Works, a
            globally-recognized digital marketing agency, Wisdom Scribe is
            rooted in the vast experience of content creation, web development,
            and marketing strategies. Our journey started in 2017, in the
            dynamic city of Bangalore, where we initially excelled in digital
            marketing and later ventured into the extraordinary world of AI.
            <br />
            <br />
            <h3>Democratizing AI: Bringing Power to the Masses</h3>
            Our aim with Wisdom Scribe is simple yet profound — to democratize
            the power of AI for the masses. Our mission is to bring AI to
            businesses, digital marketing agencies, journalists, and educators,
            simplifying their tasks and amplifying their impact. With a wide
            array of tools from blog writing, press release rewriters, lesson
            planners to proposal writers, Wisdom Scribe is designed to be a
            one-stop solution for all your content needs.
            <br />
            <br />
            Our journey into the development of this AI tool was ignited by a
            passion for digital marketing and an innate understanding of its
            intricacies. Our rich background in content writing and web
            development has allowed us to incorporate all the nitty-gritties
            required to make Wisdom Scribe an indispensable tool.
            <br />
            <br />
            <h3>Tailored Tool for Journalists</h3>
            With the leadership of an experienced journalist at the helm, we
            have laid the groundwork for the first AI tool specifically tailored
            for journalists, enabling seamless and efficient news reporting and
            content generation.
            <br />
            <br />
            <h3>A Teacher's AI Companion</h3>
            As educators ourselves, we understand the pivotal role of teachers
            in shaping our future. So we've built the first AI tool that caters
            exclusively to the needs of teachers. Wisdom Scribe not only
            facilitates question paper generation but also assists in creating
            interactive activities, lesson planning, and much more. The unique
            feature of Wisdom Scribe is its capability to accept uploads of
            images and audio in multiple languages, creating a comprehensive
            tool that bridges language barriers.
            <br />
            <br />
            <h3>Your All-in-One AI Assistant: More Than Just a Tool</h3>
            Wisdom Scribe is more than an AI tool. It's your personal content
            generator, social media manager, lesson planner, and digital
            marketing strategist all rolled into one. It's a platform that
            embraces your tone of voice, understands your knowledge base, and
            resonates with your audience in a language they understand. Whether
            you're penning an SEO-optimized blog or designing your entire
            website's content, Wisdom Scribe has got you covered.
            <br />
            <br />
            Join us on this extraordinary journey of exploration and innovation
            where AI empowers creativity. Embrace the future of content creation
            with Wisdom Scribe — where wisdom meets words, and the world meets
            you.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
