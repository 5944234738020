import createKeywordKnowledgeBaseTemplate from "../../KeywordKnowledgeBaseTemplate";

const keywordResearchTool = createKeywordKnowledgeBaseTemplate({
  name: "keywordresearchtool",
  endpoint: "/api/openai/keyword-research-tool",
  aiText: "keywordResearchTool",
  aiTextHandler: "keywordResearchToolHandler",
  aiTextPlainText: "keywordResearchToolPlainText",
  title: "Keyword Research Tool",
  subText:
    "Meet our Advanced Keyword Planner, a powerful tool for strategic SEO content planning. This tool, tailored for native speakers, delivers a comprehensive list of super categories, long-tail keywords, searcher intent, post titles, and captivating meta descriptions. With the added ability to consider organizational details and extra data, it's your comprehensive solution for enhanced, targeted SEO content strategy.",
  label: "Keyword Research",
  placeholderText: "Enter your topic…",
  infoText: "Input any additional information (optional)",
  buttonText: "Research Keywords",
  aiPlaceholder: "Your keyword suggestions will appear here",
});

export default keywordResearchTool;
