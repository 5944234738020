import createNewsTemplate from "../../NewsTemplate";

const newsReportPrint = createNewsTemplate({
  name: "newsreportprint",
  endpoint: "/api/openai/news-report-print",
  aiText: "newsReportPrint",
  aiTextHandler: "newsReportPrintHandler",
  aiTextPlainText: "newsReportPrintPlainText",
  title: "News Report - Print",
  subText:
    "Transform raw facts into a comprehensive print news story with our report writing tool for print media. Key in your news topic, preferred word count, news information, and background data. Ideal for covering a wide range of news from politics to entertainment, and more!",
  label: "News Article",
  placeholderText: "Enter Main News Here",
  buttonText: "Compose Article",
  aiPlaceholder: "Your article will appear here",
});

export default newsReportPrint;
