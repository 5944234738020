// client/src/helpers/api.js
import instance from './axiosInstance';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from 'firebase/auth';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { authState } from '../atoms';
import { auth } from '../firebaseConfig';

export const loginWithGoogle = async () => {
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const idToken = await user.getIdToken();

    // Send the ID token to your backend
    const response = await instance.post('/auth/google-login', { idToken });

    // Update Recoil state
    setRecoil(authState, {
      user: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      },
      accessToken: idToken,
      isAuthenticated: true,
      isLoading: false,
      userHMAC: response.data.userHMAC, // Assuming your backend returns this
    });

    return response.data;
  } catch (error) {
    console.error('Google login failed:', error);
    throw error;
  }
};

export const logout = () => {
  return signOut(auth)
    .then(() => {
      console.log('Sign-out successful');
      // Clear the Recoil state
      setRecoil(authState, {
        user: null,
        accessToken: null,
        isAuthenticated: false,
        isLoading: false,
        userHMAC: null,
      });
      // Clear any local storage items if needed
      localStorage.removeItem('authState');
      localStorage.removeItem('lastRoute');
      localStorage.removeItem('justLoggedIn');
      // Clear session storage
      sessionStorage.clear();
      return true;
    })
    .catch((error) => {
      console.error('An error happened during sign-out:', error);
      throw error;
    });
};

export const fetchUserSubscription = async () => {
  try {
    const auth = getRecoil(authState);
    const response = await instance.get('/api/subscription', {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
    setRecoil(authState, (prevState) => ({
      ...prevState,
      subscriptionStatus: response.data.subscriptionStatus,
    }));
  } catch (error) {
    console.error('Failed to fetch subscription:', error);
    throw error;
  }
};
