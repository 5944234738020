import createPressReleaseNewsTemplate from "../../PressReleaseNewsTemplate";

const pressReleaseRewriterPrint = createPressReleaseNewsTemplate({
  name: "pressreleaserewriterprint",
  endpoint: "/api/openai/press-release-rewriter-print",
  aiText: "pressReleaseRewriterPrint",
  aiTextHandler: "pressReleaseRewriterPrintHandler",
  aiTextPlainText: "pressReleaseRewriterPrintPlainText",
  title: "Press Release Rewriter - Print",
  subText:
    "Let our Press Release Rewriter turn press releases into captivating news articles. Just paste the press release, and the desired word count, and receive a rewritten version, maintaining the key points and facts.",
  label: "Rewritten Press Release",
  placeholderText: "Paste Press Release Here",
  buttonText: "Rewrite for Print",
  aiPlaceholder: "Rewritten press release will appear here",
});

export default pressReleaseRewriterPrint;
