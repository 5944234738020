import React from "react";
import { colors } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { alpha } from "@mui/material";

const keywordResearchTool = {
  title: "Keyword Research Tool",
  subtitle:
    "Expert-level, language-specific SEO planning, categorizing precise keywords into clusters.",
  targetRoute: "/keyword-research-tool",
  color: colors.teal["A700"],
  bgcolor: alpha(colors.teal["A700"], 0.1),
  icon: <ManageSearchIcon fontSize="medium" />,
  tags: [],
};

export default keywordResearchTool;
