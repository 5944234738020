import React from "react";
import { colors } from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { alpha } from "@mui/material";

const simpleKeywordResearchTool = {
  title: "Simple Keyword Research Tool",
  subtitle:
    "Craft a strategic SEO keyword list tailored to funnel stages and search volume.",
  targetRoute: "/simple-keyword-research-tool",
  color: colors.amber["A700"],
  bgcolor: alpha(colors.amber["A700"], 0.1),
  icon: <ContentPasteSearchIcon fontSize="medium" />,
  tags: [],
};

export default simpleKeywordResearchTool;
