import React from "react";
import { colors } from "@mui/material";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { alpha } from "@mui/material";

const teaserWriter = {
  title: "Teaser Writer",
  subtitle: "Write intriguing teasers for upcoming articles or broadcasts.",
  targetRoute: "/teaser-writer",
  color: colors.grey[800],
  bgcolor: alpha(colors.grey[800], 0.1),
  icon: <FeaturedPlayListIcon fontSize="medium" />,
  tags: [],
};

export default teaserWriter;
