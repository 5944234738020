// import React from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
// import { useNavigate } from 'react-router-dom';

// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { Button } from '@mui/material';
// import { alpha, useTheme } from '@mui/material/styles';
// import Card from '@mui/material/Card';
// import Avatar from '@mui/material/Avatar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Container from '../../Container';

// import { authState } from '../../../atoms';
// import instance from '../../../helpers/axiosInstance';

// import {
//   contentIdeasGenerator,
//   podcastCreator,
//   socialMediaContentGenerator,
//   youTubeScriptwriter,
//   youTubeTagGenerator,
//   youTubeTitleDescriptionKeywordsHashtagsGenerator,
// } from '../cards/SocialMediaTools';

// const SocialMediaTools = () => {
//   const theme = useTheme();
//   const navigate = useNavigate();

//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const setAuth = useSetRecoilState(authState);
//   const { isAuthenticated } = auth;

//   const handleToolClick = (item) => {
//     if (!isAuthenticated) {
//       navigate('/login');
//     } else {
//       navigate(item);
//     }
//   };

//   const features = [
//     contentIdeasGenerator,
//     podcastCreator,
//     socialMediaContentGenerator,
//     youTubeScriptwriter,
//     youTubeTagGenerator,
//     youTubeTitleDescriptionKeywordsHashtagsGenerator,
//   ];

//   const seoToolsInstructions = (
//     <Box
//       sx={{
//         margin: '8px',
//         padding: '16px',
//         border: `1px solid ${theme.palette.divider}`,
//         borderRadius: '5px',
//         backgroundColor: alpha(theme.palette.primary.main, 0.1),
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         Personalizing Your Social Media Tools
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         To provide a unique and tailored social media presence, we offer you the
//         chance to customize our tools by uploading your organization's knowledge
//         base and defining your desired tone of voice. Here's how to do it:
//         <ul>
//           <li>
//             Upload your knowledge base: Click the button below, provide your
//             organization's specifics, give it a title, and submit it. During the
//             tool's usage, simply select "Use my knowledge base" and choose the
//             relevant data to integrate it with the tool's capabilities.
//           </li>
//           <li>
//             Define your tone of voice: Click the button below, describe your
//             brand's preferred communication style, name it, and save it. This
//             might include specific linguistic preferences or phrasing patterns
//             that reflect your brand's unique voice. You can then choose this
//             tone from a dropdown list when using our tools.
//           </li>
//         </ul>
//       </Typography>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: { xs: 'center', sm: 'flex-start' }, // center on mobile, left-align on larger screens
//           alignItems: 'center',
//           gap: 1, // provides a gap between the buttons
//         }}
//       >
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/knowledge-base')}
//         >
//           Upload Knowledge Base
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           sx={{
//             mt: { xs: 1, sm: 0 }, // Adjust as necessary
//           }}
//           onClick={() => navigate('/upload-tone')}
//         >
//           Define Tone of Voice
//         </Button>
//       </Box>
//     </Box>
//   );

//   return (
//     <>
//       <Box
//         sx={{
//           marginTop: {
//             xs: '-16px', // value for screens 0px or larger
//             sm: '-70px', // value for screens 600px or larger
//             md: '-32px', // value for screens 960px or larger
//             lg: '-40px', // value for screens 1280px or larger
//             xl: '-48px', // value for screens 1920px or larger
//           },
//           backgroundImage: `linear-gradient(to bottom, ${alpha(
//             theme.palette.background.paper,
//             0
//           )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
//           backgroundRepeat: 'repeat-x',
//           position: 'relative',
//         }}
//       >
//         <Container>
//           {seoToolsInstructions}
//           <Grid container spacing={4} sx={{ marginTop: '32px' }}>
//             {features.map((item, i) => (
//               <Grid item xs={12} sm={6} md={4} key={i}>
//                 <IconButton
//                   onClick={() => handleToolClick(item.targetRoute)}
//                   sx={{
//                     width: '100%',
//                     height: '100%',
//                     padding: 0,
//                     borderRadius: 2,
//                     border: '1px solid transparent',
//                     transition: 'border-color 0.3s, box-shadow 0.3s',
//                     '&:hover': {
//                       borderColor: theme.palette.action.hover,
//                       boxShadow: theme.shadows[4],
//                     },
//                   }}
//                 >
//                   <Box
//                     component={Card}
//                     padding={4}
//                     borderRadius={2}
//                     width={1}
//                     height={1}
//                     data-aos={'fade-up'}
//                     data-aos-delay={i * 100}
//                   >
//                     <Box
//                       component={Avatar}
//                       width={60}
//                       height={60}
//                       marginBottom={2}
//                       bgcolor={item.bgcolor}
//                       color={item.color}
//                       variant={'rounded'}
//                       borderRadius={2}
//                     >
//                       {item.icon}
//                     </Box>
//                     <Typography
//                       variant={'h6'}
//                       gutterBottom
//                       sx={{ fontWeight: 500 }}
//                     >
//                       {item.title}
//                     </Typography>
//                     <Typography color="text.secondary">
//                       {item.subtitle}
//                     </Typography>
//                   </Box>
//                 </IconButton>
//               </Grid>
//             ))}
//           </Grid>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default SocialMediaTools;

import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../Container';
import { authState } from '../../../atoms';

import {
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
} from '../cards/SocialMediaTools';

const features = [
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
];

const SocialMediaTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <SocialMediaToolsInstructions navigate={navigate} />
      </Container>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.background.paper,
            0
          )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: 'repeat-x',
          position: 'relative',
        }}
      >
        <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
          <Grid container spacing={4}>
            {features.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureCard item={item} onClick={handleClickOpen} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const SocialMediaToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Social Media Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To provide a unique and tailored social media presence, we offer you the
        chance to customize our tools by uploading your organization's knowledge
        base and defining your desired tone of voice. Here's how to do it:
        <ul>
          <li>
            Upload your knowledge base: Click the button below, provide your
            organization's specifics, give it a title, and submit it. During the
            tool's usage, simply select "Use my knowledge base" and choose the
            relevant data to integrate it with the tool's capabilities.
          </li>
          <li>
            Define your tone of voice: Click the button below, describe your
            brand's preferred communication style, name it, and save it. This
            might include specific linguistic preferences or phrasing patterns
            that reflect your brand's unique voice. You can then choose this
            tone from a dropdown list when using our tools.
          </li>
        </ul>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/knowledge-base')}
        >
          Upload Knowledge Base
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-tone')}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default SocialMediaTools;
