import createTopicLanguageTemplate from "../../TopicLanguageTemplate";

const ultimateTopicResearcher = createTopicLanguageTemplate({
  name: "ultimatetopicresearcher",
  endpoint: "/api/openai/ultimate-topic-researcher",
  aiText: "ultimateTopicResearcher",
  aiTextHandler: "ultimateTopicResearcherHandler",
  aiTextPlainText: "ultimateTopicResearcherPlainText",
  title: "Ultimate Topic Researcher",
  subText:
    "Unearth hot topics in your niche with the Ultimate Topic Researcher. Input your field of interest, and it delivers trending topics, complete with relevant keywords and reference materials to fuel your content creation.",
  label: "Topic Research",
  placeholderText: "Input your main keyword",
  buttonText: "Research Topics",
  aiPlaceholder: "Your researched topics will appear here",
});

export default ultimateTopicResearcher;
