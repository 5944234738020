import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const steps = [
  {
    title: 'Upload Your Knowledge',
    description:
      "Securely upload your company's unique knowledge base through our intuitive interface. Your business insights become Link's foundation.",
    icon: (
      <Box
        component={'svg'}
        width={40}
        height={40}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        color={'primary.main'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
        />
      </Box>
    ),
  },
  {
    title: 'AI-Powered Processing',
    description:
      'Our advanced AI converts your data into a sophisticated vector database, making every piece of information instantly accessible.',
    icon: (
      <Box
        component={'svg'}
        width={40}
        height={40}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        color={'primary.main'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
        />
      </Box>
    ),
  },
  {
    title: 'Engage with Link',
    description:
      'Interact with Link, your AI-powered private chatbot. Ask questions, seek insights, and receive instant, accurate responses tailored to your business.',
    icon: (
      <Box
        component={'svg'}
        width={40}
        height={40}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        color={'primary.main'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        />
      </Box>
    ),
  },
];

const HowItWorks = () => {
  return (
    <Box width={1} height={1} data-aos={'fade-up'} component={Card}>
      <CardContent>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant="h4"
              align={'center'}
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: 1,
              }}
            >
              Experience the Power of Link: Your Private AI Assistant
            </Typography>
            <Typography
              variant="h6"
              align={'center'}
              color="text.secondary"
              gutterBottom
            >
              Harness your company's collective knowledge with cutting-edge AI
              technology
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {steps.map((step, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  component={Card}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  boxShadow={0}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box marginBottom={2}>{step.icon}</Box>
                    <Typography
                      align={'center'}
                      variant={'h6'}
                      sx={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      {step.title}
                    </Typography>
                    <Typography align={'center'} color="text.secondary">
                      {step.description}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Box>
  );
};

export default HowItWorks;
