import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// import AiImage from "../../../../../assets/images/ai-meets-humans.jpg";

const AiImage =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689954769/Wisdom-Scribe/about-us-ai_tktlxj.png";

const Gallery = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: AiImage,
      source: AiImage,
      rows: 1,
      cols: 1,
    },
  ];

  return (
    <Box>
      <ImageList
        variant="quilted"
        cols={1}
        rowHeight={isMd ? 500 : 200}
        gap={isMd ? 16 : 4}
      >
        {photos.map((item, i) => (
          <ImageListItem key={i} cols={item.cols} rows={item.rows}>
            <img
              height={"100%"}
              width={"100%"}
              src={item.src}
              alt="..."
              loading="lazy"
              style={{
                objectFit: "cover",
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                cursor: "poiner",
                borderRadius: 8,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default Gallery;
