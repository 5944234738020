import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { authState, currentDashboardState } from '../../../../../../atoms';

import { useNavigate } from 'react-router-dom';

const GetStarted = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const auth = useRecoilValue(authState);
  const currentDashboard = useRecoilValue(currentDashboardState);

  const handleAction = () => {
    if (auth.isAuthenticated) {
      navigate(currentDashboard || '/dashboard');
    } else {
      navigate('/register');
    }
  };

  return (
    <Box>
      <Typography
        variant="h4"
        color="text.primary"
        align={'center'}
        gutterBottom
        sx={{
          fontWeight: 700,
        }}
      >
        Join the AI Revolution Tailored for Your Business
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ fontWeight: 400 }}
        align={'center'}
      >
        Don't settle for one-size-fits-all AI. Choose the solution designed for
        your unique business needs.
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        justifyContent={'center'}
        marginTop={4}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth={!isMd}
          onClick={handleAction}
        >
          {auth.isAuthenticated ? 'Go to Dashboard' : 'Start Your Free Trial'}
        </Button>
      </Box>
    </Box>
  );
};

export default GetStarted;
