import createAITextGenerator from "../../AiTextGeneratorTemplate";

const relatedTopicsResearch = createAITextGenerator({
  name: "relatedtopicsresearch",
  endpoint: "/api/openai/related-topics-research",
  aiText: "relatedTopicsResearch",
  aiTextHandler: "relatedTopicsResearchHandler",
  aiTextPlainText: "relatedTopicsResearchPlainText",
  title: "Related Topics Research",
  subText:
    "This is a powerful aid for experienced writers looking to optimize their content for SEO. Just enter your theme and targeted keywords, and this tool will generate a list of 10 unique, engaging sub-topics closely related to your main subject. It crafts these sub-topics in your preferred language, ensuring they can incorporate your keywords while providing new insights. This tool helps you add depth to your main topic, engage your audience more effectively, and boost your content's SEO performance.",
  label: "Related Topics",
  placeholderText: "Enter your main topic…",
  buttonText: "Find Topics",
  aiPlaceholder: "Generated topics will be displayed here",
  seoTitle: "Related Topics Research - Wisdom Scribe",
  seoDescription:
    "Engaging and detailed analysis on related topics research. Useful for SEO and content writers.",
});

export default relatedTopicsResearch;
