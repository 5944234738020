import createWebsitePageContentTemplate from "../../WebsitePageContentTemplate";

const websitePageContentGenerator = createWebsitePageContentTemplate({
  name: "websitepagecontentgenerator",
  endpoint: "/api/openai/website-page-content-generator",
  aiText: "websitePageContentGenerator",
  aiTextHandler: "websitePageContentGeneratorHandler",
  aiTextPlainText: "websitePageContentGeneratorPlainText",
  title: "Website Page Content Generator",
  subText:
    "Don't let writer's block slow you down. Our Website Page Content Generator delivers precise, engaging content for any page on your site, seamlessly.",
  label: "Web Page Content",
  placeholderText: "Page type",
  infoText: "Enter information about your page",
  buttonText: "Create Content",
  aiPlaceholder: "Your page content will appear here",
});

export default websitePageContentGenerator;
