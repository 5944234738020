import createAudiencePlatformTypeTemplate from "../../AudiencePlatformTypeTemplate";

const contentIdeasGenerator = createAudiencePlatformTypeTemplate({
  name: "contentideasgenerator",
  endpoint: "/api/openai/content-ideas-generator",
  aiText: "contentIdeasGenerator",
  aiTextHandler: "contentIdeasGeneratorHandler",
  aiTextPlainText: "contentIdeasGeneratorPlainText",
  title: "Content Ideas Generator",
  subText:
    "Fuel your content pipeline with this tool. Input your niche and keywords, and it will generate a list of fresh, engaging content ideas that resonate with your audience and boost engagement.",
  label: "Content Ideas",
  placeholderText: "Enter your main theme...",
  buttonText: "Generate Ideas",
  aiPlaceholder: "Your content ideas will appear here",
});

export default contentIdeasGenerator;
