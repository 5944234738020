import createInterviewNewsTemplate from "../../InterviewNewsTemplate";

const interviewQuestionGenerator = createInterviewNewsTemplate({
  name: "interviewquestiongenerator",
  endpoint: "/api/openai/interview-question-generator",
  aiText: "interviewQuestionGenerator",
  aiTextHandler: "interviewQuestionGeneratorHandler",
  aiTextPlainText: "interviewQuestionGeneratorPlainText",
  title: "Interview Question Generator",
  subText:
    "Suggest insightful questions for journalists to ask during interviews, based on the topic and interviewee.",
  label: "Interview Qs",
  placeholderText: "Enter Interview Topic Here",
  buttonText: "Generate Questions",
  aiPlaceholder: "Interview questions will appear here",
});

export default interviewQuestionGenerator;
