import React from "react";
import { colors } from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import { alpha } from "@mui/material";

const analogyMaker = {
  title: "Analogy Maker",
  subtitle:
    "Transform complexity into clarity, distilling intricate concepts into simple, engaging analogies.",
  targetRoute: "/analogy-maker",

  color: colors.blueGrey["A400"],
  bgcolor: alpha(colors.blueGrey["A400"], 0.1),
  icon: <SegmentIcon fontSize="medium" />,
  tags: [],
};

export default analogyMaker;
