import React from "react";
import { colors } from "@mui/material";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { alpha } from "@mui/material";

const blogTopicIdeas = {
  title: "Blog Topic Ideas",
  subtitle: "Generate engaging blog post ideas for any topic in 21 languages.",
  targetRoute: "/blog-topic-ideas",
  color: colors.red["A400"],
  bgcolor: alpha(colors.red["A400"], 0.1),
  icon: <OnlinePredictionIcon fontSize="medium" />,
  tags: [],
};

export default blogTopicIdeas;
