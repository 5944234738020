import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriterPas = createLanguageKeywordTopicTemplate({
  name: "blogwriterpas",
  endpoint: "/api/openai/blog-writer-pas",
  aiText: "blogWriterPas",
  aiTextHandler: "blogWriterPasHandler",
  aiTextPlainText: "blogWriterPasHandlerPlainText",
  title: "PAS Solution Blog",
  subText:
    "Leveraging the power of the PAS (Problem, Agitate, Solve) model, this tool creates content that identifies a problem, intensifies it, and then presents a solution. Feed it a topic like 'Lack of Sleep', and it will create a gripping narrative that ends with practical solutions for your readers.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterPas;
