import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getAuth } from 'firebase/auth';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import UploadingTone from './Dialog/UploadingTone';
import instance from '../../helpers/axiosInstance';

import { authState } from '../../atoms';

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function ToneOfVoiceUpload() {
  const [toneName, setToneName] = useState('');
  const [toneDescription, setToneDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');

  // Recoil state
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const toneNameError = toneName && countWords(toneName) > 10;
  const toneDescriptionError =
    toneDescription && countWords(toneDescription) > 200;

  const uploadTone = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadSuccess(false);
    setUploadError('');

    if (toneNameError || toneDescriptionError) {
      setUploadError('Please correct errors before uploading.');
      setIsUploading(false);
      return;
    }

    const data = {
      toneName,
      toneDescription,
    };

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      await instance.post('/api/tone/upload-tone', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      setUploadSuccess(true);
      setToneName('');
      setToneDescription('');
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadError('Failed to upload data. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={uploadTone}
      sx={{
        margin: { xs: '2rem', sm: '10rem' },
        mt: 12,
        maxWidth: 800,
        mx: 'auto',
      }}
    >
      <UploadingTone open={isUploading} />
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        Upload Your Tone of Voice
      </Typography>
      <Typography color="text.secondary">
        Fill out the form and press "Upload".
      </Typography>
      {uploadSuccess && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Upload successful!
        </Alert>
      )}
      {uploadError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {uploadError}
        </Alert>
      )}
      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Name your tone of voice"
            value={toneName}
            onChange={(e) => setToneName(e.target.value)}
            error={Boolean(toneNameError)}
            helperText={toneNameError && 'Tone name should not exceed 10 words'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            multiline
            fullWidth
            rows={3}
            label="Tone Description"
            value={toneDescription}
            onChange={(e) => setToneDescription(e.target.value)}
            error={Boolean(toneDescriptionError)}
            helperText={
              toneDescriptionError &&
              'Tone description should not exceed 200 words'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={
              isUploading ||
              toneNameError ||
              toneDescriptionError ||
              !toneName ||
              !toneDescription
            }
            sx={{ height: '56px' }}
          >
            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ToneOfVoiceUpload;
