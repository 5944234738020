import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import instance from '../../helpers/axiosInstance';

import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import filterAndRenderTone from './filterAndRenderTone';

import { authState } from '../../atoms';

const UserToneScreen = (props) => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated, accessToken } = auth;

  const [uploadedTone, setUploadedTone] = useState([]);
  const [search, setSearch] = useState('');
  const [toneNameFilter, setToneNameFilter] = useState('');
  const [toneNames, setToneNames] = useState([]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleToneNameFilterChange = (event) => {
    setToneNameFilter(event.target.value);
  };

  const handleViewToneDetails = (toneId) => {
    window.open(`/my-tone-of-voice/${toneId}`, '_blank');
  };

  // Fetch user tone data on mount
  useEffect(() => {
    const fetchUserTone = async () => {
      if (!isAuthenticated || !accessToken) {
        console.error('Authentication required or access token missing.');
        return;
      }

      try {
        const response = await instance.get('/api/auth/uploadedTone', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setUploadedTone(response.data);

        const uniqueToneNames = [
          ...new Set(response.data.map((tone) => tone.toneName)),
        ];
        setToneNames(uniqueToneNames);
      } catch (error) {
        console.error('Error fetching user tone:', error);
      }
    };

    fetchUserTone();
  }, [isAuthenticated, accessToken]);

  const renderedTones = filterAndRenderTone(
    uploadedTone,
    search,
    toneNameFilter,
    handleViewToneDetails
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your Uploaded Tone of Voice
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="tone-name-label">Tone Name</InputLabel>
              <Select
                labelId="tone-name-label"
                id="tone-name-select"
                value={toneNameFilter}
                onChange={handleToneNameFilterChange}
                label="Tone Name"
              >
                {toneNames.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" mt={3}>
          <Grid item xs={12}>
            {renderedTones}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserToneScreen;
