import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

const mock = [
  {
    title: "What is WisdomScribe.ai?",
    subtitle:
      "WisdomScribe.ai is an AI-powered web application designed to generate, edit, and manage content in a variety of formats. It helps users with tasks ranging from blog posts to technical reports, simplifying the content creation process with advanced AI technology.",
  },
  {
    title: "What is the $1 Plan at WisdomScribe.ai?",
    subtitle:
      "The $1 plan at WisdomScribe.ai is an introductory offer designed for new users to experience our multi-modal tools at an affordable price. This one-time purchase provides 10,000 credits, which can be utilized across various features, including text generation and image creation. With this plan, you can generate up to 10,000 words, or create up to 5 Standard Definition images or 2 High Definition images, offering a comprehensive glimpse into the capabilities of WisdomScribe.ai.",
  },
  {
    title: "How does the Credit System Work at WisdomScribe.ai?",
    subtitle:
      "WisdomScribe.ai uses a credit system for accessing services. Each plan provides a set number of credits, usable across our tools. Credits function as a universal currency: 1 word costs 1 credit, an SD image is 2,000 credits, and an HD image is 4,000 credits. This allows flexible usage of services, such as text generation or image creation, tailored to your specific needs. For example, the Starter Plan offers 10,000 credits, which can be used for various combinations of text and images.",
  },
  {
    title: "How accurate is the AI writer?",
    subtitle:
      "WisdomScribe.ai utilizes the latest AI technology to ensure high accuracy in content generation. However, like any AI tool, it is recommended that users perform a final review to ensure the content perfectly suits their needs.",
  },
  {
    title: "In which languages can WisdomScribe.ai generate content?",
    subtitle:
      "WisdomScribe.ai can generate content in various languages including English (US & UK), Arabic, Chinese, Danish, Dutch, French, German, Greek, Hindi, Italian, Japanese, Korean, Persian (Farsi), Polish, Portuguese, Russian, Spanish, Swedish, Tamil, and Turkish.",
  },

  {
    title: "Can I upload a knowledge base to WisdomScribe.ai?",
    subtitle:
      "Yes, WisdomScribe.ai allows you to upload a knowledge base and use it anytime later to generate text with our various tools. Simply click the upload button, input your organization's information, assign it a title, and save it. During tool usage, you can select 'Use my knowledge base' and choose the appropriate information to integrate into the tool's functionality.",
  },
];

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Frequently asked questions
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={"h6"} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center">
        <Button
          component={RouterLink}
          to="/faq"
          variant="contained"
          color="primary"
          size="large"
        >
          Click for more FAQs
        </Button>
      </Box>
    </Box>
  );
};

export default Faq;
