//client/src/layouts/Main Sidebar/components/Topbar/Topbar.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import ThemeModeToggler from '../../../../components/ThemeModeToggler';
import Sidebar from '../../../Main/components/Sidebar/Sidebar';
import NavItem from './components/NavItem/NavItem';
import SimpleNavItem from './components/NavItem/SimpleNavItem';
import pagesData from '../../../side-navigation';
import logo from '../../../../assets/images/WisdomScribe-logo.svg';
import negativeLogo from '../../../../assets/images/WisdomScribe-logo-negative.svg';
import MySidebar from '../MySidebar/MySidebar';

import { authState } from '../../../../atoms';
import { logout } from '../../../../helpers/api';

const Topbar = ({
  onSidebarOpen,
  onMySidebarOpen,
  pages,
  colorInvert = false,
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const authValue = useRecoilValue(authState);
  const isAuthenticated = authValue.isAuthenticated;

  const {
    about: aboutPages,
    tools: toolsPages,
    voicelab: voicelabPages,
    dashboard: dashboardPages,
  } = pagesData;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [mySidebarOpen, setMySidebarOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const handleMenuClick = (href) => {
    navigate(href);
    setMobileOpen(false);
    setMySidebarOpen(false);
  };

  const handleMySidebarOpen = () => {
    setMySidebarOpen(!mySidebarOpen);
    if (typeof onMySidebarOpen === 'function') {
      onMySidebarOpen();
    }
  };

  const logoutHandler = async () => {
    try {
      await logout();
      // Force a reload of the page to ensure all state is cleared
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebar = (
    <div>
      <List>
        {[
          { name: 'About', href: '/about' },
          { name: 'Tools', href: '/tools' },
          { name: 'Voice Lab', href: '/voice-lab' },
          { name: 'Dashboard', href: '/dashboard' },
          { name: 'Contact', href: '/contact' },
        ].map((item) => (
          <ListItem key={item.name} onClick={() => handleMenuClick(item.href)}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>

      <Box marginLeft={4}>
        <ThemeModeToggler />
      </Box>
      {isAuthenticated ? (
        <Box marginLeft={4}>
          <Button onClick={logoutHandler} variant="contained" color="primary">
            Logout
          </Button>
        </Box>
      ) : (
        <>
          <Box marginLeft={4}>
            <Button
              component={Link}
              href="/register"
              variant="contained"
              color="primary"
            >
              Register
            </Button>
          </Box>
          <Box marginLeft={4}>
            <Button
              component={Link}
              href="/login"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </Box>
        </>
      )}
    </div>
  );

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Wisdom Scribe"
        width={{ xs: 150, md: 180 }} // Increased from 100/120 to 150/180
      >
        <Box
          component={'img'}
          src={mode === 'light' ? logo : negativeLogo}
          height={{ xs: 45, md: 55 }} // Increased from 35 to 45/55
          width="100%"
          sx={{ objectFit: 'contain' }}
        />
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <NavItem
            title={'Text Room'}
            id={'tools-pages'}
            items={toolsPages}
            colorInvert={colorInvert}
            onClose={handleDrawerToggle}
          />
        </Box>
        <Box marginLeft={3}>
          <SimpleNavItem
            title="Image Studio"
            href="/create-image"
            colorInvert={colorInvert}
          />
        </Box>

        <Box marginLeft={3}>
          <NavItem
            title={'Voice Lab'}
            id={'voicelab-pages'}
            items={voicelabPages}
            colorInvert={colorInvert}
            onClose={handleDrawerToggle}
          />
        </Box>

        <Box marginLeft={3}>
          <SimpleNavItem
            title="Pricing"
            href="/pricing"
            colorInvert={colorInvert}
          />
        </Box>

        <Box marginLeft={3}>
          <NavItem
            title={'Dashboard'}
            id={'dashboard-pages'}
            items={dashboardPages}
            colorInvert={colorInvert}
            onClose={handleDrawerToggle}
          />
        </Box>
        <Box marginLeft={3}>
          <NavItem
            title={'About'}
            id={'about-pages'}
            items={aboutPages}
            colorInvert={colorInvert}
            onClose={handleDrawerToggle}
          />
        </Box>
        <Box marginLeft={3}>
          <ThemeModeToggler />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
          {isAuthenticated ? (
            <Box marginLeft={3}>
              <Button
                onClick={logoutHandler}
                variant="contained"
                color="primary"
              >
                Logout
              </Button>
            </Box>
          ) : (
            <>
              <Box marginLeft={4}>
                <Button
                  component={Link}
                  href="/register"
                  variant="contained"
                  color="primary"
                >
                  Register
                </Button>
              </Box>
              <Box marginLeft={4}>
                <Button
                  component={Link}
                  href="/login"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={handleMySidebarOpen}
          aria-label="MySidebar Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            marginLeft: 2,
          }}
        >
          <TuneOutlinedIcon />
        </Button>
        <Button
          onClick={handleDrawerToggle}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            marginLeft: 2,
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <MySidebar
        onClose={handleMySidebarOpen}
        open={mySidebarOpen}
        variant="temporary"
      />
      <Sidebar
        onClose={handleDrawerToggle}
        open={mobileOpen}
        variant="temporary"
        pages={pagesData}
      />
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  onMySidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
