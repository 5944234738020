import React from "react";
import { colors } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { alpha } from "@mui/material";

const mixedQuestionPaperMaker = {
  title: "Mixed Question Paper Maker",
  subtitle: "Create question papers with varied difficulty levels.",
  targetRoute: "/mixed-question-paper-maker",
  color: colors.pink[500],
  bgcolor: alpha(colors.pink[500], 0.1),
  icon: <PostAddIcon fontSize="medium" />,
  tags: [],
};

export default mixedQuestionPaperMaker;
