import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriterHowTo = createLanguageKeywordTopicTemplate({
  name: "blogwriterhowto",
  endpoint: "/api/openai/blog-writer-how-to",
  aiText: "blogWriterHowTo",
  aiTextHandler: "blogWriterHowToHandler",
  aiTextPlainText: "blogWriterHowToHandlerPlainText",
  title: "How To Blog",
  subText:
    "Creating 'how-to' guides has never been easier! Enter a subject like 'Cooking Pizza' and the AI writer will generate a comprehensive, step-by-step guide, ensuring your readers can easily follow and learn from your content.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterHowTo;
