import createQuestionPaperGeneratorTemplate from "../../QuestionPaperGeneratorTemplate";

const difficultQuestionPaperMaker = createQuestionPaperGeneratorTemplate({
  name: "difficultquestionpapermaker",
  endpoint: "/api/openai/difficult-question-paper-maker",
  aiText: "difficultQuestionPaperMaker",
  aiTextHandler: "difficultQuestionPaperMakerHandler",
  aiTextPlainText: "difficultQuestionPaperMakerPlainText",
  title: "Difficult Question Paper Maker",
  subText:
    "Propel your students to new heights. Choose the class, subject, and specific chapters from your syllabus, and this tool will compile a rigorous question paper to stretch your students' understanding. Ensure your syllabus is uploaded to stay syllabus-bound. Example: \"Grade 12, Physics, Chapters 5-10. Focus no Quantum Physics.“",
  label: "Difficult Question Paper",
  placeholderText: "Highlight any particular concepts you want to stress on",
  buttonText: "Create Advanced Test",
  aiPlaceholder: "Question Paper with answers will appear here",
});

export default difficultQuestionPaperMaker;
