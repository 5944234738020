import React from "react";
import { colors } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { alpha } from "@mui/material";

const topicExplainerTeaching = {
  title: "Topic Explainer",
  subtitle: "Simplify complex topics from your syllabus.",
  targetRoute: "/topic-explainer-teaching",
  color: colors.brown[500],
  bgcolor: alpha(colors.brown[500], 0.1),
  icon: <ChatIcon fontSize="medium" />,
  tags: [],
};

export default topicExplainerTeaching;
