import createKeywordKnowledgeBaseTemplate from "../../KeywordKnowledgeBaseTemplate";

const blogContentPlanner = createKeywordKnowledgeBaseTemplate({
  name: "blogcontentplanner",
  endpoint: "/api/openai/blog-content-planner",
  aiText: "blogContentPlanner",
  aiTextHandler: "blogContentPlannerHandler",
  aiTextPlainText: "blogContentPlannerPlainText",
  title: "Blog Content Planner",
  subText:
    "Streamline your content creation process with our Blog Content Planner. Provide your themes, keywords, and target audience, and it will generate a strategic content plan to engage readers and optimize SEO performance.",
  label: "Content Plan",
  placeholderText: "Enter your theme here",
  infoText: "Input any additional information",
  buttonText: "Plan Content",
  aiPlaceholder: "Your content plan will appear here",
});

export default blogContentPlanner;
