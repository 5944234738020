import React from "react";
import { colors } from "@mui/material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { alpha } from "@mui/material";

const blogWriterBenefits = {
  title: "Blog Writer - Benefits",
  subtitle:
    "Highlight, detail, and present key benefits of various products/services.",
  targetRoute: "/blog-writer-benefits",
  color: colors.deepOrange[400],
  bgcolor: alpha(colors.deepOrange[400], 0.1),
  icon: <AutoStoriesOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriterBenefits;
