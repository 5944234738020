import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import StoreIcon from "@mui/icons-material/Store";
import SchoolIcon from "@mui/icons-material/School";
import DescriptionIcon from "@mui/icons-material/Description";
import EditNoteIcon from "@mui/icons-material/EditNote";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import LanguageIcon from "@mui/icons-material/Language";

import Container from "../../../../Container";

const mock = [
  {
    title: "Brand Voice for Businesses",
    subtitle:
      "Introduce your business' unique voice and let Wisdom Scribe do the rest. Upload your company's data and let the prowess of GPT4 craft compelling content - be it marketing materials, pitches, presentations, videos, and more!",

    icon: <StoreIcon fontSize="large" />,
  },
  {
    title: "Syllabus-focused learning",
    subtitle:
      "Tried chatGPT for teaching or learning but got everything out of the syllabus? Not anymore! Simply upload your textbook images using your phone, and start generating learning material from your syllabus!",
    icon: <SchoolIcon fontSize="large" />,
  },
  {
    title: "Print/Video/Digital Journalism Copies",
    subtitle:
      "Journalists, it's time to rejoice! Forget the one-size-fits-all model of chatGPT. Feed your story's key points, and let Wisdom Scribe weave a captivating script in your unique style.",
    icon: <DescriptionIcon fontSize="large" />,
  },
  {
    title: "Content Writing in your style!",
    subtitle:
      "When chatGPT writes for you, it's in chatGPT style! But what about your style? Train Wisdom Scribe to mirror your unique writing style and produce content that feels like your own work - or even something Kipling might write!",
    icon: <EditNoteIcon fontSize="large" />,
  },
  {
    title: "Write blogs from audio clips",
    subtitle:
      "Say goodbye to the mundane task of transcription. With Wisdom Scribe, simply upload audio files in up to 22 languages and effortlessly convert them into engaging blogs, insightful reports, concise summaries, and more!",
    icon: <VolumeUpIcon fontSize="large" />,
  },
  {
    title: "Generate SEO content in minutes",
    subtitle:
      "Just input your desired title and focus keywords, and let Wisdom Scribe do the rest. It generates a superior, SEO-optimized blog that not only resonates with your audience but also secures a higher rank in search engine results.",
    icon: <LanguageIcon fontSize="large" />,
  },
];

const FeaturesIcons = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
          <Grid item xs={12} md={12}>
            <Box marginBottom={4}>
              <Typography
                sx={{ fontWeight: 700 }}
                variant={"h4"}
                gutterBottom
                align="center"
              >
                Custom Train Your AI with Wisdom Scribe!
              </Typography>
              <Typography
                variant={"h6"}
                component={"p"}
                color={"text.secondary"}
                align="center"
              >
                End the struggles of conventional AI writing. Wisdom Scribe is
                here to transform your experience, whether you're a businessman,
                teacher, content writer, journalist, digital marketer, SEO
                specialist, or just an intern. Step into a new era of
                personalized, efficient, and precise AI writing.
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={6}>
                  <ListItem
                    component="div"
                    disableGutters
                    sx={{
                      alignItems: "flex-start",
                      padding: 0,
                    }}
                  >
                    <ListItemAvatar>
                      <Box color={theme.palette.primary.main}>{item.icon}</Box>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={item.subtitle}
                      primaryTypographyProps={{
                        variant: "h6",
                        gutterBottom: true,
                        sx: { fontWeight: 700 },
                      }}
                      sx={{
                        margin: 0,
                      }}
                    />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FeaturesIcons;
