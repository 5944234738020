import React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { alpha } from "@mui/material";

const interactiveActivityDesigner = {
  title: "Interactive Activity Designer",
  subtitle: "Enhance learning with hands-on activities.",
  targetRoute: "/interactive-activity-designer",
  color: "#FF6969",
  bgcolor: alpha("#FF6969", 0.1),
  icon: <DirectionsRunIcon fontSize="medium" style={{ color: "#FF6969" }} />,

  tags: [],
};

export default interactiveActivityDesigner;
