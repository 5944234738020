import React from "react";
import { colors } from "@mui/material";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { alpha } from "@mui/material";

const analogyCreatorTeaching = {
  title: "Analogy Creator - Teaching",
  subtitle: "Generate basic exams from chosen chapters.",
  targetRoute: "/analogy-creator-teaching",
  color: colors.teal[500],
  bgcolor: alpha(colors.teal[500], 0.1),
  icon: <ShortTextIcon fontSize="medium" />,
  tags: [],
};

export default analogyCreatorTeaching;
