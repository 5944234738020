// pricingData.js
const individualPlans = [
  {
    title: 'Basic',
    subtitle: 'Kickstart your text generation projects!',
    price: {
      monthly: { USD: '10', INR: '830', EUR: '9' },
      annual: { USD: '100', INR: '8300', EUR: '90' },
    },
    credits: 100000,
    features: [
      'Exclusively for text generation with 100,000 credits',
      'Generate up to 100,000 words—ideal for articles, scripts, and more',
      'Explore the potential of AI-driven text creation',
      'Perfect for individuals or small teams just starting with AI',
      "Includes 'Scribe', a free chatbot where you can ask anything, anytime",
    ],
    isHighlighted: false,
    support:
      '9am to 5pm weekday human support, plus round-the-clock AI bot support with help articles',
  },
  {
    title: 'Pro',
    subtitle: 'Elevate your content creation with diverse capabilities!',
    price: {
      monthly: { USD: '49', INR: '4090', EUR: '45' },
      annual: { USD: '490', INR: '40900', EUR: '450' },
    },
    credits: 415000,
    features: [
      '415,000 credits monthly: Ideal for professionals and content creators',
      'Create extensive content: up to 415,000 words, or diversify with images, voice overs',
      'Options include up to 207 SD images or 103 HD images',
      '1,383 minutes of speech-to-text, and 329 minutes of text-to-speech using 54 voices',
      'Tailored for digital marketers, bloggers, and digital creators seeking high-volume content',
      "Includes 'Scribe', a versatile chatbot free to answer any queries",
    ],
    isHighlighted: true,
    support:
      'Dedicated 9am to 5pm weekday human support, complemented by 24/7 AI bot support and access to help articles',
  },
  {
    title: 'Enterprise',
    subtitle: 'Dominate your industry with our top-tier resources!',
    price: {
      monthly: { USD: '70', INR: '5845', EUR: '65' },
      annual: { USD: '700', INR: '58450', EUR: '650' },
    },
    credits: 630000,
    features: [
      '630,000 credits monthly for extensive content generation across multiple mediums',
      'Generate up to 315 standard definition images or 157 high definition images for large-scale projects',
      '2,100 minutes of speech-to-text, and 500 minutes of text-to-speech using 54 voices',
      'Ideal for businesses, agencies, and educational institutions with substantial content needs',
      'Supports continuous, high-quality content creation at scale',
      "Includes 'Scribe', a powerful chatbot ready to assist with any inquiries and streamline your workflow",
    ],
    isHighlighted: false,
    support:
      'Comprehensive support with 9am to 5pm weekday human help, alongside 24x7 AI bot assistance and resourceful help articles',
  },
];

const groupPlans = [
  {
    title: 'Team Basic',
    subtitle: 'Empower your team with AI-driven content creation',
    price: {
      monthly: { USD: '8', INR: '650', EUR: '7' },
      annual: { USD: '80', INR: '6500', EUR: '70' },
    },
    minUsers: 3,
    tiers: [3, 5, 10, 15, 20],
    creditsPerUser: 100000,
    features: [
      'Scalable solution for teams of 3 to 20 members',
      '100,000 credits per team member',
      'Collaborative AI-powered content generation',
      'Ideal for small to medium-sized businesses and agencies',
      'Centralized billing and user management',
    ],
    isHighlighted: false,
    support:
      'Priority 9am to 7pm weekday human support, plus 24/7 AI bot assistance',
  },
  {
    title: 'Team Pro',
    subtitle: 'Advanced features for high-performance teams',
    price: {
      monthly: { USD: '45', INR: '3700', EUR: '40' },
      annual: { USD: '450', INR: '37000', EUR: '400' },
    },
    minUsers: 3,
    tiers: [3, 5, 10, 15, 20],
    creditsPerUser: 415000,
    features: [
      '415,000 credits per team member',
      'Advanced AI models for superior content quality',
      'Enhanced collaboration tools and analytics',
      'Suitable for content-heavy industries and large marketing teams',
      'API access available for $20/month with 150,000 additional credits',
    ],
    isHighlighted: true,
    support: 'Dedicated account manager and 24/7 priority support',
  },
  {
    title: 'Team Enterprise',
    subtitle: 'Unparalleled resources for industry leaders',
    price: {
      monthly: { USD: '65', INR: '5300', EUR: '58' },
      annual: { USD: '650', INR: '53000', EUR: '580' },
    },
    minUsers: 3,
    tiers: [3, 5, 10, 15, 20],
    creditsPerUser: 630000,
    features: [
      '630,000 credits per team member',
      'Custom AI model fine-tuning options',
      'Advanced security features and user permissions',
      'Ideal for large enterprises and agencies with diverse content needs',
      'Includes API access with 150,000 additional credits',
    ],
    isHighlighted: false,
    support:
      'Dedicated account manager, 24/7 priority support, and quarterly strategy sessions',
  },
];

const apiPricing = {
  monthlyFee: {
    USD: 20,
    INR: 1600,
    EUR: 18,
  },
  additionalCredits: 150000,
  minimumPlan: 'Pro',
};

const additionalCreditsPricing = [
  { price: 10, credits: 150000 },
  { price: 20, credits: 310000 },
  { price: 40, credits: 700000 },
];

const pricingDetails = {
  Basic: {
    monthly: {
      USD: 'plan_OTjGViF9AoHjfj',
      INR: 'plan_O4k6fe1Pm7MTAp',
      EUR: 'plan_OTkimcDBwlgDtZ',
    },
    annual: {
      USD: 'plan_OTjK06XMInoxh5',
      INR: 'plan_O4k8LJAwF5yabp',
      EUR: 'plan_OTkjQf4Eqyg7Nv',
    },
  },

  Pro: {
    monthly: {
      USD: {
        standard: 'plan_OTkIKT6Rh3IpL5',
        withApiAccess: 'plan_OqDOWusfLqjeDk',
      },
      INR: {
        standard: 'plan_O4kH8ErHoetHKu',
        withApiAccess: 'plan_OqD5f325DqGdJt',
      },
      EUR: {
        standard: 'plan_OTkl68jzIQbHSL',
        withApiAccess: 'plan_OqDPRI9nvNkPE2',
      },
    },
    annual: {
      USD: {
        standard: 'plan_OTkIzLSUzATRc5',
        withApiAccess: 'plan_OqDWNrldZjYL6w',
      },
      INR: {
        standard: 'plan_O4kN7DdDaa5Xct',
        withApiAccess: 'plan_OqDUbOBIPmcNkF',
      },
      EUR: {
        standard: 'plan_OTklUjpMwpiKoD',
        withApiAccess: 'plan_OqDYcWsXZOkUNI',
      },
    },

    Enterprise: {
      monthly: {
        USD: 'plan_OTkJsLufa3YKbZ',
        INR: 'plan_O4kSnhpzX7e8I0',
        EUR: 'plan_OTkluCysX3E47G',
      },
      annual: {
        USD: 'plan_OTkKGdkeREn2EX',
        INR: 'plan_O4kTZrBFuHUzck',
        EUR: 'plan_OTkmK8LjyYV9pu',
      },
    },
    // Group plans
    'Team Basic': {
      monthly: {
        USD: 'plan_group_basic_monthly_usd',
        INR: 'plan_group_basic_monthly_inr',
        EUR: 'plan_group_basic_monthly_eur',
      },
      annual: {
        USD: 'plan_group_basic_annual_usd',
        INR: 'plan_group_basic_annual_inr',
        EUR: 'plan_group_basic_annual_eur',
      },
    },
    'Team Pro': {
      monthly: {
        USD: 'plan_group_pro_monthly_usd',
        INR: 'plan_group_pro_monthly_inr',
        EUR: 'plan_group_pro_monthly_eur',
      },
      annual: {
        USD: 'plan_group_pro_annual_usd',
        INR: 'plan_group_pro_annual_inr',
        EUR: 'plan_group_pro_annual_eur',
      },
    },
    'Team Enterprise': {
      monthly: {
        USD: 'plan_group_enterprise_monthly_usd',
        INR: 'plan_group_enterprise_monthly_inr',
        EUR: 'plan_group_enterprise_monthly_eur',
      },
      annual: {
        USD: 'plan_group_enterprise_annual_usd',
        INR: 'plan_group_enterprise_annual_inr',
        EUR: 'plan_group_enterprise_annual_eur',
      },
    },
  },
};

export {
  individualPlans,
  groupPlans,
  pricingDetails,
  apiPricing,
  additionalCreditsPricing,
};
