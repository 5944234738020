import createNoKnowledgeToneTextGenerator from "../../NoKnowledgeToneTextGenerator";

const youTubeTagGenerator = createNoKnowledgeToneTextGenerator({
  name: "youtubetaggenerator",
  endpoint: "/api/openai/youtube-tag-generator",
  aiText: "youTubeTagGenerator",
  aiTextHandler: "youTubeTagGeneratorHandler",
  aiTextPlainText: "youTubeTagGeneratorPlainText",
  title: "YouTube Tag Generator",
  subText:
    "Boost your YouTube video's visibility with our tag generator tool. YouTube tags are keywords that help categorize your content, enhancing its presence in search results and 'Up Next' suggestions. Simply input your video details, and our tool will generate relevant, high-performance tags to optimize your reach. Harness the power of strategic tagging and maximize your video's potential audience with us.",
  label: "YouTube Tags",
  placeholderText: "Enter your video topic and details…",
  buttonText: "Generate Tags",
  aiPlaceholder: "YouTube tags will appear here",
});

export default youTubeTagGenerator;
