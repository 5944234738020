import React from "react";
import { colors } from "@mui/material";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import { alpha } from "@mui/material";

const landingPageHeadlineGenerator = {
  title: "Landing Page Headline Generator",
  subtitle:
    "Craft engaging, concise headlines that encapsulate your product's key offerings.",
  targetRoute: "/landing-page-headline-generator",
  color: colors.teal[500],
  bgcolor: alpha(colors.teal[500], 0.1),
  icon: <LineWeightIcon fontSize="medium" />,
  tags: [],
};

export default landingPageHeadlineGenerator;
