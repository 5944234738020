import React from "react";
import { colors } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import { alpha } from "@mui/material";

const headlineGeneratorDigital = {
  title: "Headline Generator - Digital",
  subtitle: "Generate SEO-friendly headlines for digital news platforms.",
  targetRoute: "/headline-generator-digital",
  color: colors.pink[500],
  bgcolor: alpha(colors.pink[500], 0.1),
  icon: <TitleIcon fontSize="medium" />,
  tags: [],
};

export default headlineGeneratorDigital;
