import React from "react";
import { colors } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { alpha } from "@mui/material";

const quoteGenerator = {
  title: "Quote Generator",
  subtitle:
    "Generate inspiring quotes from text, adding a touch of motivation.",
  targetRoute: "/quote-generator",
  color: colors.pink["A400"],
  bgcolor: alpha(colors.pink["A400"], 0.1),
  icon: <FormatQuoteIcon fontSize="medium" />,
  tags: [],
};

export default quoteGenerator;
