import React from "react";
import { colors } from "@mui/material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { alpha } from "@mui/material";

const youTubeTitleDescriptionKeywordsHashtagsGenerator = {
  title: "YouTube Title + Description + Hashtags Generator",
  subtitle: "Write SEO-optimized YouTube titles, descriptions, and hashtags.",
  targetRoute: "/youtube-title-description-keywords-hashtags-generator",
  color: colors.deepPurple["A200"],
  bgcolor: alpha(colors.deepPurple["A200"], 0.1),

  icon: <SubscriptionsIcon fontSize="medium" />,
  tags: [],
};

export default youTubeTitleDescriptionKeywordsHashtagsGenerator;
