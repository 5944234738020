import React from 'react';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import Form from './components/Form/Form';
import LoginImage from '../../../assets/images/loginScreen.svg';

const LoginScreen = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd ? (
          <Grid item container justifyContent={'center'} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={'img'}
                src={LoginImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                }}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Form />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginScreen;
