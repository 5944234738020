//client/src/components/screens/Register/components/Form/Form.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MuiTelInput } from 'mui-tel-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { auth, googleProvider } from '../../../../../firebaseConfig';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
} from 'firebase/auth';
import axiosInstance from '../../../../../helpers/axiosInstance';
import { authState as recoilAuthState } from '../../../../../atoms';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import AvatarSelection from '../../../../Avatars/AvatarSelection';
import EmailUseDialogComponent from '../../../Dialog/EmailUseDialogComponent';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'First name must be at least 2 characters')
    .max(50, 'First name must be at most 50 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Last name must be at least 2 characters')
    .max(50, 'Last name must be at most 50 characters')
    .required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .max(100, 'Email must be at most 100 characters')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    )
    .required('Password is required'),
  avatar: yup.string().required('Avatar selection is required'),
  role: yup.string().required('Role selection is required'),
  referralCode: yup
    .string()
    .max(40, 'Referral code must be at most 40 characters'),
  companyName: yup
    .string()
    .max(100, 'Company name must be at most 100 characters'),
  phoneNumber: yup
    .string()
    .test(
      'len',
      'Phone number must be valid',
      (val) =>
        !val ||
        (val &&
          val.replace(/\D/g, '').length >= 10 &&
          val.replace(/\D/g, '').length <= 15)
    )
    .required('Phone number is required'),
});

const RoleSelectionForm = ({
  onSubmit,
  referralCode,
  setReferralCode,
  companyName,
  setCompanyName,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      role: '',
      referralCode: referralCode,
      companyName: companyName,
      phoneNumber: phoneNumber,
    },
    validationSchema: yup.object({
      role: yup.string().required('Role selection is required'),
      referralCode: yup.string(),
      companyName: yup
        .string()
        .max(100, 'Company name must be at most 100 characters'),
      phoneNumber: yup.string().required('Phone number is required'),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await onSubmit(values);
      } catch (error) {
        console.error('Submission error:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        fullWidth
        error={formik.touched.role && Boolean(formik.errors.role)}
        sx={{ mb: 2 }}
      >
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          name="role"
          value={formik.values.role}
          label="Role"
          onChange={formik.handleChange}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="journalist">Journalist</MenuItem>
          <MenuItem value="educator">Educator</MenuItem>
          <MenuItem value="business">Business</MenuItem>
          <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
        {formik.touched.role && formik.errors.role && (
          <FormHelperText>{formik.errors.role}</FormHelperText>
        )}
      </FormControl>
      <TextField
        fullWidth
        label="Company Name (Optional)"
        variant="outlined"
        name="companyName"
        value={formik.values.companyName}
        onChange={(e) => {
          formik.handleChange(e);
          setCompanyName(e.target.value);
        }}
        sx={{ mb: 2 }}
      />
      <MuiTelInput
        label="Phone Number"
        value={formik.values.phoneNumber}
        onChange={(newValue) => {
          formik.setFieldValue('phoneNumber', newValue);
          setPhoneNumber(newValue);
        }}
        defaultCountry="IN"
        fullWidth
        sx={{ mb: 1 }}
      />
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{ mb: 2, display: 'block' }}
      >
        We require your phone number to prevent misuse of our services.
      </Typography>
      <TextField
        fullWidth
        label="Referral Code (Optional)"
        variant="outlined"
        name="referralCode"
        value={formik.values.referralCode}
        onChange={(e) => {
          formik.handleChange(e);
          setReferralCode(e.target.value);
        }}
        sx={{ mb: 2 }}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={isSubmitting}
        sx={{ mt: 2 }}
      >
        {isSubmitting ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Continue to Dashboard'
        )}
      </Button>
    </form>
  );
};

const RegistrationFormBottom = ({
  formik,
  termsAccepted,
  setTermsAccepted,
  loading,
  handleCaptchaChange,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <ReCAPTCHA
          sitekey="6Lfvf0oqAAAAAOr9ygLKmGkBj5E3e8Lj4E_sSTlv"
          // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test key
          onChange={handleCaptchaChange}
        />
        {formik.errors.recaptcha && (
          <Typography color="error" variant="caption">
            {formik.errors.recaptcha}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              name="terms"
              color="primary"
            />
          }
          label={
            <Typography variant="subtitle2" color="text.secondary">
              By clicking the "Sign up" button, you agree to our{' '}
              <Link
                component="a"
                color="primary"
                href="/company-terms"
                underline="none"
              >
                company's terms and conditions.
              </Link>
            </Typography>
          }
        />
      </Grid>

      <Grid item container xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'center' }}
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          width={1}
          maxWidth={600}
          margin="0 auto"
        >
          <Box
            marginTop={{ xs: 2, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            width={{ xs: '100%', sm: 'auto' }}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            <Typography variant="subtitle2">
              Already have an account?{' '}
              <Link
                component="a"
                color="primary"
                href="/login"
                underline="none"
              >
                Login.
              </Link>
            </Typography>
          </Box>

          <Box width={{ xs: '100%', sm: 'auto' }}>
            <Button
              fullWidth={true}
              size="large"
              variant="contained"
              type="submit"
              disabled={!termsAccepted || loading || formik.isSubmitting}
            >
              {loading || formik.isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Sign up'
              )}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [, setAuthState] = useRecoilState(recoilAuthState);
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openEmailInUseDialog, setOpenEmailInUseDialog] = useState(false);
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [openReferralErrorDialog, setOpenReferralErrorDialog] = useState(false);
  const [referralErrorMessage, setReferralErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+91');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const handlePhoneChange = (newValue) => {
    setPhoneNumber(newValue);
    formik.setFieldValue('phoneNumber', newValue);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      companyName: '',
      phoneNumber: '+91',
      avatar: '',
      role: '',
      referralCode: '',
    },
    validationSchema,
    onSubmit: handleEmailPasswordSignUp,
  });

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const getRoleDashboard = (role) => {
    switch (role) {
      case 'journalist':
        return '/my-journalist-dashboard';
      case 'educator':
        return '/my-teaching-dashboard';
      case 'business':
        return '/my-business-dashboard';
      case 'digital-marketer':
        return '/my-digital-marketing-dashboard';
      case 'writer':
        return '/my-writing-dashboard';
      case 'user':
      default:
        return '/dashboard';
    }
  };

  async function handleEmailPasswordSignUp(values) {
    if (!recaptchaValue) {
      setErrorMessage('Please complete the reCAPTCHA');
      setOpenErrorDialog(true);
      return;
    }

    setLoading(true);
    try {
      // Check registration eligibility
      const eligibilityResponse = await axiosInstance.post(
        '/api/auth/check-eligibility',
        {
          email: values.email,
          phoneNumber: values.phoneNumber,
        }
      );

      console.log('Eligibility response:', eligibilityResponse);

      if (!eligibilityResponse.data.success) {
        throw new Error(eligibilityResponse.data.error);
      }

      // Validate referral code if provided
      const uppercaseReferralCode = values.referralCode.toUpperCase();
      if (uppercaseReferralCode) {
        try {
          await axiosInstance.post('/api/auth/validate-referral', {
            referralCode: uppercaseReferralCode,
          });
        } catch (error) {
          if (error.response) {
            setReferralErrorMessage(error.response.data.message);
            setOpenReferralErrorDialog(true);
            setLoading(false);
            return;
          }
        }
      }

      // Proceed with Firebase registration
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: `${values.firstName} ${values.lastName}`,
        photoURL: values.avatar,
      });

      // Register user in your backend
      await axiosInstance.post('/api/auth/register', {
        uid: user.uid,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        avatar: values.avatar,
        role: values.role,
        referralCode: uppercaseReferralCode,
        recaptcha: recaptchaValue,
        phoneNumber: values.phoneNumber,
        companyName: values.companyName,
      });

      // Store user role in localStorage for future use
      localStorage.setItem('userRole', values.role);

      navigate('/email-verification');
    } catch (error) {
      console.error(
        'Error details:',
        error.response ? error.response.data : error.message
      );

      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage(
          'This email is already associated with an account. Please use a different email or try logging in.'
        );
      } else if (error.response && error.response.data.error) {
        if (error.response.data.error === 'Email already in use') {
          setErrorMessage(
            'This email is already associated with an account. Please use a different email or try logging in.'
          );
        } else if (
          error.response.data.error === 'Phone number already in use'
        ) {
          setErrorMessage(
            'This phone number is already associated with another account. Please register using a different phone number.'
          );
        } else {
          setErrorMessage(error.response.data.error);
        }
      } else {
        setErrorMessage(
          'An error occurred during registration. Please try again.'
        );
      }
      setOpenErrorDialog(true);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    if (errorMessage.includes('email')) {
      formik.setFieldValue('email', '');
      document.getElementById('email').focus();
    } else if (errorMessage.includes('phone number')) {
      formik.setFieldValue('phoneNumber', '');
      setPhoneNumber('+91');
      setTimeout(() => {
        const phoneInput = document.querySelector('input[name="phoneNumber"]');
        if (phoneInput) {
          phoneInput.focus();
        }
      }, 0);
    }
  };

  async function handleGoogleSignUp() {
    console.log('Starting Google sign-up process');
    try {
      // 1. Sign in with Google
      console.log('Attempting to sign in with Google');
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log('Google sign-in successful', {
        uid: user.uid,
        email: user.email,
      });

      // 2. Send user data to backend
      console.log('Sending user data to backend');
      const response = await axiosInstance.post('/api/auth/google-register', {
        uid: user.uid,
        email: user.email,
        firstName: user.displayName ? user.displayName.split(' ')[0] : '',
        lastName: user.displayName
          ? user.displayName.split(' ').slice(1).join(' ')
          : '',
        avatar: user.photoURL,
        googleId: user.providerData[0]?.uid,
        emailVerified: user.emailVerified,
      });
      console.log('Backend response received', response.data);

      if (response.data.success) {
        console.log('Registration successful');
        if (response.data.needsCompletion) {
          console.log('User needs to complete registration');
          // Store partial user data and redirect to complete registration
          localStorage.setItem(
            'googleUser',
            JSON.stringify(response.data.partialUser)
          );
          console.log('Stored partial user data in localStorage');
          console.log('Navigating to /complete-registration');
          navigate('/complete-registration');
        } else {
          console.log('User is fully registered');
          // User is fully registered, set auth state and redirect to dashboard
          const accessToken = await user.getIdToken();
          console.log('Got access token from Firebase');
          setAuthState({
            user: response.data.user,
            accessToken: accessToken,
            isAuthenticated: true,
            isLoading: false,
          });
          console.log('Auth state set');
          localStorage.setItem('userRole', response.data.user.role);
          console.log('User role stored in localStorage');
          const dashboardRoute = getRoleDashboard(response.data.user.role);
          console.log('Navigating to dashboard', dashboardRoute);
          navigate(dashboardRoute);
        }
      } else {
        console.log('Registration failed', response.data.message);
        throw new Error(response.data.message || 'Failed to register user');
      }
    } catch (error) {
      console.error('Google sign-up failed:', error);
      setErrorMessage('Google sign-up failed. Please try again.');
      setOpenErrorDialog(true);
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    formik.setFieldValue(
      'role',
      selectedRole === 'other' ? 'user' : selectedRole
    );
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Create an account
      </Typography>
      <Typography color="text.secondary" marginBottom={2}>
        And get 10,000 credits free to explore our various tools - no
        credit/debit card required!
      </Typography>

      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleSignUp}
        sx={{ mb: 2 }}
      >
        Sign up with Google
      </Button>

      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" color="text.secondary">
          OR
        </Typography>
      </Divider>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First name"
              variant="outlined"
              name={'firstName'}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last name"
              variant="outlined"
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              name={'password'}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <MuiTelInput
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={handlePhoneChange}
                defaultCountry="IN"
                fullWidth
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                margin="normal"
              />
              <Typography variant="caption" color="textSecondary">
                We require your phone number to provide better support and to
                prevent misuse of our services.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Company Name (Optional)"
              variant="outlined"
              name="companyName"
              fullWidth
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              <InputLabel id="role-select-label">
                How do you identify yourself?
              </InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                value={formik.values.role}
                label="Role"
                onChange={handleRoleChange}
              >
                <MenuItem value="writer">Writer</MenuItem>
                <MenuItem value="journalist">Journalist</MenuItem>
                <MenuItem value="educator">Educator</MenuItem>
                <MenuItem value="business">Business</MenuItem>
                <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {formik.touched.role && formik.errors.role && (
                <FormHelperText>{formik.errors.role}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Referral Code (Optional)"
              variant="outlined"
              name="referralCode"
              fullWidth
              value={formik.values.referralCode}
              onChange={formik.handleChange}
              error={
                formik.touched.referralCode &&
                Boolean(formik.errors.referralCode)
              }
              helperText={
                formik.touched.referralCode && formik.errors.referralCode
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              Select your Avatar
            </Typography>
            <AvatarSelection
              selectedAvatar={formik.values.avatar}
              setSelectedAvatar={(avatar) =>
                formik.setFieldValue('avatar', avatar)
              }
            />
            {formik.touched.avatar && Boolean(formik.errors.avatar) && (
              <Typography
                variant={'subtitle2'}
                sx={{ color: 'red', marginTop: 2 }}
              >
                {formik.errors.avatar}
              </Typography>
            )}
          </Grid>
          <RegistrationFormBottom
            formik={formik}
            termsAccepted={termsAccepted}
            setTermsAccepted={setTermsAccepted}
            loading={loading}
            handleCaptchaChange={handleCaptchaChange}
          />
        </Grid>
      </form>

      <Dialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Registration Error</DialogTitle>
        <DialogContent>
          <Typography id="error-dialog-description">{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <EmailUseDialogComponent
        open={openEmailInUseDialog}
        handleClose={() => setOpenEmailInUseDialog(false)}
        navigate={navigate}
      />
      <Dialog
        open={openReferralErrorDialog}
        onClose={() => setOpenReferralErrorDialog(false)}
      >
        <DialogTitle>Referral Code Error</DialogTitle>
        <DialogContent>
          <Typography>{referralErrorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenReferralErrorDialog(false)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RegistrationForm;
