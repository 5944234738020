import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import instance from '../../helpers/axiosInstance';
import { CircularProgress } from '@mui/material';
import SuccessfulVerification from './SuccessfulVerification';
import FailedVerification from './FailedVerification';

const VerifyEmail = () => {
  const [status, setStatus] = useState('loading');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await instance.get(`/api/auth/verifyemail/${token}`);
        if (response.data.success) {
          setStatus('success');
        } else {
          setStatus('failed');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('failed');
      }
    };
    verifyEmail();
  }, [token]);

  // Separate useEffect for handling redirection based on status
  useEffect(() => {
    if (status === 'success') {
      navigate('/successful-verification');
    } else if (status === 'failed') {
      navigate('/failed-verification');
    }
  }, [status, navigate]);

  if (status === 'loading') {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (status === 'success') {
    return <SuccessfulVerification />;
  }

  if (status === 'failed') {
    return <FailedVerification />;
  }

  return null; // This should ideally never be reached
};

export default VerifyEmail;
