// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
// import { authState } from '../../atoms';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import axiosInstance from '../../helpers/axiosInstance';
// import { MuiTelInput } from 'mui-tel-input';
// import {
//   Box,
//   Grid,
//   Typography,
//   Button,
//   TextField,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
//   useTheme,
//   useMediaQuery,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import completeRegistrationImage from '../../assets/images/completeRegistrationImage.svg';

// const validationSchema = yup.object({
//   phoneNumber: yup.string().required('Phone number is required'),
//   role: yup.string().required('Role selection is required'),
//   companyName: yup
//     .string()
//     .max(100, 'Company name must be at most 100 characters'),
//   referralCode: yup
//     .string()
//     .max(40, 'Referral code must be at most 40 characters'),
// });

// const CompleteRegistration = () => {
//   const navigate = useNavigate();
//   const [auth, setAuthState] = useRecoilState(authState);
//   const [loading, setLoading] = useState(false);
//   const [googleUser, setGoogleUser] = useState(null);
//   const [error, setError] = useState('');
//   const [openErrorDialog, setOpenErrorDialog] = useState(false);
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'));

//   useEffect(() => {
//     console.log('CompleteRegistration: Component mounted');
//     console.log('CompleteRegistration: Current auth state:', auth);

//     const storedGoogleUser = localStorage.getItem('googleUser');
//     console.log('CompleteRegistration: Stored Google User:', storedGoogleUser);

//     if (storedGoogleUser) {
//       const parsedUser = JSON.parse(storedGoogleUser);
//       setGoogleUser(parsedUser);
//       console.log('CompleteRegistration: Google User set in state');

//       // Set a temporary auth state for the completion process
//       setAuthState((prevState) => ({
//         ...prevState,
//         user: parsedUser,
//         isAuthenticated: true,
//         isLoading: false,
//       }));
//     } else {
//       console.log(
//         'CompleteRegistration: No stored Google User, navigating to /register'
//       );
//       navigate('/register');
//     }

//     return () => {
//       console.log('CompleteRegistration: Component unmounting');
//     };
//   }, [navigate, setAuthState]);

//   // Log when the component re-renders
//   console.log('CompleteRegistration component rendering');

//   const getRoleDashboard = (role) => {
//     switch (role) {
//       case 'journalist':
//         return '/my-journalist-dashboard';
//       case 'educator':
//         return '/my-teaching-dashboard';
//       case 'business':
//         return '/my-business-dashboard';
//       case 'digital-marketer':
//         return '/my-digital-marketing-dashboard';
//       case 'writer':
//         return '/my-writing-dashboard';
//       default:
//         return '/dashboard';
//     }
//   };

//   const handleCloseErrorDialog = () => {
//     setOpenErrorDialog(false);
//     navigate('/register');
//   };

//   const formik = useFormik({
//     initialValues: {
//       phoneNumber: '',
//       role: '',
//       companyName: '',
//       referralCode: '',
//     },
//     validationSchema,
//     onSubmit: async (values) => {
//       setLoading(true);
//       setError('');
//       try {
//         const response = await axiosInstance.post(
//           '/api/auth/complete-registration',
//           {
//             ...googleUser,
//             ...values,
//             referralCode: values.referralCode.toUpperCase(),
//           }
//         );

//         if (response.data.success) {
//           localStorage.removeItem('googleUser');

//           setAuthState({
//             user: response.data.user,
//             accessToken: response.data.accessToken,
//             isAuthenticated: true,
//             isLoading: false,
//           });

//           localStorage.setItem('userRole', response.data.user.role);
//           const dashboardRoute = getRoleDashboard(response.data.user.role);
//           navigate(dashboardRoute);
//         } else {
//           throw new Error(
//             response.data.message || 'Failed to complete registration'
//           );
//         }
//       } catch (error) {
//         console.error('Registration completion failed:', error);
//         if (
//           error.response &&
//           error.response.data.action === 'delete_and_redirect'
//         ) {
//           setError('Phone number is already associated with another account.');
//           setOpenErrorDialog(true);
//         } else {
//           setError(
//             error.response?.data?.message ||
//               error.message ||
//               'An error occurred. Please try again.'
//           );
//         }
//       } finally {
//         setLoading(false);
//       }
//     },
//   });

//   if (!googleUser) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box
//       px={1}
//       width="100%"
//       height="100vh"
//       mx="auto"
//       position="relative"
//       zIndex={2}
//     >
//       <Grid
//         container
//         spacing={1}
//         justifyContent="center"
//         alignItems="center"
//         height="100%"
//       >
//         {isMd && (
//           <Grid item container justifyContent={'center'} xs={12} md={6}>
//             <Box height={1} width={1} maxWidth={500}>
//               <Box
//                 component={'img'}
//                 src={completeRegistrationImage}
//                 width={1}
//                 height={1}
//                 sx={{
//                   filter:
//                     theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
//                 }}
//               />
//             </Box>
//           </Grid>
//         )}
//         <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
//           <Box mt={3} mb={1} textAlign="center">
//             <Typography variant="h4" color="text.primary" gutterBottom>
//               Complete Your Registration
//             </Typography>
//             <Typography variant="body1" color="text.secondary" paragraph>
//               Welcome to Wisdom Scribe! Please provide a few more details to
//               complete your registration and customize your experience.
//             </Typography>
//             {error && !openErrorDialog && (
//               <Alert severity="error" sx={{ mb: 2 }}>
//                 {error}
//               </Alert>
//             )}
//             <form onSubmit={formik.handleSubmit}>
//               <MuiTelInput
//                 label="Phone Number"
//                 value={formik.values.phoneNumber}
//                 onChange={(value) => formik.setFieldValue('phoneNumber', value)}
//                 fullWidth
//                 margin="normal"
//                 error={
//                   formik.touched.phoneNumber &&
//                   Boolean(formik.errors.phoneNumber)
//                 }
//                 helperText={
//                   formik.touched.phoneNumber && formik.errors.phoneNumber
//                 }
//               />
//               <FormControl fullWidth margin="normal">
//                 <InputLabel id="role-select-label">Role</InputLabel>
//                 <Select
//                   labelId="role-select-label"
//                   id="role"
//                   name="role"
//                   value={formik.values.role}
//                   label="Role"
//                   onChange={formik.handleChange}
//                   error={formik.touched.role && Boolean(formik.errors.role)}
//                 >
//                   <MenuItem value="writer">Writer</MenuItem>
//                   <MenuItem value="journalist">Journalist</MenuItem>
//                   <MenuItem value="educator">Educator</MenuItem>
//                   <MenuItem value="business">Business</MenuItem>
//                   <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
//                   <MenuItem value="other">Other</MenuItem>
//                 </Select>
//               </FormControl>
//               <TextField
//                 fullWidth
//                 label="Company Name (Optional)"
//                 name="companyName"
//                 value={formik.values.companyName}
//                 onChange={formik.handleChange}
//                 margin="normal"
//               />
//               <TextField
//                 fullWidth
//                 label="Referral Code (Optional)"
//                 name="referralCode"
//                 value={formik.values.referralCode}
//                 onChange={formik.handleChange}
//                 margin="normal"
//               />
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 disabled={loading}
//                 sx={{ mt: 2 }}
//               >
//                 {loading ? (
//                   <CircularProgress size={24} />
//                 ) : (
//                   'Complete Registration'
//                 )}
//               </Button>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//       <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
//         <DialogTitle>Registration Error</DialogTitle>
//         <DialogContent>
//           <Typography>{error}</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseErrorDialog} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default CompleteRegistration;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authState } from '../../atoms';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosInstance from '../../helpers/axiosInstance';
import { MuiTelInput } from 'mui-tel-input';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import completeRegistrationImage from '../../assets/images/completeRegistrationImage.svg';

const validationSchema = yup.object({
  phoneNumber: yup.string().required('Phone number is required'),
  role: yup.string().required('Role selection is required'),
  companyName: yup
    .string()
    .max(100, 'Company name must be at most 100 characters'),
  referralCode: yup
    .string()
    .max(40, 'Referral code must be at most 40 characters'),
});

const CompleteRegistration = () => {
  const navigate = useNavigate();
  const [auth, setAuthState] = useRecoilState(authState);
  const [loading, setLoading] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [error, setError] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [referralError, setReferralError] = useState('');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    console.log('CompleteRegistration: Component mounted');
    console.log('CompleteRegistration: Current auth state:', auth);

    const storedGoogleUser = localStorage.getItem('googleUser');
    console.log('CompleteRegistration: Stored Google User:', storedGoogleUser);

    if (storedGoogleUser) {
      const parsedUser = JSON.parse(storedGoogleUser);
      setGoogleUser(parsedUser);
      console.log('CompleteRegistration: Google User set in state');

      setAuthState((prevState) => ({
        ...prevState,
        user: parsedUser,
        isAuthenticated: true,
        isLoading: false,
      }));
    } else {
      console.log(
        'CompleteRegistration: No stored Google User, navigating to /register'
      );
      navigate('/register');
    }

    return () => {
      console.log('CompleteRegistration: Component unmounting');
    };
  }, [navigate, setAuthState]);

  console.log('CompleteRegistration component rendering');

  const getRoleDashboard = (role) => {
    switch (role) {
      case 'journalist':
        return '/my-journalist-dashboard';
      case 'educator':
        return '/my-teaching-dashboard';
      case 'business':
        return '/my-business-dashboard';
      case 'digital-marketer':
        return '/my-digital-marketing-dashboard';
      case 'writer':
        return '/my-writing-dashboard';
      default:
        return '/dashboard';
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    navigate('/register');
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      role: '',
      companyName: '',
      referralCode: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError('');
      setReferralError('');
      try {
        // First, validate the referral code if provided
        if (values.referralCode) {
          try {
            await axiosInstance.post('/api/auth/validate-referral', {
              referralCode: values.referralCode.toUpperCase(),
            });
          } catch (referralError) {
            if (referralError.response && referralError.response.data.message) {
              setReferralError(referralError.response.data.message);
              setLoading(false);
              return;
            }
          }
        }

        const response = await axiosInstance.post(
          '/api/auth/complete-registration',
          {
            ...googleUser,
            ...values,
            referralCode: values.referralCode.toUpperCase(),
          }
        );

        if (response.data.success) {
          localStorage.removeItem('googleUser');

          setAuthState({
            user: response.data.user,
            accessToken: response.data.accessToken,
            isAuthenticated: true,
            isLoading: false,
          });

          localStorage.setItem('userRole', response.data.user.role);
          const dashboardRoute = getRoleDashboard(response.data.user.role);
          navigate(dashboardRoute);
        } else {
          throw new Error(
            response.data.message || 'Failed to complete registration'
          );
        }
      } catch (error) {
        console.error('Registration completion failed:', error);
        if (
          error.response &&
          error.response.data.action === 'delete_and_redirect'
        ) {
          setError('Phone number is already associated with another account.');
          setOpenErrorDialog(true);
        } else {
          setError(
            error.response?.data?.message ||
              error.message ||
              'An error occurred. Please try again.'
          );
        }
      } finally {
        setLoading(false);
      }
    },
  });

  if (!googleUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd && (
          <Grid item container justifyContent={'center'} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={'img'}
                src={completeRegistrationImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary" gutterBottom>
              Complete Your Registration
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Welcome to Wisdom Scribe! Please provide a few more details to
              complete your registration and customize your experience.
            </Typography>
            {error && !openErrorDialog && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {referralError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {referralError}
              </Alert>
            )}
            <form onSubmit={formik.handleSubmit}>
              <MuiTelInput
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                fullWidth
                margin="normal"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role"
                  name="role"
                  value={formik.values.role}
                  label="Role"
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                >
                  <MenuItem value="writer">Writer</MenuItem>
                  <MenuItem value="journalist">Journalist</MenuItem>
                  <MenuItem value="educator">Educator</MenuItem>
                  <MenuItem value="business">Business</MenuItem>
                  <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Company Name (Optional)"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Referral Code (Optional)"
                name="referralCode"
                value={formik.values.referralCode}
                onChange={formik.handleChange}
                margin="normal"
                error={Boolean(referralError)}
                helperText={referralError}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Complete Registration'
                )}
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
        <DialogTitle>Registration Error</DialogTitle>
        <DialogContent>
          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompleteRegistration;
