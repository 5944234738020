import React from "react";
import { colors } from "@mui/material";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import { alpha } from "@mui/material";

const pressReleaseRewriterDigital = {
  title: "Press Release Rewriter - Digital",
  subtitle: "Transform press releases into SEO-optimized digital content.",
  targetRoute: "/press-release-rewriter-digital",
  color: colors.blue[700],
  bgcolor: alpha(colors.blue[700], 0.1),
  icon: <TabletMacIcon fontSize="medium" />,
  tags: [],
};

export default pressReleaseRewriterDigital;
