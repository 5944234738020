import createBusinessToolsTemplate from "../../BusinessToolsTemplate";

const proposalWriter = createBusinessToolsTemplate({
  name: "proposalwriter",
  endpoint: "/api/openai/proposal-writer",
  aiText: "proposalWriter",
  aiTextHandler: "proposalWriterHandler",
  aiTextPlainText: "proposalWriterPlainText",
  title: "Proposal Writer",
  subText:
    "Input the proposal type and project details, and let our Proposal Writer do the heavy lifting. It generates persuasive, professionally-structured proposals, increasing your chances of winning projects and saving you time to focus on what you do best.",
  label: "Proposal",
  placeholderText: "Enter details of the proposal…",
  buttonText: "Draft Proposal",
  aiPlaceholder: "Proposal will appear here",
});

export default proposalWriter;
