import React, { useRef, useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  Grid,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import filterAndRenderHistory from './filterAndRenderHistory';

import instance from '../../helpers/axiosInstance';
import { authState } from '../../atoms';

const UserHistoryScreen = (props) => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated, accessToken, refreshToken, tokenExpiry } = auth;

  const [userHistory, setUserHistory] = useState([]);
  const [search, setSearch] = useState('');
  const [contentTypeFilter, setContentTypeFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState('');
  const [page, setPage] = useState(1);

  const itemsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleContentTypeFilterChange = (event) => {
    setContentTypeFilter(event.target.value);
  };

  const handleDateRangeFilterChange = (event) => {
    setDateRangeFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchUserHistory = async () => {
      try {
        const response = await instance.get('/api/auth/history', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setUserHistory(response.data);
      } catch (error) {
        console.error('Error fetching user history:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserHistory();
    }
  }, [isAuthenticated, accessToken]);

  // Handle click on "View full content" button
  const handleViewFullContent = (historyItemId) => {
    window.open(`/history/${historyItemId}`, '_blank');
  };

  // Render user history items using filterAndRenderHistory function
  const renderedHistory = filterAndRenderHistory(
    userHistory,
    search,
    contentTypeFilter,
    dateRangeFilter,
    handleViewFullContent
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your History
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="content-type-label">Content Type</InputLabel>
              <Select
                labelId="content-type-label"
                id="content-type-select"
                value={contentTypeFilter}
                onChange={handleContentTypeFilterChange}
                label="Content Type"
              >
                <MenuItem value={'analogyCreatorTeaching'}>
                  Analogy Creator Teaching
                </MenuItem>
                <MenuItem value={'analogyMaker'}>Analogy Maker</MenuItem>
                <MenuItem value={'blogContentPlanner'}>
                  Blog Content Planner
                </MenuItem>
                <MenuItem value={'blogOutlineBuilder'}>
                  Blog Outline Builder
                </MenuItem>
                <MenuItem value={'blogTopicIdeas'}>Blog Topic Ideas</MenuItem>
                <MenuItem value={'blogWriter'}>Blog Writer</MenuItem>
                <MenuItem value={'blogWriterAida'}>Blog Writer AIDA</MenuItem>
                <MenuItem value={'blogWriterBenefits'}>
                  Blog Writer Benefits
                </MenuItem>
                <MenuItem value={'blogWriterFromSpeech'}>
                  Blog Writer From Speech
                </MenuItem>
                <MenuItem value={'blogWriterHowTo'}>
                  Blog Writer How To
                </MenuItem>
                <MenuItem value={'blogWriterPas'}>Blog Writer PAS</MenuItem>
                <MenuItem value={'blogWriterStorytelling'}>
                  Blog Writer Storytelling
                </MenuItem>
                <MenuItem value={'breakingNewsAlertGenerator'}>
                  Breaking News Alert Generator
                </MenuItem>
                <MenuItem value={'contentIdeasGenerator'}>
                  Content Ideas Generator
                </MenuItem>
                <MenuItem value={'customerEmailResponder'}>
                  Customer Email Responder
                </MenuItem>
                <MenuItem value={'difficultQuestionPaperMaker'}>
                  Difficult Question Paper Maker
                </MenuItem>
                <MenuItem value={'flashcardMaker'}>Flashcard Maker</MenuItem>
                <MenuItem value={'grammarCorrector'}>
                  Grammar Corrector
                </MenuItem>
                <MenuItem value={'headlineGeneratorBroadcast'}>
                  Headline Generator Broadcast
                </MenuItem>
                <MenuItem value={'headlineGeneratorDigital'}>
                  Headline Generator Digital
                </MenuItem>
                <MenuItem value={'headlineGeneratorPrint'}>
                  Headline Generator Print
                </MenuItem>
                <MenuItem value={'headlineIdeasWriter'}>
                  Headline Ideas Writer
                </MenuItem>
                <MenuItem value={'homeworkAssigner'}>
                  Homework Assigner
                </MenuItem>
                <MenuItem value={'individualLearningPlanCreator'}>
                  Individual Learning Plan Creator
                </MenuItem>
                <MenuItem value={'interactiveActivityDesigner'}>
                  Interactive Activity Designer
                </MenuItem>
                <MenuItem value={'interactiveContentGenerator'}>
                  Interactive Content Generator
                </MenuItem>
                <MenuItem value={'interviewQuestionGenerator'}>
                  Interview Question Generator
                </MenuItem>
                <MenuItem value={'keywordResearchTool'}>
                  Keyword Research Tool
                </MenuItem>
                <MenuItem value={'landingPageHeadlineGenerator'}>
                  Landing Page Headline Generator
                </MenuItem>
                <MenuItem value={'lessonPlanner'}>Lesson Planner</MenuItem>
                <MenuItem value={'mixedQuestionPaperMaker'}>
                  Mixed Question Paper Maker
                </MenuItem>
                <MenuItem value={'narrateToChild'}>Narrate To Child</MenuItem>
                <MenuItem value={'newsReportBroadcast'}>
                  News Report Broadcast
                </MenuItem>
                <MenuItem value={'newsReportDigital'}>
                  News Report Digital
                </MenuItem>
                <MenuItem value={'newsReportPrint'}>News Report Print</MenuItem>
                <MenuItem value={'newsSummaryTool'}>News Summary Tool</MenuItem>
                <MenuItem value={'outlineBasedBlogWriter'}>
                  Outline Based Blog Writer
                </MenuItem>
                <MenuItem value={'performanceReportCreator'}>
                  Performance Report Creator
                </MenuItem>
                <MenuItem value={'pitchDeckMaker'}>Pitch Deck Maker</MenuItem>
                <MenuItem value={'podcastCreator'}>Podcast Creator</MenuItem>
                <MenuItem value={'pressReleaseRewriterBroadcast'}>
                  Press Release Rewriter Broadcast
                </MenuItem>
                <MenuItem value={'pressReleaseRewriterDigital'}>
                  Press Release Rewriter Digital
                </MenuItem>
                <MenuItem value={'pressReleaseRewriterPrint'}>
                  Press Release Rewriter Print
                </MenuItem>
                <MenuItem value={'productDescriptionGenerator'}>
                  Product Description Generator
                </MenuItem>
                <MenuItem value={'proposalWriter'}>Proposal Writer</MenuItem>
                <MenuItem value={'quizGeneratorTeaching'}>
                  Quiz Generator Teaching
                </MenuItem>
                <MenuItem value={'relatedTopicsResearch'}>
                  Related Topics Research
                </MenuItem>
                <MenuItem value={'revisionNotes'}>Revision Notes</MenuItem>
                <MenuItem value={'simpleKeywordResearchTool'}>
                  Simple Keyword Research Tool
                </MenuItem>
                <MenuItem value={'simpleQuestionPaperMaker'}>
                  Simple Question Paper Maker
                </MenuItem>
                <MenuItem value={'socialMediaContentGenerator'}>
                  Social Media Content Generator
                </MenuItem>
                <MenuItem value={'socialMediaPostGenerator'}>
                  Social Media Post Generator
                </MenuItem>
                <MenuItem value={'stepByStepProductGuideWriter'}>
                  Step By Step Product Guide Writer
                </MenuItem>
                <MenuItem value={'summaryFromSpeech'}>
                  Summary From Speech
                </MenuItem>
                <MenuItem value={'teaserWriter'}>Teaser Writer</MenuItem>
                <MenuItem value={'textReadabilityScanner'}>
                  Text Readability Scanner
                </MenuItem>
                <MenuItem value={'tickerTextGenerator'}>
                  Ticker Text Generator
                </MenuItem>
                <MenuItem value={'topicExplainerTeaching'}>
                  Topic Explainer Teaching
                </MenuItem>
                <MenuItem value={'transcriptionFromSpeech'}>
                  Transcription From Speech
                </MenuItem>
                <MenuItem value={'ultimateTopicResearcher'}>
                  Ultimate Topic Researcher
                </MenuItem>
                <MenuItem value={'websiteContentPlanner'}>
                  Website Content Planner
                </MenuItem>
                <MenuItem value={'websitePageContentGenerator'}>
                  Website Page Content Generator
                </MenuItem>
                <MenuItem value={'youTubeScriptwriter'}>
                  YouTube Scriptwriter
                </MenuItem>
                <MenuItem value={'youTubeTagGenerator'}>
                  YouTube Tag Generator
                </MenuItem>
                <MenuItem
                  value={'youTubeTitleDescriptionKeywordsHashtagsGenerator'}
                >
                  YouTube Title, Description, Keywords, Hashtags Generator
                </MenuItem>
                {/* Add more content types if needed */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="date-range-label">Date Range</InputLabel>
              <Select
                labelId="date-range-label"
                id="date-range-select"
                value={dateRangeFilter}
                onChange={handleDateRangeFilterChange}
                label="Date Range"
              >
                <MenuItem value={'today'}>Today</MenuItem>
                <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                <MenuItem value={'thisWeek'}>This Week</MenuItem>
                <MenuItem value={'thisMonth'}>This Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" mt={3}>
          <Grid item xs={12}>
            {renderedHistory.slice(
              (page - 1) * itemsPerPage,
              page * itemsPerPage
            )}
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(renderedHistory.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                shape="rounded"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserHistoryScreen;
