const pages = {
  about: [
    {
      title: 'About us',
      path: '/about',
    },
    {
      title: 'Contact us',
      path: '/contact',
    },
    {
      title: 'Support',
      path: '/support',
    },
    {
      title: 'Request new feature',
      path: '/new-feature',
    },
    {
      title: 'FAQ',
      path: '/faq',
    },
    {
      title: 'Terms and Privacy',
      path: '/company-terms',
    },
  ],

  tools: [
    {
      title: 'All Text Tools',
      path: '/dashboard',
    },
    {
      title: 'Business Tools',
      path: '/my-business-tools',
    },
    {
      title: 'Content Writers',
      path: '/my-content-writing-tools',
    },

    {
      title: 'SEO Tools',
      path: '/my-seo-tools',
    },
    {
      title: 'Social Media Tools',
      path: '/my-social-media-tools',
    },

    {
      title: 'Teaching Tools',
      path: '/my-teaching-tools',
    },
    {
      title: 'News Writers',
      path: '/my-news-writing-tools',
    },
  ],

  image: [
    {
      title: 'Create Image',
      path: '/create-image',
    },
  ],
  voicelab: [
    {
      title: 'Voice Lab',
      path: '/voice-lab',
    },
    {
      title: 'Transcription',
      path: '/transcription-from-speech',
    },
    {
      title: 'Translation',
      path: '/translation-from-speech',
    },
  ],

  talk2text: [
    {
      title: 'Transcription',
      path: '/transcription-from-speech',
    },
    {
      title: 'Translation',
      path: '/translation-from-speech',
    },
  ],

  pricing: [
    {
      title: 'Pricing',
      path: '/pricing',
    },
    {
      title: 'Billing Portal',
      path: '/billing-portal',
    },
  ],
  dashboard: [
    {
      title: 'My Dashboard',
      path: '/dashboard',
    },
    {
      title: 'My Profile',
      path: '/user-profile',
    },
  ],

  contact: [
    {
      title: 'Contact us',
      path: '/contact',
    },
    {
      title: 'Support',
      path: '/support',
    },
    {
      title: 'Request new feature',
      path: '/new-feature',
    },
  ],
};

export default pages;
