import React from "react";
import { colors } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { alpha } from "@mui/material";

const contentIdeasGenerator = {
  title: "Content Ideas Generator",
  subtitle:
    "Generate compelling and audience-tailored content ideas effortlessly.",
  targetRoute: "/content-ideas-generator",
  color: colors.yellow[800],
  bgcolor: alpha(colors.yellow[800], 0.1),
  icon: <TipsAndUpdatesIcon fontSize="medium" />,
  tags: [],
};

export default contentIdeasGenerator;
