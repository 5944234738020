// import React, { useState, useEffect } from 'react';
// import { Button, Box, Typography, CircularProgress } from '@mui/material';

// const PRESENTATION_DATA = {
//   title: 'The Divinity of Jesus: Historical and Theological Perspectives',
//   slides: [
//     {
//       layout: 'TITLE',
//       content: {
//         title: 'The Divinity of Jesus: Historical and Theological Perspectives',
//         subtitle: 'Exploring the Foundations of Christian Faith',
//       },
//     },
//     {
//       layout: 'TWO_COLUMNS',
//       content: {
//         title: "Jesus' Teachings and Ministry",
//         leftColumn: {
//           title: 'Core Teachings',
//           content:
//             '• Love God and neighbor (Mark 12:30-31)\n• Forgiveness and reconciliation (Matthew 6:14-15)\n• Compassion for the marginalized (Luke 4:18-19)\n• Kingdom of God (Mark 1:15)\n• Selfless service (Mark 10:45)',
//         },
//         rightColumn: {
//           title: 'Transformative Impact',
//           content:
//             "Jesus' ministry profoundly impacted individuals and society:\n\n• Healing the sick and outcast\n• Empowering women and children\n• Challenging religious and social norms\n• Inspiring a movement of radical love",
//         },
//       },
//     },
//   ],
// };

// const GoogleSlidesCreator = () => {
//   const [presentationId, setPresentationId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://apis.google.com/js/api.js';
//     script.onload = () => {
//       window.gapi.load('client:auth2', initClient);
//     };
//     document.body.appendChild(script);
//   }, []);

//   const initClient = () => {
//     window.gapi.client
//       .init({
//         clientId:
//           '123565986852-obietl01e1jcglfirru21jm0bis9k7mr.apps.googleusercontent.com', // Replace with your actual Client ID
//         discoveryDocs: [
//           'https://slides.googleapis.com/$discovery/rest?version=v1',
//         ],
//         scope: 'https://www.googleapis.com/auth/presentations',
//         ux_mode: 'redirect',
//         redirect_uri: window.location.origin, // Make sure this matches your authorized redirect URIs in Google Cloud Console
//       })
//       .then(() => {
//         console.log('Google API client initialized');
//       })
//       .catch((error) => {
//         setError(error.details || 'An error occurred during initialization');
//       });
//   };
//   const createPresentation = async () => {
//     setLoading(true);
//     setError(null);

//     try {
//       // Ensure the user is signed in
//       await window.gapi.auth2.getAuthInstance().signIn();

//       // Create a new presentation
//       const createResponse =
//         await window.gapi.client.slides.presentations.create({
//           title: PRESENTATION_DATA.title,
//         });
//       const presentationId = createResponse.result.presentationId;

//       // Prepare requests for adding slides
//       const requests = PRESENTATION_DATA.slides.map((slide, index) => {
//         const request = {
//           createSlide: {
//             objectId: `slide_${index}`,
//             insertionIndex: index,
//             slideLayoutReference: { predefinedLayout: slide.layout },
//           },
//         };

//         if (slide.layout === 'TITLE') {
//           request.createSlide.placeholderIdMappings = [
//             {
//               layoutPlaceholder: { type: 'TITLE' },
//               objectId: `title_${index}`,
//             },
//             {
//               layoutPlaceholder: { type: 'SUBTITLE' },
//               objectId: `subtitle_${index}`,
//             },
//           ];
//         } else if (slide.layout === 'TWO_COLUMNS') {
//           request.createSlide.placeholderIdMappings = [
//             {
//               layoutPlaceholder: { type: 'TITLE' },
//               objectId: `title_${index}`,
//             },
//             {
//               layoutPlaceholder: { type: 'BODY', index: 0 },
//               objectId: `left_column_${index}`,
//             },
//             {
//               layoutPlaceholder: { type: 'BODY', index: 1 },
//               objectId: `right_column_${index}`,
//             },
//           ];
//         }

//         return request;
//       });

//       // Add content to slides
//       PRESENTATION_DATA.slides.forEach((slide, index) => {
//         if (slide.layout === 'TITLE') {
//           requests.push(
//             {
//               insertText: {
//                 objectId: `title_${index}`,
//                 text: slide.content.title,
//               },
//             },
//             {
//               insertText: {
//                 objectId: `subtitle_${index}`,
//                 text: slide.content.subtitle,
//               },
//             }
//           );
//         } else if (slide.layout === 'TWO_COLUMNS') {
//           requests.push(
//             {
//               insertText: {
//                 objectId: `title_${index}`,
//                 text: slide.content.title,
//               },
//             },
//             {
//               insertText: {
//                 objectId: `left_column_${index}`,
//                 text: `${slide.content.leftColumn.title}\n\n${slide.content.leftColumn.content}`,
//               },
//             },
//             {
//               insertText: {
//                 objectId: `right_column_${index}`,
//                 text: `${slide.content.rightColumn.title}\n\n${slide.content.rightColumn.content}`,
//               },
//             }
//           );
//         }
//       });

//       // Execute the requests
//       await window.gapi.client.slides.presentations.batchUpdate({
//         presentationId: presentationId,
//         resource: { requests: requests },
//       });

//       setPresentationId(presentationId);
//     } catch (error) {
//       setError(
//         error.message || 'An error occurred while creating the presentation'
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         Google Slides Creator
//       </Typography>
//       <Button
//         variant="contained"
//         onClick={createPresentation}
//         disabled={loading}
//       >
//         {loading ? <CircularProgress size={24} /> : 'Create Presentation'}
//       </Button>
//       {error && (
//         <Typography color="error" sx={{ mt: 2 }}>
//           Error: {error}
//         </Typography>
//       )}
//       {presentationId && (
//         <Box sx={{ mt: 2 }}>
//           <Typography>Presentation created successfully!</Typography>
//           <iframe
//             src={`https://docs.google.com/presentation/d/${presentationId}/embed`}
//             frameBorder="0"
//             width="100%"
//             height="400"
//             allowFullScreen
//             title="Created Presentation"
//           />
//         </Box>
//       )}
//     </Box>
//   );
// };

// // export default GoogleSlidesCreator;
// import React, { useState } from 'react';
// import {
//   Button,
//   Box,
//   Typography,
//   CircularProgress,
//   TextField,
// } from '@mui/material';
// import { getAuth } from 'firebase/auth';
// import instance from '../../../helpers/axiosInstance';
// import trackButtonClick from '../../../helpers/trackButtonClick';

// const GoogleSlidesCreator = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [title, setTitle] = useState('');

//   const createPresentation = async () => {
//     if (!title.trim()) {
//       setError('Please enter a title for the presentation');
//       return;
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       trackButtonClick('Google Slides Creator');

//       const firebaseAuth = getAuth();
//       const user = firebaseAuth.currentUser;
//       let idToken = '';
//       if (user) {
//         idToken = await user.getIdToken();
//       }

//       const response = await instance.post(
//         '/api/ppt/generate-google-slides',
//         { presentationData: { title } },
//         {
//           headers: { Authorization: `Bearer ${idToken}` },
//           responseType: 'blob', // Important: This tells axios to expect binary data
//         }
//       );

//       // Create a Blob from the response data
//       const blob = new Blob([response.data], {
//         type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
//       });

//       // Create a link element and trigger the download
//       const downloadUrl = window.URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = `${title}.pptx`;
//       document.body.appendChild(link);
//       link.click();
//       link.remove();

//       // Clean up the URL object
//       window.URL.revokeObjectURL(downloadUrl);
//     } catch (error) {
//       console.error('Error:', error);
//       setError('An error occurred while creating the presentation');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         Google Slides Creator
//       </Typography>
//       <TextField
//         fullWidth
//         label="Presentation Title"
//         variant="outlined"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//         margin="normal"
//       />
//       <Button
//         variant="contained"
//         onClick={createPresentation}
//         disabled={loading}
//         sx={{ mt: 2 }}
//       >
//         {loading ? (
//           <CircularProgress size={24} />
//         ) : (
//           'Create and Download Presentation'
//         )}
//       </Button>
//       {error && (
//         <Typography color="error" sx={{ mt: 2 }}>
//           Error: {error}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default GoogleSlidesCreator;

import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import instance from '../../../helpers/axiosInstance';
import trackButtonClick from '../../../helpers/trackButtonClick';

const GoogleSlidesCreator = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');

  const createPresentation = async () => {
    if (!title.trim()) {
      setError('Please enter a title for the presentation');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      trackButtonClick('Google Slides Creator');

      const firebaseAuth = getAuth();
      const user = firebaseAuth.currentUser;
      let idToken = '';
      if (user) {
        idToken = await user.getIdToken();
      }

      const response = await instance.post(
        '/api/ppt/generate-google-slides',
        { presentationData: { title } },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob', // Important: This tells axios to expect binary data
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      });

      // Create a link element and trigger the download
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${title}.pptx`;
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Clean up the URL object
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while creating the presentation');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Google Slides Creator
      </Typography>
      <TextField
        fullWidth
        label="Presentation Title"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        onClick={createPresentation}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          'Create and Download Presentation'
        )}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          Error: {error}
        </Typography>
      )}
    </Box>
  );
};

export default GoogleSlidesCreator;
