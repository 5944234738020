// import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';

// const VideoSection = () => {
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
//     defaultMatches: true,
//   });

//   const handleBookDemo = () => {
//     // Replace this URL with your actual Calendly link or preferred booking system
//     window.open('https://calendly.com/poorva-wisdomscribe/15min', '_blank');
//   };

//   return (
//     <Grid container spacing={4}>
//       <Grid item container alignItems={'center'} xs={12} md={6}>
//         <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
//           <Box marginBottom={2}>
//             <Typography
//               color={'primary'}
//               component={'span'}
//               variant="h4"
//               sx={{ fontWeight: 700 }}
//             >
//               Why choose Wisdom Scribe?
//             </Typography>
//           </Box>
//           <Box marginBottom={3}>
//             <Typography variant="h6" component="p" color="text.secondary">
//               Discover how our AI-powered tools can transform your business
//               operations, boost efficiency, and drive growth.
//             </Typography>
//           </Box>
//           <Box
//             display="flex"
//             flexDirection={'column'}
//             justifyContent={'center'}
//           >
//             <Box marginBottom={2}>
//               <Typography variant="body1" component="p">
//                 Ready to see Wisdom Scribe in action? Book a personalized demo
//                 now!
//               </Typography>
//             </Box>
//             <Box
//               display="flex"
//               flexDirection={{ xs: 'column', sm: 'row' }}
//               alignItems={{ xs: 'stretched', sm: 'flex-start' }}
//             >
//               <Box
//                 component={Button}
//                 variant="contained"
//                 color="primary"
//                 size="large"
//                 fullWidth={!isMd}
//                 onClick={handleBookDemo}
//               >
//                 Book a Demo
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Grid>
//       <Grid item xs={12} md={6}>
//         <Box
//           height={1}
//           width={1}
//           display={'flex'}
//           justifyContent={'center'}
//           alignItems={'center'}
//         >
//           <Box height={1} width={1} maxHeight={600}>
//             <Box
//               component={'iframe'}
//               title="Wisdom Scribe Demo Video"
//               width="100%"
//               height="100%"
//               minHeight={320}
//               src="https://www.youtube.com/embed/YOUR_YOUTUBE_VIDEO_ID"
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               sx={{
//                 boxShadow: 4,
//                 borderRadius: 2,
//               }}
//             />
//           </Box>
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default VideoSection;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const VideoSection = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleBookDemo = () => {
    // Replace this URL with your actual Calendly link or preferred booking system
    window.open('https://calendly.com/poorva-wisdomscribe/15min', '_blank');
  };

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              color={'primary'}
              component={'span'}
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              Why choose Wisdom Scribe?
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="text.secondary">
              Discover how our AI-powered tools can transform your business
              operations, boost efficiency, and drive growth.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Box marginBottom={2}>
              <Typography variant="body1" component="p">
                Ready to see Wisdom Scribe in action? Book a personalized demo
                now!
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={!isMd}
                onClick={handleBookDemo}
              >
                Book a Demo
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoSection;
