import createTeachingQuizGeneratorTemplate from "../../TeachingQuizGeneratorTemplate";

const quizGeneratorTeaching = createTeachingQuizGeneratorTemplate({
  name: "quizgeneratorteaching",
  endpoint: "/api/openai/quiz-generator-teaching",
  aiText: "quizGeneratorTeaching",
  aiTextHandler: "quizGeneratorTeachingHandler",
  aiTextPlainText: "quizGeneratorTeachingPlainText",
  title: "Quiz Generator",
  subText:
    'Make learning fun! Select the class, subject, and the chapters from your uploaded syllabus, and this tool will generate an engaging quiz, making assessment a joy. Remember, your uploaded syllabus guides the quiz content. Example: "Grade 9, Biology, Quiz for Cellular Respiration chapters."',
  label: "Interactive Quiz",
  placeholderText: "Any specific topics?",
  buttonText: "Create Quiz",
  aiPlaceholder: "Quiz will be displayed here",
});

export default quizGeneratorTeaching;
