import createNewsHeadlineTemplate from "../../NewsHeadlineTemplate";

const headlineGeneratorDigital = createNewsHeadlineTemplate({
  name: "headlinegeneratordigital",
  endpoint: "/api/openai/headline-generator-digital",
  aiText: "headlineGeneratorDigital",
  aiTextHandler: "headlineGeneratorDigitalHandler",
  aiTextPlainText: "headlineGeneratorDigitalPlainText",
  title: "Headline Generator - Digital",
  subText:
    "Our Digital Headline Generator crafts SEO-friendly headlines for online media. Input your topic, word count, and news info to generate engaging headlines, boosting your digital news presence.",
  label: "Headlines",
  placeholderText: "Enter Main News Here",
  buttonText: "Generate Headline",
  aiPlaceholder: "Headline will appear here",
});

export default headlineGeneratorDigital;
