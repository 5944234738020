import createTeachingTemplate from "../../TeachingTemplate";
const homeworkAssigner = createTeachingTemplate({
  name: "homeworkassigner",
  endpoint: "/api/openai/homework-assigner",
  aiText: "homeworkAssigner",
  aiTextHandler: "homeworkAssignerHandler",
  aiTextPlainText: "homeworkAssignerPlainText",
  title: "Homework Assigner",
  subText:
    'Homework that hits the mark! Select the class, subject, and chapters from your uploaded syllabus, and this tool will assign homework matching your students\' learning pace. Do upload your syllabus for the most appropriate homework tasks. Example: "Grade 10, Biology, Homework for 7th chapter."',
  label: "Custom Homework Assignment",
  placeholderText: "Any specific topics?",
  buttonText: "Compile Homework",
  aiPlaceholder: "Homework assignment will appear here",
});

export default homeworkAssigner;
