import React from "react";
import { colors } from "@mui/material";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { alpha } from "@mui/material";

const blogWriterPas = {
  title: "Blog Writer - PAS",
  subtitle: "Generate gripping narratives with solutions.",
  targetRoute: "/blog-writer-pas",
  color: colors.purple[600],
  bgcolor: alpha(colors.purple[600], 0.1),
  icon: <QuizOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriterPas;
