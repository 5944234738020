// import React, { useState } from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';

// import instance from '../../helpers/axiosInstance';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// import UploadingDialog from './Dialog/UploadingDialog';

// import { authState } from '../../atoms';

// function SyllabusUpload() {
//   const [images, setImages] = useState();
//   const [title, setTitle] = useState('');
//   const [standard, setStandard] = useState('');
//   const [subject, setSubject] = useState('');
//   const [chapterNumber, setChapterNumber] = useState('');
//   const [isUploading, setIsUploading] = useState(false);
//   const [imageError, setImageError] = useState(null);
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [uploadError, setUploadError] = useState('');

//   const standards = [...Array(12).keys()].map((i) => i + 1);
//   const subjects = [
// 'Accounting',
// 'Advertising',
// 'Aerospace Engineering',
// 'Agricultural Studies',
// 'Anatomy',
// 'Anthropology',
// 'Archaeology',
// 'Astronomy',
// 'Auditing',
// 'Biochemistry',
// 'Biology',
// 'Broadcasting',
// 'Business Administration',
// 'Business Law',
// 'Business Studies',
// 'Chemical Engineering',
// 'Chemistry',
// 'Civics',
// 'Civil Engineering',
// 'Computer Science',
// 'Creative Writing',
// 'Cultural Studies',
// 'Design and Technology',
// 'Digital Media',
// 'Economics',
// 'Electrical Engineering',
// 'Engineering',
// 'English Language',
// 'English Literature',
// 'Entrepreneurship',
// 'Environmental Engineering',
// 'Environmental Studies',
// 'Ethics',
// 'Film Studies',
// 'Finance',
// 'Forensic Science',
// 'Geography',
// 'Geology',
// 'Global Studies',
// 'Health Education',
// 'History',
// 'Home Economics',
// 'Human Resource Management',
// 'Journalism',
// 'Legal Studies',
// 'Linguistics',
// 'Marine Biology',
// 'Marketing',
// 'Mass Communication',
// 'Mathematics I',
// 'Mathematics II',
// 'Mechanical Engineering',
// 'Media Ethics',
// 'Media Production',
// 'Media Studies',
// 'Medicine',
// 'Meteorology',
// 'Microbiology',
// 'Military Science',
// 'Nutrition and Food Science',
// 'Obstetrics and Gynecology',
// 'Pathology',
// 'Pediatrics',
// 'Personal Finance',
// 'Pharmacology',
// 'Philosophy',
// 'Physics',
// 'Physiology',
// 'Political Science',
// 'Psychology',
// 'Public Relations',
// 'Religious Education',
// 'Robotics',
// 'Science',
// 'Social Studies',
// 'Sociology',
// 'Sports Science',
// 'Surgery',
// 'Taxation',
// 'Technology',
// 'Visual Communication',
// 'World Religions',
//   ];
//   const chapterNumbers = [...Array(30).keys()].map((i) => i + 1); // Chapters 1-30

//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const { isAuthenticated } = auth;

//   const handleFileChange = (e) => {
//     const selectedImages = e.target.files;
//     if (selectedImages.length > 10) {
//       setImageError('You can only upload up to 10 images.');
//     } else {
//       setImageError(null);
//       setImages(selectedImages);
//     }
//   };

//   const uploadImages = async (e) => {
//     e.preventDefault();
//     setIsUploading(true);
//     setUploadSuccess(false);
//     setUploadError('');

//     if (!images) {
//       console.error('No images selected for upload.');
//       setIsUploading(false);
//       return;
//     }

//     const selectedImages = Array.isArray(images) ? images : Array.from(images);
//     const formData = new FormData();
//     selectedImages.forEach((image, i) => {
//       formData.append('image', image);
//     });

//     formData.append('title', title);
//     formData.append('standard', standard);
//     formData.append('subject', subject);
//     formData.append('chapterNumber', chapterNumber);

//     try {
//       const response = await instance.post(
//         '/api/syllabus/upload-syllabus',
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );
//       setUploadSuccess(true);
//       console.log('Upload successful:', response.data);
//     } catch (error) {
//       console.error('Upload failed:', error);
//       console.error('Error response:', error.response?.data);
//       setUploadError(
//         error.response?.data?.error ||
//           'Failed to upload syllabus. Please try again.'
//       );
//     } finally {
//       setIsUploading(false);
//       setImages();
//       setTitle('');
//       setStandard('');
//       setSubject('');
//       setChapterNumber('');
//     }
//   };

//   if (!isAuthenticated) {
//     return (
//       <Box
//         sx={{
//           margin: { xs: '2rem', sm: '8rem' },
//           mt: 12,
//           maxWidth: 800,
//           mx: 'auto',
//         }}
//       >
//         <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
//           Authentication Required
//         </Typography>
//         <Typography color="text.secondary">
//           Please log in to upload your Syllabus.
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       component="form"
//       onSubmit={uploadImages}
//       sx={{ margin: { xs: '2rem', sm: '10rem' }, mt: 12 }}
//     >
//       <UploadingDialog open={isUploading} />

//       <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
//         Upload your syllabus
//       </Typography>
//       <Typography color="text.secondary">
//         Fill out the form and select images to upload.
//       </Typography>
//       <Grid container spacing={3} marginTop={2}>
//         <Grid item xs={12}>
//           <TextField
//             variant="outlined"
//             fullWidth
//             label="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="standard-label">Standard</InputLabel>
//             <Select
//               labelId="standard-label"
//               id="standard"
//               value={standard}
//               onChange={(e) => setStandard(e.target.value)}
//               label="Standard"
//             >
//               {standards.map((std) => (
//                 <MenuItem key={std} value={std}>
//                   {std}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="subject-label">Subject</InputLabel>
//             <Select
//               labelId="subject-label"
//               id="subject"
//               value={subject}
//               onChange={(e) => setSubject(e.target.value)}
//               label="Subject"
//             >
//               {subjects.map((sub) => (
//                 <MenuItem key={sub} value={sub}>
//                   {sub}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="chapter-number-label">Chapter Number</InputLabel>
//             <Select
//               labelId="chapter-number-label"
//               id="chapter-number"
//               value={chapterNumber}
//               onChange={(e) => setChapterNumber(e.target.value)}
//               label="Chapter Number"
//             >
//               {chapterNumbers.map((chapter) => (
//                 <MenuItem key={chapter} value={chapter}>
//                   {chapter}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Button
//             variant="outlined"
//             component="label"
//             fullWidth
//             sx={{ height: { xs: 'auto', md: '56px' } }}
//           >
//             Select Files
//             <input type="file" hidden multiple onChange={handleFileChange} />
//           </Button>
//           {imageError && <Typography color="error">{imageError}</Typography>}
//           {images && (
//             <Typography variant="body2">
//               Attached files:
//               <ul>
//                 {Array.from(images).map((image, index) => (
//                   <li key={index}>{image.name}</li>
//                 ))}
//               </ul>
//             </Typography>
//           )}
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             type="submit"
//             fullWidth
//             disabled={isUploading}
//             sx={{ height: { xs: 'auto', md: '56px' } }}
//           >
//             Upload
//           </Button>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default SyllabusUpload;

import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { authState } from '../../atoms';
import axiosInstance from '../../helpers/axiosInstance';

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function SyllabusUpload() {
  const [title, setTitle] = useState('');
  const [standard, setStandard] = useState('');
  const [subject, setSubject] = useState('');
  const [chapterNumber, setChapterNumber] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);

  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const standards = [...Array(12).keys()].map((i) => i + 1);
  const subjects = [
    'Accounting',
    'Advertising',
    'Aerospace Engineering',
    'Agricultural Studies',
    'Anatomy',
    'Anthropology',
    'Archaeology',
    'Astronomy',
    'Auditing',
    'Biochemistry',
    'Biology',
    'Broadcasting',
    'Business Administration',
    'Business Law',
    'Business Studies',
    'Chemical Engineering',
    'Chemistry',
    'Civics',
    'Civil Engineering',
    'Computer Science',
    'Creative Writing',
    'Cultural Studies',
    'Design and Technology',
    'Digital Media',
    'Economics',
    'Electrical Engineering',
    'Engineering',
    'English Language',
    'English Literature',
    'Entrepreneurship',
    'Environmental Engineering',
    'Environmental Studies',
    'Ethics',
    'Film Studies',
    'Finance',
    'Forensic Science',
    'Geography',
    'Geology',
    'Global Studies',
    'Health Education',
    'History',
    'Home Economics',
    'Human Resource Management',
    'Journalism',
    'Legal Studies',
    'Linguistics',
    'Marine Biology',
    'Marketing',
    'Mass Communication',
    'Mathematics I',
    'Mathematics II',
    'Mechanical Engineering',
    'Media Ethics',
    'Media Production',
    'Media Studies',
    'Medicine',
    'Meteorology',
    'Microbiology',
    'Military Science',
    'Nutrition and Food Science',
    'Obstetrics and Gynecology',
    'Pathology',
    'Pediatrics',
    'Personal Finance',
    'Pharmacology',
    'Philosophy',
    'Physics',
    'Physiology',
    'Political Science',
    'Psychology',
    'Public Relations',
    'Religious Education',
    'Robotics',
    'Science',
    'Social Studies',
    'Sociology',
    'Sports Science',
    'Surgery',
    'Taxation',
    'Technology',
    'Visual Communication',
    'World Religions',
  ];
  const chapterNumbers = [...Array(30).keys()].map((i) => i + 1);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(
      (file) =>
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
    );
    setFiles(validFiles);
  };

  const validateInputs = () => {
    const titleWordCount = countWords(title);
    setTitleError(titleWordCount < 1 || titleWordCount > 15);

    const contentWordCount = countWords(content);
    setContentError(contentWordCount > 20000);

    return !titleError && !contentError;
  };

  const uploadSyllabus = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    setIsUploading(true);
    setUploadSuccess(false);
    setUploadError('');

    const formData = new FormData();
    formData.append('title', title);
    formData.append('standard', standard);
    formData.append('subject', subject);
    formData.append('chapterNumber', chapterNumber);
    if (content) formData.append('content', content);
    formData.append('is_public', isPublic);

    if (files.length > 0) {
      files.forEach((file, index) => {
        console.log(`Appending file ${index}:`, file.name);
        formData.append('files', file);
      });
    } else {
      console.log('No files to upload');
    }

    try {
      console.log('Sending request to:', '/api/syllabus/upload-syllabus');

      const response = await axiosInstance.post(
        '/api/syllabus/upload-syllabus',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUploadSuccess(true);
      console.log('Upload successful:', response.data);
    } catch (error) {
      console.error('Upload failed:', error);
      console.error('Error response:', error.response?.data);
      setUploadError(
        error.response?.data?.error ||
          'Failed to upload syllabus. Please try again.'
      );
    } finally {
      setIsUploading(false);
      setTitle('');
      setStandard('');
      setSubject('');
      setChapterNumber('');
      setContent('');
      setFiles([]);
      setIsPublic(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          margin: { xs: '2rem', sm: '8rem' },
          mt: 12,
          maxWidth: 800,
          mx: 'auto',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
          Authentication Required
        </Typography>
        <Typography color="text.secondary">
          Please log in to upload your Syllabus.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={uploadSyllabus}
      sx={{
        margin: { xs: '2rem', sm: '8rem' },
        mt: 12,
        maxWidth: 800,
        mx: 'auto',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        Upload your Syllabus
      </Typography>
      <Typography color="text.secondary">
        Fill out the form and press "Upload".
      </Typography>
      {uploadSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Upload successful!
        </Alert>
      )}
      {uploadError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {uploadError}
        </Alert>
      )}
      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            helperText={titleError ? 'Title must be 1 to 15 words' : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="standard-label">Standard</InputLabel>
            <Select
              labelId="standard-label"
              id="standard"
              value={standard}
              onChange={(e) => setStandard(e.target.value)}
              label="Standard"
            >
              {standards.map((std) => (
                <MenuItem key={std} value={std}>
                  {std}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="subject-label">Subject</InputLabel>
            <Select
              labelId="subject-label"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              label="Subject"
            >
              {subjects.map((sub) => (
                <MenuItem key={sub} value={sub}>
                  {sub}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="chapter-number-label">Chapter Number</InputLabel>
            <Select
              labelId="chapter-number-label"
              id="chapter-number"
              value={chapterNumber}
              onChange={(e) => setChapterNumber(e.target.value)}
              label="Chapter Number"
            >
              {chapterNumbers.map((chapter) => (
                <MenuItem key={chapter} value={chapter}>
                  {chapter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            label="Content (Optional)"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            error={contentError}
            helperText={
              contentError ? 'Content must be 20000 words or less' : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <input
            accept=".pdf,.png,.jpg,.jpeg"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span">
              Upload PDF or Images (Optional)
            </Button>
          </label>
          {files.length > 0 && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {files.length} file(s) selected
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                name="isPublic"
              />
            }
            label="Make public"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={
              isUploading ||
              titleError ||
              contentError ||
              !title ||
              !standard ||
              !subject ||
              !chapterNumber
            }
            sx={{ height: '56px' }}
          >
            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SyllabusUpload;
