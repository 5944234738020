//client/src/layouts/Main/components/Topbar/Topbar.js

import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../assets/images/WisdomScribe-logo.svg';
import negativeLogo from '../../../../assets/images/WisdomScribe-logo-negative.svg';

import ThemeModeToggler from '../../../../components/ThemeModeToggler';

import NavItem from './components/NavItem/NavItem';
import SimpleNavItem from './components/NavItem/SimpleNavItem';

import { authState } from '../../../../atoms';
import { logout } from '../../../../helpers/api';

const Topbar = ({ onSidebarOpen, onClose, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const resetAuthState = useResetRecoilState(authState);
  const isAuthenticated = auth.isAuthenticated;

  const {
    tools: toolsPages,
    about: aboutPages,
    dashboard: dashboardPages,
    voicelab: voicelabPages,
  } = pages;

  const logoutHandler = async () => {
    try {
      await logout();
      resetAuthState();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component={RouterLink}
        to="/"
        title="Wisdom Scribe"
        width={{ xs: 150, md: 180 }} // Increased from 100/120 to 150/180
      >
        <Box
          component={'img'}
          src={mode === 'light' && !colorInvert ? logo : negativeLogo}
          height={{ xs: 45, md: 55 }} // Increased from 35 to 45/55
          width="100%"
          sx={{ objectFit: 'contain' }}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <NavItem
            title={'Text Room'}
            id={'tools-pages'}
            items={toolsPages}
            colorInvert={colorInvert}
            onClose={onClose}
          />
        </Box>
        <Box marginLeft={3}>
          <SimpleNavItem
            title="Image Studio"
            href="/create-image"
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={3}>
          <NavItem
            title={'Voice Lab'}
            id={'voicelab-pages'}
            items={voicelabPages}
            colorInvert={colorInvert}
            onClose={onClose}
          />
        </Box>

        <Box marginLeft={3}>
          <SimpleNavItem
            title="Pricing"
            href="/pricing"
            colorInvert={colorInvert}
          />
        </Box>

        <Box marginLeft={3}>
          <NavItem
            title={'Dashboard'}
            id={'dashboard-pages'}
            items={dashboardPages}
            colorInvert={colorInvert}
            onClose={onClose}
          />
        </Box>
        <Box marginLeft={3}>
          <NavItem
            title={'About'}
            id={'about-pages'}
            items={aboutPages}
            colorInvert={colorInvert}
            onClose={onClose}
          />
        </Box>
        <Box marginLeft={3}>
          <ThemeModeToggler />
        </Box>
        {isAuthenticated ? (
          <Box marginLeft={3}>
            <Button onClick={logoutHandler} variant="contained" color="primary">
              Logout
            </Button>
          </Box>
        ) : (
          <>
            <Box marginLeft={4}>
              <Button
                component={RouterLink}
                to="/register"
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </Box>
            <Box marginLeft={4}>
              <Button
                component={RouterLink}
                to="/login"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Topbar;
