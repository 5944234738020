import createTeachingTemplate from "../../TeachingTemplate";

const individualLearningPlanCreator = createTeachingTemplate({
  name: "individuallearningplancreator",
  endpoint: "/api/openai/individual-learning-plan-creator",
  aiText: "individualLearningPlanCreator",
  aiTextHandler: "individualLearningPlanCreatorHandler",
  aiTextPlainText: "individualLearningPlanCreatorPlainText",
  title: "Individual Learning Plan Creator",
  subText:
    'Personalized learning at its best! Enter the class, subject, and the student\'s strengths and areas for improvement based on the syllabus topics, and this tool will create a customized learning plan. For the most effective plans, upload your syllabus. Example: "Grade 7, John, excels in Algebra chapters, struggles with Reading Comprehension chapters."',
  label: "Individual Learning Plan",
  placeholderText: "Strengths and weaknesses",
  buttonText: "Create Learning Plan",
  aiPlaceholder: "Individual learning plan will appear here",
});

export default individualLearningPlanCreator;
