import createNewsTemplate from "../../NewsTemplate";

const newsReportDigital = createNewsTemplate({
  name: "newsreportdigital",
  endpoint: "/api/openai/news-report-digital",
  aiText: "newsReportDigital",
  aiTextHandler: "newsReportDigitalHandler",
  aiTextPlainText: "newsReportDigitalPlainText",
  title: "News Report - Digital",
  subText:
    "Our tool for digital media turns your inputs into compelling online news content. Simply input your topic, word count, news data, and background info, and watch as it crafts SEO-optimized digital news content. Suitable for diverse news types.",
  label: "News Script",
  placeholderText: "Enter Main News Here",
  buttonText: "Write Blog",
  aiPlaceholder: "News blog will appear here",
});

export default newsReportDigital;
