import createTeachingTemplate from "../../TeachingTemplate";
const revisionNotes = createTeachingTemplate({
  name: "revisionnotes",
  endpoint: "/api/openai/revision-notes",
  aiText: "revisionNotes",
  aiTextHandler: "revisionNotesHandler",
  aiTextPlainText: "revisionNotesPlainText",
  title: "Revision Notes Generator",
  subText:
    "Revise effectively with tailor-made notes! Select the class, subject, and desired chapters from your syllabus. The tool will create well-structured revision notes, making your study sessions more productive. To get the most out of this tool, ensure your syllabus is uploaded. Example: 'Grade 11, Mathematics, Chapters 5-8.",
  label: "Revision Flashcards",
  placeholderText: "Any key points to be added?",
  buttonText: "Generate Notes",
  aiPlaceholder: "Revision notes will appear here",
});

export default revisionNotes;
