//client/src/components/screens/Pricing/components/Main/Main.js

import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import {
  individualPlans,
  groupPlans,
  apiPricing,
  pricingDetails,
} from './pricingData';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardActions,
  Button,
  CardContent,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import SplashScreen from '../../../../splashScreen';
import Flag from 'react-world-flags';
import { styled } from '@mui/material/styles';

import { authState } from '../../../../../atoms';
import { fetchUserSubscription } from '../../../../../helpers/api';

const PricingDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);

  const { isAuthenticated, subscriptionStatus } = auth;

  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [pricingOption, setPricingOption] = useState('monthly');
  const [currency, setCurrency] = useState('USD');
  const [includeApiAccess, setIncludeApiAccess] = useState(false);
  const [planType, setPlanType] = useState('individual');
  const [groupSize, setGroupSize] = useState(3);

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserSubscription()
        .then(() => setIsLoadingSubscription(false))
        .catch((error) => {
          console.error('Failed to fetch subscription:', error);
          setIsLoadingSubscription(false);
        });
    }
  }, [isAuthenticated]);

  const handleSubscriptionButtonClick = (plan) => {
    const isProOrEnterprise =
      plan.title === 'Pro' || plan.title === 'Enterprise';
    let planId;

    if (isProOrEnterprise) {
      planId =
        pricingDetails[plan.title][pricingOption][currency][
          includeApiAccess ? 'withApiAccess' : 'standard'
        ];
    } else {
      // For Basic plan
      planId = pricingDetails[plan.title][pricingOption][currency];
    }

    // Make sure planId is a string
    if (typeof planId !== 'string') {
      console.error('Invalid planId:', planId);
      // You might want to show an error message to the user here
      return;
    }

    const apiPrice =
      isProOrEnterprise && includeApiAccess
        ? apiPricing.monthlyFee[currency]
        : 0;
    const totalPrice =
      parseFloat(plan.price[pricingOption][currency]) + apiPrice;

    navigate('/subscription-confirmation', {
      state: {
        planId,
        planTitle: plan.title,
        pricingOption,
        currency,
        price: totalPrice,
        features: plan.features,
        includeApiAccess: isProOrEnterprise && includeApiAccess,
      },
    });
  };

  const PlanTypeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.common.white,
      width: 32,
      height: 32,
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  const renderPlanSwitch = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginBottom={4}
    >
      <Typography variant="body1" marginRight={2}>
        Individual
      </Typography>
      <FormControlLabel
        control={
          <PlanTypeSwitch
            checked={planType === 'group'}
            onChange={(event) =>
              setPlanType(event.target.checked ? 'group' : 'individual')
            }
          />
        }
        label={
          <Typography variant="body1" marginLeft={2}>
            Group
          </Typography>
        }
      />
    </Box>
  );

  const renderToggler = () => (
    <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
      <ToggleButtonGroup
        value={pricingOption}
        exclusive
        onChange={(event, newPricingOption) =>
          setPricingOption(newPricingOption)
        }
      >
        <ToggleButton
          value="annual"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'annual'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === 'annual' ? 'common.white' : 'text.primary',
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="monthly"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'monthly'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== 'annual' ? 'common.white' : 'text.primary',
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <Select
        value={currency}
        onChange={(event) => setCurrency(event.target.value)}
        displayEmpty
        sx={{ marginLeft: 2, minWidth: 120 }}
      >
        <MenuItem value="USD">
          <Box display="flex" alignItems="center">
            <Flag code="USA" height="20" />
            <Typography marginLeft={1}>USD</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="INR">
          <Box display="flex" alignItems="center">
            <Flag code="IND" height="20" />
            <Typography marginLeft={1}>INR</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="EUR">
          <Box display="flex" alignItems="center">
            <Flag code="EU" height="20" />
            <Typography marginLeft={1}>EUR</Typography>
          </Box>
        </MenuItem>
      </Select>
    </Box>
  );

  const renderGroupSizeSlider = () => (
    <Box sx={{ width: 300, margin: '0 auto', marginBottom: 4 }}>
      <Typography gutterBottom>Team Size: {groupSize}</Typography>
      <Slider
        value={groupSize}
        onChange={(event, newValue) => setGroupSize(newValue)}
        step={null}
        marks={groupPlans[0].tiers.map((tier) => ({
          value: tier,
          label: tier.toString(),
        }))}
        min={groupPlans[0].tiers[0]}
        max={groupPlans[0].tiers[groupPlans[0].tiers.length - 1]}
      />
    </Box>
  );

  const formatCurrency = (amount, currencyCode) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(amount);
  };

  const calculatePrice = (plan, option, userCount) => {
    const basePrice = plan.price[option][currency];
    return planType === 'group' ? basePrice * userCount : basePrice;
  };

  const renderPricingCards = () => {
    const plans = planType === 'individual' ? individualPlans : groupPlans;
    return (
      <Grid container spacing={4}>
        {plans.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={Card}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              variant={'outlined'}
            >
              <CardContent sx={{ padding: 4 }}>
                <Box marginBottom={2}>
                  <Typography variant={'h4'} fontWeight={600} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography color={'text.secondary'}>
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'baseline'} marginBottom={2}>
                  <Typography variant={'h3'} fontWeight={700}>
                    {formatCurrency(
                      item.price[pricingOption][currency],
                      currency
                    )}
                  </Typography>
                  <Typography
                    variant={'subtitle1'}
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    {planType === 'group' ? '/user' : ''}{' '}
                    {pricingOption === 'annual' ? '/year' : '/month'}
                  </Typography>
                </Box>
                {planType === 'group' && (
                  <Typography
                    variant={'body1'}
                    color={'text.secondary'}
                    marginBottom={2}
                  >
                    Total:{' '}
                    {formatCurrency(
                      calculatePrice(item, pricingOption, groupSize),
                      currency
                    )}
                    {pricingOption === 'annual' ? '/year' : '/month'} for{' '}
                    {groupSize} users
                  </Typography>
                )}
                <Grid container spacing={1}>
                  {item.features.map((feature, j) => (
                    <Grid item xs={12} key={j}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.primary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={feature} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {(item.title === 'Pro' || item.title === 'Enterprise') && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeApiAccess}
                        onChange={(e) => setIncludeApiAccess(e.target.checked)}
                      />
                    }
                    label="Include API Access"
                  />
                )}
              </CardContent>
              <Box flexGrow={1} />
              <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => handleSubscriptionButtonClick(item)}
                >
                  {subscriptionStatus && subscriptionStatus.isSubscribed
                    ? 'Manage Subscription'
                    : 'Subscribe'}
                </Button>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <Box>
          <Box
            sx={{
              position: 'relative',
              backgroundColor: theme.palette.alternate.main,
              backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
              marginTop: -13,
              paddingTop: 13,
            }}
          >
            <Container position={'relative'} zIndex={3}>
              <Box>
                <Box marginBottom={4}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    align={'center'}
                    sx={{ fontWeight: 900, mb: 2, mt: 10 }}
                  >
                    Flexible pricing options
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="text.primary"
                    align={'center'}
                  >
                    A 24/7 AI-powered ally without breaking the bank. Embrace
                    our flexible pricing, supercharge your content creation, and
                    free up time for something greater, something more
                    inspiring!
                  </Typography>
                </Box>
                {renderPlanSwitch()}
                {planType === 'group' && renderGroupSizeSlider()}
                {renderToggler()}
              </Box>
            </Container>
          </Box>
          <Container>{renderPricingCards()}</Container>
        </Box>
      )}
    </>
  );
};

export default PricingDashboard;
