import React from "react";
import { colors } from "@mui/material";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { alpha } from "@mui/material";

const newsReportPrint = {
  title: "News Report - Print",
  subtitle: "Craft comprehensive print news stories from raw data.",
  targetRoute: "/news-report-print",
  color: colors.amber[600],
  bgcolor: alpha(colors.amber[600], 0.1),
  icon: <NewspaperIcon fontSize="medium" />,
  tags: [],
};

export default newsReportPrint;
