import React from "react";
import { colors } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { alpha } from "@mui/material";

const interactiveContentGenerator = {
  title: "Interactive Content Generator - News",
  subtitle:
    "Create intriguing quizzes, polls, or trivia for your digital platform.",
  targetRoute: "/interactive-content-generator",
  color: colors.teal[900],
  bgcolor: alpha(colors.teal[900], 0.1),
  icon: <QuestionAnswerIcon fontSize="medium" />,
  tags: [],
};

export default interactiveContentGenerator;
