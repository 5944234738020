import React, { useState } from "react";
import {
  Grid,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import avatars from "./avatars";

const AvatarSelection = ({ selectedAvatar, setSelectedAvatar }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAvatarClick = (avatarUrl) => {
    setSelectedAvatar(avatarUrl);
    handleClose();
  };

  return (
    <Box display="flex" alignItems="center">
      {selectedAvatar && (
        <Avatar
          src={selectedAvatar}
          alt="Selected avatar"
          sx={{ width: 60, height: 60, marginRight: 2 }}
        />
      )}
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Choose Avatar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="avatar-selection-dialog"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="avatar-selection-dialog">Select an avatar</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {avatars.map((avatar, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Card
                  sx={{
                    width: "100%",
                    paddingTop: "100%", // This will make the card a square
                    position: "relative", // Required for the children to position correctly
                  }}
                >
                  <CardContent
                    sx={{
                      position: "absolute", // Required to position correctly
                      top: 0,
                      left: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center", // Centers content vertically
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography variant="subtitle1">{avatar.name}</Typography>
                    <Avatar
                      src={avatar.url}
                      sx={{
                        width: 80,
                        height: 80,
                        cursor: "pointer",
                        border:
                          selectedAvatar === avatar.url
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                      }}
                      onClick={() => handleAvatarClick(avatar.url)}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AvatarSelection;
