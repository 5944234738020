import createShortNewsTemplate from "../../ShortNewsTemplate";

const breakingNewsAlertGenerator = createShortNewsTemplate({
  name: "breakingnewsalertgenerator",
  endpoint: "/api/openai/breaking-news-alert-generator",
  aiText: "breakingNewsAlertGenerator",
  aiTextHandler: "breakingNewsAlertGeneratorHandler",
  aiTextPlainText: "breakingNewsAlertGeneratorPlainText",
  title: "Breaking News Alert Generator",
  subText:
    "With the Breaking News Alert Generator, create swift and concise news alerts. Input your topic, word count, news information, and background data. Perfect for creating urgent updates for any news genre.",
  label: "Breaking News",
  placeholderText: "Enter Breaking News Info",
  buttonText: "Generate News Alert",
  aiPlaceholder: "Breaking news alert will appear here",
});

export default breakingNewsAlertGenerator;
