import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logo from '../../../../assets/images/WisdomScribe-logo.svg';
import negativeLogo from '../../../../assets/images/WisdomScribe-logo-negative.svg';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            component="a"
            href="/"
            title="wisdomScribe"
            display="inline-block"
            marginLeft={{ xs: 'auto', sm: 0 }}
            marginRight={{ xs: 'auto', sm: 0 }}
          >
            <Box
              component={'img'}
              src={mode === 'light' ? logo : negativeLogo}
              height={25}
              width={'auto'}
            />
          </Box>

          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/support"
                color="text.primary"
                variant={'subtitle2'}
              >
                Support
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/faq"
                color="text.primary"
                variant={'subtitle2'}
              >
                FAQs
              </Link>
            </Box>
            <Box marginTop={1}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                target="blank"
                href="/tools"
                size="small"
              >
                All Features
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; wisdomScribe. 2024, John 10.10 Tech Private Limited. All rights
          reserved.
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
