import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const introductionsWriter = createLanguageKeywordTopicTemplate({
  name: "introductionswriter",
  endpoint: "/api/openai/introductions-writer",
  aiText: "introductionsWriter",
  aiTextHandler: "introductionsWriterHandler",
  aiTextPlainText: "introductionsWriterPlainText",
  title: "Introductions Writer",
  subText:
    "Make a strong first impression with the Introductions Writer. Input your topic, and it will craft captivating introductions that hook readers from the get-go, piquing curiosity and ensuring they stick around for more.",
  label: "Introduction",
  placeholderText: "Enter your topic…",
  buttonText: "Write Introduction",
  aiPlaceholder: "Introduction will appear here",
});

export default introductionsWriter;
