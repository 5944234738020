import React from "react";
import { colors } from "@mui/material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { alpha } from "@mui/material";

const pressReleaseRewriterBroadcast = {
  title: "Press Release Rewriter - Broadcast",
  subtitle: "Reword press releases into engaging broadcast scripts.",
  targetRoute: "/press-release-rewriter-broadcast",
  color: colors.purple[700],
  bgcolor: alpha(colors.purple[700], 0.1),
  icon: <LiveTvIcon fontSize="medium" />,
  tags: [],
};

export default pressReleaseRewriterBroadcast;
