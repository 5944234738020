import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

import Container from "../../Container";
import { Form } from "./components";

const NewFeaturePic =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690270904/Wisdom-Scribe/new-feature_yua9r9.svg";

const NewFeature = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const Sidebar = () => (
    <Box
      flex={"1 1 30%"}
      maxWidth={"30%"}
      maxHeight={"100vh"}
      position={"sticky"}
      top={0}
    >
      <Box display={"flex"} alignItems={"center"} height={1} width={1}>
        <Box
          component={"img"}
          loading="lazy"
          height={1}
          width={1}
          src={NewFeaturePic}
          alt="Request New Features image"
          sx={{
            objectFit: "contain",
          }}
        />
      </Box>
    </Box>
  );
  return (
    <Box
      position={"relative"}
      minHeight={"100vh"}
      display={"flex"}
      marginTop={-13}
    >
      {isMd ? <Sidebar /> : null}
      <Box
        flex={{ xs: "1 1 100%", md: "1 1 70%" }}
        maxWidth={{ xs: "100%", md: "70%" }}
        paddingTop={14}
      >
        <Box height={1}>
          <Container>
            <Form />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default NewFeature;
