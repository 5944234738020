import React from "react";
import { colors } from "@mui/material";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import { alpha } from "@mui/material";

const blogWriterAida = {
  title: "Blog Writer - AIDA",
  subtitle: "Craft compelling blogs using AIDA framework.",
  targetRoute: "/blog-writer-aida",
  color: colors.cyan[900],
  bgcolor: alpha(colors.cyan[900], 0.1),
  icon: <GamepadOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriterAida;
