import createPressReleaseNewsTemplate from "../../PressReleaseNewsTemplate";

const pressReleaseRewriterBroadcast = createPressReleaseNewsTemplate({
  name: "pressreleaserewriterbroadcast",
  endpoint: "/api/openai/press-release-rewriter-broadcast",
  aiText: "pressReleaseRewriterBroadcast",
  aiTextHandler: "pressReleaseRewriterBroadcastHandler",
  aiTextPlainText: "pressReleaseRewriterBroadcastPlainText",
  title: "Press Release Rewriter - Broadcast",
  subText:
    "Reword press releases into unique news scripts, maintaining the factual integrity. Paste the press release and the desired word count, and get it transformed into a unique, engaging broadcast script.",
  label: "Rewritten Press Release",
  placeholderText: "Paste Press Release Here",
  buttonText: "Rewrite for Broadcast",
  aiPlaceholder: "Rewritten press release will appear here",
});

export default pressReleaseRewriterBroadcast;
