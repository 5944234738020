import React from "react";
import { colors } from "@mui/material";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { alpha } from "@mui/material";

const headlineGeneratorBroadcast = {
  title: "Headline Generator - Broadcast",
  subtitle: "Create compelling headlines for news channels.",
  targetRoute: "/headline-generator-broadcast",
  color: colors.blue[800],
  bgcolor: alpha(colors.blue[800], 0.1),
  icon: <ShortTextIcon fontSize="medium" />,
  tags: [],
};

export default headlineGeneratorBroadcast;
