// client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/digitalMarketerMock.js

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HistoryIcon from '@mui/icons-material/History';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShareIcon from '@mui/icons-material/Share';
import NotesIcon from '@mui/icons-material/Notes';

const digitalMarketerMock = [
  {
    groupTitle: 'AI Tools for Digital Marketers',
    id: 'tools',
    pages: [
      {
        title: 'All Marketing Tools',
        path: '/my-digital-marketing-dashboard',
        icon: <AdsClickIcon />,
      },
      {
        title: 'SEO Tools',
        path: '/digital-marketing/seo-tools',
        icon: <ManageSearchIcon />,
      },
      {
        title: 'Content Strategy and Planning',
        path: '/digital-marketing/content-strategy-and-planning-tools',
        icon: <CalendarMonthIcon />,
      },

      {
        title: 'Content Generation',
        path: '/digital-marketing/content-generation-tools',
        icon: <NotesIcon />,
      },
      {
        title: 'Social Media Marketing',
        path: '/digital-marketing/social-media-marketing-tools',
        icon: <ShareIcon />,
      },

      {
        title: 'Performance Analysis',
        path: '/digital-marketing/performance-analysis-tools',
        icon: <AssessmentIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Documents',
    id: 'documents',
    pages: [
      {
        title: 'History',
        path: '/history',
        icon: <HistoryIcon />,
      },
      {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />,
      },
      {
        title: 'Playlist',
        path: '/playlist',
        icon: <LibraryMusicIcon />,
      },
      {
        title: 'Knowledge Base',
        path: '/my-knowledge-base',
        icon: <TextSnippetIcon />,
      },
      {
        title: 'Tone of Voice',
        path: '/my-tone-of-voice',
        icon: <GraphicEqIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Account',
    id: 'account',
    pages: [
      {
        title: 'General Settings',
        path: '/user-profile',
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: 'Change Password',
        path: '/update-password',
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: 'Billing Portal',
        path: '/billing-portal',
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Support',
    id: 'support',
    pages: [
      {
        title: 'Support',
        path: '#',
        icon: <EmailOutlinedIcon />,
      },
      {
        title: 'Announcements',
        path: '#',
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

export default digitalMarketerMock;
