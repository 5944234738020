import React from "react";
import { colors } from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { alpha } from "@mui/material";

const grammarCorrector = {
  title: "Grammar Corrector",
  subtitle:
    "The world's best virtual English professor, fixes grammar and sentence structure without altering your text's essence.",
  targetRoute: "/grammar-corrector",
  color: colors.lightBlue["A700"],
  bgcolor: alpha(colors.lightBlue["A700"], 0.1),
  icon: <PlaylistAddCheckIcon fontSize="medium" />,
  tags: [],
};

export default grammarCorrector;
