import createToneTopicInfoLanguageTemplate from "../../ToneTopicInfoLanguageTemplate";

const youTubeTitleDescriptionKeywordsHashtagsGenerator =
  createToneTopicInfoLanguageTemplate({
    name: "youtubetitledescriptionkeywordshashtagsgenerator",
    endpoint:
      "/api/openai/youtube-title-description-keywords-hashtags-generator",
    aiText: "youTubeTitleDescriptionKeywordsHashtagsGenerator",
    aiTextHandler: "youTubeTitleDescriptionKeywordsHashtagsGenerator",
    aiTextPlainText: "youTubeTitleDescriptionKeywordsHashtagsGeneratorHandler",
    title: "YouTube Title Description Keywords Hashtags Generator",
    subText:
      "Enhance your YouTube content with this all-in-one tool. Enter your video details, and it will generate an engaging title, optimized description, keywords and hashtags, ensuring your content is captivating and SEO-friendly.",
    infoText: "Paste your script here",
    label: "YouTube Content",
    placeholderText: "Enter your video topic",
    buttonText: "Generate Content",
    aiPlaceholder:
      "YouTube title, description, keywords and hashtags will appear here",
  });

export default youTubeTitleDescriptionKeywordsHashtagsGenerator;
