import React from "react";
import { Select, MenuItem } from "@mui/material";

const NumberSelect = ({ number, setNumber }) => {
  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  // Create an array from 1 to 30
  const numberOptions = Array.from({ length: 30 }, (_, i) => i + 1);

  return (
    <Select
      displayEmpty
      value={number}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
      renderValue={(selected) => {
        if (!selected) {
          return "Number of posts";
        }
        return selected;
      }}
      fullWidth
    >
      {numberOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default NumberSelect;
