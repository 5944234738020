import createBusinessToolsTemplate from "../../BusinessToolsTemplate";

const pitchDeckMaker = createBusinessToolsTemplate({
  name: "pitchdeckmaker",
  endpoint: "/api/openai/pitch-deck-maker",
  aiText: "pitchDeckMaker",
  aiTextHandler: "pitchDeckMakerHandler",
  aiTextPlainText: "pitchDeckMakerPlainText",
  title: "Pitch Deck Maker",
  subText:
    "Revolutionize your fundraising journey with Pitch Deck Maker. Input your venture details, and watch as it crafts a compelling pitch deck, articulating your vision sharply to captivate potential investors and lay a clear roadmap for your company's growth.",
  label: "Pitch Deck",
  placeholderText: "Input your data…",
  buttonText: "Write My Pitch",
  aiPlaceholder: "Your pitch deck will appear here",
});

export default pitchDeckMaker;
