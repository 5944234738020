import React from "react";
import Box from "@mui/material/Box";

const HeroImage =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690261829/Wisdom-Scribe/bot-writing_zdoxbi.jpg";

const CoverSlider = () => {
  return (
    <Box
      sx={{
        height: { xs: "auto", md: 1 },
        "& img": {
          objectFit: "cover",
        },
      }}
    >
      <img
        src={HeroImage}
        alt="Bot Writing"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          objectPosition: "center",
        }}
      />
    </Box>
  );
};

export default CoverSlider;
