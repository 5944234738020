import React from "react";
import { colors } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { alpha } from "@mui/material";

const introductionsWriter = {
  title: "Introductions Writer",
  subtitle:
    "Write compelling, concise introductions following the A-P-P method to reel in readers and establish trust.",
  targetRoute: "/introductions-writer",
  color: colors.purple[300],
  bgcolor: alpha(colors.purple[300], 0.1),
  icon: <ModeEditOutlineIcon fontSize="medium" />,
  tags: [],
};

export default introductionsWriter;
