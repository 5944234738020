import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../../Container';
import SearchBar from '../SearchBar/SearchBar';
import { authState } from '../../../../atoms';
import Fuse from 'fuse.js';

import {
  analogyCreatorTeaching,
  difficultQuestionPaperMaker,
  flashcardMaker,
  homeworkAssigner,
  individualLearningPlanCreator,
  interactiveActivityDesigner,
  lessonPlanner,
  mixedQuestionPaperMaker,
  quizGeneratorTeaching,
  revisionNotes,
  simpleQuestionPaperMaker,
  topicExplainerTeaching,
} from '../../cards/TeachingTools';

const features = {
  'Lesson Planning': [
    lessonPlanner,
    individualLearningPlanCreator,
    interactiveActivityDesigner,
    analogyCreatorTeaching,
  ],

  'Assessment Tools': [
    simpleQuestionPaperMaker,
    mixedQuestionPaperMaker,
    difficultQuestionPaperMaker,
    quizGeneratorTeaching,
  ],

  'Study Aids': [flashcardMaker, revisionNotes, topicExplainerTeaching],

  'Homework and Assignment': [homeworkAssigner],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: ['title', 'subtitle', 'tags'],
};

const TeachingTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = useMemo(() => {
    const allFeatures = Object.values(features).flat();
    return new Fuse(allFeatures, fuseOptions);
  }, []);

  const searchResults = useMemo(() => {
    if (!searchQuery) return features;
    const results = fuse.search(searchQuery);
    return Object.fromEntries(
      Object.entries(features).map(([category, items]) => [
        category,
        items.filter((item) => results.some((result) => result.item === item)),
      ])
    );
  }, [fuse, searchQuery]);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <JournalistToolsInstructions navigate={navigate} />
        <Box sx={{ mt: 3 }}>
          {' '}
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
      </Container>
      {Object.entries(searchResults).map(([category, cards], index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
            {' '}
            {/* Reduced top padding */}
            <Typography variant="h5" gutterBottom fontWeight="500" mb={3}>
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <FeatureCard item={item} onClick={handleClickOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

const JournalistToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        How to Upload Your Syllabus
      </Typography>
      <Typography variant="body1" gutterBottom>
        Begin by taking a clear snapshot of the text from your book, ensuring
        that the lighting is optimal. The image should be in either .png or .jpg
        format, as other formats will not be accepted.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Once your syllabus is uploaded, an array of tools becomes available for
        your use. You can generate various materials such as question papers,
        revision notes and more, tailored specifically to your uploaded
        syllabus.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-syllabus')}
        >
          Upload Syllabus
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default TeachingTools;
