import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../../Container';
import SearchBar from '../SearchBar/SearchBar';
import { authState } from '../../../../atoms';
import Fuse from 'fuse.js';

import {
  analogyMaker,
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  faqCreator,
  grammarCorrector,
  headlineIdeasWriter,
  outlineBasedBlogWriter,
  introductionsWriter,
  landingPageHeadlineGenerator,
  narrateToChild,
  productDescriptionGenerator,
  quoteGenerator,
  stepByStepProductGuideWriter,
  textReadabilityScanner,
  websitePageContentGenerator,
  translator,
} from '../../cards/ContentWriters';

import {
  blogContentPlanner,
  ultimateTopicResearcher,
} from '../../cards/SeoTools';

import {
  podcastCreator,
  youTubeScriptwriter,
} from '../../cards/SocialMediaTools';

const features = {
  'Blog Writers': [
    blogWriter,
    blogWriterAida,
    blogWriterPas,
    blogWriterBenefits,
    blogWriterHowTo,
    blogWriterStorytelling,
    blogOutlineBuilder,
    outlineBasedBlogWriter,
    introductionsWriter,
  ],

  'Specialized Content Writers': [
    websitePageContentGenerator,
    stepByStepProductGuideWriter,
    faqCreator,
    productDescriptionGenerator,
    youTubeScriptwriter,
    podcastCreator,
  ],

  'Content Optimization and Research Tools': [
    textReadabilityScanner,
    grammarCorrector,
    blogContentPlanner,
    ultimateTopicResearcher,
  ],

  'Copywriting and Creative Tools': [
    headlineIdeasWriter,
    landingPageHeadlineGenerator,
    quoteGenerator,
    narrateToChild,
    analogyMaker,
    translator,
  ],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: ['title', 'subtitle', 'tags'],
};

const WritingTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = useMemo(() => {
    const allFeatures = Object.values(features).flat();
    return new Fuse(allFeatures, fuseOptions);
  }, []);

  const searchResults = useMemo(() => {
    if (!searchQuery) return features;
    const results = fuse.search(searchQuery);
    return Object.fromEntries(
      Object.entries(features).map(([category, items]) => [
        category,
        items.filter((item) => results.some((result) => result.item === item)),
      ])
    );
  }, [fuse, searchQuery]);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <ContentWritingToolsInstructions navigate={navigate} />
        <Box sx={{ mt: 3 }}>
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
      </Container>
      {Object.entries(searchResults).map(([category, cards], index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
            <Typography variant="h5" gutterBottom fontWeight="500" mb={3}>
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <FeatureCard item={item} onClick={handleClickOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

const ContentWritingToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Content Writing Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To tailor our content writing tools to your specific needs, you can
        upload your knowledge base and define your preferred tone of voice:
        <ul>
          <li>
            Upload your knowledge base: Add your subject matter expertise, give
            it a title, and save it. When using the tools, select "Use my
            knowledge base" to incorporate this information into your content
            creation process.
          </li>
          <li>
            Define your tone of voice: Describe your desired writing style, name
            it, and save it. This could include specific language preferences,
            writing conventions, or stylistic choices that reflect your brand's
            voice. You can then select this tone when using our content writing
            tools.
          </li>
        </ul>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/knowledge-base')}
        >
          Upload Knowledge Base
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-tone')}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default WritingTools;
