import createNewsHeadlineTemplate from "../../NewsHeadlineTemplate";

const headlineGeneratorPrint = createNewsHeadlineTemplate({
  name: "headlinegeneratorprint",
  endpoint: "/api/openai/headline-generator-print",
  aiText: "headlineGeneratorPrint",
  aiTextHandler: "headlineGeneratorPrintHandler",
  aiTextPlainText: "headlineGeneratorPrintPlainText",
  title: "Headline Generator - Print",
  subText:
    "Our Print Headline Generator creates engaging headlines for newspapers or magazines. Input your topic, word count, and news information, and get attention-grabbing headlines suited for print media.",
  label: "Headlines",
  placeholderText: "Enter Main News Here",
  buttonText: "Generate Headline",
  aiPlaceholder: "Headline will appear here",
});

export default headlineGeneratorPrint;
