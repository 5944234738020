import createTeachingTemplate from "../../TeachingTemplate";

const topicExplainerTeaching = createTeachingTemplate({
  name: "topicexplainerteaching",
  endpoint: "/api/openai/topic-explainer-teaching",
  aiText: "topicExplainerTeaching",
  aiTextHandler: "topicExplainerTeachingHandler",
  aiTextPlainText: "topicExplainerTeachingPlainText",
  title: "Topic Explainer",
  subText:
    'Break down barriers in understanding. Just enter the class, subject, and the tricky topic from your syllabus, and this tool will simplify it into digestible bits. To ensure the topic is from the syllabus, make sure you\'ve uploaded it. Example: "Grade 8, Mathematics, Pythagorean theorem topic."',
  label: "Topic Breakdown",
  placeholderText: "Highlight any key areas",
  buttonText: "Explain Topic",
  aiPlaceholder: "Detailed Topic Breakdown will appear here",
});

export default topicExplainerTeaching;
