//client/src/components/screens/filterAndRenderKnowledge.js
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/system';

const KnowledgeBaseItem = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
}));

const KnowledgeBaseTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
}));

const KnowledgeBaseContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const filterAndRenderKnowledge = (
  knowledgeBase,
  search,
  companyNameFilter,
  itemNameFilter,
  handleViewFullContent,
  handleItemSelect,
  selectedItems
) => {
  const filteredKnowledge = knowledgeBase
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((item) => {
      // Search Filter
      if (
        search &&
        !(
          item.text.toLowerCase().includes(search.toLowerCase()) ||
          item.companyName.toLowerCase().includes(search.toLowerCase()) ||
          item.itemName.toLowerCase().includes(search.toLowerCase())
        )
      ) {
        return false;
      }

      // Company Name Filter
      if (companyNameFilter && companyNameFilter !== item.companyName) {
        return false;
      }

      // Item Name Filter
      if (itemNameFilter && itemNameFilter !== item.itemName) {
        return false;
      }

      return true;
    })
    .map((item, index) => {
      // Check if text exists before attempting to split it
      const content = item.text
        ? item.text.split(' ').slice(0, 50).join(' ') + '...'
        : ''; // Default to an empty string if text does not exist

      return (
        <KnowledgeBaseItem key={index}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={selectedItems.includes(item.id)}
                  onChange={() => handleItemSelect(item.id)}
                />
                <KnowledgeBaseTitle variant="h6">
                  {item.companyName}
                </KnowledgeBaseTitle>
              </Box>
              <Typography variant="body2" fontWeight={500}>
                Company Name: {item.companyName}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                Item Name: {item.itemName}
              </Typography>
              <KnowledgeBaseContent
                variant="body2"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <Button onClick={() => handleViewFullContent(item.id)}>
                View full content
              </Button>
            </CardContent>
          </Card>
        </KnowledgeBaseItem>
      );
    });

  return filteredKnowledge;
};

export default filterAndRenderKnowledge;
