import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

const QuestionTypeSelect = ({ typeQuestions, setTypeQuestions }) => {
  const questionTypes = [
    "Multiple Choice Questions (MCQs)",
    "True or False Questions",
    "Fill in the Blanks",
    "Short Answer Questions",
    "One-word Answers",
    "Long Answer/Essay Questions",
    "Matching Type Questions",
    "Diagram or Picture-Based Questions",
    "Numerical or Calculation Questions",
    "Problem-Solving Questions",
    "Case Study Questions",
  ];

  return (
    <FormControl fullWidth>
      <InputLabel id="type-questions-label">Select Question Type</InputLabel>
      <Select
        labelId="type-questions-label"
        id="type-questions"
        multiple
        value={typeQuestions}
        onChange={(e) => setTypeQuestions(e.target.value)}
        renderValue={(selected) => selected.join(", ")}
      >
        {questionTypes.map((typeQuestion) => (
          <MenuItem key={typeQuestion} value={typeQuestion}>
            <Checkbox checked={typeQuestions.indexOf(typeQuestion) > -1} />
            <ListItemText primary={typeQuestion} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default QuestionTypeSelect;
