import React from "react";
import { colors } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { alpha } from "@mui/material";

const translator = {
  title: "Translator",
  subtitle:
    "Bridge language divides, seamlessly translating intricate text into fluent, understandable language.",
  targetRoute: "/translator",

  color: colors.teal["A700"],
  bgcolor: alpha(colors.teal["A700"], 0.1),
  icon: <TranslateIcon fontSize="medium" />,
  tags: [],
};

export default translator;
