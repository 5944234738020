import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Headline = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        "&::after": {
          position: "absolute",
          content: '""',
          width: "30%",
          zIndex: 1,
          top: 0,
          right: 0,
          height: "100%",
          backgroundSize: "18px 18px",
          backgroundImage: `radial-gradient(${alpha(
            theme.palette.primary.dark,
            0.4
          )} 20%, transparent 20%)`,
          opacity: 0.2,
        },
      }}
    >
      <Box position="relative" zIndex={2}>
        <Typography
          fontWeight={600}
          variant={"h3"}
          gutterBottom
          align={"center"}
        >
          Wisdom Scribe: Where Innovation meets Wisdom
        </Typography>
        <Typography
          variant="h6"
          color={"text.secondary"}
          align={"center"}
          gutterBottom
        >
          Embark on a journey with Wisdom Scribe, the game-changer in AI-powered
          content creation, sculpting the future of digital engagement and
          innovation. We're where the horizons of machine learning and human
          creativity merge, unfolding a universe where artificial intelligence
          makes content generation not just simpler but also smarter and more
          impactful.
        </Typography>
      </Box>
    </Box>
  );
};

export default Headline;
