import createLongTextMoreLanguageTemplate from "../../LongTextMoreLanguageTemplate";

const narrateToChild = createLongTextMoreLanguageTemplate({
  name: "narratetochild",
  endpoint: "/api/openai/narrate-to-child",
  aiText: "narrateToChild",
  aiTextHandler: "narrateToChildHandler",
  aiTextPlainText: "narrateToChildPlainText",
  title: "Narrate to a Child",
  subText:
    "Transform complex topics into child-friendly narratives. Simply paste your content, select your preferred language, and watch as our tool effortlessly breaks it down into an easy-to-understand and engaging story, perfectly tailored for young minds.",
  label: "Kid-Friendly Narration",
  placeholderText: "Enter a complex topic...",
  buttonText: "Create Story",
  aiPlaceholder: "Narration for children will appear here",
});

export default narrateToChild;
