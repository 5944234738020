// import React, { useRef, useState, useEffect } from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
// import { useNavigate } from 'react-router-dom';

// import instance from '../../helpers/axiosInstance';

// import {
//   Box,
//   Container,
//   Typography,
//   FormControl,
//   FormControlLabel,
//   RadioGroup,
//   Radio,
//   Button,
// } from '@mui/material';
// import Masonry from '@mui/lab/Masonry';

// import { authState } from '../../atoms';

// const HoverableImage = ({ src, title }) => {
//   const handleClick = () => {
//     window.open(src, '_blank');
//   };

//   const styles = {
//     imageContainer: {
//       position: 'relative',
//       cursor: 'pointer',
//       marginBottom: '2rem',
//     },
//     image: {
//       width: '100%',
//       height: 'auto',
//       display: 'block',
//     },
//     zoomIcon: {
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       display: 'none',
//       fontSize: '24px',
//     },
//   };

//   return (
//     <div style={styles.imageContainer} onClick={handleClick}>
//       <img src={src} alt={title} style={styles.image} loading="lazy" />
//       <div style={styles.zoomIcon}>🔍</div>
//     </div>
//   );
// };

// const UserGalleryScreen = (props) => {
//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const setAuth = useSetRecoilState(authState);
//   const { isAuthenticated, accessToken, refreshToken, tokenExpiry } = auth;

//   const [userGallery, setUserGallery] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(20);
//   const [sourceFilter, setSourceFilter] = useState('all');

//   useEffect(() => {
//     const fetchUserGallery = async () => {
//       if (!isAuthenticated || !accessToken) {
//         console.error('Authentication required or access token missing.');
//         return;
//       }

//       try {
//         const response = await instance.get(
//           `/api/auth/gallery?page=${currentPage}&limit=${pageSize}&source=${sourceFilter}`,
//           {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           }
//         );
//         const galleryData = response.data.items;
//         setUserGallery((prev) =>
//           currentPage === 1 ? galleryData : [...prev, ...galleryData]
//         );
//       } catch (error) {
//         console.error(
//           'Error fetching user gallery:',
//           error.response ? error.response.data : error.message
//         );
//       }
//     };

//     fetchUserGallery();
//   }, [currentPage, pageSize, sourceFilter, isAuthenticated, accessToken]);

//   const handleSourceFilterChange = (event) => {
//     setSourceFilter(event.target.value);
//     setCurrentPage(1); // Reset to the first page
//     setUserGallery([]); // Clear previous gallery data
//   };

//   const filteredGallery = userGallery.filter((item) =>
//     sourceFilter === 'all' ? true : item.source === sourceFilter
//   );

//   return (
//     <Container sx={{ padding: 0 }}>
//       <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
//         <Typography
//           variant="h5"
//           component="h3"
//           textAlign="center"
//           gutterBottom
//           mb={4}
//         >
//           Your Gallery
//         </Typography>
//         <FormControl component="fieldset">
//           <RadioGroup
//             row
//             aria-label="source"
//             name="row-radio-buttons-group"
//             value={sourceFilter}
//             onChange={handleSourceFilterChange}
//           >
//             <FormControlLabel value="all" control={<Radio />} label="All" />
//             <FormControlLabel
//               value="stable_diffusion"
//               control={<Radio />}
//               label="Stable Diffusion"
//             />
//             <FormControlLabel
//               value="midjourney"
//               control={<Radio />}
//               label="Midjourney"
//             />
//             <FormControlLabel
//               value="dalle"
//               control={<Radio />}
//               label="Dall-e"
//             />
//           </RadioGroup>
//         </FormControl>

//         <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
//           {filteredGallery.map((item, index) => (
//             <HoverableImage
//               key={index}
//               src={`${item.url}?w=248&fit=crop&auto=format`}
//               title={item.title}
//             />
//           ))}
//         </Masonry>

//         <Box textAlign="center" mt={4}>
//           <Button
//             variant="contained"
//             onClick={() => setCurrentPage((prev) => prev + 1)}
//           >
//             Load More
//           </Button>
//         </Box>
//       </Box>

//       <style>
//         {`
//           .imageContainer:hover .zoomIcon {
//             display: block;
//           }
//         `}
//       </style>
//     </Container>
//   );
// };

// export default UserGalleryScreen;

import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Container,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { authState } from '../../atoms';

const HoverableImage = ({ src, title }) => {
  const handleClick = () => {
    window.open(src, '_blank');
  };

  const styles = {
    imageContainer: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: '2rem',
    },
    image: {
      width: '100%',
      height: 'auto',
      display: 'block',
    },
    zoomIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'none',
      fontSize: '24px',
    },
  };

  return (
    <div style={styles.imageContainer} onClick={handleClick}>
      <img src={src} alt={title} style={styles.image} loading="lazy" />
      <div style={styles.zoomIcon}>🔍</div>
    </div>
  );
};

const UserGalleryScreen = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const [userGallery, setUserGallery] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sourceFilter, setSourceFilter] = useState('all');

  const fetchUserGallery = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      navigate('/login'); // Redirect to login page if not authenticated
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.get(
        `/api/auth/gallery?page=${currentPage}&limit=${pageSize}&source=${sourceFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const galleryData = response.data.items;
      setUserGallery((prev) =>
        currentPage === 1 ? galleryData : [...prev, ...galleryData]
      );
    } catch (error) {
      console.error(
        'Error fetching user gallery:',
        error.response ? error.response.data : error.message
      );
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access
        navigate('/login');
      }
    }
  }, [currentPage, pageSize, sourceFilter, isAuthenticated, navigate]);

  useEffect(() => {
    fetchUserGallery();
  }, [fetchUserGallery]);

  const handleSourceFilterChange = (event) => {
    setSourceFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page
    setUserGallery([]); // Clear previous gallery data
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const filteredGallery = userGallery.filter((item) =>
    sourceFilter === 'all' ? true : item.source === sourceFilter
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your Gallery
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="source"
            name="row-radio-buttons-group"
            value={sourceFilter}
            onChange={handleSourceFilterChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="stable_diffusion"
              control={<Radio />}
              label="Stable Diffusion"
            />
            <FormControlLabel
              value="midjourney"
              control={<Radio />}
              label="Midjourney"
            />
            <FormControlLabel
              value="dalle"
              control={<Radio />}
              label="Dall-e"
            />
          </RadioGroup>
        </FormControl>

        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
          {filteredGallery.map((item, index) => (
            <HoverableImage
              key={index}
              src={`${item.url}?w=248&fit=crop&auto=format`}
              title={item.title}
            />
          ))}
        </Masonry>

        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      </Box>

      <style>
        {`
          .imageContainer:hover .zoomIcon {
            display: block;
          }
        `}
      </style>
    </Container>
  );
};

export default UserGalleryScreen;
