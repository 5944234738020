import ReactGA from "react-ga4";
import mixpanel from "../mixpanelConfig";

export default function trackButtonClick(label) {
  // Tracking with Google Analytics
  ReactGA.event({
    name: "click_button",
    params: {
      event_category: "User",
      event_label: label,
    },
  });

  // Tracking with Mixpanel
  mixpanel.track("Button Clicked", {
    event_category: "User",
    event_label: label,
  });
}
