import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";

const Support = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant={"h6"}
              fontWeight={700}
              align={"center"}
              gutterBottom
            >
              Need assistance?
            </Typography>
            <Typography align={"center"}>
              We're Here to Help You Understand
            </Typography>
            <Box marginTop={2} display={"flex"} justifyContent={"center"}>
              <Button size={"large"} endIcon={<ArrowRightAltOutlinedIcon />}>
                Talk to Us
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderLeft: {
              xs: "none",
              md: `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <Box>
            <Typography
              variant={"h6"}
              fontWeight={700}
              align={"center"}
              gutterBottom
            >
              Tailored Plans for Teams
            </Typography>
            <Typography align={"center"}>
              Need specific features or more credits for your team? Contact us
              for customized team plans.
            </Typography>
            <Box marginTop={2} display={"flex"} justifyContent={"center"}>
              <Button size={"large"} endIcon={<ArrowRightAltOutlinedIcon />}>
                Contact Us for Customization
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Support;
