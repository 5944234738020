//client/src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createRoot, hydrateRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import AuthProvider from './providers/AuthProvider'; // Adjust the path according to your project structure

const clientId =
  '324216124086-u8aekqu4fe07gki9c4lc53thcge25rnt.apps.googleusercontent.com';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <RecoilRoot>
        <RecoilNexus />
        <BrowserRouter>
          <HelmetProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </GoogleOAuthProvider>
          </HelmetProvider>
        </BrowserRouter>
      </RecoilRoot>
    </React.StrictMode>
  );
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <RecoilRoot>
        <RecoilNexus />
        <BrowserRouter>
          <HelmetProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </GoogleOAuthProvider>
          </HelmetProvider>
        </BrowserRouter>
      </RecoilRoot>
    </React.StrictMode>
  );
}

reportWebVitals();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <RecoilRoot>
//       <RecoilNexus />
//       <BrowserRouter>
//         <HelmetProvider>
//           <GoogleOAuthProvider clientId={clientId}>
//             <AuthProvider>
//               <App />
//             </AuthProvider>
//           </GoogleOAuthProvider>
//         </HelmetProvider>
//       </BrowserRouter>
//     </RecoilRoot>
//   </React.StrictMode>
// );

// reportWebVitals();
