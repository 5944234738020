import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../Container';
import { authState } from '../../../atoms';

import {
  customerEmailResponder,
  performanceReportCreator,
  pitchDeckMaker,
  proposalWriter,
  websiteContentPlanner,
} from '../cards/BusinessTools';

const features = [
  customerEmailResponder,
  performanceReportCreator,
  pitchDeckMaker,
  proposalWriter,
  websiteContentPlanner,
];

const MyBusinessTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <MyBusinessToolsInstructions navigate={navigate} />
      </Container>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.background.paper,
            0
          )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: 'repeat-x',
          position: 'relative',
        }}
      >
        <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
          <Grid container spacing={4}>
            {features.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureCard item={item} onClick={handleClickOpen} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const MyBusinessToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Business Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To tailor our business tools to your specific needs, you can customize
        them by uploading your company's knowledge base and defining your
        preferred tone of voice. Here's how:
        <ul>
          <li>
            Upload your knowledge base: Click the button below, input your
            company's information, assign it a title, and save it. When using
            the tools, select "Use my knowledge base" to incorporate your
            specific information.
          </li>
          <li>
            Define your tone of voice: Click the button below, describe your
            preferred communication style, name it, and save it. This could
            include specific phrasing or language that reflects your company's
            voice. You can then select this tone when using our tools.
          </li>
        </ul>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/knowledge-base')}
        >
          Upload Knowledge Base
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-tone')}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default MyBusinessTools;
