import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const youTubeScriptwriter = createLanguageKeywordTopicTemplate({
  name: "youtubescriptwriter",
  endpoint: "/api/openai/youtube-scriptwriter",
  aiText: "youTubeScriptwriter",
  aiTextHandler: "youTubeScriptwriterHandler",
  aiTextPlainText: "youTubeScriptwriterPlainText",
  title: "YouTube Scriptwriter",
  subText:
    "Create engaging video content with our YouTube Scriptwriter. Enter your video's topic, and it will generate a dynamic, captivating script, ensuring your message is delivered effectively to your audience.",
  label: "YouTube Script",
  placeholderText: "Enter your video's topic…",
  buttonText: "Write Script",
  aiPlaceholder: "YouTube script will appear here",
});

export default youTubeScriptwriter;
