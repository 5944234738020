import React from "react";
import { colors } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { alpha } from "@mui/material";

const interviewQuestionGenerator = {
  title: "Interview Question Generator",
  subtitle: "Compile insightful interview questions for journalists.",
  targetRoute: "/interview-question-generator",
  color: colors.lightBlue[600],
  bgcolor: alpha(colors.lightBlue[600], 0.1),
  icon: <ContactSupportIcon fontSize="medium" />,
  tags: [],
};

export default interviewQuestionGenerator;
