import React from "react";
import { colors } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { alpha } from "@mui/material";

const youTubeScriptwriter = {
  title: "YouTube Scriptwriter",
  subtitle:
    "Develop captivating YouTube scripts that capture audience attention and maximize viewer retention.",
  targetRoute: "/youtube-scriptwriter",
  color: colors.red[700],
  bgcolor: alpha(colors.red[700], 0.1),

  icon: <YouTubeIcon fontSize="medium" />,
  tags: [],
};

export default youTubeScriptwriter;
