import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Card,
  Avatar,
} from '@mui/material';
import Fuse from 'fuse.js';

import { authState } from '../../../atoms';

import CtaSimpleCentered from './CtaSimpleCentered/CtaSimpleCentered';
import {
  customerEmailResponder,
  performanceReportCreator,
  pitchDeckMaker,
  proposalWriter,
  websiteContentPlanner,
} from '../cards/BusinessTools';

import {
  analogyMaker,
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  faqCreator,
  grammarCorrector,
  headlineIdeasWriter,
  introductionsWriter,
  landingPageHeadlineGenerator,
  narrateToChild,
  outlineBasedBlogWriter,
  productDescriptionGenerator,
  quoteGenerator,
  stepByStepProductGuideWriter,
  textReadabilityScanner,
  websitePageContentGenerator,
} from '../cards/ContentWriters';

import {
  breakingNewsAlertGenerator,
  headlineGeneratorBroadcast,
  headlineGeneratorDigital,
  headlineGeneratorPrint,
  interactiveContentGenerator,
  interviewQuestionGenerator,
  newsReportBroadcast,
  newsReportDigital,
  newsReportPrint,
  newsSummaryTool,
  pressReleaseRewriterBroadcast,
  pressReleaseRewriterDigital,
  pressReleaseRewriterPrint,
  socialMediaPostGenerator,
  teaserWriter,
  tickerTextGenerator,
} from '../cards/NewsWriters';

import {
  blogContentPlanner,
  blogTopicIdeas,
  keywordResearchTool,
  relatedTopicsResearch,
  simpleKeywordResearchTool,
  ultimateTopicResearcher,
} from '../cards/SeoTools';

import {
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
} from '../cards/SocialMediaTools';

import {
  analogyCreatorTeaching,
  difficultQuestionPaperMaker,
  flashcardMaker,
  homeworkAssigner,
  individualLearningPlanCreator,
  interactiveActivityDesigner,
  lessonPlanner,
  mixedQuestionPaperMaker,
  quizGeneratorTeaching,
  revisionNotes,
  simpleQuestionPaperMaker,
  topicExplainerTeaching,
} from '../cards/TeachingTools';

const features = {
  'Business Tools': [
    customerEmailResponder,
    performanceReportCreator,
    pitchDeckMaker,
    proposalWriter,
    websiteContentPlanner,
  ],

  'Content Writers': [
    analogyMaker,
    blogOutlineBuilder,
    blogWriter,
    blogWriterAida,
    blogWriterPas,
    blogWriterBenefits,
    blogWriterHowTo,
    blogWriterStorytelling,
    faqCreator,
    grammarCorrector,
    headlineIdeasWriter,
    introductionsWriter,
    landingPageHeadlineGenerator,
    narrateToChild,
    outlineBasedBlogWriter,
    productDescriptionGenerator,
    quoteGenerator,
    stepByStepProductGuideWriter,
    textReadabilityScanner,
    websitePageContentGenerator,
  ],

  'News Writers': [
    breakingNewsAlertGenerator,
    headlineGeneratorBroadcast,
    headlineGeneratorDigital,
    headlineGeneratorPrint,
    interactiveContentGenerator,
    interviewQuestionGenerator,
    newsReportBroadcast,
    newsReportDigital,
    newsReportPrint,
    newsSummaryTool,
    pressReleaseRewriterBroadcast,
    pressReleaseRewriterDigital,
    pressReleaseRewriterPrint,
    socialMediaPostGenerator,
    teaserWriter,
    tickerTextGenerator,
  ],

  'SEO Tools': [
    blogContentPlanner,
    blogTopicIdeas,
    keywordResearchTool,
    relatedTopicsResearch,
    simpleKeywordResearchTool,
    ultimateTopicResearcher,
  ],

  'Social Media Tools': [
    contentIdeasGenerator,
    podcastCreator,
    socialMediaContentGenerator,
    youTubeScriptwriter,
    youTubeTagGenerator,
    youTubeTitleDescriptionKeywordsHashtagsGenerator,
  ],

  'Teaching Tools': [
    analogyCreatorTeaching,
    difficultQuestionPaperMaker,
    flashcardMaker,
    homeworkAssigner,
    individualLearningPlanCreator,
    interactiveActivityDesigner,
    lessonPlanner,
    mixedQuestionPaperMaker,
    quizGeneratorTeaching,
    revisionNotes,
    simpleQuestionPaperMaker,
    topicExplainerTeaching,
  ],
};

const options = {
  includeScore: true,
  keys: ['title', 'subtitle', 'tags'],
};

const AllTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated } = auth;

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(features);

  useEffect(() => {
    if (searchQuery) {
      const results = {};
      for (let category in features) {
        const fuse = new Fuse(features[category], options);
        const searchRes = fuse.search(searchQuery);
        results[category] = searchRes.map(({ item }) => item);
      }
      setSearchResults(results);
    } else {
      setSearchResults(features);
    }
  }, [searchQuery]);

  const handleClickOpen = (item) => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(item);
    }
  };

  return (
    <>
      <CtaSimpleCentered
        searchQuery={searchQuery}
        onSearchChange={(event) => setSearchQuery(event.target.value)}
      />
      {Object.entries(searchResults).map(([category, cards], i) => (
        <Box
          key={i}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container
            sx={{
              paddingTop: theme.spacing(10), // Adds padding top of 16px
              paddingBottom: theme.spacing(10), // Adds padding bottom of 16px
              marginTop: theme.spacing(0), // Adds margin top of 16px
              marginBottom: theme.spacing(0), // Adds margin bottom of 16px
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                marginBottom: 3,
                fontWeight: '500',
              }}
            >
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <IconButton
                    onClick={() => handleClickOpen(item.targetRoute)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: 0,
                      borderRadius: 2,
                      border: '1px solid transparent',
                      transition: 'border-color 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        borderColor: theme.palette.action.hover,
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Box
                      component={Card}
                      padding={4}
                      borderRadius={2}
                      width={1}
                      height={1}
                      data-aos={'fade-up'}
                      data-aos-delay={i * 100}
                    >
                      <Box
                        component={Avatar}
                        width={60}
                        height={60}
                        marginBottom={2}
                        bgcolor={item.bgcolor}
                        color={item.color}
                        variant={'rounded'}
                        borderRadius={2}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant={'h6'}
                        gutterBottom
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

export default AllTools;
