import createNoTopicKeywordKnowledgeTemplate from "../../NoTopicKeywordKnowledgeTemplate";

const websiteContentPlanner = createNoTopicKeywordKnowledgeTemplate({
  name: "websitecontentplanner",
  endpoint: "/api/openai/website-content-planner",
  aiText: "websiteContentPlanner",
  aiTextHandler: "websiteContentPlannerHandler",
  aiTextPlainText: "websiteContentPlannerPlainText",
  title: "Website Content Planner",
  subText:
    "This is a comprehensive tool designed to bring structure and coherence to your product, service, or organizational website. By simply inputting specific details about your business, this tool will generate a sophisticated 'silo' structure, a proven method in SEO that enhances user experience and search engine indexing. Employing a silo structure through our tool not only streamlines your content organization but also optimizes your site's visibility. It logically sequences your content, creating a thematic consistency that both users and search engines appreciate. Our Website Content Planner is more than a content arrangement tool - it's a strategic asset for your online presence.",
  label: "Content Plan",
  infoText: "Input information about your organisation/service/product",
  buttonText: "Create Website Plan",
  aiPlaceholder: "Website content plan will appear here",
});

export default websiteContentPlanner;
