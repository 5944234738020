import React from "react";
import { colors } from "@mui/material";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import { alpha } from "@mui/material";

const blogWriter = {
  title: "Blog Writer",
  subtitle: "Convert ideas into engaging blog content.",
  targetRoute: "/blog-writer",
  color: colors.blue["A700"],
  bgcolor: alpha(colors.blue["A700"], 0.1),
  icon: <VerticalSplitOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriter;
