import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../../Container';
import SearchBar from '../SearchBar/SearchBar';
import { authState } from '../../../../atoms';
import Fuse from 'fuse.js';

import {
  breakingNewsAlertGenerator,
  headlineGeneratorBroadcast,
  headlineGeneratorDigital,
  headlineGeneratorPrint,
  interactiveContentGenerator,
  interviewQuestionGenerator,
  newsReportBroadcast,
  newsReportDigital,
  newsReportPrint,
  newsSummaryTool,
  pressReleaseRewriterBroadcast,
  pressReleaseRewriterDigital,
  pressReleaseRewriterPrint,
  socialMediaPostGenerator,
  teaserWriter,
  tickerTextGenerator,
} from '../../cards/NewsWriters';

const features = {
  'Print Media': [
    headlineGeneratorPrint,
    newsReportPrint,
    newsSummaryTool,
    interviewQuestionGenerator,
    pressReleaseRewriterPrint,
  ],
  'Broadcast Media': [
    breakingNewsAlertGenerator,
    headlineGeneratorBroadcast,
    newsReportBroadcast,
    newsSummaryTool,
    interviewQuestionGenerator,
    pressReleaseRewriterBroadcast,
    teaserWriter,
    tickerTextGenerator,
  ],
  'Digital Media': [
    breakingNewsAlertGenerator,
    headlineGeneratorDigital,
    newsReportDigital,
    newsSummaryTool,
    interviewQuestionGenerator,
    pressReleaseRewriterDigital,
  ],
  'Social Media for News': [
    interactiveContentGenerator,
    socialMediaPostGenerator,
  ],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: ['title', 'subtitle', 'tags'],
};

const JournalistTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = useMemo(() => {
    const allFeatures = Object.values(features).flat();
    return new Fuse(allFeatures, fuseOptions);
  }, []);

  const searchResults = useMemo(() => {
    if (!searchQuery) return features;
    const results = fuse.search(searchQuery);
    return Object.fromEntries(
      Object.entries(features).map(([category, items]) => [
        category,
        items.filter((item) => results.some((result) => result.item === item)),
      ])
    );
  }, [fuse, searchQuery]);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <JournalistToolsInstructions navigate={navigate} />
        <Box sx={{ mt: 3 }}>
          {' '}
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
      </Container>
      {Object.entries(searchResults).map(([category, cards], index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
            {' '}
            {/* Reduced top padding */}
            <Typography variant="h5" gutterBottom fontWeight="500" mb={3}>
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <FeatureCard item={item} onClick={handleClickOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

const JournalistToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Journalism Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To achieve an experience that truly aligns with your specific
        journalistic requirements, we offer the opportunity to customize our
        tools by defining your preferred tone of voice. Here's how you can do
        it:
        <ul>
          <li>
            Define your tone of voice: Click the button below, describe your
            preferred writing style, name it, and save it. This could include
            specific sentence structures or language nuances that echo your
            publication's style of communication. You can then select this tone
            from a dropdown list when using our tools.
          </li>
        </ul>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-tone')}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default JournalistTools;
