import React from 'react';
import useInitializeAuth from '../hooks/useInitializeAuth'; // Adjust the path according to your project structure

const AuthProvider = ({ children }) => {
  const loading = useInitializeAuth();

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or your custom loading component
  }

  return <>{children}</>;
};

export default AuthProvider;
