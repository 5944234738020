import React from "react";
import { colors } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { alpha } from "@mui/material";

const youTubeTagGenerator = {
  title: "YouTube Tag Generator",
  subtitle:
    "Produce high-search-volume YouTube tags for improved video ranking.",
  targetRoute: "/youtube-tag-generator",
  color: colors.pink["A700"],
  bgcolor: alpha(colors.pink["A700"], 0.1),
  icon: <LocalOfferIcon fontSize="medium" />,
  tags: [],
};

export default youTubeTagGenerator;
