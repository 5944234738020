import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '../../../Container';
import {
  Hero,
  Features,
  Banner,
  VideoSection,
  GetStarted,
  HowItWorks,
} from './components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BusinessLanding = () => {
  const theme = useTheme();

  return (
    <HelmetProvider>
      <Box sx={{ overflowX: 'hidden' }}>
        <Helmet>
          <title>AI Tools for Your Business</title>
          <meta
            name="description"
            content="Empower your business with Wisdom Scribe's specialized AI tools. Transform your marketing, reporting, and customer experience with our innovative solutions designed specifically for modern businesses. Increase efficiency and drive growth with our tailored AI technology."
          />
          <meta
            name="keywords"
            content="AI tools for business, business automation, marketing AI, customer experience optimization, business efficiency, Wisdom Scribe"
          />
          <meta property="og:title" content="AI Tools for Business" />
          <meta
            property="og:description"
            content="Transform your business with Wisdom Scribe's specialized AI tools. Optimize marketing, reporting, and customer experience with our innovative business solutions."
          />
          <meta
            property="og:image"
            content="https://wisdomscribe.ai/robot.jpg"
          />
          <meta
            property="og:url"
            content="https://wisdomscribe.ai/landing-business"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Box
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          bgcolor={'alternate.main'}
          marginTop={-13}
          paddingTop={13}
          paddingBottom={4}
        >
          <Container>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Hero />
            </Box>
          </Container>
        </Box>
        <Box bgcolor={theme.palette.alternate.main}>
          <Container>
            <Features />
          </Container>
        </Box>

        <Box
          sx={{
            backgroundImage: `linear-gradient(180deg, ${theme.palette.alternate.main} 50%, ${theme.palette.background.paper} 0%)`,
          }}
        >
          <Container>
            <HowItWorks />
          </Container>
        </Box>

        <Box bgcolor={'primary.main'}>
          <Container>
            <Banner />
          </Container>
        </Box>
        <Box>
          <Container>
            <VideoSection />
          </Container>
        </Box>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
        <Box bgcolor={theme.palette.alternate.main}>
          <Container>
            <GetStarted />
          </Container>
        </Box>
      </Box>
    </HelmetProvider>
  );
};

export default BusinessLanding;
