import React from "react";
import { colors } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { alpha } from "@mui/material";

const stepByStepProductGuideWriter = {
  title: "Step-by-Step Product Guide Writer",
  subtitle:
    "Provide comprehensive, step-by-step guides to navigate your product.",
  targetRoute: "/step-by-step-product-guide-writer",
  color: colors.blueGrey[900],
  bgcolor: alpha(colors.blueGrey[900], 0.1),
  icon: <FormatListBulletedIcon fontSize="medium" />,
  tags: [],
};

export default stepByStepProductGuideWriter;
