// // client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/SidebarNav.js

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { useRecoilValue } from 'recoil';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemAvatar,
//   Avatar,
//   ListItemText,
//   Select,
//   MenuItem,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

// import { CollapsibleItem } from './components';
// import { authState } from '../../../../../../atoms';

// import generalMock from './generalMock';
// import journalistMock from './journalistMock';
// import educatorMock from './educatorMock';
// import businessMock from './businessMock';
// import digitalMarketerMock from './digitalMarketerMock';
// import writerMock from './writerMock';

// const SidebarNav = ({ onRoleChange }) => {
//   const navigate = useNavigate();
//   const auth = useRecoilValue(authState);
//   const { user } = auth;
//   const [selectedRole, setSelectedRole] = useState('general');

//   const theme = useTheme();
//   const { mode } = theme.palette;

//   useEffect(() => {
//     if (user?.role) {
//       setSelectedRole(user.role);
//       localStorage.setItem('selectedRole', user.role);
//       navigateToDashboard(user.role);
//     } else {
//       const storedRole = localStorage.getItem('selectedRole');
//       if (storedRole) {
//         setSelectedRole(storedRole);
//         navigateToDashboard(storedRole);
//       } else {
//         setSelectedRole('general');
//         localStorage.setItem('selectedRole', 'general');
//         navigateToDashboard('general');
//       }
//     }
//   }, [user]);

//   const navigateToDashboard = (role) => {
//     switch (role) {
//       case 'journalist':
//         navigate('/my-journalist-dashboard');
//         break;
//       case 'educator':
//         navigate('/my-teaching-dashboard');
//         break;
//       case 'business':
//         navigate('/my-business-dashboard');
//         break;
//       case 'writer':
//         navigate('/my-writing-dashboard');
//         break;
//       case 'digital-marketer':
//         navigate('/my-digital-marketing-dashboard');
//         break;
//       default:
//         navigate('/dashboard');
//     }
//   };

//   const handleRoleChange = (event) => {
//     const newRole = event.target.value;
//     setSelectedRole(newRole);
//     localStorage.setItem('selectedRole', newRole);
//     onRoleChange(newRole);
//     navigateToDashboard(newRole);
//   };

//   if (auth.isLoading || user?.role === undefined) {
//     return <div>Loading...</div>;
//   }

//   const handleNavigation = (path) => {
//     navigate(path);
//   };

//   const getMockData = () => {
//     switch (selectedRole) {
//       case 'journalist':
//         return journalistMock;
//       case 'educator':
//         return educatorMock;
//       case 'business':
//         return businessMock;
//       case 'digital-marketer':
//         return digitalMarketerMock;
//       case 'writer':
//         return writerMock;
//       default:
//         return generalMock;
//     }
//   };

//   const mockData = getMockData();

//   return (
//     <Box paddingY={2} display={'flex'} flexDirection={'column'} height={1}>
//       <Box paddingX={2} marginBottom={2}>
//         <Select
//           value={selectedRole}
//           onChange={handleRoleChange}
//           fullWidth
//           sx={{
//             color: theme.palette.text.primary,
//             '& .MuiOutlinedInput-notchedOutline': {
//               borderColor: theme.palette.divider,
//             },
//             '&:hover .MuiOutlinedInput-notchedOutline': {
//               borderColor: theme.palette.primary.main,
//             },
//             '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//               borderColor: theme.palette.primary.main,
//             },
//           }}
//         >
//           <MenuItem value="general">General</MenuItem>
//           <MenuItem value="business">Business</MenuItem>
//           <MenuItem value="educator">Educator</MenuItem>
//           <MenuItem value="journalist">Journalist</MenuItem>
//           <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
//           <MenuItem value="writer">Writer</MenuItem>
//         </Select>
//       </Box>
//       {mockData.map((group, index) => (
//         <Box key={index} marginBottom={3}>
//           <CollapsibleItem
//             item={group}
//             isOpen={true}
//             onNavigate={handleNavigation}
//           />
//         </Box>
//       ))}
//       <List sx={{ paddingX: 2 }}>
//         <ListItem alignItems="flex-start" disableGutters disablePadding>
//           <ListItemAvatar>
//             <Avatar
//               alt={user ? `${user.firstName} ${user.lastName}` : ''}
//               src={user ? user.avatar : ''}
//             />
//           </ListItemAvatar>
//           <ListItemText
//             primary={user ? `${user.firstName} ${user.lastName}` : ''}
//             secondary={user ? user.email : ''}
//           />
//         </ListItem>
//       </List>
//     </Box>
//   );
// };

// SidebarNav.propTypes = {
//   onRoleChange: PropTypes.func.isRequired,
// };

// export default SidebarNav;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CollapsibleItem } from './components';
import { authState } from '../../../../../../atoms';

import generalMock from './generalMock';
import journalistMock from './journalistMock';
import educatorMock from './educatorMock';
import businessMock from './businessMock';
import digitalMarketerMock from './digitalMarketerMock';
import writerMock from './writerMock';

const SidebarNav = ({ onRoleChange, onDashboardChange, currentDashboard }) => {
  const auth = useRecoilValue(authState);
  const { user } = auth;
  const [selectedRole, setSelectedRole] = useState(
    localStorage.getItem('selectedRole') || 'general'
  );

  const theme = useTheme();

  useEffect(() => {
    if (user?.role && !localStorage.getItem('selectedRole')) {
      setSelectedRole(user.role);
      localStorage.setItem('selectedRole', user.role);
    }
  }, [user]);

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);
    localStorage.setItem('selectedRole', newRole);
    onRoleChange(newRole);
  };

  const handleNavigation = (path) => {
    onDashboardChange(path);
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  const getMockData = () => {
    switch (selectedRole) {
      case 'journalist':
        return journalistMock;
      case 'educator':
        return educatorMock;
      case 'business':
        return businessMock;
      case 'digital-marketer':
        return digitalMarketerMock;
      case 'writer':
        return writerMock;
      default:
        return generalMock;
    }
  };

  const mockData = getMockData();

  return (
    <Box paddingY={2} display={'flex'} flexDirection={'column'} height={1}>
      <Box paddingX={2} marginBottom={2}>
        <Select
          value={selectedRole}
          onChange={handleRoleChange}
          fullWidth
          sx={{
            color: theme.palette.text.primary,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.divider,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <MenuItem value="general">General</MenuItem>
          <MenuItem value="business">Business</MenuItem>
          <MenuItem value="educator">Educator</MenuItem>
          <MenuItem value="journalist">Journalist</MenuItem>
          <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
          <MenuItem value="writer">Writer</MenuItem>
        </Select>
      </Box>
      {mockData.map((group, index) => (
        <Box key={index} marginBottom={3}>
          <CollapsibleItem
            item={group}
            isOpen={true}
            onNavigate={handleNavigation}
            currentPath={currentDashboard}
          />
        </Box>
      ))}
      <List sx={{ paddingX: 2 }}>
        <ListItem alignItems="flex-start" disableGutters disablePadding>
          <ListItemAvatar>
            <Avatar
              alt={user ? `${user.firstName} ${user.lastName}` : ''}
              src={user ? user.avatar : ''}
            />
          </ListItemAvatar>
          <ListItemText
            primary={user ? `${user.firstName} ${user.lastName}` : ''}
            secondary={user ? user.email : ''}
          />
        </ListItem>
      </List>
    </Box>
  );
};

SidebarNav.propTypes = {
  onRoleChange: PropTypes.func.isRequired,
  onDashboardChange: PropTypes.func.isRequired,
  currentDashboard: PropTypes.string,
};

export default SidebarNav;
