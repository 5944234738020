// // client/src/components/routing/ProtectedRoute.js

// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../atoms';

// const ProtectedRoute = ({ component: Component }) => {
//   const auth = useRecoilValue(authState);

//   if (auth.isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!auth.isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }

//   // Check if the user is authenticated with Google (has a googleId)
//   const isGoogleUser = auth.user && auth.user.googleId;

//   // Only redirect to email verification if the user is not a Google user and their email is not verified
//   if (auth.user && !auth.user.emailVerified && !isGoogleUser) {
//     return <Navigate to="/email-verification" replace />;
//   }

//   return <Component />;
// };

// export default ProtectedRoute;

// import React, { useEffect } from 'react';
// import { Navigate, useNavigate } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../atoms';

// const ProtectedRoute = ({ component: Component }) => {
//   const auth = useRecoilValue(authState);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (auth.isAuthenticated) {
//       const isIncomplete = localStorage.getItem('googleRegistrationIncomplete');
//       if (isIncomplete) {
//         navigate('/complete-registration'); // Route to phone number input and role selection
//       }
//     }
//   }, [auth.isAuthenticated, navigate]);

//   if (auth.isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!auth.isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }

//   // Check if the user is authenticated with Google (has a googleId)
//   const isGoogleUser = auth.user && auth.user.googleId;

//   // Only redirect to email verification if the user is not a Google user and their email is not verified
//   if (auth.user && !auth.user.emailVerified && !isGoogleUser) {
//     return <Navigate to="/email-verification" replace />;
//   }

//   return <Component />;
// };

// export default ProtectedRoute;

// import React, { useEffect } from 'react';
// import { Navigate, useNavigate, useLocation } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../atoms';

// const ProtectedRoute = ({ component: Component }) => {
//   const auth = useRecoilValue(authState);
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     console.log('ProtectedRoute: Current auth state:', auth);
//     console.log('ProtectedRoute: Current path:', location.pathname);

//     if (auth.isAuthenticated) {
//       const isIncomplete = localStorage.getItem('googleRegistrationIncomplete');
//       if (isIncomplete) {
//         navigate('/complete-registration');
//       }
//     }
//   }, [auth.isAuthenticated, navigate, location]);

//   if (auth.isLoading) {
//     return <div>Loading...</div>;
//   }

//   // Allow access to /complete-registration even if not authenticated
//   if (location.pathname === '/complete-registration') {
//     const storedGoogleUser = localStorage.getItem('googleUser');
//     if (storedGoogleUser) {
//       return <Component />;
//     }
//   }

//   if (!auth.isAuthenticated) {
//     console.log('ProtectedRoute: User not authenticated, redirecting to /');
//     return <Navigate to="/" replace />;
//   }

//   // Check if the user is authenticated with Google (has a googleId)
//   const isGoogleUser = auth.user && auth.user.googleId;

//   // Only redirect to email verification if the user is not a Google user and their email is not verified
//   if (auth.user && !auth.user.emailVerified && !isGoogleUser) {
//     return <Navigate to="/email-verification" replace />;
//   }

//   return <Component />;
// };

// export default ProtectedRoute;

import React, { useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';

const ProtectedRoute = ({ component: Component }) => {
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('ProtectedRoute: Current auth state:', auth);
    console.log('ProtectedRoute: Current path:', location.pathname);

    if (auth.isAuthenticated) {
      const isIncomplete = localStorage.getItem('googleRegistrationIncomplete');
      if (isIncomplete) {
        navigate('/complete-registration');
      }
    }
  }, [auth.isAuthenticated, navigate, location]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    console.log('ProtectedRoute: User not authenticated, redirecting to /');
    return <Navigate to="/" replace />;
  }

  // Check if the user is authenticated with Google (has a googleId)
  const isGoogleUser = auth.user && auth.user.googleId;

  // Only redirect to email verification if the user is not a Google user and their email is not verified
  if (auth.user && !auth.user.emailVerified && !isGoogleUser) {
    console.log('ProtectedRoute: Redirecting to email verification');
    return <Navigate to="/email-verification" replace />;
  }

  console.log('ProtectedRoute: Rendering protected component');
  return <Component />;
};

export default ProtectedRoute;
