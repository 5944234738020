import React from "react";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const GeneratingDialog = ({ open, onClose }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Generating Text
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Hang tight! We're quickly generating your text. Please stay on this
            page.
          </Typography>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};

export default GeneratingDialog;
