import createKeywordKnowledgeBaseTemplate from "../../KeywordKnowledgeBaseTemplate";

const blogTopicIdeas = createKeywordKnowledgeBaseTemplate({
  name: "blogtopicideas",
  endpoint: "/api/openai/blog-topic-ideas",
  aiText: "blogTopicIdeas",
  aiTextHandler: "blogTopicIdeasHandler",
  aiTextPlainText: "blogTopicIdeasPlainText",
  title: "Blog Idea Generator",
  subText:
    "Need ideas? Blog Idea Generator suggests unique topic ideas based on your input. Simply enter your topic and receive suggestions that can perform well on Google.",
  label: "Blog Ideas",
  placeholderText: "What is your blog about?",
  infoText: "Input any additional information (optional)",
  buttonText: "Suggest Topics",
  aiPlaceholder: "Your blog topic ideas will appear here",
});

export default blogTopicIdeas;
