import React from "react";
import { colors } from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { alpha } from "@mui/material";

const customerEmailResponder = {
  title: "Customer Email Responder",
  subtitle: "Craft professional, empathetic customer responses.",
  targetRoute: "/customer-email-responder",
  color: colors.blue[800],
  bgcolor: alpha(colors.blue[800], 0.1),
  icon: <MarkEmailReadIcon fontSize="medium" />,
  tags: [],
};

export default customerEmailResponder;
