import createNewsTemplate from "../../NewsTemplate";

const newsReportBroadcast = createNewsTemplate({
  name: "newsreportbroadcast",
  endpoint: "/api/openai/news-report-broadcast",
  aiText: "newsReportBroadcast",
  aiTextHandler: "newsReportBroadcastHandler",
  aiTextPlainText: "newsReportBroadcastPlainText",
  title: "News Report - Broadcast",
  subText:
    "This tool for broadcast media translates your news information into dynamic scripts. Give it a topic, word count, news details, background info, and choose a news type. Watch as it produces engaging scripts complete with speaker cues and scene transitions.",
  label: "News Script",
  placeholderText: "Enter Main News Here",
  buttonText: "Write Script",
  aiPlaceholder: "Your script will appear here",
});

export default newsReportBroadcast;
