import createTeachingTemplate from "../../TeachingTemplate";
const flashcardMaker = createTeachingTemplate({
  name: "flashcardmaker",
  endpoint: "/api/openai/flashcard-maker",
  aiText: "flashcardMaker",
  aiTextHandler: "flashcardMakerHandler",
  aiTextPlainText: "flashcardMakerPlainText",
  title: "Flashcard Maker",
  subText:
    'Revising has never been easier! Just choose the class, subject, and the relevant chapters from your uploaded syllabus. This tool will create a set of flashcards to streamline revision. Ensure your syllabus is uploaded for the most effective revision aids. Example: "Grade 10, English Literature, Chapters 1-3.',
  label: "Revision Flashcards",
  placeholderText: "Specify any key points",
  buttonText: "Create Flashcards",
  aiPlaceholder: "Revision flashcards will appear here",
});

export default flashcardMaker;
