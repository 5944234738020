import React from "react";
import { colors } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { alpha } from "@mui/material";

const blogWriterFromSpeech = {
  title: "Blog Writer From Speech",
  subtitle: "Turn speeches into powerful multilingual blog posts.",
  targetRoute: "/blog-writer-from-speech",
  color: colors.pink[700],
  bgcolor: alpha(colors.pink[700], 0.1),
  icon: <ChatIcon fontSize="medium" />,
  tags: [],
};

export default blogWriterFromSpeech;
