import createOutlineBlogWriterTemplate from "../../OutlineBlogWriterTemplate";

const outlineBasedBlogwriter = createOutlineBlogWriterTemplate({
  name: "outlinebasedblogwriter",
  endpoint: "/api/openai/outline-based-blog-writer",
  aiText: "outlineBasedBlogwriter",
  aiTextHandler: "outlineBasedBlogwriterHandler",
  aiTextPlainText: "outlineBasedBlogwriterPlainText",
  title: "Outline-Based Blog Writer",
  subText:
    "Streamline your content creation with this tool. Feed it an outline, and it will develop a fully fleshed-out, engaging blog post, saving you time and effort while ensuring consistent quality.",
  label: "Blog Post",
  placeholderText: "Enter your outline…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default outlineBasedBlogwriter;
