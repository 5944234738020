import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import logo from '../../../../../../assets/images/WisdomScribe-logo.svg';
import negativeLogo from '../../../../../../assets/images/WisdomScribe-logo-negative.svg';
import ThemeModeToggler from '../../../../../../components/ThemeModeToggler';
import NavItem from './components/NavItem';
import { authState } from '../../../../../../atoms';
import { logout } from '../../../../../../helpers/api';

const SidebarNav = ({ pages, colorInvert = false, onClose }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);
  const isAuthenticated = auth.isAuthenticated;

  const logoutHandler = () => {
    logout();
    setAuth({
      user: null,
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
      tokenExpiry: null,
    });
    navigate('/login');
  };

  const {
    about: aboutPages,
    tools: toolsPages,
    image: imagePages,
    voicelab: voicelabPages,
    pricing: pricingPages,
    dashboard: dashboardPages,
    contact: contactPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component={RouterLink}
          to="/"
          title="Wisdom Scribe"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={mode === 'light' && !colorInvert ? logo : negativeLogo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'About'} items={aboutPages} onClose={onClose} />
        </Box>
        <Box>
          <NavItem title={'Text Room'} items={toolsPages} onClose={onClose} />
        </Box>
        <Box>
          <NavItem
            title={'Image Studio'}
            items={imagePages}
            onClose={onClose}
          />
        </Box>
        <Box>
          <NavItem
            title={'Voice Lab'}
            items={voicelabPages}
            onClose={onClose}
          />
        </Box>

        <Box>
          <NavItem title={'Pricing'} items={pricingPages} onClose={onClose} />
        </Box>
        <Box>
          <NavItem
            title={'Dashboard'}
            items={dashboardPages}
            onClose={onClose}
          />
        </Box>
        <Box>
          <NavItem title={'Contact'} items={contactPages} onClose={onClose} />
        </Box>
        <Box marginTop={2}>
          <ThemeModeToggler />
        </Box>
        {isAuthenticated ? (
          <Button onClick={logoutHandler} variant="contained">
            Logout
          </Button>
        ) : (
          <>
            <Box marginLeft={4}>
              <Button
                component={RouterLink}
                to="/register"
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </Box>
            <Box marginLeft={4}>
              <Button
                component={RouterLink}
                to="/login"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default SidebarNav;
