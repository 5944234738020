import React, { useState, useEffect, useRef } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Grid,
  Chip,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import instance from './axiosInstance';

const VoicesDropdown = ({ onVoiceChange }) => {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('');
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await instance.get('/api/voices');
        setVoices(response.data.voices);
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };

    fetchVoices();
  }, []);

  const handleVoiceChange = (event) => {
    const newVoiceId = event.target.value;
    const voice = voices.find((v) => v.voice_id === newVoiceId);
    setSelectedVoice(newVoiceId);
    if (voice) {
      onVoiceChange(newVoiceId, voice.name); // Pass both ID and name
    }
  };

  const playPreview = (previewUrl) => {
    // Stop any currently playing audio
    if (!audioRef.current.paused) {
      audioRef.current.pause();
    }
    // Set the new source and play
    audioRef.current.src = previewUrl;
    audioRef.current
      .play()
      .catch((error) => console.error('Error playing audio:', error));
  };

  const getChipStyle = (labelType) => {
    const normalizedLabelType = labelType.replace('_', ' ');
    switch (normalizedLabelType) {
      case 'accent':
        return { backgroundColor: '#c5cae9' };
      case 'description':
      case 'descriptive':
        return { backgroundColor: '#b2dfdb' };
      case 'age':
        return { backgroundColor: '#ffecb3' };
      case 'gender':
        return { backgroundColor: '#e0e0e0' };
      case 'use case':
      case 'use_case':
        return { backgroundColor: '#ffcdd2' };
      default:
        return {};
    }
  };

  // MUI Theme and Media Query for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FormControl fullWidth>
      <InputLabel id="voice-select-label">Voice</InputLabel>
      <Select
        labelId="voice-select-label"
        id="voice-select"
        value={selectedVoice}
        label="Voice"
        onChange={handleVoiceChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: isMobile ? 200 : 300, // Adjust dropdown height for mobile
            },
          },
        }}
      >
        {voices.map((voice) => (
          <MenuItem key={voice.voice_id} value={voice.voice_id}>
            <Grid
              container
              alignItems="center"
              direction="row"
              wrap="nowrap" // Change this to nowrap to keep items in a row on desktop
              spacing={1}
            >
              <Grid item>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    playPreview(voice.preview_url);
                  }}
                  size="small"
                >
                  <PlayArrowIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ flexShrink: 1 }}>
                <Typography variant="body1" noWrap>
                  {voice.name}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexWrap: 'wrap', // This will allow the chips to wrap to the next line on mobile
                  alignItems: 'center',
                  marginLeft: '8px', // Add some left margin for spacing from the name
                }}
              >
                {Object.entries(voice.labels).map(([key, value]) => (
                  <Chip
                    key={key}
                    label={value}
                    size="small"
                    style={{ ...getChipStyle(key), margin: '2px' }} // Adjust the margin as needed
                  />
                ))}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VoicesDropdown;
