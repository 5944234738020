import React from "react";
import { colors } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { alpha } from "@mui/material";

const ultimateTopicResearcher = {
  title: "Ultimate Topic Researcher",
  subtitle:
    "Top-notch keyword research and content planner following industry-leading SEO strategies.",
  targetRoute: "/ultimate-topic-researcher",
  color: colors.grey["A700"],
  bgcolor: alpha(colors.grey["A700"], 0.1),
  icon: <TravelExploreIcon fontSize="medium" />,
  tags: [],
};

export default ultimateTopicResearcher;
