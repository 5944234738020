import createQuoteToneKnowledgeInfoLangTemplate from "../../QuoteToneKnowledgeInfoLangTemplate";

const quoteGenerator = createQuoteToneKnowledgeInfoLangTemplate({
  name: "quotegenerator",
  endpoint: "/api/openai/quote-generator",
  aiText: "quoteGenerator",
  aiTextHandler: "quoteGeneratorHandler",
  aiTextPlainText: "quoteGeneratorPlainText",
  title: "Quote Generator",
  subText:
    "Unleash the power of creativity with our AI-driven quote generator. Input your preferred theme, select your language, and with a simple click, receive a unique, thought-provoking quote. Tailor-made for your needs, it produces inspiring and memorable lines in the blink of an eye.",
  label: "Words of Wisdom",
  placeholderText: "Enter the text for extracting quote",
  buttonText: "Generate Quote",
  aiPlaceholder: "Quote will appear here",
});

export default quoteGenerator;
