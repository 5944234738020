import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const podcastCreator = createLanguageKeywordTopicTemplate({
  name: "podcastcreator",
  endpoint: "/api/openai/podcast-creator",
  aiText: "podcastCreator",
  aiTextHandler: "podcastCreatorHandler",
  aiTextPlainText: "podcastCreatorPlainText",
  title: "Podcast Creator",
  subText:
    "Simplify podcast production with our tool. Feed it your topic or theme, and it will create a podcast complete with natural inflection and pauses, creating an engaging listening experience for your audience.",
  label: "Podcast",
  placeholderText: "Enter your topic or theme...",
  buttonText: "Create Podcast",
  aiPlaceholder: "Your podcast will appear here",
});

export default podcastCreator;
