import createNoToneLanguageKeywordTopicTemplate from "../../NoToneLanguageKeywordTopicTemplate";

const blogOutlineBuilder = createNoToneLanguageKeywordTopicTemplate({
  name: "blogoutlinebuilder",
  endpoint: "/api/openai/blog-outline-builder",
  aiText: "blogOutlineBuilder",
  aiTextHandler: "blogOutlineBuilderHandler",
  aiTextPlainText: "blogOutlineBuilderPlainText",
  title: "Blog Outline Builder",
  subText:
    "Our Blog Outline Builder simplifies content creation. Enter your topic and keywords, and it will produce a comprehensive, structured outline, making the writing process smoother and more efficient.",
  label: "Blog Outline",
  placeholderText: "Enter your topic…",
  buttonText: "Generate Outline",
  aiPlaceholder: "Your blog outline will appear here",
});

export default blogOutlineBuilder;
