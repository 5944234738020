//client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/generalMock.js

import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HistoryIcon from '@mui/icons-material/History';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CampaignIcon from '@mui/icons-material/Campaign';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TagIcon from '@mui/icons-material/Tag';

const businessMock = [
  {
    groupTitle: 'AI Writing Tools',
    id: 'tools',
    pages: [
      {
        title: 'All Business Tools',
        path: '/my-business-dashboard',
        icon: <BusinessCenterIcon />,
      },
      {
        title: 'Marketing and Branding',
        path: '/business/marketing-and-branding',
        icon: <CampaignIcon />,
      },
      {
        title: 'Content Creation',
        path: '/business/content-creation',
        icon: <BorderColorIcon />,
      },
      {
        title: 'Customer Relations',
        path: '/business/customer-relations',
        icon: <SupportAgentIcon />,
      },
      {
        title: 'Business Documentation',
        path: '/business/business-documentation',
        icon: <SummarizeIcon />,
      },
      {
        title: 'SEO and Analytics',
        path: '/business/seo-and-analytics',
        icon: <StackedBarChartIcon />,
      },
      {
        title: 'Social Media Tools',
        path: '/business/social-media-tools',
        icon: <TagIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Documents',
    id: 'documents',
    pages: [
      {
        title: 'History',
        path: '/history',
        icon: <HistoryIcon />,
      },
      {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />,
      },
      {
        title: 'Playlist',
        path: '/playlist',
        icon: <LibraryMusicIcon />,
      },
      {
        title: 'Knowledge Base',
        path: '/my-knowledge-base',
        icon: <TextSnippetIcon />,
      },
      {
        title: 'Tone of Voice',
        path: '/my-tone-of-voice',
        icon: <GraphicEqIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Account',
    id: 'account',
    pages: [
      {
        title: 'General Settings',
        path: '/user-profile',
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: 'Change Password',
        path: '/update-password',
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: 'Billing Portal',
        path: '/billing-portal',
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Support',
    id: 'support',
    pages: [
      {
        title: 'Support',
        path: '#',
        icon: <EmailOutlinedIcon />,
      },
      {
        title: 'Announcements',
        path: '#',
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

export default businessMock;
