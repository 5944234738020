import createBusinessToolsTemplate from "../../BusinessToolsTemplate";

const performanceReportCreator = createBusinessToolsTemplate({
  name: "performancereportcreator",
  endpoint: "/api/openai/performance-report-creator",
  aiText: "performanceReportCreator",
  aiTextHandler: "performanceReportCreatorHandler",
  aiTextPlainText: "performanceReportCreatorPlainText",
  title: "Performance Report Creator",
  subText:
    "Streamline your reporting process with this tool. Input your data, and it will create a comprehensive performance report, visualizing data clearly to provide actionable insights for future strategies.",
  label: "Performance Report",
  placeholderText: "Input your data…",
  buttonText: "Create Report",
  aiPlaceholder: "Your performance report will appear here",
});

export default performanceReportCreator;
