import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import Container from '../../../Container';
import SearchBar from '../SearchBar/SearchBar';
import { authState } from '../../../../atoms';
import Fuse from 'fuse.js';

import {
  performanceReportCreator,
  websiteContentPlanner,
} from '../../cards/BusinessTools';

import {
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  headlineIdeasWriter,
  introductionsWriter,
  landingPageHeadlineGenerator,
  textReadabilityScanner,
} from '../../cards/ContentWriters';

import {
  blogContentPlanner,
  blogTopicIdeas,
  keywordResearchTool,
  relatedTopicsResearch,
  simpleKeywordResearchTool,
  ultimateTopicResearcher,
} from '../../cards/SeoTools';

import {
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
} from '../../cards/SocialMediaTools';

const features = {
  'SEO Tools': [
    keywordResearchTool,
    relatedTopicsResearch,
    simpleKeywordResearchTool,
    blogOutlineBuilder,
    blogContentPlanner,
    textReadabilityScanner,
  ],

  'Content Strategy and Planning': [
    blogTopicIdeas,
    contentIdeasGenerator,
    websiteContentPlanner,
    ultimateTopicResearcher,
  ],

  'Content Generation': [
    blogWriter,
    blogWriterAida,
    blogWriterPas,
    blogWriterBenefits,
    blogWriterHowTo,
    blogWriterStorytelling,
    headlineIdeasWriter,
    introductionsWriter,
    landingPageHeadlineGenerator,
  ],

  'Social Media Tools': [
    podcastCreator,
    socialMediaContentGenerator,
    youTubeScriptwriter,
    youTubeTagGenerator,
    youTubeTitleDescriptionKeywordsHashtagsGenerator,
  ],

  'Performance Analysis': [performanceReportCreator],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: ['title', 'subtitle', 'tags'],
};

const DigitalMarketingTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = useMemo(() => {
    const allFeatures = Object.values(features).flat();
    return new Fuse(allFeatures, fuseOptions);
  }, []);

  const searchResults = useMemo(() => {
    if (!searchQuery) return features;
    const results = fuse.search(searchQuery);
    return Object.fromEntries(
      Object.entries(features).map(([category, items]) => [
        category,
        items.filter((item) => results.some((result) => result.item === item)),
      ])
    );
  }, [fuse, searchQuery]);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <DigitalMarketingToolsInstructions navigate={navigate} />
        <Box sx={{ mt: 3 }}>
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
      </Container>
      {Object.entries(searchResults).map(([category, cards], index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
            <Typography variant="h5" gutterBottom fontWeight="500" mb={3}>
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <FeatureCard item={item} onClick={handleClickOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

const DigitalMarketingToolsInstructions = ({ navigate }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '16px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Digital Marketing Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To optimize our digital marketing tools for your specific campaigns and
        brand, you can upload your marketing knowledge base and define your
        brand's tone of voice:
        <ul>
          <li>
            Upload your knowledge base: Input your marketing strategies, target
            audience information, and campaign details. Assign it a title and
            save it. When using the tools, select "Use my knowledge base" to
            integrate this information into your marketing efforts.
          </li>
          <li>
            Define your tone of voice: Describe your brand's unique voice and
            communication style, name it, and save it. This could include
            specific phrases, tone preferences, or stylistic elements that
            resonate with your target audience. You can then select this tone
            when using our digital marketing tools.
          </li>
        </ul>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/knowledge-base')}
        >
          Upload Knowledge Base
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/upload-tone')}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default DigitalMarketingTools;
