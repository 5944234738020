import React from "react";
import { colors } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import { alpha } from "@mui/material";

const difficultQuestionPaperMaker = {
  title: "Difficult Question Paper Maker",
  subtitle: "Generate advanced, syllabus-based question papers.",
  targetRoute: "/difficult-question-paper-maker",
  color: colors.deepOrange[800],
  bgcolor: alpha(colors.deepOrange[800], 0.1),
  icon: <FeedIcon fontSize="medium" />,
  tags: [],
};

export default difficultQuestionPaperMaker;
