//client/src/atoms.js
import { atom } from 'recoil';

export const authState = atom({
  key: 'authState',
  default: {
    user: null,
    accessToken: null,
    isAuthenticated: false,
    isLoading: true,
    userHMAC: null,
    role: null,
    id: null,
    _id: null,
    emailVerified: null,
    googleId: null,
  },
});

export const currentDashboardState = atom({
  key: 'currentDashboardState',
  default: null,
});
