import createTeachingTemplate from "../../TeachingTemplate";

const lessonPlanner = createTeachingTemplate({
  name: "lessonplanner",
  endpoint: "/api/openai/lesson-planner",
  aiText: "lessonPlanner",
  aiTextHandler: "lessonPlannerHandler",
  aiTextPlainText: "lessonPlannerPlainText",
  title: "Lesson Planner",
  subText:
    'No more planning woes! Just specify the class, subject, and the chapters from your syllabus, and this tool will devise a well-structured lesson plan. Lesson planning is most effective when the syllabus is uploaded. Example: "Grade 8, History, 1-hour lesson plan for chapters 3, 4.“',
  label: "Comprehensive Lesson Plan",
  placeholderText: "Indicate any preferred flow",
  buttonText: "Create Lesson Plan",
  aiPlaceholder: "Comprehensive lesson plan will appear here",
});

export default lessonPlanner;
