import createFaqToneKnowledgeInfoNoKeywordTemplate from "../../FaqToneKnowledgeInfoNoKeywordTemplate";

const stepByStepProductGuideWriter =
  createFaqToneKnowledgeInfoNoKeywordTemplate({
    name: "stepbystepproductguidewriter",
    endpoint: "/api/openai/step-by-step-product-guide-writer",
    aiText: "stepByStepProductGuideWriter",
    aiTextHandler: "stepByStepProductGuideWriterHandler",
    aiTextPlainText: "stepByStepProductGuideWriterPlainText",
    title: "Step-by-Step Product Guide Writer",
    subText:
      "Simplify product understanding with this tool. Enter product details, and it will generate a clear, step-by-step guide to using your product, enhancing customer satisfaction and reducing support calls.",
    label: "Product Guide",
    placeholderText: "Enter product details…",
    buttonText: "Write Guide",
    aiPlaceholder: "Your step-by-step product guide will appear here",
  });

export default stepByStepProductGuideWriter;
