import React from "react";
import { colors } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import { alpha } from "@mui/material";

const socialMediaContentGenerator = {
  title: "Social Media Content Generator",
  subtitle: "Create engaging content for diverse social media platforms.",
  targetRoute: "/social-media-content-generator",
  color: colors.blue[600],
  bgcolor: alpha(colors.blue[600], 0.1),
  icon: <TagIcon fontSize="medium" />,
  tags: [],
};

export default socialMediaContentGenerator;
