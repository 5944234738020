import React from "react";
import { colors } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import { alpha } from "@mui/material";

const pressReleaseRewriterPrint = {
  title: "Press Release Rewriter - Print",
  subtitle: "Turn press releases into captivating print news articles.",
  targetRoute: "/press-release-rewriter-print",
  color: colors.green[900],
  bgcolor: alpha(colors.green[900], 0.1),
  icon: <FeedIcon fontSize="medium" />,
  tags: [],
};

export default pressReleaseRewriterPrint;
