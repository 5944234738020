import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

const ToneName = styled(Typography)(({ theme }) => ({
  marginBottom: "0.5rem",
  fontWeight: "bold",
}));

const ToneDescription = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
}));

const ToneItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const filterAndRenderTone = (
  uploadedTone,
  search,
  toneNameFilter,
  handleViewToneDetails
) => {
  const filteredTone = uploadedTone
    .filter((tone) => {
      if (
        search &&
        !(
          tone.toneName.toLowerCase().includes(search.toLowerCase()) ||
          tone.toneDescription.toLowerCase().includes(search.toLowerCase())
        )
      ) {
        return false;
      }

      if (toneNameFilter && tone.toneName !== toneNameFilter) {
        return false;
      }

      return true;
    })
    .map((tone, index) => (
      <ToneItem key={index}>
        <Card>
          <CardContent>
            <ToneName variant="h6">{tone.toneName}</ToneName>
            <ToneDescription variant="body2">
              {tone.toneDescription}
            </ToneDescription>
            <Button onClick={() => handleViewToneDetails(tone._id)}>
              View full content
            </Button>
          </CardContent>
        </Card>
      </ToneItem>
    ));

  return filteredTone;
};

export default filterAndRenderTone;
