import React from "react";
import { colors } from "@mui/material";
import PowerInputIcon from "@mui/icons-material/PowerInput";
import { alpha } from "@mui/material";

const blogOutlineBuilder = {
  title: "Blog Outline Builder",
  subtitle:
    "Craft detailed, MECE-framework based blog outlines, complete with title, subheadings, and FAQs.",
  targetRoute: "/blog-outline-builder",
  color: colors.red["A700"],
  bgcolor: alpha(colors.red["A700"], 0.1),
  icon: <PowerInputIcon fontSize="medium" />,
  tags: [],
};

export default blogOutlineBuilder;
