import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import instance from '../../../helpers/axiosInstance';
import { getAuth } from 'firebase/auth';

import {
  useTheme,
  useMediaQuery,
  Box,
  Grid,
  TextField,
  Button,
  Card,
  CircularProgress,
  Alert,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Collapse,
} from '@mui/material';

import CreatingImageDialog from '../Dialog/CreatingImageDialog';
import ErrorDialog from '../Dialog/ImageErrorDialog';
import MonthlyWordCountErrorDialog from '../Dialog/monthlyWordCountErrorDialog';

// Recoil state imports
import { authState } from '../../../atoms';

export function createImageGenerator({
  name,
  placeholderText,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
}) {
  return function ImageGeneratorScreen() {
    const theme = useTheme();
    const navigate = useNavigate();

    // Recoil state
    const auth = useRecoilValue(authState);
    const { isAuthenticated } = auth;

    const isNotMobile = useMediaQuery('(min-width: 1000px)');

    const [text, setText] = useState('');
    const [imageData, setImageData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTool, setSelectedTool] = useState('stability'); // Default to Stable Diffusion
    const [midjourneyImageUrls, setMidjourneyImageUrls] = useState([]);
    const [progress, setProgress] = useState(0);
    const [primaryImageUrl, setPrimaryImageUrl] = useState('');
    const [upscaledImageUrls, setUpscaledImageUrls] = useState([]);

    // State for dropdowns - these can be used for image generation parameters
    const [imageStyle, setImageStyle] = useState('natural');
    const [imageType, setImageType] = useState('realistic');
    const [imageSize, setImageSize] = useState('square');
    const [imageQuality, setImageQuality] = useState('standard');

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    const handleImageStyleChange = (event) => {
      setImageStyle(event.target.value);
    };

    const handleImageTypeChange = (event) => {
      setImageType(event.target.value);
    };

    const handleImageSizeChange = (event) => {
      setImageSize(event.target.value);
    };

    const handleImageQualityChange = (event) => {
      setImageQuality(event.target.value);
    };

    const getAspectRatio = () => {
      switch (imageSize) {
        case 'landscape':
          return '1792 / 1024';
        case 'portrait':
          return '1024 / 1792';
        default: // "square"
          return '1 / 1';
      }
    };

    const getWidthForImageSize = () => {
      switch (imageSize) {
        case 'portrait':
          return { xs: '100%', sm: '100%', md: '70%' };
        case 'square':
          return { xs: '100%', sm: '100%', md: '90%' };
        case 'landscape':
          return { xs: '100%', sm: '100%', md: '95%' };
        default:
          return { xs: '100%', sm: '100%', md: '90%' }; // Default value
      }
    };

    const getQualityLabelWithCredits = (quality) => {
      let credits = 2000; // Default value for "Standard" and "Square"
      if (imageSize !== 'square' && quality === 'Standard') {
        credits = 4000;
      } else if (imageSize === 'square' && quality === 'HD') {
        credits = 4000;
      } else if (imageSize !== 'square' && quality === 'HD') {
        credits = 6000;
      }
      return `${quality} (${credits} credits/image)`;
    };

    const resetForm = () => {
      setText('');
      setImageData(null);
      setError('');
      setLoading(false);
      setIsGenerating(false);
      setImageStyle('natural');
      setImageType('realistic');
      setImageSize('square');
      setImageQuality('standard');
      setMidjourneyImageUrls([]); // Clear Midjourney image URLs
    };

    const aiImageHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      setIsGenerating(true);
      setImageData(null); // Clear previous image

      try {
        let apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4242';

        const auth = getAuth();
        const user = auth.currentUser;
        let idToken = '';
        if (user) {
          idToken = await user.getIdToken();
        }

        let endpoint = '/api/imageGeneration/create-stability-image'; // Default to Stability
        let toolName = 'createstabilityimage'; // Default tool name

        if (selectedTool === 'midjourney') {
          endpoint = '/api/imageGeneration/create-midjourney-image';
          toolName = 'createmidjourneyimage';
        } else if (selectedTool === 'dalle') {
          endpoint = '/api/imageGeneration/create-image';
          toolName = 'createimage';
        }

        const response = await instance.post(
          `${apiUrl}${endpoint}`,
          {
            text,
            imageType,
            imageSize,
            imageStyle,
            imageQuality,
            toolName,
          },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );

        const taskId = response.data.taskId;
        console.log('Image Generation Task ID received:', taskId);

        let eventSource = new EventSource(
          `${apiUrl}/api/sse/stream-results?taskId=${taskId}&token=${idToken}`
        );

        eventSource.onmessage = (event) => {
          console.log('SSE event received:', event.data);

          if (event.data.startsWith(':')) {
            // Ignore keep-alive messages
            return;
          }

          try {
            const responseImageData = JSON.parse(event.data);

            // Check for MidJourney specific data structure
            if (
              selectedTool === 'midjourney' &&
              responseImageData.primaryImageUrl
            ) {
              setPrimaryImageUrl(responseImageData.primaryImageUrl);
              setUpscaledImageUrls(responseImageData.upscaledImageUrls || []);
              setImageData(null); // Clear imageData as MidJourney uses different states
            }
            // Check for DALL-E and Stability image URL updates
            else if (responseImageData.imageUrl) {
              setImageData(responseImageData.imageUrl);
              console.log('Image URL set:', responseImageData.imageUrl);
              // Clear MidJourney-specific states if previously used
              setPrimaryImageUrl('');
              setUpscaledImageUrls([]);
            }

            if (
              responseImageData.status === 'error' ||
              responseImageData.error
            ) {
              setErrorMessage(
                responseImageData.error || 'An unknown error occurred.'
              );
              setShowErrorDialog(true);
            }

            setLoading(false);
            setIsGenerating(false);
            eventSource.close();

            // Handle progress updates for all tools
            if (responseImageData.progress) {
              console.log(
                `Progress update received: ${responseImageData.progress}%`
              );
              setProgress(responseImageData.progress);
            }
          } catch (e) {
            console.error('Error handling SSE message content:', e);
          } finally {
            // Ensure that loading and generating states are cleared on error
            setLoading(false);
            setIsGenerating(false);
            eventSource.close();
          }
        };

        eventSource.onerror = function (error) {
          console.error('EventSource failed:', error);
          setError(
            'There was an error in the connection. Please refresh and try again.'
          );
          setLoading(false);
          setIsGenerating(false); // Hide dialog on SSE error

          eventSource.close();
        };
      } catch (err) {
        console.error('Error in create-image request:', err);
        setError('An error occurred while starting the image generation.');

        if (err.response && err.response.status === 402) {
          // Monthly word limit exceeded
          setDialogOpen(true);
          setLoading(false);
          setIsGenerating(false);
        } else if (
          err.response &&
          err.response.status === 403 &&
          err.response.data.message.includes(
            'Monthly limit exceeded. Upgrade your plan.'
          )
        ) {
          // Handle other specific error case
          setDialogOpen(true);
          setLoading(false);
          setIsGenerating(false);
        } else {
          // General error handling
          let errorMessage =
            'An error occurred while starting the image generation.';
          if (err.response && err.response.data && err.response.data.error) {
            errorMessage = err.response.data.error;
          }
          setError(errorMessage);
          setLoading(false);
          setIsGenerating(false);
        }
      }
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
      <Box width="100%" m="2rem auto">
        <Grid
          container
          spacing={2}
          alignItems="center" // This ensures vertical centering within the grid
          justifyContent="center"
        >
          <Grid item xs={12} md={5}>
            <form onSubmit={aiImageHandler} style={{ padding: '0 24px' }}>
              <FormControl component="fieldset" sx={{ mb: 2 }}>
                <FormLabel component="legend">Select Tool</FormLabel>
                <RadioGroup
                  row
                  aria-label="tool"
                  name="row-radio-buttons-group"
                  value={selectedTool}
                  onChange={(e) => setSelectedTool(e.target.value)}
                >
                  <FormControlLabel
                    value="stability"
                    control={<Radio />}
                    label="Stable Diffusion"
                  />
                  <FormControlLabel
                    value="midjourney"
                    control={<Radio />}
                    label="MidJourney"
                  />

                  <FormControlLabel
                    value="dalle"
                    control={<Radio />}
                    label="DALL-E"
                  />
                </RadioGroup>
              </FormControl>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Typography variant="body2" mb={2}>
                {subText}
              </Typography>

              {/* Displaying cost information based on the selected tool */}
              {selectedTool === 'stability' && (
                <Typography variant="body2" color="textSecondary" mb={2}>
                  Each image generated with Stable Diffusion costs 900
                  credits/image.
                </Typography>
              )}

              {selectedTool === 'midjourney' && (
                <Typography variant="body2" color="textSecondary" mb={2}>
                  Each MidJourney process generates 4 images at a time, costing
                  1000 credits/image.
                </Typography>
              )}

              <TextField
                disabled={isGenerating}
                placeholder={placeholderText}
                fullWidth
                multiline
                rows={6}
                value={text}
                onChange={(e) => setText(e.target.value)}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Dropdowns */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Image Type</InputLabel>
                <Select
                  value={imageType}
                  label="Type of Image"
                  onChange={handleImageTypeChange}
                >
                  <MenuItem value="realistic">Realistic</MenuItem>
                  <MenuItem value="illustration">
                    Artistic Illustration
                  </MenuItem>
                  <MenuItem value="cartoon">Fun Cartoons</MenuItem>
                  <MenuItem value="coloring">Coloring Pages</MenuItem>
                  <MenuItem value="logo">Brand Logos</MenuItem>
                  <MenuItem value="vintage">Nostalgic Vintage</MenuItem>
                  <MenuItem value="popart">Creative Pop Art</MenuItem>
                  <MenuItem value="anime">Anime Style</MenuItem>
                  <MenuItem value="architecture">
                    Architectural Visualization
                  </MenuItem>
                  <MenuItem value="fashion">Fashion Sketch</MenuItem>
                  <MenuItem value="myprompt">Write my own prompt</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Image Size</InputLabel>
                <Select
                  value={imageSize}
                  label="Image Size"
                  onChange={handleImageSizeChange}
                >
                  <MenuItem value="square">Square</MenuItem>
                  <MenuItem value="landscape">Landscape</MenuItem>
                  <MenuItem value="portrait">Portrait</MenuItem>
                </Select>
              </FormControl>

              {selectedTool !== 'midjourney' &&
                selectedTool !== 'stability' && (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Image Style</InputLabel>
                      <Select
                        value={imageStyle}
                        label="Image Style"
                        onChange={handleImageStyleChange}
                      >
                        <MenuItem value="natural">Natural Look</MenuItem>
                        <MenuItem value="vivid">Vivid Look</MenuItem>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </>
                )}

              {selectedTool !== 'midjourney' &&
                selectedTool !== 'stability' && (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Image Quality</InputLabel>
                      <Select
                        value={imageQuality}
                        label="Image Quality"
                        onChange={handleImageQualityChange}
                      >
                        <MenuItem value="standard">
                          {getQualityLabelWithCredits('standard')}
                        </MenuItem>
                        <MenuItem value="hd">
                          {getQualityLabelWithCredits('HD')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

              <Box sx={{ mt: 1 }}>
                {/* First Row - Create Image Button */}
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12}>
                    <Button
                      disableElevation
                      variant="contained"
                      type="submit"
                      fullWidth
                      sx={{
                        color: 'white',
                        padding: '1em',
                      }}
                      disabled={loading || isGenerating}
                    >
                      {buttonText}
                    </Button>
                  </Grid>
                </Grid>

                {/* Second Row - Reset and Download Image Buttons */}
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      onClick={resetForm}
                      fullWidth
                      disabled={isGenerating}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      href={imageData}
                      download="generated-image.jpg"
                      fullWidth
                      disabled={!imageData}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Image
                    </Button>
                  </Grid>
                </Grid>

                {/* Third Row - Midjourney Image Buttons */}
                {selectedTool === 'midjourney' &&
                  upscaledImageUrls.length > 0 && (
                    <Grid container spacing={2}>
                      {upscaledImageUrls.map((url, index) => (
                        <Grid item xs={3} key={index}>
                          <Button
                            variant="contained"
                            color="success"
                            href={url}
                            download={`midjourney-upscaled-image-${
                              index + 1
                            }.jpg`}
                            fullWidth
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            M{index + 1}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
              </Box>
            </form>
          </Grid>

          <Grid item xs={12} md={7}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
                boxShadow: 2,
                borderColor: 'grey.300',
                borderWidth: 1,
                borderStyle: 'solid',
                position: 'relative',
                borderRadius: 2,
                overflow: 'hidden',
                aspectRatio: getAspectRatio(),
                maxWidth: '100%', // Ensure card does not exceed the screen width
                width: getWidthForImageSize(), // Apply dynamic width based on imageSize
                maxHeight: imageSize === 'portrait' ? '106vh' : 'auto', // Limit height for portrait
                height: 'auto',
                [theme.breakpoints.down('sm')]: {
                  aspectRatio: getAspectRatio(),
                },
              }}
            >
              {loading && !imageData && <CircularProgress />}
              {imageData && (
                <img
                  src={imageData}
                  alt="Generated Image"
                  style={{
                    width: imageSize === 'landscape' ? '100%' : 'auto',
                    height: imageSize === 'landscape' ? 'auto' : '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              )}

              {primaryImageUrl && selectedTool === 'midjourney' && (
                <img
                  src={primaryImageUrl}
                  alt="Primary Generated"
                  style={{
                    width: imageSize === 'landscape' ? '100%' : 'auto',
                    height: imageSize === 'landscape' ? 'auto' : '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              )}
              {error && !loading && <Alert severity="error">{error}</Alert>}
              {!loading && !imageData && !primaryImageUrl && (
                <Typography>No image</Typography>
              )}
            </Card>
          </Grid>
        </Grid>

        <CreatingImageDialog
          open={isGenerating}
          onClose={() => setIsGenerating(false)}
        />

        {/* Error Dialog */}
        <ErrorDialog
          open={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          errorMessage={errorMessage}
        />

        <MonthlyWordCountErrorDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          errorMessage="Monthly credit limit exceeded. Please wait until the next reset or upgrade your subscription."
        />
      </Box>
    );
  };
}

export default createImageGenerator;
