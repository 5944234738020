import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const productDescriptionGenerator = createLanguageKeywordTopicTemplate({
  name: "productdescriptiongenerator",
  endpoint: "/api/openai/product-description-generator",
  aiText: "productDescriptionGenerator",
  aiTextHandler: "productDescriptionGeneratorHandler",
  aiTextPlainText: "productDescriptionGeneratorPlainText",
  title: "Product Description Generator",
  subText:
    "This tool provides rich, engaging descriptions for your products, tailored to your specific market. Just enter product details and it will create compelling content that highlights unique features and benefits to captivate your audience.",
  label: "Product Description",
  placeholderText: "Enter your product name and category",
  buttonText: "Generate Description",
  aiPlaceholder: "Your product description will appear here...",
});

export default productDescriptionGenerator;
