import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'I. Acceptance of Terms',
    description:
      'By using Wisdom Scribe (a product of John 10.10 Tech Private Limited), an AI Writer service based on GPT-4 from OpenAI (the "Service"), you agree to be bound by the following terms and conditions ("Terms") and our Privacy Policy. If you do not agree with these Terms or the Privacy Policy, you should not use the Service.',
  },
  {
    title: 'II. Description of Service',
    description:
      'Wisdom Scribe is designed to assist with the creation of text and documents across a broad range of applications. The service uses GPT-4 from OpenAI to generate its content. It is intended to supplement, not replace, human input and discretion.',
  },
  {
    title: 'III. User Responsibilities and Rights',
    description:
      'As a user, you are responsible for the content you create using Wisdom Scribe. You agree not to use the Service to create content that is illegal, harmful, threatening, abusive, defamatory, or in violation of any laws. All content created by users ("User Content") using our Service is owned by the user who created it. The user retains all rights and responsibilities for their User Content. Wisdom Scribe is not responsible for the text that is generated on our platform.',
  },
  {
    title: 'IV. Intellectual Property',
    description:
      'Unless otherwise stated, all materials within the Service, including, but not limited to, the design, text, software, graphics, and other files, as well as their selection and arrangement, are the proprietary property of Wisdom Scribe, excluding User Content.',
  },
  {
    title: 'V. Limitations of Liability',
    description:
      'Wisdom Scribe and its affiliates, representatives, officers, employees, agents, and successors shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the Service.',
  },
  {
    title: 'VI. Changes to Terms',
    description:
      'We reserve the right to modify these Terms at any time. We will always post the most current version of these Terms on our website. By continuing to use the Service after the changes become effective, you agree to be bound by the revised Terms.',
  },
  {
    title: 'VII. Governing Law',
    description:
      'These Terms are governed by the laws of Karnataka, India without regard to its conflict of laws provisions.',
  },
  {
    title: 'VIII. Privacy Policy',
    description:
      'At Wisdom Scribe, we prioritize your privacy. This section outlines our handling of any personal data obtained from you, especially through Google APIs. Your data is used solely as described here and in our service interactions. We do not share this information with third parties except as necessary to offer our services or as required by law. Data security is paramount, and we take all appropriate measures to ensure its protection. Should we modify our privacy practices, we will update this policy and notify you accordingly.',
  },
  {
    title: 'IX. Data Collection and Use',
    description:
      "We collect personal data when you register, use our Service, or communicate with us. This may include your name, email address, and any content you generate or upload. When connecting your Google account, we adhere to Google's API Services User Data Policy, including the Limited Use requirements.",
  },
  {
    title: 'X. Data Storage and Security',
    description:
      'Your personal data is securely stored and protected against unauthorized access. We employ industry-standard security measures and regularly update our practices to ensure the safety of your information.',
  },
  {
    title: 'XI. Data Sharing and Disclosure',
    description:
      'We only share your data with third parties as necessary to provide you with our services. If required to do so by law, we may disclose your information to protect our rights or to comply with judicial proceedings.',
  },
  {
    title: 'XII. User Rights',
    description:
      'You have the right to access, amend, or delete your personal data under our control. If you wish to exercise these rights, please contact us directly.',
  },
  {
    title: 'XIII. Changes to Privacy Policy',
    description:
      'If we update this privacy policy, we will post the changes here and may also notify you by other means, such as email, at our discretion.',
  },
  {
    title: 'XIV. Contact Information',
    description:
      'If you have any questions about these Terms or our Privacy Policy, please contact us at hello@wisdomscribe.ai',
  },
  {
    title: 'XV. Shipping Policy',
    description:
      'Since Wisdom Scribe offers a digital service accessible immediately upon purchase or registration, there is no physical shipping involved. All services are delivered electronically and are available for use as soon as you have completed your purchase or registration process.',
  },

  {
    title: 'XVI. Refund Policy',
    description:
      'At Wisdom Scribe, we are committed to ensuring that our customers feel confident about their purchase. Therefore, we do not offer refunds once a purchase is made. However, we provide a free trial that includes 10,000 credits upon new registration. This allows you to try the service extensively before any financial commitment is required.',
  },

  {
    title: 'XVII. Cancellation Policy',
    description:
      'You may cancel your subscription to Wisdom Scribe at any time. Upon cancellation, you will not be charged for any subsequent periods, and you can continue to use the service until the end of the current billing cycle. Please note that following cancellation, your access will cease once the current period expires.',
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
