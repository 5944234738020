import createSummaryNewsTemplate from "../../SummaryNewsTemplate";

const tickerTextGenerator = createSummaryNewsTemplate({
  name: "tickertextgenerator",
  endpoint: "/api/openai/ticker-text-generator",
  aiText: "tickerTextGenerator",
  aiTextHandler: "tickerTextGeneratorHandler",
  aiTextPlainText: "tickerTextGeneratorPlainText",
  title: "Ticker Text Generator",
  subText:
    "Generate concise, quick-paced text for news tickers with our Ticker Text Generator. Input your topic, word count, and news info, and watch as it crafts effective ticker text, perfect for any news genre",
  label: "Ticker Text",
  placeholderText: "Enter News Topic for Ticker Here",
  buttonText: "Generate Ticker Text",
  aiPlaceholder: "Ticker text will appear here",
});

export default tickerTextGenerator;
