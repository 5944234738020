import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const SubscribeDialog = ({ open, handleClose, navigate }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Subscribe
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please subscribe to a plan to use our tools
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate("/pricing")}
            color="primary"
            variant="contained"
          >
            Subscribe now
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default SubscribeDialog;
