import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { getAuth } from 'firebase/auth';

import instance from '../../helpers/axiosInstance';

import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';

import { authState } from '../../atoms';

const ToneTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
}));

const ToneDescription = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const ToneDetailCardContent = styled(CardContent)(({ theme }) => ({
  p: 2,
  display: 'flex',
  flexDirection: 'column',
}));

const ToneDetailBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CopyButton = styled(IconButton)(({ theme }) => ({}));

const ToneDetailScreen = () => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const { id } = useParams();

  const [toneItem, setToneItem] = useState(null);
  const [copied, setCopied] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState('');

  const handleEdit = () => {
    setEditing(true);
    setEditedDescription(toneItem.toneDescription);
  };

  const handleSave = async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.put(
        `/api/auth/uploadedTone/${id}`,
        {
          toneDescription: editedDescription,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setToneItem(response.data);
      setEditing(false);
    } catch (error) {
      console.error(
        'Error updating tone item:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const fetchToneItem = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.get(`/api/auth/uploadedTone/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      setToneItem(response.data);
    } catch (error) {
      console.error(
        'Error fetching tone item:',
        error.response ? error.response.data : error.message
      );
    }
  }, [id, isAuthenticated]);

  useEffect(() => {
    fetchToneItem();
  }, [fetchToneItem]);

  const handleCopyContent = () => {
    if (toneItem && toneItem.toneDescription) {
      try {
        navigator.clipboard.writeText('\n' + toneItem.toneDescription + '\n');
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (error) {
        console.error('Error copying content:', error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      {toneItem ? (
        <ToneDetailBox sx={{ p: 4 }}>
          <Card>
            <ToneDetailCardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <ToneTitle variant="h6" fontWeight={700}>
                  {toneItem.toneName}
                </ToneTitle>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <CopyButton onClick={handleCopyContent} disableRipple>
                    {copied ? (
                      <>
                        <DoneIcon />
                        <Typography variant="body2" component="span">
                          Copied
                        </Typography>
                      </>
                    ) : (
                      <>
                        <DescriptionIcon />
                        <Typography variant="body2" component="span">
                          Copy text
                        </Typography>
                      </>
                    )}
                  </CopyButton>
                  {!editing ? (
                    <Button onClick={handleEdit}>Edit</Button>
                  ) : (
                    <Button onClick={handleSave}>Save</Button>
                  )}
                </Box>
              </Box>

              {editing ? (
                <TextField
                  multiline
                  fullWidth
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              ) : (
                <ToneDescription
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: toneItem.toneDescription }}
                />
              )}
            </ToneDetailCardContent>
          </Card>
        </ToneDetailBox>
      ) : (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Loading...
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ToneDetailScreen;
