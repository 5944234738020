import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import instance from '../../../../../helpers/axiosInstance';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTheme } from '@mui/material/styles';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  message: yup.string().trim().required('Please specify your message'),
});

const Contact = () => {
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!recaptchaValue) {
      setMessage('Please complete the reCAPTCHA.');
      setIsSuccess(false);
      return;
    }
    try {
      console.log('Form Values:', values);
      // Add the reCAPTCHA value to the form values
      const data = {
        ...values,
        recaptcha: recaptchaValue,
      };

      await instance.post('/api/auth/contact', data);
      setMessage('Form submitted successfully!');
      setIsSuccess(true);
      resetForm();
    } catch (error) {
      console.error('Error with form submission:', error);
      let errorMessage = 'An error occurred while trying to submit the form.';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        console.error('Error response:', error.response.data.message);
        errorMessage = error.response.data.message;
      }
      setMessage(errorMessage);
      setIsSuccess(false);
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box maxWidth={600} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h3'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          Contact us
        </Typography>
        <Typography color="text.secondary" align={'center'}>
          Got questions or just want to say hi? We're all ears at Wisdom Scribe!
          Get in touch today!
        </Typography>
      </Box>

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey="6Lfvf0oqAAAAAOr9ygLKmGkBj5E3e8Lj4E_sSTlv"
                onChange={handleCaptchaChange}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Button
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Typography color="text.secondary">
                We'll get back to you in 1-2 business days. If you want to pay
                us a visit, do come over to T103, Smart Avenue, Residency Road,
                Bengaluru 560025
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Box>
                <Typography component="p" variant="body2" align="left">
                  By clicking on "submit" you agree to our{' '}
                  <Box
                    component="a"
                    href="/company-terms"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Privacy Policy
                  </Box>
                </Typography>
                {message && (
                  <Typography
                    variant="body2"
                    color={isSuccess ? 'success.main' : 'error.main'}
                  >
                    {message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
