// import React from 'react';
// import {
//   Box,
//   TextField,
//   InputAdornment,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import Container from '../../../Container';

// const SearchBar = React.memo(({ searchQuery, onSearchChange }) => {
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'));

//   return (
//     <Container>
//       <Box
//         display="flex"
//         flexDirection={{ xs: 'column', sm: 'row' }}
//         alignItems="center"
//         justifyContent="center"
//         mt={4}
//       >
//         <TextField
//           autoComplete="off"
//           name="searchInput"
//           variant="outlined"
//           color="primary"
//           size="medium"
//           value={searchQuery}
//           onChange={onSearchChange}
//           placeholder="Search for features..."
//           sx={{
//             width: { xs: '100%', sm: '800px' },
//             mr: isMd ? 2 : 0,
//             mb: isMd ? 0 : 1,
//           }}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <SearchOutlinedIcon color="primary" />
//               </InputAdornment>
//             ),
//           }}
//         />
//       </Box>
//     </Container>
//   );
// });

// export default SearchBar;

import React from 'react';
import { Box, TextField, InputAdornment, useTheme } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchBar = React.memo(({ searchQuery, onSearchChange }) => {
  const theme = useTheme();

  return (
    <Box>
      <TextField
        autoComplete="off"
        name="searchInput"
        variant="outlined"
        color="primary"
        size="medium"
        value={searchQuery}
        onChange={onSearchChange}
        placeholder="Search for features..."
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
});

export default SearchBar;
