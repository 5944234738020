// // client/src/layouts/Main Sidebar/components/MySidebar/MySidebar.js

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
// import PropTypes from 'prop-types';
// import Drawer from '@mui/material/Drawer';
// import { SidebarNav } from './components';
// import { useTheme } from '@mui/material/styles';
// import { colorTokens } from '../../../../theme';
// import { currentDashboardState } from '../../../../atoms';

// const roleColors = {
//   business: {
//     light: '#E6F3FF',
//     dark: '#1A2A3A',
//   },
//   journalist: {
//     light: '#FFF0E6',
//     dark: '#3A2A1A',
//   },
//   'digital-marketer': {
//     light: '#E6F7FF',
//     dark: '#003366',
//   },
//   general: {
//     light: colorTokens.grey[10], // F6F6F6
//     dark: colorTokens.grey[800], // 1A1A1A
//   },
//   educator: {
//     light: '#E6FFE6',
//     dark: '#1A3A1A',
//   },
//   writer: {
//     light: '#F0E6FF',
//     dark: '#2A1A3A',
//   },
// };

// const MySidebar = ({ open, variant, onClose }) => {
//   const [currentDashboard, setCurrentDashboard] = useRecoilState(
//     currentDashboardState
//   );
//   const [currentRole, setCurrentRole] = useState(
//     localStorage.getItem('selectedRole') || 'general'
//   );
//   const theme = useTheme();
//   const mode = theme.palette.mode || 'light'; // Add fallback to 'light'
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedDashboard = localStorage.getItem('currentDashboard');
//     if (storedDashboard) {
//       setCurrentDashboard(storedDashboard);
//       navigate(storedDashboard);
//     } else {
//       const initialDashboard = localStorage.getItem('initialDashboard');
//       if (initialDashboard) {
//         setCurrentDashboard(initialDashboard);
//         navigate(initialDashboard);
//       }
//     }
//   }, [setCurrentDashboard, navigate]);

//   const handleRoleChange = (newRole) => {
//     let newDashboard;
//     switch (newRole) {
//       case 'journalist':
//         newDashboard = '/my-journalist-dashboard';
//         break;
//       case 'educator':
//         newDashboard = '/my-teaching-dashboard';
//         break;
//       case 'business':
//         newDashboard = '/my-business-dashboard';
//         break;
//       case 'digital-marketer':
//         newDashboard = '/my-digital-marketing-dashboard';
//         break;
//       case 'writer':
//         newDashboard = '/my-writing-dashboard';
//         break;
//       default:
//         newDashboard = '/dashboard';
//     }
//     setCurrentDashboard(newDashboard);
//     localStorage.setItem('currentDashboard', newDashboard);
//     navigate(newDashboard);
//   };

//   const getBackgroundColor = () => {
//     const currentRole = currentDashboard.split('-')[1] || 'general';
//     if (currentRole === 'general') {
//       return theme.palette.background.default;
//     }
//     return roleColors[currentRole]?.[mode] || theme.palette.background.default;
//   };

//   return (
//     <Drawer
//       anchor="left"
//       onClose={() => onClose()}
//       open={open}
//       variant={variant}
//       sx={{
//         '& .MuiPaper-root': {
//           width: '100%',
//           maxWidth: 320,
//           top: { xs: 0, md: 71 },
//           height: { xs: '100%', md: 'calc(100% - 71px)' },
//           backgroundColor: getBackgroundColor(),
//           transition: 'background-color 0.3s ease',
//         },
//       }}
//     >
//       <SidebarNav onRoleChange={handleRoleChange} />
//     </Drawer>
//   );
// };

// MySidebar.propTypes = {
//   onClose: PropTypes.func,
//   open: PropTypes.bool.isRequired,
//   variant: PropTypes.string.isRequired,
// };

// export default MySidebar;

// client/src/layouts/Main Sidebar/components/MySidebar/MySidebar.js

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { SidebarNav } from './components';
import { useTheme } from '@mui/material/styles';
import { colorTokens } from '../../../../theme';
import { currentDashboardState, authState } from '../../../../atoms';

const roleColors = {
  business: {
    light: '#E6F3FF',
    dark: '#1A2A3A',
  },
  journalist: {
    light: '#FFF0E6',
    dark: '#3A2A1A',
  },
  'digital-marketer': {
    light: '#E6F7FF',
    dark: '#003366',
  },
  general: {
    light: colorTokens.grey[10],
    dark: colorTokens.grey[800],
  },
  educator: {
    light: '#E6FFE6',
    dark: '#1A3A1A',
  },
  writer: {
    light: '#F0E6FF',
    dark: '#2A1A3A',
  },
};

const MySidebar = ({ open, variant, onClose }) => {
  const [currentDashboard, setCurrentDashboard] = useRecoilState(
    currentDashboardState
  );
  const [auth] = useRecoilState(authState);
  const theme = useTheme();
  const mode = theme.palette.mode || 'light';
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const loadDashboard = async () => {
  //     if (auth.isAuthenticated) {
  //       const storedDashboard = localStorage.getItem('currentDashboard');
  //       console.log('Stored Dashboard:', storedDashboard);
  //       console.log('Auth State:', auth);
  //       console.log('Current Path:', location.pathname);

  //       if (storedDashboard && location.pathname === '/') {
  //         console.log('Using stored dashboard:', storedDashboard);
  //         setCurrentDashboard(storedDashboard);
  //         //       navigate(storedDashboard);
  //         //     } else {
  //         //       setCurrentDashboard(location.pathname);
  //         //     }
  //         //   }
  //         // };

  //         navigate(storedDashboard, { replace: true });
  //       } else if (
  //         !currentDashboard ||
  //         currentDashboard !== location.pathname
  //       ) {
  //         console.log('Setting current dashboard to:', location.pathname);
  //         setCurrentDashboard(location.pathname);
  //         localStorage.setItem('currentDashboard', location.pathname);
  //       }
  //     }
  //   };

  //   loadDashboard();
  // }, [
  //   auth.isAuthenticated,
  //   setCurrentDashboard,
  //   navigate,
  //   location.pathname,
  //   currentDashboard,
  // ]);

  useEffect(() => {
    const loadDashboard = async () => {
      if (auth.isAuthenticated) {
        const storedDashboard = localStorage.getItem('currentDashboard');
        console.log('Stored Dashboard:', storedDashboard);
        console.log('Auth State:', auth);
        console.log('Current Path:', location.pathname);

        if (location.pathname === '/') {
          const roleDashboard = getRoleDashboard(auth.user.role);
          console.log('Navigating to role dashboard:', roleDashboard);
          setCurrentDashboard(roleDashboard);
          navigate(roleDashboard, { replace: true });
        } else if (
          !currentDashboard ||
          currentDashboard !== location.pathname
        ) {
          console.log('Setting current dashboard to:', location.pathname);
          setCurrentDashboard(location.pathname);
          localStorage.setItem('currentDashboard', location.pathname);
        }
      }
    };

    loadDashboard();
  }, [
    auth.isAuthenticated,
    auth.user.role,
    setCurrentDashboard,
    navigate,
    location.pathname,
    currentDashboard,
  ]);

  const getRoleDashboard = (role) => {
    switch (role) {
      case 'journalist':
        return '/my-journalist-dashboard';
      case 'educator':
        return '/my-teaching-dashboard';
      case 'business':
        return '/my-business-dashboard';
      case 'digital-marketer':
        return '/my-digital-marketing-dashboard';
      case 'writer':
        return '/my-writing-dashboard';
      default:
        return '/dashboard';
    }
  };

  const handleRoleChange = (newRole) => {
    const newDashboard = getRoleDashboard(newRole);
    console.log('Changing to new dashboard:', newDashboard);
    setCurrentDashboard(newDashboard);
    localStorage.setItem('currentDashboard', newDashboard);
    localStorage.setItem('selectedRole', newRole);
    navigate(newDashboard);
  };

  const handleDashboardChange = (newDashboard) => {
    console.log('Changing to dashboard:', newDashboard);
    setCurrentDashboard(newDashboard);
    localStorage.setItem('currentDashboard', newDashboard);
    navigate(newDashboard);
  };

  const getBackgroundColor = () => {
    const currentRole = currentDashboard?.split('-')[1] || 'general';
    return roleColors[currentRole]?.[mode] || theme.palette.background.default;
  };

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 320,
          top: { xs: 0, md: 71 },
          height: { xs: '100%', md: 'calc(100% - 71px)' },
          backgroundColor: getBackgroundColor(),
          transition: 'background-color 0.3s ease',
        },
      }}
    >
      <SidebarNav
        onRoleChange={handleRoleChange}
        onDashboardChange={handleDashboardChange}
        currentDashboard={currentDashboard}
      />
    </Drawer>
  );
};

MySidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default MySidebar;
