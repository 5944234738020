// import React from 'react';
// import { Box, Card, CardContent, Typography, Button } from '@mui/material';
// import { styled } from '@mui/system';

// const SyllabusItem = styled(Box)(({ theme }) => ({
//   marginBottom: '1rem',
// }));

// const SyllabusTitle = styled(Typography)(({ theme }) => ({
//   marginBottom: '0.5rem',
//   fontWeight: 'bold',
// }));

// const SyllabusContent = styled(Typography)(({ theme }) => ({
//   whiteSpace: 'pre-line',
// }));

// const filterAndRenderSyllabus = (
//   documents,
//   search,
//   standardFilter,
//   subjectFilter,
//   chapterNumberFilter,
//   handleViewFullContent
// ) => {
//   const filteredSyllabus = documents
//     .slice()
//     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//     .filter((item) => {
//       // Search Filter
//       if (
//         search &&
//         !(
//           item.title.toLowerCase().includes(search.toLowerCase()) ||
//           item.content.toLowerCase().includes(search.toLowerCase())
//         )
//       ) {
//         return false;
//       }

//       // Standard Filter
//       if (standardFilter && standardFilter !== item.standard) {
//         return false;
//       }

//       // Subject Filter
//       if (subjectFilter && subjectFilter !== item.subject) {
//         return false;
//       }

//       // Chapter Number Filter
//       if (
//         chapterNumberFilter &&
//         Number(chapterNumberFilter) !== item.chapterNumber
//       ) {
//         return false;
//       }

//       return true;
//     })
//     .map((item, index) => {
//       // Check if content exists before attempting to split it
//       const content = item.content
//         ? item.content.split(' ').slice(0, 50).join(' ') + '...'
//         : ''; // Default to an empty string if content does not exist

//       return (
//         <SyllabusItem key={index}>
//           <Card>
//             <CardContent>
//               <SyllabusTitle variant="h6">{item.title}</SyllabusTitle>
//               <Typography variant="body2" fontWeight={500}>
//                 Standard: {item.standard}
//               </Typography>
//               <Typography variant="body2" fontWeight={500}>
//                 Subject: {item.subject}
//               </Typography>
//               <Typography variant="body2" fontWeight={500}>
//                 Chapter Number: {item.chapterNumber}
//               </Typography>
//               <SyllabusContent
//                 variant="body2"
//                 dangerouslySetInnerHTML={{ __html: content }}
//               />
//               <Button onClick={() => handleViewFullContent(item._id)}>
//                 View full content
//               </Button>
//             </CardContent>
//           </Card>
//         </SyllabusItem>
//       );
//     });

//   return filteredSyllabus;
// };

// export default filterAndRenderSyllabus;

import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/system';

const SyllabusItem = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
}));

const SyllabusTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
}));

const SyllabusContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const filterAndRenderSyllabus = (
  documents,
  search,
  standardFilter,
  subjectFilter,
  chapterNumberFilter,
  handleViewFullContent,
  handleItemSelect,
  selectedItems
) => {
  const filteredSyllabus = documents
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((item) => {
      // Search Filter
      if (
        search &&
        !(
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.text.toLowerCase().includes(search.toLowerCase()) ||
          item.className.toLowerCase().includes(search.toLowerCase()) ||
          item.subject.toLowerCase().includes(search.toLowerCase())
        )
      ) {
        return false;
      }

      // Standard Filter
      if (standardFilter && standardFilter !== item.className) {
        return false;
      }

      // Subject Filter
      if (subjectFilter && subjectFilter !== item.subject) {
        return false;
      }

      // Chapter Number Filter
      if (chapterNumberFilter && chapterNumberFilter !== item.chapter) {
        return false;
      }

      return true;
    })
    .map((item, index) => {
      // Check if text exists before attempting to split it
      const content = item.text
        ? item.text.split(' ').slice(0, 50).join(' ') + '...'
        : ''; // Default to an empty string if text does not exist

      return (
        <SyllabusItem key={index}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={selectedItems.includes(item.id)}
                  onChange={() => handleItemSelect(item.id)}
                />
                <SyllabusTitle variant="h6">{item.title}</SyllabusTitle>
              </Box>
              <Typography variant="body2" fontWeight={500}>
                Standard: {item.className}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                Subject: {item.subject}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                Chapter: {item.chapter}
              </Typography>
              <SyllabusContent
                variant="body2"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <Button onClick={() => handleViewFullContent(item.id)}>
                View full content
              </Button>
            </CardContent>
          </Card>
        </SyllabusItem>
      );
    });

  return filteredSyllabus;
};

export default filterAndRenderSyllabus;
