import React from "react";
import { colors } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import { alpha } from "@mui/material";

const quizGeneratorTeaching = {
  title: "Quiz Generator - Teaching",
  subtitle: "Generate fun quizzes from selected chapters.",
  targetRoute: "/quiz-generator-teaching",
  color: colors.purple[600],
  bgcolor: alpha(colors.purple[600], 0.1),
  icon: <QuizIcon fontSize="medium" />,
  tags: [],
};

export default quizGeneratorTeaching;
