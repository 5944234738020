import React from "react";
import { colors } from "@mui/material";
import GradingIcon from "@mui/icons-material/Grading";
import { alpha } from "@mui/material";

const outlineBasedBlogWriter = {
  title: "Outline-based Blog Writer",
  subtitle:
    "Create fact-checked, engaging blog content following a detailed outline.",
  targetRoute: "/outline-based-blog-writer",
  color: colors.lightGreen[800],
  bgcolor: alpha(colors.lightGreen[800], 0.1),
  icon: <GradingIcon fontSize="medium" />,
  tags: [],
};

export default outlineBasedBlogWriter;
