import React, { useRef, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Card,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ButtonGroup,
} from '@mui/material';

import instance from '../../../helpers/axiosInstance';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MonthlyWordCountErrorDialog from '../Dialog/monthlyWordCountErrorDialog';
import trackButtonClick from '../../../helpers/trackButtonClick';
import GeneratingDialog from '../Dialog/GeneratingDialog';
import LanguageSelect from '../../../helpers/LanguageSelect';
import ToneOfVoice from '../../../helpers/ToneOfVoice';
import downloadPdf from '../../../helpers/downloadPdf';
import printDocument from '../../../helpers/printDocument';
import PrintDownErrorDialog from '../Dialog/PrintDownErrorDialog';
import LLMSwitch from '../../shared-components/LLMSwitch';

import { authState } from '../../../atoms';

export function createPressReleaseNewsTemplate({
  name,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
}) {
  return function PressReleaseNewsScreen() {
    const theme = useTheme();
    const isNotMobile = useMediaQuery('(min-width: 1000px)');
    const contentRef = useRef(null);
    const navigate = useNavigate();

    // Recoil state
    const auth = useRecoilValue(authState);
    const { isAuthenticated } = auth;

    const [information, setInformation] = useState('');
    const [type, setType] = useState('');
    const [error, setError] = useState('');
    const [aiText, setAiText] = useState('');
    const [isPrintDownErrorDialogOpen, setPrintDownErrorDialogOpen] =
      useState(false);
    const [aiTextPlainText, setAiTextPlainText] = useState('');
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [informationError, setInformationError] = useState('');
    const [typeError, setTypeError] = useState('');

    const [language, setLanguage] = useState('English (American)');
    const [toneOfVoice, setToneOfVoice] = useState('');
    const [toneOfVoiceError, setToneOfVoiceError] = useState('');
    const [toneName, setToneName] = useState([]);
    const [selectedTone, setSelectedTone] = useState('');
    const [useUploadedTone, setUseUploadedTone] = useState(false);
    const [toneOption, setToneOption] = useState('');
    const [selectedLLM, setSelectedLLM] = useState('gpt');
    const handleLLMChange = (event) => {
      setSelectedLLM(event.target.checked ? 'claude' : 'gpt');
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
      const fetchToneName = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (useUploadedTone && user) {
          try {
            const idToken = await user.getIdToken();
            const { data } = await instance.get('/api/tone/toneName', {
              headers: { Authorization: `Bearer ${idToken}` },
            });
            setToneName(data.toneName);
            console.log(data.toneName);
          } catch (error) {
            console.error('Error fetching tone name:', error);
          }
        }
      };

      fetchToneName();
    }, [useUploadedTone, instance]);

    const aiTextHandler = async (e) => {
      e.preventDefault();

      trackButtonClick(label);

      setLoading(true);
      setButtonDisabled(true);

      // New validations
      if (information.split(' ').filter((n) => n).length < 100) {
        setInformationError('Press Release should contain at least 100 words.');
        setLoading(false);
        setButtonDisabled(false);
        return;
      }

      if (!type) {
        setTypeError('Please select a news type.');
        setLoading(false);
        setButtonDisabled(false);
        return;
      }

      try {
        let apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4242';

        const auth = getAuth();
        const user = auth.currentUser;
        let idToken = '';
        if (user) {
          idToken = await user.getIdToken();
        }

        const payload = {
          information,
          type,
          language,
          toneOfVoice,
          useMyUploadedTone: useUploadedTone,
          toolName: name,
          llm: selectedLLM,
        };

        if (useUploadedTone) {
          payload.toneName = selectedTone;
        }
        const { data } = await instance.post(
          '/api/generation/start-generation',
          payload,
          { headers: { Authorization: `Bearer ${idToken}` } }
        );

        console.log('name:', name);

        const taskId = data.taskId;
        console.log('taskId:', taskId);

        let eventSource = new EventSource(
          `${apiUrl}/api/sse/stream-results?taskId=${taskId}&token=${idToken}`
        );

        let isFirstChunkReceived = false;

        eventSource.onmessage = (event) => {
          console.log('SSE event received:', event.data);
          try {
            if (!isFirstChunkReceived) {
              setLoading(false); // Hide the GeneratingDialog
              isFirstChunkReceived = true;
            }

            // Process the received data immediately
            const processedText = processTextForDisplay(event.data);

            // Update the state with the processed text
            setAiText((prevAiText) => prevAiText + processedText);

            // Update aiTextPlainText with plain text
            const plainText = stripHtml(processedText);
            setAiTextPlainText((prevText) => prevText + plainText);
          } catch (e) {
            console.error('Error handling message content:', e);
            setLoading(false); // Ensure loading is set to false in case of error
          }
        };

        eventSource.onerror = function (error) {
          console.error('EventSource failed:', error);

          if (!data.taskId) {
            console.error('No task ID received from the server:', data);
            setLoading(false);
            setButtonDisabled(false);
            return;
          }

          eventSource.close();
        };
      } catch (err) {
        console.error('Error in start-generation request:', err);
        let errorMessage = 'Unknown error. Please try again.';

        // Handle specific error for monthly word count exceeded
        if (err.response && err.response.status === 402) {
          errorMessage = err.response.data.message;
          setDialogOpen(true);
        } else if (
          err.response &&
          err.response.status === 403 &&
          err.response.data.message.includes(
            'Monthly limit exceeded. Upgrade your plan.'
          )
        ) {
          errorMessage = err.response.data.message;
          setDialogOpen(true);
        } else if (err.response && err.response.data.error) {
          setError(err.response.data.error);
        } else if (err.message) {
          setError(err.message);
        }
        setTimeout(() => {
          setError('');
        }, 5000);

        setLoading(false); // Ensure loading is set to false in case of error
        setButtonDisabled(false);
      } finally {
        setButtonDisabled(false); // Reset button state
      }
    };

    const processTextForDisplay = (text) => {
      // Remove literal \n"" and "" from the string
      let processedText = text.replace(/\\n""/g, '');
      processedText = processedText.replace(/""/g, '');

      // Remove any stray \n and standalone quotation marks
      processedText = processedText.replace(/\\n/g, '');
      processedText = processedText.replace(/\"\"/g, '');

      // Remove any stray # symbols
      processedText = processedText.replace(/#/g, '');

      // Remove individual quotation marks that are out of place
      processedText = processedText.replace(/\"/g, '');

      // Add quotation marks around text within <blockquote> tags
      processedText = processedText.replace(
        /<blockquote>(.*?)<\/blockquote>/g,
        '"$1"'
      );

      // Replace double newlines with two line breaks for HTML display
      return processedText.replace(/\\n\\n/g, '<br><br>');
    };

    const stripHtml = (html) => {
      let text = html;
      // Replace ending heading tags with two newlines for a break
      text = text.replace(/<\/h[1-6]>/gi, '\n\n');
      // Replace paragraph endings and line breaks with two newlines
      text = text.replace(/<\/p>/gi, '\n\n');
      text = text.replace(/<br\s*\/?>/gi, '\n\n');

      // Create a temporary div element
      let temporalDivElement = document.createElement('div');
      // Set the HTML content with the provided
      temporalDivElement.innerHTML = text;
      // Retrieve the text property of the element
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ''
      );
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(aiTextPlainText);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    };

    const handleDownload = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      downloadPdf(contentRef);
    };

    const handlePrint = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      printDocument(contentRef);
    };

    // Define the resetAll function to reset all the states
    const resetAll = () => {
      setInformation('');
      setAiText('');
      setAiTextPlainText('');
      setError('');
      setInformationError('');
      setLoading(false);
      setButtonDisabled(false);
      setCopied(false);
      setType('');
      setTypeError('');
      setLanguage('English (American)');
      setToneOfVoice('');
      setToneOfVoiceError('');
      setToneName([]);
      setSelectedTone('');
      setUseUploadedTone(false);
      setToneOption('');
    };
    const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
      <Box
        width={isNotMobile ? '70%' : '90%'}
        p="2rem"
        m="2rem auto"
        borderRadius={5}
        backgroundColor={theme.palette.background.alt}
        sx={{ boxShadow: 5 }}
      >
        <Collapse in={error}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Collapse>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5">{title}</Typography>
          <LLMSwitch
            checked={selectedLLM === 'claude'}
            onChange={handleLLMChange}
          />
        </Box>
        <form onSubmit={aiTextHandler}>
          <Typography variant="body2" mb={2}>
            {subText}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="tone-option"
              name="tone-option"
              value={toneOption}
              onChange={(e) => {
                setToneOption(e.target.value);
                // set useUploadedTone according to radio button selection
                setUseUploadedTone(e.target.value === 'select');
              }}
            >
              <FormControlLabel
                value="write"
                control={<Radio color="primary" />}
                label="Write a tone"
              />
              <FormControlLabel
                value="select"
                control={<Radio color="primary" />}
                label="Select a tone"
              />
            </RadioGroup>
          </FormControl>

          <Grid item xs={12} sm={4}>
            <TextField
              multiline
              rows={3}
              placeholder="Paste press release here"
              fullWidth
              value={information}
              error={Boolean(informationError)}
              helperText={informationError}
              onChange={(e) => {
                const wordCount = e.target.value
                  .split(' ')
                  .filter((n) => n).length;
                if (wordCount <= 2000) {
                  setInformation(e.target.value);
                  setInformationError('');
                } else {
                  setInformationError(
                    'Press Release should not contain more than 2000 words.'
                  );
                }
              }}
              sx={{ mb: 1.5 }}
            />
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <ToneOfVoice
              toneOption={toneOption}
              toneOfVoice={toneOfVoice}
              setToneOfVoice={setToneOfVoice}
              setToneOfVoiceError={setToneOfVoiceError}
              toneName={toneName}
              selectedTone={selectedTone}
              setSelectedTone={setSelectedTone}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LanguageSelect language={language} setLanguage={setLanguage} />
          </Grid>

          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={8}>
              <Select
                displayEmpty
                renderValue={(value) =>
                  value !== '' ? value : 'Select news type'
                }
                fullWidth
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setTypeError('');
                }}
                error={Boolean(typeError)}
                helperText={typeError}
                sx={{ mb: 1.5 }}
              >
                <MenuItem value="Political">Political</MenuItem>
                <MenuItem value="National">National</MenuItem>
                <MenuItem value="Crime">Crime</MenuItem>
                <MenuItem value="City">City</MenuItem>
                <MenuItem value="Business">Business</MenuItem>
                <MenuItem value="Sports">Sports</MenuItem>
                <MenuItem value="Entertainment">Entertainment</MenuItem>
                <MenuItem value="Technology">Technology</MenuItem>
                <MenuItem value="International">International</MenuItem>
                <MenuItem value="Health">Health</MenuItem>
                <MenuItem value="Environmental">Environmental</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button
                disableElevation
                variant="contained"
                type="submit"
                sx={{
                  color: 'white',
                  width: '100%',
                  padding: '1.1em',
                  mb: 1.5,
                }}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Card component */}
        <Card
          sx={{
            p: 2,
            border: 1,
            boxShadow: 0,
            borderColor: theme.palette.divider,
            borderRadius: 2,
            height: '500px',
            bgcolor: 'background.default',
            position: 'relative',
            overflow: loading ? 'hidden' : 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* Top border with label and copy button */}
          {aiText && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '50px',
                position: 'absolute',
                top: -16,
                left: 0,
                right: 0,
                bgcolor: theme.palette.background.level1,
                px: 1,
                zIndex: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  marginTop: 1.8,
                  marginLeft: 1,
                  color: 'currentColor',
                  fontWeight: 500,
                }}
              >
                {label}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={copyToClipboard}
              >
                {copied ? (
                  <DoneOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                ) : (
                  <ContentPasteOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                )}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    marginTop: 1.8,
                    color: 'currentColor',
                    fontWeight: 500,
                  }}
                >
                  {copied ? 'Copied!' : 'Copy text'}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Content */}
          {loading ? (
            <Box
              sx={{
                textAlign: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <>
              {aiText ? (
                <Box
                  component="div"
                  sx={{
                    margin: 0,
                    padding: '3rem 16px',
                    marginBottom: '1.5rem',
                    flexGrow: 1,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'auto',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.8,
                  }}
                  dangerouslySetInnerHTML={{ __html: aiText }}
                  ref={contentRef}
                />
              ) : (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontWeight: 500,
                    fontSize: 20,
                    textAlign: 'center',
                  }}
                >
                  {aiPlaceholder}
                </Box>
              )}
            </>
          )}
        </Card>

        {/* Card Actions */}
        <Grid
          container
          justifyContent={matches ? 'center' : 'space-between'}
          alignItems="center"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Grid
            item
            xs={matches ? 12 : 'auto'}
            style={{ textAlign: matches ? 'center' : 'left' }}
          >
            <Typography>
              Not the tool you were looking for?{' '}
              <Link href="/dashboard">Go back</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={matches ? 12 : 'auto'}
            style={{ textAlign: matches ? 'center' : 'right' }}
          >
            <ButtonGroup variant="contained" color="primary">
              <Button
                variant="contained"
                color="inherit"
                onClick={handleDownload}
              >
                Download PDF
              </Button>
              <Button variant="contained" color="inherit" onClick={handlePrint}>
                Print
              </Button>
              <Button variant="contained" color="primary" onClick={resetAll}>
                Reset
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <PrintDownErrorDialog
          open={isPrintDownErrorDialogOpen}
          onClose={() => setPrintDownErrorDialogOpen(false)}
        />
        <GeneratingDialog open={loading} />
        <MonthlyWordCountErrorDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          errorMessage="Monthly credit limit exceeded. Please wait until the next reset or upgrade your subscription."
        />
      </Box>
    );
  };
}

export default createPressReleaseNewsTemplate;
