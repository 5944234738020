import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Slider,
  Box,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const VoiceSettingsAccordion = ({ onSettingsChange }) => {
  const defaultSettings = {
    stability: 1,
    similarity_boost: 1,
    style: 0,
  };

  const [voiceSettings, setVoiceSettings] = useState(defaultSettings);

  const handleSliderChange = (event, newValue, settingName) => {
    const newSettings = { ...voiceSettings, [settingName]: newValue };
    setVoiceSettings(newSettings);
    onSettingsChange(newSettings);
  };

  // Custom styles
  const customAccordionStyle = {
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "&:before": {
      display: "none",
    },
  };

  const sectionHeadingStyle = {
    fontWeight: "bold", // Makes the text bold
    fontSize: "0.9rem", // Adjust the font size as needed
    marginBottom: 1, // Adds a little space below the heading
    backgroundColor: "#f5f5f5", // Grey background for each section heading
    padding: "8px", // Padding for the heading
  };

  const sliderContainerStyle = {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  };

  const noWrapStyle = {
    whiteSpace: "nowrap",
  };

  const sliderStyle = {
    margin: "0 16px",
    maxWidth: "calc(100% - 48px)",
  };

  return (
    <Box sx={{ width: "100%", marginTop: 0.5 }}>
      <Accordion sx={customAccordionStyle}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Voice Settings</Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ flexDirection: "column" }}>
          <Box sx={sectionHeadingStyle}>
            <Typography gutterBottom variant="subtitle1">
              Stability
            </Typography>
          </Box>
          <Box sx={sliderContainerStyle}>
            <Tooltip title="Increasing variability can make speech more expressive with output varying between re-generations. It can also lead to instabilities.">
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Typography variant="caption" sx={noWrapStyle}>
              Variable
            </Typography>
            <Slider
              value={voiceSettings.stability}
              size="small"
              onChange={(event, newValue) =>
                handleSliderChange(event, newValue, "stability")
              }
              aria-labelledby="stability-slider"
              step={0.1}
              min={0}
              max={2}
              valueLabelDisplay="auto"
              sx={{ ...sliderStyle, mx: 2, mb: 1 }}
            />

            <Typography variant="caption" sx={noWrapStyle}>
              Stable
            </Typography>
            <Tooltip title="Increasing stability will make the voice more consistent between re-generations, but it can also make it sound a bit monotone. On longer text fragments, we recommend lowering this value.">
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Divider />
          </Box>
          <Box sx={sectionHeadingStyle}>
            <Typography gutterBottom variant="subtitle1">
              Clarity
            </Typography>
          </Box>
          <Box sx={sliderContainerStyle}>
            <Tooltip title="Low values are recommended if background artifacts are present in generated speech.">
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Typography variant="caption" sx={noWrapStyle}>
              Low
            </Typography>
            <Slider
              value={voiceSettings.similarity_boost}
              size="small"
              onChange={(event, newValue) =>
                handleSliderChange(event, newValue, "similarity_boost")
              }
              aria-labelledby="similarity_boost-slider"
              step={0.1}
              min={0}
              max={2}
              valueLabelDisplay="auto"
              sx={{ ...sliderStyle, mx: 2, mb: 1 }}
            />
            <Typography variant="caption" sx={noWrapStyle}>
              High
            </Typography>
            <Tooltip title="High enhancement boosts overall voice clarity and target speaker similarity. Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged.">
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Divider />
          </Box>
          <Box sx={sectionHeadingStyle}>
            <Typography gutterBottom variant="subtitle1">
              Style Exaggeration
            </Typography>
          </Box>
          <Box sx={sliderContainerStyle}>
            <Typography variant="caption" sx={noWrapStyle}>
              None
            </Typography>
            <Slider
              value={voiceSettings.style}
              size="small"
              onChange={(event, newValue) =>
                handleSliderChange(event, newValue, "style")
              }
              aria-labelledby="style-slider"
              step={0.1}
              min={0}
              max={2}
              valueLabelDisplay="auto"
              sx={{ ...sliderStyle, mx: 2 }}
            />
            <Typography variant="caption" sx={noWrapStyle}>
              Exaggerated
            </Typography>
            <Tooltip title="High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio. Higher values can lead to more instability in the generated speech. Setting this to 0.0 will greatly increase generation speed and is the default setting.">
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>{" "}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default VoiceSettingsAccordion;
