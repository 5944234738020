import React from "react";
import { colors } from "@mui/material";
import TextRotationNoneIcon from "@mui/icons-material/TextRotationNone";
import { alpha } from "@mui/material";

const tickerTextGenerator = {
  title: "Ticker Text Generator",
  subtitle: "Generate concise, quick-paced text for news tickers.",
  targetRoute: "/ticker-text-generator",
  color: colors.grey[800],
  bgcolor: alpha(colors.grey[800], 0.1),
  icon: <TextRotationNoneIcon fontSize="medium" />,
  tags: [],
};

export default tickerTextGenerator;
