import React from "react";
import { colors } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { alpha } from "@mui/material";

const breakingNewsAlertGenerator = {
  title: "Breaking News Alert Generator",
  subtitle: "Swiftly create concise, impactful breaking news alerts.",
  targetRoute: "/breaking-news-alert-generator",
  color: colors.red[600],
  bgcolor: alpha(colors.red[600], 0.1),
  icon: <FeedbackIcon fontSize="medium" />,
  tags: [],
};

export default breakingNewsAlertGenerator;
