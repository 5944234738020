import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

const HistoryItem = styled(Box)(({ theme }) => ({
  marginBottom: "1rem",
}));

const HistoryTitle = styled(Typography)(({ theme }) => ({
  marginBottom: "0.5rem",
  fontWeight: "bold",
}));

const HistoryContent = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
}));

const filterAndRenderHistory = (
  userHistory,
  search,
  contentTypeFilter,
  dateRangeFilter,
  handleViewFullContent
) => {
  const filteredHistory = userHistory
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((item) => {
      // Search Filter
      if (
        search &&
        !item.content.toLowerCase().includes(search.toLowerCase())
      ) {
        return false;
      }

      // ContentType Filter
      if (contentTypeFilter.length && !contentTypeFilter.includes(item.type)) {
        return false;
      }

      // DateRange Filter
      const itemDate = new Date(item.createdAt);
      const today = new Date();
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
      );
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

      switch (dateRangeFilter) {
        case "today":
          if (itemDate.toDateString() !== today.toDateString()) return false;
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          if (itemDate.toDateString() !== yesterday.toDateString())
            return false;
          break;
        case "thisWeek":
          if (itemDate < startOfWeek) return false;
          break;
        case "thisMonth":
          if (itemDate < startOfMonth) return false;
          break;
        default:
          break;
      }

      return true;
    })
    .map((historyItem, index) => {
      const formattedDate = new Date(historyItem.createdAt).toLocaleString();
      const content =
        historyItem.content.split(" ").slice(0, 50).join(" ") + "...";

      return (
        <HistoryItem key={index}>
          <Card>
            <CardContent>
              <HistoryTitle variant="h6">{formattedDate}</HistoryTitle>
              <HistoryContent
                variant="body1"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <Button onClick={() => handleViewFullContent(historyItem._id)}>
                View full content
              </Button>
            </CardContent>
          </Card>
        </HistoryItem>
      );
    });

  return filteredHistory;
};

export default filterAndRenderHistory;
