import createBlogFromSpeechTemplate from "../../BlogFromSpeechTemplate";

const transcriptionFromSpeech = createBlogFromSpeechTemplate({
  name: "transcriptionfromspeech",
  endpoint: "/api/openai/transcription-from-speech",
  aiText: "transcriptionFromSpeech",
  aiTextHandler: "transcriptionFromSpeechHandler",
  aiTextPlainText: "transcriptionFromSpeechPlainText",
  title: "Transcription From Speech",
  subText:
    "Simplify global communication with this precise transcription tool. Upload an mp3 file in any of the 22 supported languages, choose your desired output from a list of 21 languages, and the tool will transcribe the speech into clear, written text. Save time, mitigate manual errors, and ensure every spoken word is accurately transcribed, fostering clear understanding across multiple languages.",
  label: "Transcription",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Transcribe",
  aiPlaceholder: "Your transcription will appear here",
  notes: `
    <strong>Please note:</strong>
    <ul>
      <li>The audio file could be in flac, mp3, mp4, mpeg, mpga, m4a, ogg, wav, or webm formats and not exceed 25mb.</li>
      <li>The quality of results is highly dependent on the clarity of the audio. Please ensure the audio is clear.</li>
      <li>The transcription process will detect and transcribe the audio in the language it is spoken in. For example, if the audio is in English, it will be transcribed in English, and if it's in Hindi, it will be transcribed in Hindi.</li>
      <li>We currently support these languages for transcriptions: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.</li>
      <li>Audio-to-text transcriptions may take longer due to their complexity. Please be patient.</li>
    </ul>
  `,
});

export default transcriptionFromSpeech;
