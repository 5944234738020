import React from "react";
import { colors } from "@mui/material";
import PodcastsOutlinedIcon from "@mui/icons-material/PodcastsOutlined";
import { alpha } from "@mui/material";

const podcastCreator = {
  title: "Podcast Creator",
  subtitle:
    "Craft engaging and thought-provoking scripts for your podcast episodes.",
  targetRoute: "/podcast-creator",
  color: colors.teal[600],
  bgcolor: alpha(colors.teal[600], 0.1),
  icon: <PodcastsOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default podcastCreator;
