import createTeachingTemplate from "../../TeachingTemplate";

const analogyCreatorTeaching = createTeachingTemplate({
  name: "analogycreatorteaching",
  endpoint: "/api/openai/analogy-creator-teaching",
  aiText: "analogyCreatorTeaching",
  aiTextHandler: "analogyCreatorTeachingHandler",
  aiTextPlainText: "analogyCreatorTeachingPlainText",
  title: "Analogy Creator",
  subText:
    'Facilitate easier comprehension. Just feed in the class, subject, and a complex topic from your syllabus, and this tool will offer a relatable analogy to make understanding a breeze. Upload your syllabus to get accurate, curriculum-specific analogies. Example: "Grade 11, Physics, Electric circuits topic."',
  label: "Relatable Analogy",
  placeholderText: "Specify any complex topic",
  buttonText: "Create Analogy",
  aiPlaceholder: "Relatable analogy will appear here",
});

export default analogyCreatorTeaching;
