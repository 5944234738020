import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Container from '../../Container';

import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import HeadlineSubtextNews from './HeadlineSubtext/HeadlineSubtextNews';

import { authState } from '../../../atoms';
import instance from '../../../helpers/axiosInstance';

import {
  breakingNewsAlertGenerator,
  headlineGeneratorBroadcast,
  headlineGeneratorDigital,
  headlineGeneratorPrint,
  interactiveContentGenerator,
  interviewQuestionGenerator,
  newsReportBroadcast,
  newsReportDigital,
  newsReportPrint,
  newsSummaryTool,
  pressReleaseRewriterBroadcast,
  pressReleaseRewriterDigital,
  pressReleaseRewriterPrint,
  socialMediaPostGenerator,
  teaserWriter,
  tickerTextGenerator,
} from '../cards/NewsWriters';

const FeaturedWriters = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated } = auth;

  const handleClickOpen = (item) => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(item);
    }
  };

  const features = [
    breakingNewsAlertGenerator,
    headlineGeneratorBroadcast,
    headlineGeneratorDigital,
    headlineGeneratorPrint,
    interactiveContentGenerator,
    interviewQuestionGenerator,
    newsReportBroadcast,
    newsReportDigital,
    newsReportPrint,
    newsSummaryTool,
    pressReleaseRewriterBroadcast,
    pressReleaseRewriterDigital,
    pressReleaseRewriterPrint,
    socialMediaPostGenerator,
    teaserWriter,
    tickerTextGenerator,
  ];

  return (
    <>
      <HeadlineSubtextNews />
      <Box
        sx={{
          marginTop: {
            xs: '-16px', // value for screens 0px or larger
            sm: '-70px', // value for screens 600px or larger
            md: '-32px', // value for screens 960px or larger
            lg: '-40px', // value for screens 1280px or larger
            xl: '-48px', // value for screens 1920px or larger
          },

          paddingBottom: '32px', // <-- Add this line to give padding at the bottom

          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.background.paper,
            0
          )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: 'repeat-x',
          position: 'relative',
        }}
      >
        <Container>
          <Grid container spacing={4}>
            {features.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <IconButton
                  onClick={() => handleClickOpen(item.targetRoute)}
                  sx={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    borderRadius: 2,
                    border: '1px solid transparent',
                    transition: 'border-color 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      borderColor: theme.palette.action.hover,
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <Box
                    component={Card}
                    padding={4}
                    borderRadius={2}
                    width={1}
                    height={1}
                    data-aos={'fade-up'}
                    data-aos-delay={i * 100}
                  >
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={item.bgcolor}
                      color={item.color}
                      variant={'rounded'}
                      borderRadius={2}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 500 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                  </Box>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FeaturedWriters;
