// // //client/src/components/screens/Features/pptTest.js

// // import React, { useState, useEffect } from 'react';
// // import { useRecoilValue } from 'recoil';
// // import { useNavigate } from 'react-router-dom';
// // import { getAuth } from 'firebase/auth';

// // import {
// //   Box,
// //   Typography,
// //   useTheme,
// //   useMediaQuery,
// //   TextField,
// //   Button,
// //   CircularProgress,
// //   Stepper,
// //   Step,
// //   StepLabel,
// //   Alert,
// //   Grid,
// //   MenuItem,
// // } from '@mui/material';

// // import instance from '../../../helpers/axiosInstance';
// // import trackButtonClick from '../../../helpers/trackButtonClick';
// // import GeneratingDialog from '../Dialog/GeneratingDialog';
// // import { authState } from '../../../atoms';
// // import SlideContentRenderer from '../SlideContentRenderer';
// // import TemplateSelector from '../TemplateSelector';
// // import FontSelector from '../FontSelector';
// // import ColorThemeSelector from '../ColorThemeSelector';
// // import ColorCombinations from '../ColorCombinations';
// // import { colorCombinations } from '../../../utils/colorCombinations';

// // // Define default fonts and color themes
// // const getDefaultFonts = (template) => {
// //   const defaultFonts = {
// //     nice_slides: {
// //       title: 'Bahnschrift Regular (Headings)',
// //       subtitle: 'Bahnschrift Regular (Headings)',
// //       body: 'Tw Cen MT (Body)',
// //     },
// //     // Add more templates as needed
// //   };
// //   return defaultFonts[template] || {};
// // };

// // const getDefaultColorTheme = (template) => {
// //   const defaultThemes = {
// //     nice_slides: {
// //       primary: '#ffffff',
// //       secondary: '#000000',
// //       accent: '#4a86e8',
// //     },
// //     // Add more templates as needed
// //   };
// //   return defaultThemes[template] || {};
// // };

// // function PowerPointGenerator() {
// //   const theme = useTheme();
// //   const isNotMobile = useMediaQuery('(min-width: 1000px)');
// //   const navigate = useNavigate();

// //   // Recoil state
// //   const auth = useRecoilValue(authState);
// //   const { isAuthenticated } = auth;

// //   const [activeStep, setActiveStep] = useState(0);
// //   const [initialPrompt, setInitialPrompt] = useState('');
// //   const [slideCount, setSlideCount] = useState(5);
// //   const [outline, setOutline] = useState([]);
// //   const [fullContent, setFullContent] = useState({ slides: [] });
// //   const [error, setError] = useState('');
// //   const [loading, setLoading] = useState(false);
// //   const [unsplashQuery, setUnsplashQuery] = useState('');
// //   const [unsplashResults, setUnsplashResults] = useState([]);
// //   const [isSearching, setIsSearching] = useState(false);
// //   const [presenterName, setPresenterName] = useState('');
// //   const [selectedTemplate, setSelectedTemplate] = useState('');
// //   const [selectedFonts, setSelectedFonts] = useState({});
// //   const [selectedColorTheme, setSelectedColorTheme] = useState({});
// //   const [selectedColorCombination, setSelectedColorCombination] =
// //     useState(null);

// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, []);

// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate('/login');
// //     }
// //   }, [isAuthenticated, navigate]);

// //   const handleTemplateSelect = (template) => {
// //     setSelectedTemplate(template);
// //     setSelectedFonts(getDefaultFonts(template));
// //     setSelectedColorTheme(getDefaultColorTheme(template));
// //   };

// //   const handleColorCombinationSelect = (index) => {
// //     setSelectedColorCombination(index);
// //     setSelectedColorTheme(colorCombinations[index].colors);
// //   };

// //   const handleInitialSubmit = async (e) => {
// //     e.preventDefault();
// //     trackButtonClick('PowerPoint Generator');
// //     setLoading(true);
// //     setError('');

// //     if (!initialPrompt) {
// //       setError('Please input a prompt for the presentation.');
// //       setLoading(false);
// //       return;
// //     }

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = '';
// //       if (user) {
// //         idToken = await user.getIdToken();
// //       }

// //       const response = await instance.post(
// //         '/api/ppt/generate-outline',
// //         { prompt: initialPrompt, slideCount },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );

// //       const { outline } = response.data;
// //       setOutline(outline);
// //       setLoading(false);
// //       setActiveStep(1);
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while generating the outline. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   const handleOutlineSubmit = async () => {
// //     setLoading(true);
// //     setError('');

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = '';
// //       if (user) {
// //         idToken = await user.getIdToken();
// //       }

// //       const response = await instance.post(
// //         '/api/ppt/generate-content',
// //         { outline, slideCount },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );

// //       const { content } = response.data;
// //       setFullContent(content);
// //       setLoading(false);
// //       setActiveStep(2);
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while generating the content. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   const handleFinalSubmit = async () => {
// //     setLoading(true);
// //     setError('');

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = '';
// //       if (user) {
// //         idToken = await user.getIdToken();
// //       }

// //       const presentationData = {
// //         presentation_title: 'AI Generated Presentation',
// //         template: selectedTemplate,
// //         styles: {
// //           title: {
// //             font: selectedFonts.title || 'Bahnschrift Regular (Headings)',
// //             size: 44,
// //             color: selectedColorTheme.primary || '#ffffff',
// //           },
// //           subtitle: {
// //             font: selectedFonts.subtitle || 'Bahnschrift Regular (Headings)',
// //             size: 24,
// //             color: selectedColorTheme.secondary || '#000000',
// //           },
// //           body: {
// //             font: selectedFonts.body || 'Tw Cen MT (Body)',
// //             size: 18,
// //             color: selectedColorTheme.secondary || '#000000',
// //           },
// //         },
// //         slides: fullContent.slides,
// //         presenter_name: presenterName,
// //         color_theme: selectedColorTheme, // Add this line
// //       };

// //       const response = await instance.post(
// //         'http://localhost:5000/create_presentation',
// //         presentationData,
// //         {
// //           headers: {
// //             'Content-Type': 'application/json',
// //             Authorization: `Bearer ${idToken}`,
// //           },
// //           responseType: 'blob',
// //           withCredentials: true,
// //           crossDomain: true,
// //         }
// //       );

// //       const blob = new Blob([response.data], {
// //         type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
// //       });

// //       const link = document.createElement('a');
// //       link.href = window.URL.createObjectURL(blob);
// //       link.download = 'AI_Generated_Presentation.pptx';
// //       link.click();

// //       window.URL.revokeObjectURL(link.href);
// //       setLoading(false);
// //       alert('Presentation downloaded successfully!');

// //       await instance.post(
// //         '/api/ppt/log-presentation-creation',
// //         { presentationData },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while creating the presentation. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   const handleUnsplashSearch = async (index) => {
// //     setIsSearching(true);
// //     setError('');

// //     if (!unsplashQuery.trim()) {
// //       setError('Please enter a search term');
// //       setIsSearching(false);
// //       return;
// //     }

// //     try {
// //       const response = await instance.get(
// //         `/api/ppt/unsplash/search?query=${encodeURIComponent(unsplashQuery.trim())}`
// //       );
// //       setUnsplashResults(response.data || []);
// //     } catch (error) {
// //       console.error('Error searching Unsplash:', error);
// //       setError(
// //         error.response?.data?.error ||
// //           'Failed to search Unsplash images. Please try again.'
// //       );
// //       setUnsplashResults([]);
// //     }
// //     setIsSearching(false);
// //   };

// //   const handleImageSelect = (imageUrl, index) => {
// //     const newContent = { ...fullContent };
// //     newContent.slides[index].content.image_url = imageUrl;
// //     setFullContent(newContent);
// //   };

// //   const handleImageUpload = async (event, index) => {
// //     const file = event.target.files[0];
// //     if (file) {
// //       const formData = new FormData();
// //       formData.append('image', file);
// //       try {
// //         const response = await instance.post(
// //           '/api/ppt/upload-image',
// //           formData,
// //           {
// //             headers: { 'Content-Type': 'multipart/form-data' },
// //           }
// //         );
// //         handleImageSelect(response.data.imageUrl, index);
// //       } catch (error) {
// //         console.error('Error uploading image:', error);
// //         setError('Failed to upload image. Please try again.');
// //       }
// //     }
// //   };

// //   const renderSlideContent = (slide, index) => {
// //     switch (slide.layout) {
// //       case 'title':
// //         return (
// //           <Box key={index}>
// //             <TextField
// //               fullWidth
// //               label="Title"
// //               value={slide.content.title || ''}
// //               onChange={(e) => {
// //                 const words = e.target.value.split(' ');
// //                 const limitedWords = words.slice(0, 10).join(' ');
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.title = limitedWords;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //               helperText={`${slide.content.title?.split(' ').length || 0}/10 words`}
// //             />
// //             <TextField
// //               fullWidth
// //               label="Subtitle"
// //               value={slide.content.subtitle || ''}
// //               onChange={(e) => {
// //                 const words = e.target.value.split(' ');
// //                 const limitedWords = words.slice(0, 15).join(' ');
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.subtitle = limitedWords;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //               helperText={`${slide.content.subtitle?.split(' ').length || 0}/15 words`}
// //             />
// //             <input
// //               accept="image/*"
// //               style={{ display: 'none' }}
// //               id={`raised-button-file-${index}`}
// //               type="file"
// //               onChange={(e) => handleImageUpload(e, index)}
// //             />
// //             <label htmlFor={`raised-button-file-${index}`}>
// //               <Button variant="contained" component="span">
// //                 Upload Title Slide Image
// //               </Button>
// //             </label>
// //             {slide.content.image_url && (
// //               <img
// //                 src={slide.content.image_url}
// //                 alt="Title slide preview"
// //                 style={{
// //                   maxWidth: '200px',
// //                   maxHeight: '200px',
// //                   marginTop: '10px',
// //                   marginBottom: '10px',
// //                 }}
// //               />
// //             )}
// //           </Box>
// //         );
// //       case 'bullet_points':
// //         return (
// //           <TextField
// //             key={index}
// //             fullWidth
// //             label="Bullet Points"
// //             value={slide.content.points ? slide.content.points.join('\n') : ''}
// //             onChange={(e) => {
// //               const newContent = { ...fullContent };
// //               newContent.slides[index].content.points =
// //                 e.target.value.split('\n');
// //               setFullContent(newContent);
// //             }}
// //             margin="normal"
// //             multiline
// //             rows={4}
// //           />
// //         );
// //       case 'image_and_text':
// //         return (
// //           <Box key={index}>
// //             <TextField
// //               fullWidth
// //               label="Text Content"
// //               value={slide.content.text || ''}
// //               onChange={(e) => {
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.text = e.target.value;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //               multiline
// //               rows={4}
// //             />
// //             {slide.content.image_url && (
// //               <img
// //                 src={slide.content.image_url}
// //                 alt="Slide preview"
// //                 style={{
// //                   maxWidth: '200px',
// //                   maxHeight: '200px',
// //                   marginTop: '10px',
// //                   marginBottom: '10px',
// //                 }}
// //               />
// //             )}
// //             <TextField
// //               fullWidth
// //               label="Unsplash Search"
// //               value={unsplashQuery}
// //               onChange={(e) => setUnsplashQuery(e.target.value)}
// //               margin="normal"
// //             />
// //             <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
// //               <Button
// //                 variant="contained"
// //                 onClick={() => handleUnsplashSearch(index)}
// //                 disabled={isSearching}
// //               >
// //                 {isSearching ? (
// //                   <CircularProgress size={24} />
// //                 ) : (
// //                   'Search Unsplash'
// //                 )}
// //               </Button>
// //               <input
// //                 accept="image/*"
// //                 style={{ display: 'none' }}
// //                 id={`raised-button-file-${index}`}
// //                 type="file"
// //                 onChange={(e) => handleImageUpload(e, index)}
// //               />
// //               <label htmlFor={`raised-button-file-${index}`}>
// //                 <Button variant="contained" component="span">
// //                   Upload Image
// //                 </Button>
// //               </label>
// //             </Box>
// //             {error && (
// //               <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
// //                 {error}
// //               </Alert>
// //             )}
// //             <Grid container spacing={2} style={{ marginTop: '10px' }}>
// //               {unsplashResults.map((image) => (
// //                 <Grid item xs={3} key={image.id}>
// //                   <img
// //                     src={image.thumb || ''}
// //                     alt={image.description || 'Unsplash image'}
// //                     style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
// //                     onClick={() => handleImageSelect(image.url, index)}
// //                   />
// //                 </Grid>
// //               ))}
// //             </Grid>
// //           </Box>
// //         );
// //       case 'chart':
// //         return (
// //           <Box key={index}>
// //             <TextField
// //               fullWidth
// //               label="Chart Title"
// //               value={slide.content.title || ''}
// //               onChange={(e) => {
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.title = e.target.value;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //             />
// //             <TextField
// //               fullWidth
// //               select
// //               label="Chart Type"
// //               value={slide.content.chart_type || 'bar'}
// //               onChange={(e) => {
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.chart_type = e.target.value;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //             >
// //               <MenuItem value="bar">Bar</MenuItem>
// //               <MenuItem value="line">Line</MenuItem>
// //               <MenuItem value="pie">Pie</MenuItem>
// //             </TextField>
// //             {/* Add more fields for chart data input */}
// //           </Box>
// //         );
// //       case 'two_column':
// //         return (
// //           <Box key={index}>
// //             <TextField
// //               fullWidth
// //               label="Left Column"
// //               value={slide.content.left_column || ''}
// //               onChange={(e) => {
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.left_column = e.target.value;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //               multiline
// //               rows={4}
// //             />
// //             <TextField
// //               fullWidth
// //               label="Right Column"
// //               value={slide.content.right_column || ''}
// //               onChange={(e) => {
// //                 const newContent = { ...fullContent };
// //                 newContent.slides[index].content.right_column = e.target.value;
// //                 setFullContent(newContent);
// //               }}
// //               margin="normal"
// //               multiline
// //               rows={4}
// //             />
// //           </Box>
// //         );
// //       case 'section_header':
// //         return (
// //           <TextField
// //             key={index}
// //             fullWidth
// //             label="Section Header"
// //             value={slide.content.title || ''}
// //             onChange={(e) => {
// //               const newContent = { ...fullContent };
// //               newContent.slides[index].content.title = e.target.value;
// //               setFullContent(newContent);
// //             }}
// //             margin="normal"
// //           />
// //         );
// //       default:
// //         return (
// //           <Typography key={index} color="error">
// //             Unsupported slide type: {slide.layout}
// //           </Typography>
// //         );
// //     }
// //   };

// //   return (
// //     <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
// //       <Typography variant="h4" gutterBottom>
// //         Create a PowerPoint Presentation
// //       </Typography>

// //       <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
// //         <Step>
// //           <StepLabel>Initial Setup</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Outline</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Full Content</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Styling</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Review and Create</StepLabel>
// //         </Step>
// //       </Stepper>

// //       {activeStep === 0 && (
// //         <>
// //           <TextField
// //             fullWidth
// //             label="Presenter Name"
// //             value={presenterName}
// //             onChange={(e) => setPresenterName(e.target.value)}
// //             margin="normal"
// //           />
// //           <TemplateSelector
// //             onSelect={handleTemplateSelect}
// //             selectedTemplate={selectedTemplate}
// //           />
// //           <TextField
// //             fullWidth
// //             multiline
// //             rows={4}
// //             value={initialPrompt}
// //             onChange={(e) => setInitialPrompt(e.target.value)}
// //             placeholder="Enter your presentation topic and any specific requirements..."
// //             margin="normal"
// //           />
// //           <TextField
// //             fullWidth
// //             type="number"
// //             label="Number of Slides"
// //             value={slideCount}
// //             onChange={(e) => setSlideCount(e.target.value)}
// //             margin="normal"
// //           />
// //           <Button
// //             onClick={handleInitialSubmit}
// //             variant="contained"
// //             disabled={loading || !selectedTemplate || !initialPrompt}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Generate Outline'}
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 1 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Outline
// //           </Typography>
// //           {outline.map((slide, index) => (
// //             <TextField
// //               key={index}
// //               fullWidth
// //               label={`Slide ${index + 1}`}
// //               value={slide}
// //               onChange={(e) => {
// //                 const newOutline = [...outline];
// //                 newOutline[index] = e.target.value;
// //                 setOutline(newOutline);
// //               }}
// //               margin="normal"
// //             />
// //           ))}
// //           <Button
// //             onClick={handleOutlineSubmit}
// //             variant="contained"
// //             disabled={loading}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Generate Full Content'}
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 2 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Full Content
// //           </Typography>
// //           {fullContent.slides &&
// //             fullContent.slides.map((slide, index) => (
// //               <Box key={index} sx={{ mb: 2 }}>
// //                 <Typography variant="subtitle1">Slide {index + 1}</Typography>
// //                 <TextField
// //                   fullWidth
// //                   label="Layout"
// //                   value={slide.layout}
// //                   onChange={(e) => {
// //                     const newContent = { ...fullContent };
// //                     newContent.slides[index].layout = e.target.value;
// //                     setFullContent(newContent);
// //                   }}
// //                   margin="normal"
// //                 />
// //                 <SlideContentRenderer
// //                   slide={slide}
// //                   index={index}
// //                   fullContent={fullContent}
// //                   setFullContent={setFullContent}
// //                   unsplashQuery={unsplashQuery}
// //                   setUnsplashQuery={setUnsplashQuery}
// //                   handleUnsplashSearch={handleUnsplashSearch}
// //                   handleImageUpload={handleImageUpload}
// //                   handleImageSelect={handleImageSelect}
// //                   isSearching={isSearching}
// //                   error={error}
// //                   unsplashResults={unsplashResults}
// //                 />
// //               </Box>
// //             ))}
// //           <Button
// //             onClick={() => setActiveStep(3)}
// //             variant="contained"
// //             sx={{ mt: 2 }}
// //           >
// //             Next: Styling
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 3 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Styling
// //           </Typography>
// //           <FontSelector
// //             selectedFonts={selectedFonts}
// //             onFontChange={setSelectedFonts}
// //           />
// //           <ColorCombinations
// //             onSelect={handleColorCombinationSelect}
// //             selectedCombination={selectedColorCombination}
// //           />
// //           <Button
// //             onClick={() => setActiveStep(4)}
// //             variant="contained"
// //             sx={{ mt: 2 }}
// //           >
// //             Next: Review
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 4 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Review and Create
// //           </Typography>
// //           {/* Add a summary of the presentation here */}
// //           <Typography variant="body1">Presenter: {presenterName}</Typography>
// //           <Typography variant="body1">Template: {selectedTemplate}</Typography>
// //           <Typography variant="body1">
// //             Number of Slides: {fullContent.slides.length}
// //           </Typography>
// //           {/* You can add more details here as needed */}
// //           <Button
// //             onClick={handleFinalSubmit}
// //             variant="contained"
// //             disabled={loading}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Create Presentation'}
// //           </Button>
// //         </>
// //       )}

// //       {error && (
// //         <Alert severity="error" sx={{ mt: 2 }}>
// //           {error}
// //         </Alert>
// //       )}

// //       {loading && <GeneratingDialog open={loading} />}
// //     </Box>
// //   );
// // }

// // export default PowerPointGenerator;

// // //client/src/components/screens/Features/pptTest.js
// // import React, { useState, useEffect } from 'react';
// // import { useRecoilValue } from 'recoil';
// // import { useNavigate } from 'react-router-dom';
// // import { getAuth } from 'firebase/auth';

// // import {
// //   Box,
// //   Typography,
// //   TextField,
// //   Button,
// //   CircularProgress,
// //   Stepper,
// //   Step,
// //   StepLabel,
// //   Alert,
// //   Grid,
// //   MenuItem,
// // } from '@mui/material';

// // import instance from '../../../helpers/axiosInstance';
// // import trackButtonClick from '../../../helpers/trackButtonClick';
// // import GeneratingDialog from '../Dialog/GeneratingDialog';
// // import { authState } from '../../../atoms';
// // import SlideContentRenderer from '../SlideContentRenderer';
// // import TemplateSelector from '../TemplateSelector';
// // import FontSelector from '../FontSelector';
// // import ThemeSelector from '../ThemeSelector';

// // const getDefaultFonts = (template) => {
// //   const defaultFonts = {
// //     template_A: {
// //       title: 'Bahnschrift Regular (Headings)',
// //       subtitle: 'Bahnschrift Regular (Headings)',
// //       body: 'Tw Cen MT (Body)',
// //     },
// //     // Add more templates as needed
// //   };
// //   return defaultFonts[template] || {};
// // };

// // function PowerPointGenerator() {
// //   const navigate = useNavigate();
// //   const auth = useRecoilValue(authState);
// //   const { isAuthenticated } = auth;

// //   const [activeStep, setActiveStep] = useState(0);
// //   const [initialPrompt, setInitialPrompt] = useState('');
// //   const [slideCount, setSlideCount] = useState(5);
// //   const [outline, setOutline] = useState([]);
// //   const [fullContent, setFullContent] = useState({ slides: [] });
// //   const [error, setError] = useState('');
// //   const [loading, setLoading] = useState(false);
// //   const [presenterName, setPresenterName] = useState('');
// //   const [selectedTemplate, setSelectedTemplate] = useState('');
// //   const [selectedFonts, setSelectedFonts] = useState({});
// //   const [selectedTheme, setSelectedTheme] = useState('');
// //   const [unsplashResults, setUnsplashResults] = useState([]);

// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, []);

// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate('/login');
// //     }
// //   }, [isAuthenticated, navigate]);

// //   const handleTemplateSelect = (template) => {
// //     setSelectedTemplate(template);
// //     setSelectedFonts(getDefaultFonts(template.split('_')[0]));
// //   };

// //   const handleInitialSubmit = async (e) => {
// //     e.preventDefault();
// //     trackButtonClick('PowerPoint Generator');
// //     setLoading(true);
// //     setError('');

// //     if (!initialPrompt) {
// //       setError('Please input a prompt for the presentation.');
// //       setLoading(false);
// //       return;
// //     }

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = await user.getIdToken();

// //       const response = await instance.post(
// //         '/api/ppt/generate-outline',
// //         { prompt: initialPrompt, slideCount },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );

// //       const { outline } = response.data;
// //       setOutline(outline);
// //       setLoading(false);
// //       setActiveStep(1);
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while generating the outline. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   const handleThemeChange = (theme) => {
// //     setSelectedTheme(theme);
// //   };

// //   const handleOutlineSubmit = async () => {
// //     setLoading(true);
// //     setError('');

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = await user.getIdToken();

// //       const response = await instance.post(
// //         '/api/ppt/generate-content',
// //         { outline, slideCount },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );

// //       const { content } = response.data;
// //       setFullContent(content);
// //       setLoading(false);
// //       setActiveStep(2);
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while generating the content. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   // const handleFinalSubmit = async () => {
// //   //   setLoading(true);
// //   //   setError('');

// //   //   try {
// //   //     const auth = getAuth();
// //   //     const user = auth.currentUser;
// //   //     let idToken = await user.getIdToken();

// //   //     const presentationData = {
// //   //       presentation_title: 'AI Generated Presentation',
// //   //       template: selectedTemplate,
// //   //       styles: {
// //   //         title: {
// //   //           font: selectedFonts.title || 'Bahnschrift Regular (Headings)',
// //   //           size: 44,
// //   //         },
// //   //         subtitle: {
// //   //           font: selectedFonts.subtitle || 'Bahnschrift Regular (Headings)',
// //   //           size: 24,
// //   //         },
// //   //         body: {
// //   //           font: selectedFonts.body || 'Tw Cen MT (Body)',
// //   //           size: 18,
// //   //         },
// //   //       },
// //   //       slides: fullContent.slides,
// //   //       presenter_name: presenterName,
// //   //     };

// //   const handleFinalSubmit = async () => {
// //     setLoading(true);
// //     setError('');

// //     try {
// //       const auth = getAuth();
// //       const user = auth.currentUser;
// //       let idToken = await user.getIdToken();

// //       const presentationData = {
// //         presentation_title: 'AI Generated Presentation',
// //         template: selectedTheme, // This now includes both the template and color variant
// //         styles: {
// //           title: {
// //             font: selectedFonts.title || 'Arial',
// //             size: 44,
// //           },
// //           subtitle: {
// //             font: selectedFonts.subtitle || 'Arial',
// //             size: 24,
// //           },
// //           body: {
// //             font: selectedFonts.body || 'Arial',
// //             size: 18,
// //           },
// //         },
// //         slides: fullContent.slides,
// //         presenter_name: presenterName,
// //       };

// //       const response = await instance.post(
// //         'http://localhost:5000/create_presentation',
// //         presentationData,
// //         {
// //           headers: {
// //             'Content-Type': 'application/json',
// //             Authorization: `Bearer ${idToken}`,
// //           },
// //           responseType: 'blob',
// //           withCredentials: true,
// //           crossDomain: true,
// //         }
// //       );

// //       const blob = new Blob([response.data], {
// //         type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
// //       });

// //       const link = document.createElement('a');
// //       link.href = window.URL.createObjectURL(blob);
// //       link.download = 'AI_Generated_Presentation.pptx';
// //       link.click();

// //       window.URL.revokeObjectURL(link.href);
// //       setLoading(false);
// //       alert('Presentation downloaded successfully!');

// //       await instance.post(
// //         '/api/ppt/log-presentation-creation',
// //         { presentationData },
// //         { headers: { Authorization: `Bearer ${idToken}` } }
// //       );
// //     } catch (err) {
// //       console.error('Error:', err);
// //       setError(
// //         'An error occurred while creating the presentation. Please try again.'
// //       );
// //       setLoading(false);
// //     }
// //   };

// //   return (
// //     <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
// //       <Typography variant="h4" gutterBottom>
// //         Create a PowerPoint Presentation
// //       </Typography>

// //       <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
// //         <Step>
// //           <StepLabel>Initial Setup</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Outline</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Full Content</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Styling</StepLabel>
// //         </Step>
// //         <Step>
// //           <StepLabel>Review and Create</StepLabel>
// //         </Step>
// //       </Stepper>

// //       {activeStep === 0 && (
// //         <>
// //           <TextField
// //             fullWidth
// //             label="Presenter Name"
// //             value={presenterName}
// //             onChange={(e) => setPresenterName(e.target.value)}
// //             margin="normal"
// //           />
// //           <TemplateSelector
// //             onSelect={handleTemplateSelect}
// //             selectedTemplate={selectedTemplate}
// //           />
// //           <TextField
// //             fullWidth
// //             multiline
// //             rows={4}
// //             value={initialPrompt}
// //             onChange={(e) => setInitialPrompt(e.target.value)}
// //             placeholder="Enter your presentation topic and any specific requirements..."
// //             margin="normal"
// //           />
// //           <TextField
// //             fullWidth
// //             type="number"
// //             label="Number of Slides"
// //             value={slideCount}
// //             onChange={(e) => setSlideCount(e.target.value)}
// //             margin="normal"
// //           />
// //           <Button
// //             onClick={handleInitialSubmit}
// //             variant="contained"
// //             disabled={loading || !initialPrompt}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Generate Outline'}
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 1 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Outline
// //           </Typography>
// //           {outline.map((slide, index) => (
// //             <TextField
// //               key={index}
// //               fullWidth
// //               label={`Slide ${index + 1}`}
// //               value={slide}
// //               onChange={(e) => {
// //                 const newOutline = [...outline];
// //                 newOutline[index] = e.target.value;
// //                 setOutline(newOutline);
// //               }}
// //               margin="normal"
// //             />
// //           ))}
// //           <Button
// //             onClick={handleOutlineSubmit}
// //             variant="contained"
// //             disabled={loading}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Generate Full Content'}
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 2 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Full Content
// //           </Typography>
// //           {fullContent.slides &&
// //             fullContent.slides.map((slide, index) => (
// //               <Box key={index} sx={{ mb: 2 }}>
// //                 <Typography variant="subtitle1">Slide {index + 1}</Typography>
// //                 <SlideContentRenderer
// //                   slide={slide}
// //                   index={index}
// //                   fullContent={fullContent}
// //                   setFullContent={setFullContent}
// //                 />
// //               </Box>
// //             ))}
// //           <Button
// //             onClick={() => setActiveStep(3)}
// //             variant="contained"
// //             sx={{ mt: 2 }}
// //           >
// //             Next: Styling
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 3 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Styling
// //           </Typography>
// //           <ThemeSelector
// //             selectedTemplate={selectedTemplate}
// //             selectedTheme={selectedTheme}
// //             onThemeChange={handleThemeChange}
// //           />
// //           <FontSelector
// //             selectedFonts={selectedFonts}
// //             onFontChange={setSelectedFonts}
// //           />
// //           <Button
// //             onClick={() => setActiveStep(4)}
// //             variant="contained"
// //             sx={{ mt: 2 }}
// //           >
// //             Next: Review
// //           </Button>
// //         </>
// //       )}

// //       {activeStep === 4 && (
// //         <>
// //           <Typography variant="h6" gutterBottom>
// //             Review and Create
// //           </Typography>
// //           <Typography variant="body1">Presenter: {presenterName}</Typography>
// //           <Typography variant="body1">Template: {selectedTemplate}</Typography>
// //           <Typography variant="body1">
// //             Number of Slides: {fullContent.slides.length}
// //           </Typography>
// //           <Button
// //             onClick={handleFinalSubmit}
// //             variant="contained"
// //             disabled={loading}
// //             sx={{ mt: 2 }}
// //           >
// //             {loading ? <CircularProgress size={24} /> : 'Create Presentation'}
// //           </Button>
// //         </>
// //       )}

// //       {error && (
// //         <Alert severity="error" sx={{ mt: 2 }}>
// //           {error}
// //         </Alert>
// //       )}

// //       {loading && <GeneratingDialog open={loading} />}
// //     </Box>
// //   );
// // }

// // export default PowerPointGenerator;

// import React, { useState, useEffect } from 'react';
// import { useRecoilValue } from 'recoil';
// import { useNavigate } from 'react-router-dom';
// import { getAuth } from 'firebase/auth';

// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   CircularProgress,
//   Stepper,
//   Step,
//   StepLabel,
//   Alert,
// } from '@mui/material';

// import instance from '../../../helpers/axiosInstance';
// import trackButtonClick from '../../../helpers/trackButtonClick';
// import GeneratingDialog from '../Dialog/GeneratingDialog';
// import { authState } from '../../../atoms';
// import SlideContentRenderer from '../SlideContentRenderer';
// import TemplateSelector from '../TemplateSelector';
// import FontSelector from '../FontSelector';
// import ThemeSelector from '../ThemeSelector';

// const getDefaultFonts = (template) => {
//   const defaultFonts = {
//     boss_favorite: {
//       title: 'Arial',
//       subtitle: 'Arial',
//       body: 'Arial',
//     },
//     // Add more templates as needed
//   };
//   return defaultFonts[template] || {};
// };

// function PowerPointGenerator() {
//   const navigate = useNavigate();
//   const auth = useRecoilValue(authState);
//   const { isAuthenticated } = auth;

//   const [activeStep, setActiveStep] = useState(0);
//   const [initialPrompt, setInitialPrompt] = useState('');
//   const [slideCount, setSlideCount] = useState(5);
//   const [outline, setOutline] = useState([]);
//   const [fullContent, setFullContent] = useState({ slides: [] });
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [presenterName, setPresenterName] = useState('');
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedTheme, setSelectedTheme] = useState('');
//   const [selectedFonts, setSelectedFonts] = useState({});
//   const [unsplashQuery, setUnsplashQuery] = useState('');
//   const [unsplashResults, setUnsplashResults] = useState([]);
//   const [isSearching, setIsSearching] = useState(false);
//   const [presentationCreated, setPresentationCreated] = useState(false);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate('/login');
//     }
//   }, [isAuthenticated, navigate]);

//   const handleTemplateSelect = (templateId) => {
//     setSelectedTemplate(templateId);
//     setSelectedTheme(''); // Reset the selected theme when a new template is chosen
//     setSelectedFonts(getDefaultFonts(templateId));
//   };

//   const handleThemeChange = (themeId) => {
//     setSelectedTheme(themeId);
//   };

//   const handleInitialSubmit = async (e) => {
//     e.preventDefault();
//     trackButtonClick('PowerPoint Generator');
//     setLoading(true);
//     setError('');

//     if (!initialPrompt) {
//       setError('Please input a prompt for the presentation.');
//       setLoading(false);
//       return;
//     }

//     try {
//       const auth = getAuth();
//       const user = auth.currentUser;
//       let idToken = await user.getIdToken();

//       const response = await instance.post(
//         '/api/ppt/generate-outline',
//         { prompt: initialPrompt, slideCount },
//         { headers: { Authorization: `Bearer ${idToken}` } }
//       );

//       const { outline } = response.data;
//       setOutline(outline);
//       setLoading(false);
//       setActiveStep(1);
//     } catch (err) {
//       console.error('Error:', err);
//       setError(
//         'An error occurred while generating the outline. Please try again.'
//       );
//       setLoading(false);
//     }
//   };

//   const handleOutlineSubmit = async () => {
//     setLoading(true);
//     setError('');

//     try {
//       const auth = getAuth();
//       const user = auth.currentUser;
//       let idToken = await user.getIdToken();

//       const response = await instance.post(
//         '/api/ppt/generate-content',
//         { outline, slideCount },
//         { headers: { Authorization: `Bearer ${idToken}` } }
//       );

//       const { content } = response.data;
//       setFullContent(content);
//       setLoading(false);
//       setActiveStep(2);
//     } catch (err) {
//       console.error('Error:', err);
//       setError(
//         'An error occurred while generating the content. Please try again.'
//       );
//       setLoading(false);
//     }
//   };

//   const handleStepChange = (step) => {
//     if (step >= 0 && step <= 4) {
//       setActiveStep(step);
//     }
//   };

//   const handleUnsplashSearch = async (index) => {
//     setIsSearching(true);
//     setError('');

//     try {
//       const response = await instance.get(
//         `/api/ppt/unsplash/search?query=${encodeURIComponent(unsplashQuery.trim())}`
//       );
//       setUnsplashResults(response.data || []);
//     } catch (error) {
//       console.error('Error searching Unsplash:', error);
//       setError('Failed to search Unsplash images. Please try again.');
//       setUnsplashResults([]);
//     }
//     setIsSearching(false);
//   };

//   const handleImageSelect = (imageUrl, index) => {
//     const newContent = { ...fullContent };
//     newContent.slides[index].content.image_url = imageUrl;
//     setFullContent(newContent);
//   };

//   const handleImageUpload = async (event, index) => {
//     const file = event.target.files[0];
//     if (file) {
//       const formData = new FormData();
//       formData.append('image', file);
//       try {
//         const response = await instance.post(
//           '/api/ppt/upload-image',
//           formData,
//           {
//             headers: { 'Content-Type': 'multipart/form-data' },
//           }
//         );
//         handleImageSelect(response.data.imageUrl, index);
//       } catch (error) {
//         console.error('Error uploading image:', error);
//         setError('Failed to upload image. Please try again.');
//       }
//     }
//   };

//   const handleFinalSubmit = async () => {
//     setLoading(true);
//     setError('');

//     try {
//       const auth = getAuth();
//       const user = auth.currentUser;
//       let idToken = await user.getIdToken();

//       const presentationData = {
//         presentation_title: 'AI Generated Presentation',
//         template: selectedTheme, // This now includes both the template and color variant
//         styles: {
//           title: {
//             font: selectedFonts.title || 'Arial',
//             size: 44,
//           },
//           subtitle: {
//             font: selectedFonts.subtitle || 'Arial',
//             size: 24,
//           },
//           body: {
//             font: selectedFonts.body || 'Arial',
//             size: 18,
//           },
//         },
//         slides: fullContent.slides,
//         presenter_name: presenterName,
//       };

//       const response = await instance.post(
//         'http://localhost:5000/create_presentation',
//         presentationData,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${idToken}`,
//           },
//           responseType: 'blob',
//           withCredentials: true,
//           crossDomain: true,
//         }
//       );

//       const blob = new Blob([response.data], {
//         type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
//       });

//       const link = document.createElement('a');
//       link.href = window.URL.createObjectURL(blob);
//       link.download = 'AI_Generated_Presentation.pptx';
//       link.click();

//       window.URL.revokeObjectURL(link.href);
//       setLoading(false);
//       alert('Presentation downloaded successfully!');
//       setPresentationCreated(true);

//       await instance.post(
//         '/api/ppt/log-presentation-creation',
//         { presentationData },
//         { headers: { Authorization: `Bearer ${idToken}` } }
//       );
//     } catch (err) {
//       console.error('Error:', err);
//       setError(
//         'An error occurred while creating the presentation. Please try again.'
//       );
//       setLoading(false);
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         Create a PowerPoint Presentation
//       </Typography>

//       <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
//         <Step>
//           <StepLabel>Initial Setup</StepLabel>
//         </Step>
//         <Step>
//           <StepLabel>Outline</StepLabel>
//         </Step>
//         <Step>
//           <StepLabel>Full Content</StepLabel>
//         </Step>
//         <Step>
//           <StepLabel>Styling</StepLabel>
//         </Step>
//         <Step>
//           <StepLabel>Review and Create</StepLabel>
//         </Step>
//       </Stepper>

//       {activeStep === 0 && (
//         <>
//           <TextField
//             fullWidth
//             label="Presenter Name"
//             value={presenterName}
//             onChange={(e) => setPresenterName(e.target.value)}
//             margin="normal"
//           />
//           <TemplateSelector
//             onSelect={handleTemplateSelect}
//             selectedTemplate={selectedTemplate}
//           />
//           <TextField
//             fullWidth
//             multiline
//             rows={4}
//             value={initialPrompt}
//             onChange={(e) => setInitialPrompt(e.target.value)}
//             placeholder="Enter your presentation topic and any specific requirements..."
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             type="number"
//             label="Number of Slides"
//             value={slideCount}
//             onChange={(e) => setSlideCount(e.target.value)}
//             margin="normal"
//           />
//           <Button
//             onClick={handleInitialSubmit}
//             variant="contained"
//             disabled={loading || !selectedTemplate || !initialPrompt}
//             sx={{ mt: 2 }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'Generate Outline'}
//           </Button>
//         </>
//       )}

//       {activeStep === 1 && (
//         <>
//           <Typography variant="h6" gutterBottom>
//             Outline
//           </Typography>
//           {outline.map((slide, index) => (
//             <TextField
//               key={index}
//               fullWidth
//               label={`Slide ${index + 1}`}
//               value={slide}
//               onChange={(e) => {
//                 const newOutline = [...outline];
//                 newOutline[index] = e.target.value;
//                 setOutline(newOutline);
//               }}
//               margin="normal"
//             />
//           ))}
//           <Button
//             onClick={handleOutlineSubmit}
//             variant="contained"
//             disabled={loading}
//             sx={{ mt: 2 }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'Generate Full Content'}
//           </Button>
//         </>
//       )}

//       {activeStep === 2 && (
//         <>
//           <Typography variant="h6" gutterBottom>
//             Full Content
//           </Typography>
//           {fullContent.slides &&
//             fullContent.slides.map((slide, index) => (
//               <Box key={index} sx={{ mb: 2 }}>
//                 <Typography variant="subtitle1">Slide {index + 1}</Typography>
//                 <SlideContentRenderer
//                   slide={slide}
//                   index={index}
//                   fullContent={fullContent}
//                   setFullContent={setFullContent}
//                   unsplashQuery={unsplashQuery}
//                   setUnsplashQuery={setUnsplashQuery}
//                   handleUnsplashSearch={handleUnsplashSearch}
//                   handleImageUpload={handleImageUpload}
//                   handleImageSelect={handleImageSelect}
//                   isSearching={isSearching}
//                   error={error}
//                   unsplashResults={unsplashResults}
//                 />
//               </Box>
//             ))}
//           <Button
//             onClick={() => setActiveStep(3)}
//             variant="contained"
//             sx={{ mt: 2 }}
//           >
//             Next: Styling
//           </Button>
//         </>
//       )}

//       {activeStep === 3 && (
//         <>
//           <Typography variant="h6" gutterBottom>
//             Styling
//           </Typography>
//           <ThemeSelector
//             selectedTemplate={selectedTemplate}
//             selectedTheme={selectedTheme}
//             onThemeChange={handleThemeChange}
//           />
//           <FontSelector
//             selectedFonts={selectedFonts}
//             onFontChange={setSelectedFonts}
//           />
//           <Button
//             onClick={() => setActiveStep(4)}
//             variant="contained"
//             sx={{ mt: 2 }}
//           >
//             Next: Review
//           </Button>
//         </>
//       )}

//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           onClick={() => handleStepChange(activeStep - 1)}
//           disabled={activeStep === 0}
//           variant="contained"
//         >
//           Previous
//         </Button>
//         <Button
//           onClick={() => handleStepChange(activeStep + 1)}
//           disabled={activeStep === 4}
//           variant="contained"
//         >
//           Next
//         </Button>
//       </Box>

//       {activeStep === 4 && (
//         <Button
//           onClick={handleFinalSubmit}
//           variant="contained"
//           disabled={loading}
//           sx={{ mt: 2, display: 'block', mx: 'auto' }}
//         >
//           {loading ? <CircularProgress size={24} /> : 'Create Presentation'}
//         </Button>
//       )}

//       {presentationCreated && (
//         <Alert severity="success" sx={{ mt: 2 }}>
//           Presentation created successfully! You can still make changes and
//           create a new version.
//         </Alert>
//       )}

//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}

//       {loading && <GeneratingDialog open={loading} />}
//     </Box>
//   );
// }

// export default PowerPointGenerator;

import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Paper,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';

import instance from '../../../helpers/axiosInstance';
import trackButtonClick from '../../../helpers/trackButtonClick';
import GeneratingDialog from '../Dialog/GeneratingDialog';
import { authState } from '../../../atoms';
import SlideContentRenderer from '../SlideContentRenderer';
import TemplateSelector from '../TemplateSelector';
import FontSelector from '../FontSelector';
import ThemeSelector from '../ThemeSelector';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1.5, 3),
  fontWeight: 600,
  textTransform: 'none',
}));

const StepContent = styled(motion.div)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

// Animation variants
const pageVariants = {
  initial: { opacity: 0, y: 50 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -50 },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
};

const getDefaultFonts = (template) => {
  const defaultFonts = {
    boss_favorite: {
      title: 'Arial',
      subtitle: 'Arial',
      body: 'Arial',
    },
    // Add more templates as needed
  };
  return defaultFonts[template] || {};
};

function PowerPointGenerator() {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const [activeStep, setActiveStep] = useState(0);
  const [initialPrompt, setInitialPrompt] = useState('');
  const [slideCount, setSlideCount] = useState(5);
  const [outline, setOutline] = useState([]);
  const [fullContent, setFullContent] = useState({ slides: [] });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [presenterName, setPresenterName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedFonts, setSelectedFonts] = useState({});
  const [unsplashQuery, setUnsplashQuery] = useState('');
  const [unsplashResults, setUnsplashResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [presentationCreated, setPresentationCreated] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
    setSelectedTheme('');
    setSelectedFonts(getDefaultFonts(templateId));
  };

  const handleThemeChange = (themeId) => {
    setSelectedTheme(themeId);
  };

  const handleInitialSubmit = async (e) => {
    e.preventDefault();
    trackButtonClick('PowerPoint Generator');
    setLoading(true);
    setError('');

    if (!initialPrompt) {
      setError('Please input a prompt for the presentation.');
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      let idToken = await user.getIdToken();

      const response = await instance.post(
        '/api/ppt/generate-outline',
        { prompt: initialPrompt, slideCount },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      const { outline } = response.data;
      setOutline(outline);
      setLoading(false);
      setActiveStep(1);
    } catch (err) {
      console.error('Error:', err);
      setError(
        'An error occurred while generating the outline. Please try again.'
      );
      setLoading(false);
    }
  };

  const handleOutlineSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      let idToken = await user.getIdToken();

      const response = await instance.post(
        '/api/ppt/generate-content',
        { outline, slideCount },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      const { content } = response.data;
      setFullContent(content);
      setLoading(false);
      setActiveStep(2);
    } catch (err) {
      console.error('Error:', err);
      setError(
        'An error occurred while generating the content. Please try again.'
      );
      setLoading(false);
    }
  };

  const handleStepChange = (step) => {
    if (step >= 0 && step <= 4) {
      setActiveStep(step);
    }
  };

  const handleUnsplashSearch = async (index) => {
    setIsSearching(true);
    setError('');

    try {
      const response = await instance.get(
        `/api/ppt/unsplash/search?query=${encodeURIComponent(unsplashQuery.trim())}`
      );
      setUnsplashResults(response.data || []);
    } catch (error) {
      console.error('Error searching Unsplash:', error);
      setError('Failed to search Unsplash images. Please try again.');
      setUnsplashResults([]);
    }
    setIsSearching(false);
  };

  const handleImageSelect = (imageUrl, index) => {
    const newContent = { ...fullContent };
    newContent.slides[index].content.image_url = imageUrl;
    setFullContent(newContent);
  };

  const handleImageUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await instance.post(
          '/api/ppt/upload-image',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        handleImageSelect(response.data.imageUrl, index);
      } catch (error) {
        console.error('Error uploading image:', error);
        setError('Failed to upload image. Please try again.');
      }
    }
  };

  const handleFinalSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      let idToken = await user.getIdToken();

      const presentationData = {
        presentation_title: 'AI Generated Presentation',
        template: selectedTheme,
        styles: {
          title: {
            font: selectedFonts.title || 'Arial',
            size: 44,
          },
          subtitle: {
            font: selectedFonts.subtitle || 'Arial',
            size: 24,
          },
          body: {
            font: selectedFonts.body || 'Arial',
            size: 18,
          },
        },
        slides: fullContent.slides,
        presenter_name: presenterName,
      };

      const response = await instance.post(
        'http://localhost:5000/create_presentation',
        presentationData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          responseType: 'blob',
          withCredentials: true,
          crossDomain: true,
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'AI_Generated_Presentation.pptx';
      link.click();

      window.URL.revokeObjectURL(link.href);
      setLoading(false);
      alert('Presentation downloaded successfully!');
      setPresentationCreated(true);

      await instance.post(
        '/api/ppt/log-presentation-creation',
        { presentationData },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
    } catch (err) {
      console.error('Error:', err);
      setError(
        'An error occurred while creating the presentation. Please try again.'
      );
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepContent
            key="step0"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <TextField
              fullWidth
              label="Presenter Name"
              value={presenterName}
              onChange={(e) => setPresenterName(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            <TemplateSelector
              onSelect={handleTemplateSelect}
              selectedTemplate={selectedTemplate}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              value={initialPrompt}
              onChange={(e) => setInitialPrompt(e.target.value)}
              placeholder="Enter your presentation topic and any specific requirements..."
              margin="normal"
              variant="outlined"
            />
            <TextField
              fullWidth
              type="number"
              label="Number of Slides"
              value={slideCount}
              onChange={(e) => setSlideCount(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            <StyledButton
              onClick={handleInitialSubmit}
              variant="contained"
              color="primary"
              disabled={loading || !selectedTemplate || !initialPrompt}
              sx={{ mt: 2 }}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Generate Outline'}
            </StyledButton>
          </StepContent>
        );
      case 1:
        return (
          <StepContent
            key="step1"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Typography variant="h6" gutterBottom>
              Outline
            </Typography>
            {outline.map((slide, index) => (
              <TextField
                key={index}
                fullWidth
                label={`Slide ${index + 1}`}
                value={slide}
                onChange={(e) => {
                  const newOutline = [...outline];
                  newOutline[index] = e.target.value;
                  setOutline(newOutline);
                }}
                margin="normal"
                variant="outlined"
              />
            ))}
            <StyledButton
              onClick={handleOutlineSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ mt: 2 }}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                'Generate Full Content'
              )}
            </StyledButton>
          </StepContent>
        );
      case 2:
        return (
          <StepContent
            key="step2"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Typography variant="h6" gutterBottom>
              Full Content
            </Typography>
            {fullContent.slides &&
              fullContent.slides.map((slide, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">Slide {index + 1}</Typography>
                  <SlideContentRenderer
                    slide={slide}
                    index={index}
                    fullContent={fullContent}
                    setFullContent={setFullContent}
                    unsplashQuery={unsplashQuery}
                    setUnsplashQuery={setUnsplashQuery}
                    handleUnsplashSearch={handleUnsplashSearch}
                    handleImageUpload={handleImageUpload}
                    handleImageSelect={handleImageSelect}
                    isSearching={isSearching}
                    error={error}
                    unsplashResults={unsplashResults}
                  />
                </Box>
              ))}
          </StepContent>
        );
      case 3:
        return (
          <StepContent
            key="step3"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Typography variant="h6" gutterBottom>
              Styling
            </Typography>
            <ThemeSelector
              selectedTemplate={selectedTemplate}
              selectedTheme={selectedTheme}
              onThemeChange={handleThemeChange}
            />
            <FontSelector
              selectedFonts={selectedFonts}
              onFontChange={setSelectedFonts}
            />
          </StepContent>
        );
      case 4:
        return (
          <StepContent
            key="step4"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Typography variant="h6" gutterBottom>
              Review and Create
            </Typography>
            <Typography variant="body1">Presenter: {presenterName}</Typography>
            <Typography variant="body1">Template: {selectedTheme}</Typography>
            <Typography variant="body1">
              Number of Slides: {fullContent.slides.length}
            </Typography>
            <StyledButton
              onClick={handleFinalSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ mt: 2 }}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Create Presentation'}
            </StyledButton>
          </StepContent>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          Create Your PowerPoint Presentation
        </Typography>

        <Stepper
          activeStep={activeStep}
          sx={{ mb: 4 }}
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          <Step>
            <StepLabel>Setup</StepLabel>
          </Step>
          <Step>
            <StepLabel>Outline</StepLabel>
          </Step>
          <Step>
            <StepLabel>Content</StepLabel>
          </Step>
          <Step>
            <StepLabel>Style</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>

        <AnimatePresence mode="wait">
          {renderStepContent(activeStep)}
        </AnimatePresence>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <StyledButton
            onClick={() => handleStepChange(activeStep - 1)}
            disabled={activeStep === 0}
            variant="outlined"
          >
            Previous
          </StyledButton>
          <StyledButton
            onClick={() => handleStepChange(activeStep + 1)}
            disabled={activeStep === 4}
            variant="contained"
            color="primary"
          >
            {activeStep === 3 ? 'Review' : 'Next'}
          </StyledButton>
        </Box>

        {presentationCreated && (
          <Alert severity="success" sx={{ mt: 4 }}>
            Presentation created successfully! You can still make changes and
            create a new version.
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
        )}

        {loading && <GeneratingDialog open={loading} />}
      </StyledPaper>
    </Container>
  );
}

export default PowerPointGenerator;
