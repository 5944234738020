// //client/src/components/screens/ThemeSelector.js

// import React from 'react';
// import {
//   Grid,
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
// } from '@mui/material';

// const ThemeSelector = ({ selectedTemplate, selectedTheme, onThemeChange }) => {
//   const themeOptions = [
//     {
//       name: `${selectedTemplate}_red`,
//       image: `/images/${selectedTemplate}_red.jpg`,
//     },
//     {
//       name: `${selectedTemplate}_blue`,
//       image: `/images/${selectedTemplate}_blue.jpg`,
//     },
//     {
//       name: `${selectedTemplate}_orange`,
//       image: `/images/${selectedTemplate}_orange.jpg`,
//     },
//   ];

//   return (
//     <Grid container spacing={2}>
//       {themeOptions.map((theme) => (
//         <Grid item xs={12} sm={4} key={theme.name}>
//           <Card>
//             <CardActionArea onClick={() => onThemeChange(theme.name)}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={theme.image}
//                 alt={theme.name}
//               />
//               <CardContent>
//                 <Typography variant="body2" color="text.secondary">
//                   {theme.name}
//                 </Typography>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default ThemeSelector;

// src/components/screens/ThemeSelector.js

import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import { getTemplateVariants } from './TemplateManager';

const ThemeSelector = ({ selectedTemplate, selectedTheme, onThemeChange }) => {
  const themeOptions = getTemplateVariants(selectedTemplate);

  return (
    <Grid container spacing={2}>
      {themeOptions.map((theme) => (
        <Grid item xs={12} sm={4} key={theme.id}>
          <Card>
            <CardActionArea onClick={() => onThemeChange(theme.id)}>
              <CardMedia
                component="img"
                height="140"
                image={theme.thumbnail}
                alt={theme.name}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {theme.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThemeSelector;
