import React, { useRef, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Card,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  ButtonGroup,
} from '@mui/material';

import instance from '../../../helpers/axiosInstance';
import GeneratingDialog from '../Dialog/GeneratingDialog';
import PrintDownErrorDialog from '../Dialog/PrintDownErrorDialog';
import MonthlyWordCountErrorDialog from '../Dialog/monthlyWordCountErrorDialog';
import trackButtonClick from '../../../helpers/trackButtonClick';
import downloadPdf from '../../../helpers/downloadPdf';
import printDocument from '../../../helpers/printDocument';
import LanguageSelect from '../../../helpers/LanguageSelect';
import VoicesDropdown from '../../../helpers/VoicesDropdown';
import VoiceSettingsDropdown from '../../../helpers/VoiceSettingsDropdown';

import { authState } from '../../../atoms';

export function createSpeechToTextTemplate({
  name,
  endpoint,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
  placeholderText,
}) {
  return function SpeechToTextScreen() {
    const theme = useTheme();
    const isNotMobile = useMediaQuery('(min-width: 1000px)');
    const contentRef = useRef(null);
    const navigate = useNavigate();

    // Recoil state
    const auth = useRecoilValue(authState);
    const { isAuthenticated } = auth;

    // State management
    const [text, setText] = useState('');
    const [textError, setTextError] = useState('');
    const [audioFile, setAudioFile] = useState(null);
    const [error, setError] = useState('');
    const [aiText, setAiText] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [language, setLanguage] = useState('English (American)');
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState('');
    const [selectedVoiceName, setSelectedVoiceName] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [style, setStyle] = useState(0); // Default value
    const [exaggeration, setExaggeration] = useState(1); // Example default value
    const [stability, setStability] = useState(1); // Example default value

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    const handleVoiceSelection = (voiceId, voiceName) => {
      setSelectedVoice(voiceId);
      setSelectedVoiceName(voiceName);
    };

    const handleVoiceSettingsChange = (voiceSettings) => {
      console.log('Voice Settings Received:', voiceSettings);

      setStability(voiceSettings.stability);
      setExaggeration(voiceSettings.similarity_boost);
      setStyle(voiceSettings.style);
    };

    const handleFileChange = (event) => {
      setAudioFile(event.target.files[0]);
    };

    // Modify the useEffect that fetches voices to include the sample URLs
    useEffect(() => {
      const fetchVoices = async () => {
        try {
          const response = await instance.get('/api/voices'); // Your backend endpoint
          setVoices(response.data.voices);
        } catch (error) {
          console.error('Error fetching voices:', error);
        }
      };

      fetchVoices();
    }, []);

    const playSampleAudio = async (voiceId, sampleId) => {
      try {
        const response = await fetch(
          `/api/voices/${voiceId}/samples/${sampleId}/audio`
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching sample audio: ${response.statusText}`
          );
        }

        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);
        audio.play();
      } catch (error) {
        console.error('Error playing audio sample:', error);
      }
    };

    // When the selected voice changes, play the sample audio
    useEffect(() => {
      if (selectedVoice) {
        const voice = voices.find((v) => v.voice_id === selectedVoice);
        if (voice && voice.sampleAudioUrl) {
          playSampleAudio(voice.sampleAudioUrl);
        }
      }
    }, [selectedVoice, voices]);

    // const handleSubmit = async (e) => {
    //   e.preventDefault();
    //   trackButtonClick(label);

    //   setLoading(true);
    //   setButtonDisabled(true);

    //   try {
    //     let apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    //     const auth = getAuth();
    //     const user = auth.currentUser;
    //     let idToken = '';
    //     if (user) {
    //       idToken = await user.getIdToken();
    //     }

    //     console.log('Selected Voice ID:', selectedVoice);

    //     // Include selectedVoice in the payload as voiceId
    //     const payload = {
    //       text: text,
    //       toolName: name,
    //       voiceId: selectedVoice,
    //       voiceName: selectedVoiceName,

    //       voiceSettings: {
    //         style: style,
    //         similarity_boost: exaggeration,
    //         stability: stability,
    //       },
    //     };

    //     console.log('Payload being sent to backend:', payload);

    //     const { data } = await instance.post(
    //       `${apiUrl}${endpoint}`,
    //       payload, // Send the payload as a JSON object
    //       { headers: { Authorization: `Bearer ${idToken}` } }
    //     );

    //     console.log('name:', name);

    //     const taskId = data.taskId;
    //     console.log('Task ID received on frontend:', taskId);

    //     // Set up the EventSource to listen to the server
    //     let eventSource = new EventSource(
    //       `${apiUrl}/api/sse/stream-results?taskId=${taskId}&token=${idToken}`
    //     );

    //     eventSource.onmessage = (event) => {
    //       console.log('SSE event received:', event.data);
    //       try {
    //         const eventData = JSON.parse(event.data);
    //         console.log('Parsed SSE event data:', eventData); // Log the parsed event data

    //         // Check if the event contains the audio URL
    //         if (eventData.status === 'complete' && eventData.audioUrl) {
    //           console.log('Audio URL received:', eventData.audioUrl); // Log the received audio URL

    //           setLoading(false); // Hide the loading indicator
    //           setAudioFile(eventData.audioUrl); // Update the state with the audio URL
    //         }
    //       } catch (e) {
    //         console.error('Error handling message content:', e);
    //         setLoading(false);
    //       }
    //     };

    //     eventSource.onerror = function (error) {
    //       console.error('EventSource failed:', error);
    //       eventSource.close();
    //       setLoading(false);
    //       setButtonDisabled(false);
    //     };
    //   } catch (err) {
    //     console.error('Error submitting:', err);
    //   } finally {
    //     setButtonDisabled(false);
    //   }
    // };

    const handleSubmit = async (e) => {
      e.preventDefault();
      trackButtonClick(label);

      setLoading(true);
      setButtonDisabled(true);
      setError(''); // Clear any previous errors

      try {
        let apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4242';
        const auth = getAuth();
        const user = auth.currentUser;
        let idToken = '';
        if (user) {
          idToken = await user.getIdToken();
        }

        console.log('Selected Voice ID:', selectedVoice);

        const payload = {
          text: text,
          toolName: name,
          voiceId: selectedVoice,
          voiceName: selectedVoiceName,
          voiceSettings: {
            style: style,
            similarity_boost: exaggeration,
            stability: stability,
          },
        };

        console.log('Payload being sent to backend:', payload);

        const { data } = await instance.post(`${apiUrl}${endpoint}`, payload, {
          headers: { Authorization: `Bearer ${idToken}` },
        });

        console.log('Response from backend:', data);

        const taskId = data.taskId;
        console.log('Task ID received on frontend:', taskId);

        // Set up the EventSource to listen to the server
        let eventSource = new EventSource(
          `${apiUrl}/api/sse/stream-results?taskId=${taskId}&token=${idToken}`
        );

        eventSource.onmessage = (event) => {
          console.log('SSE event received:', event.data);
          try {
            const eventData = JSON.parse(event.data);
            console.log('Parsed SSE event data:', eventData);

            if (eventData.status === 'complete' && eventData.audioUrl) {
              console.log('Audio URL received:', eventData.audioUrl);
              setLoading(false);
              setAudioFile(eventData.audioUrl);
              eventSource.close();
            } else if (eventData.status === 'error') {
              throw new Error(eventData.error || 'An error occurred');
            }
          } catch (e) {
            console.error('Error handling message content:', e);
            setError(
              e.message || 'An error occurred while processing the audio'
            );
            setLoading(false);
            eventSource.close();
          }
        };

        eventSource.onerror = function (error) {
          console.error('EventSource failed:', error);
          setError('Connection to server lost. Please try again.');
          setLoading(false);
          setButtonDisabled(false);
          eventSource.close();
        };
      } catch (err) {
        console.error('Error submitting:', err);
        setError(
          err.response?.data?.error ||
            err.message ||
            'An error occurred. Please try again.'
        );
        setLoading(false);
      } finally {
        setButtonDisabled(false);
      }
    };

    return (
      <Box
        width={isNotMobile ? '70%' : '90%'}
        p="2rem"
        m="2rem auto"
        borderRadius={5}
        backgroundColor={theme.palette.background.alt}
        sx={{ boxShadow: 5 }}
      >
        <Typography variant="h5" mb={2}>
          {title}
        </Typography>
        <Typography variant="body2" mb={2}>
          {subText}
        </Typography>
        <Collapse in={!!error}>
          <Alert severity="error">{error}</Alert>
        </Collapse>

        <Grid container spacing={2} alignItems="flex-end" mb={1.5}>
          <Grid item xs={12}>
            <VoicesDropdown onVoiceChange={handleVoiceSelection} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" mb={1.5}>
          <Grid item xs={12}>
            <VoiceSettingsDropdown
              onSettingsChange={handleVoiceSettingsChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <TextField
              placeholder={placeholderText}
              multiline
              rows={10}
              fullWidth
              value={text}
              error={Boolean(textError)}
              helperText={textError}
              onChange={(e) => {
                const wordCount = e.target.value
                  .split(' ')
                  .filter((n) => n).length;
                if (wordCount <= 5000) {
                  setText(e.target.value);
                  setTextError('');
                } else {
                  setTextError(
                    'Input should not contain more than 5,000 words.'
                  );
                }
              }}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={buttonDisabled}
          onClick={handleSubmit}
          sx={{ margin: '1rem 0' }}
        >
          {buttonText}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{ display: 'block', margin: '1rem auto' }}
          />
        )}
        <Typography variant="body1" gutterBottom sx={{ marginTop: '1rem' }}>
          {aiText}
        </Typography>
        {/* Additional UI elements like download and print buttons */}
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
        ></ButtonGroup>
        {/* Audio player */}
        {audioFile && (
          <audio controls src={audioFile} style={{ width: '100%' }}>
            Your browser does not support the audio element.
          </audio>
        )}
        {/* Dialog components */}
        <GeneratingDialog open={dialogOpen} />
        <MonthlyWordCountErrorDialog />
      </Box>
    );
  };
}

export default createSpeechToTextTemplate;
