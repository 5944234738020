import createLongTextMoreLanguageTemplate from "../../LongTextMoreLanguageTemplate";

const translator = createLongTextMoreLanguageTemplate({
  name: "translator",
  endpoint: "/api/openai/translator",
  aiText: "translator",
  aiTextHandler: "translatorHandler",
  aiTextPlainText: "translatorPlainText",
  title: "Translator",
  subText:
    "Facing the challenge of bridging language barriers? Our translator tool is like your own personal linguistic wizard, effortlessly transforming words from one language to another. Simply enter the text you need translated, and watch as it magically provides an accurate and fluent translation. Imagine it as turning a foreign novel into a familiar story in your native tongue. Your global communication, made seamless and effective.",
  label: "Translation",
  placeholderText: "Enter the text to be translated",
  buttonText: "Translate",
  aiPlaceholder: "Your translation will appear here",
});

export default translator;
