// client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/journalistMock.js
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import HistoryIcon from '@mui/icons-material/History';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

const journalistMock = [
  {
    groupTitle: 'AI Writing Tools',
    id: 'tools',
    pages: [
      {
        title: 'All Journo Tools',
        path: '/my-journalist-dashboard',
        icon: <BorderColorIcon />,
      },
      {
        title: 'Broadcast Media',
        path: '/journalist/broadcast-media-tools',
        icon: <VideocamIcon />,
      },
      {
        title: 'Digital Media',
        path: '/journalist/digital-media-tools',
        icon: <VerticalSplitIcon />,
      },
      {
        title: 'Print Media',
        path: '/journalist/print-media-tools',
        icon: <NewspaperIcon />,
      },
      {
        title: 'Social Media',
        path: '/journalist/social-media-tools',
        icon: <TagOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Documents',
    id: 'documents',
    pages: [
      {
        title: 'History',
        path: '/history',
        icon: <HistoryIcon />,
      },
      {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />,
      },
      {
        title: 'Playlist',
        path: '/playlist',
        icon: <LibraryMusicIcon />,
      },
      {
        title: 'Knowledge Base',
        path: '/my-knowledge-base',
        icon: <TextSnippetIcon />,
      },
      {
        title: 'Tone of Voice',
        path: '/my-tone-of-voice',
        icon: <GraphicEqIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Account',
    id: 'account',
    pages: [
      {
        title: 'General Settings',
        path: '/user-profile',
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: 'Change Password',
        path: '/update-password',
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: 'Billing Portal',
        path: '/billing-portal',
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Support',
    id: 'support',
    pages: [
      {
        title: 'Support',
        path: '#',
        icon: <EmailOutlinedIcon />,
      },
      {
        title: 'Announcements',
        path: '#',
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

export default journalistMock;
