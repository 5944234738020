const avatars = [
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/8_cyleaa.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/7_dr7tbu.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/10_m1op7a.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/9_lheo3r.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/6_q5xbfb.svg",
  },

  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/3_lusn0z.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/4_mtjlm5.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/1_e7ufnc.svg",
  },

  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/2_czruor.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189818/Wisdom-Scribe/Avatars/5_jjojeg.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189823/Wisdom-Scribe/Avatars/20_fsdsml.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189823/Wisdom-Scribe/Avatars/18_c6tfkh.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189822/Wisdom-Scribe/Avatars/19_emr0b1.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189822/Wisdom-Scribe/Avatars/13_uaa9z6.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189821/Wisdom-Scribe/Avatars/16_in7hdg.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189821/Wisdom-Scribe/Avatars/15_rrqntr.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189821/Wisdom-Scribe/Avatars/17_eqkhpg.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189821/Wisdom-Scribe/Avatars/14_ejca0f.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/11_b5nhwd.svg",
  },
  {
    url: "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690189820/Wisdom-Scribe/Avatars/12_ygkdne.svg",
  },
];

export default avatars;
