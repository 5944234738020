import React from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { alpha } from "@mui/system";

const individualLearningPlanCreator = {
  title: "Individual Learning Plan Creator",
  subtitle: "Design customized learning plans based on student needs.",
  targetRoute: "/individual-learning-plan-creator",
  color: "#4FC0D0",
  bgcolor: alpha("#4FC0D0", 0.1),
  icon: <PendingActionsIcon fontSize="medium" style={{ color: "#4FC0D0" }} />,
  tags: [],
};

export default individualLearningPlanCreator;
