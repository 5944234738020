import React from "react";
import { colors } from "@mui/material";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { alpha } from "@mui/material";

const blogWriterHowTo = {
  title: "Blog Writer - How to",
  subtitle:
    "Create comprehensive, easy-to-follow 'how-to' guides for any topic.",
  targetRoute: "/blog-writer-how-to",
  color: colors.blue[700],
  bgcolor: alpha(colors.blue[700], 0.1),
  icon: <MenuBookOutlinedIcon fontSize="medium" />,
  tags: ["Blog Writer", "How to blog"],
};

export default blogWriterHowTo;
