import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  Avatar,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import LoginDialog from '../../../Dialog/LoginDialog';
import SubscribeDialog from '../../../Dialog/SubscribeDialog';
import { authState } from '../../../../../atoms';

import {
  proposalWriter,
  websiteContentPlanner,
} from '../../../cards/BusinessTools';

import {
  blogWriter,
  grammarCorrector,
  productDescriptionGenerator,
  translator,
  websitePageContentGenerator,
} from '../../../cards/ContentWriters';

import {
  blogContentPlanner,
  keywordResearchTool,
} from '../../../cards/SeoTools';

import {
  contentIdeasGenerator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
} from '../../../cards/SocialMediaTools';

import { transcriptionFromSpeech } from '../../../cards/SpeechToTextTools';

import {
  simpleQuestionPaperMaker,
  revisionNotes,
} from '../../../cards/TeachingTools';

import {
  breakingNewsAlertGenerator,
  newsReportPrint,
} from '../../../cards/NewsWriters';

const Benefits = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const auth = useRecoilValue(authState); // Use Recoil for authentication state

  const isAuthenticated = auth.isAuthenticated;

  const handleClickOpen = (item) => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(item);
    }
  };

  const mock = [
    blogWriter,
    productDescriptionGenerator,
    websiteContentPlanner,
    websitePageContentGenerator,
    blogContentPlanner,
    keywordResearchTool,
    youTubeScriptwriter,
    transcriptionFromSpeech,
    proposalWriter,
    grammarCorrector,
    translator,
    contentIdeasGenerator,
    socialMediaContentGenerator,
    simpleQuestionPaperMaker,
    revisionNotes,
    breakingNewsAlertGenerator,
    newsReportPrint,
  ];

  return (
    <Grid container spacing={4}>
      {mock.map((item, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <IconButton
            onClick={() => handleClickOpen(item.targetRoute)}
            sx={{
              width: '100%',
              height: '100%',
              padding: 0,
              borderRadius: 2,
              border: '1px solid transparent',
              transition: 'border-color 0.3s, box-shadow 0.3s',
              '&:hover': {
                borderColor: theme.palette.action.hover,
                boxShadow: theme.shadows[4],
              },
            }}
          >
            <Box
              component={Card}
              padding={4}
              borderRadius={2}
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              sx={{
                border: `1.2px solid ${theme.palette.divider}`,
                boxShadow: 'none',
              }}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={item.bgcolor}
                  color={item.color}
                  variant={'rounded'}
                  borderRadius={2}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Box>
          </IconButton>
        </Grid>
      ))}

      <SubscribeDialog
        open={openSubscribeDialog}
        handleClose={() => setOpenSubscribeDialog(false)}
        navigate={navigate}
      />
      <LoginDialog
        open={openLoginDialog}
        handleClose={() => setOpenLoginDialog(false)}
        navigate={navigate}
      />
      <Grid item xs={12} container justifyContent="center">
        <Button
          component={RouterLink}
          to="/tools"
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 3 }}
        >
          Welcome to Your New Playground: 70+ AI Tools
        </Button>
      </Grid>
    </Grid>
  );
};

export default Benefits;
