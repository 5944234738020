import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={"h5"}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              "&::before": {
                display: "none",
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Basics"}
          items={[
            {
              title: "What is WisdomScribe.ai?",
              subtitle:
                "WisdomScribe.ai is an AI-powered web application designed to generate, edit, and manage content in a variety of formats. It helps users with tasks ranging from blog posts to technical reports, simplifying the content creation process with advanced AI technology.",
            },
            {
              title: "How accurate is the AI writer?",
              subtitle:
                "WisdomScribe.ai utilizes the latest AI technology to ensure high accuracy in content generation. However, like any AI tool, it is recommended that users perform a final review to ensure the content perfectly suits their needs.",
            },
            {
              title: "How quickly can WisdomScribe.ai generate content?",
              subtitle:
                "For content in English, WisdomScribe.ai works in near-real time, providing content within minutes. For languages other than English, the generation process might take slightly longer due to additional computational requirements.",
            },
            {
              title: "Can I use WisdomScribe.ai on my mobile device?",
              subtitle:
                "Absolutely, WisdomScribe.ai is designed to be compatible with various platforms, including desktops, laptops, and mobile devices.",
            },
            {
              title: "Is there a limit to how much I can write at one time?",
              subtitle:
                "While there is no specific limit to the volume of content that can be generated at one time, keep in mind that your total word count is subject to the subscription plan you've chosen.",
            },
            {
              title: "In which languages can WisdomScribe.ai generate content?",
              subtitle:
                "WisdomScribe.ai can generate content in various languages including English (US & UK), Arabic, Chinese, Danish, Dutch, French, German, Greek, Hindi, Italian, Japanese, Korean, Persian (Farsi), Polish, Portuguese, Russian, Spanish, Swedish, Tamil, and Turkish.",
            },
            {
              title: "Does WisdomScribe.ai require internet access?",
              subtitle:
                "Yes, WisdomScribe.ai is a web-based application, meaning it requires an internet connection to function.",
            },
            {
              title:
                "How does WisdomScribe.ai ensure the uniqueness of the content generated?",
              subtitle:
                "WisdomScribe.ai utilizes advanced AI algorithms and a vast knowledge base to generate unique content based on the specific inputs provided by the user.",
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Our tools"}
          items={[
            {
              title:
                "What languages does WisdomScribe.ai support for audio uploads?",
              subtitle:
                "WisdomScribe.ai supports audio uploads in 22 languages. These include English (America, Australia, British, Canada, India), French (France), German (Germany), Hebrew (Israel), Hindi (India), Japanese (Japan), Kannada (India), Korean (South Korea), Malayalam (India), Marathi (India), Portuguese (Brazil), Punjabi (Gurmukhi India), Russian (Russia), Spanish (Spain), Tamil (India, Sri Lanka), Telugu (India), and Urdu (Pakistan).",
            },
            {
              title:
                "How can I use the Speech to Text Tools in WisdomScribe.ai?",
              subtitle:
                "You can use WisdomScribe.ai to upload an audio file and generate a blog, transcribe the audio, or generate a summary of the audio. After uploading your clear, high-quality .mp3 audio file (not exceeding 25MB), select the original language of your audio from our list of 22 options and pick your desired output language from our list of 21 options. Click start to begin the process. Please be patient as the task may take a few minutes due to its complexity.",
            },
            {
              title: "Can I upload a knowledge base to WisdomScribe.ai?",
              subtitle:
                "Yes, WisdomScribe.ai allows you to upload a knowledge base and use it anytime later to generate text with our various tools. Simply click the upload button, input your organization's information, assign it a title, and save it. During tool usage, you can select 'Use my knowledge base' and choose the appropriate information to integrate into the tool's functionality.",
            },
            {
              title: "Can I personalize the tone of voice in WisdomScribe.ai?",
              subtitle:
                "Absolutely! WisdomScribe.ai allows you to define your preferred tone of voice. You can specify details such as how sentences should start, the length of paragraphs, etc. Simply click the appropriate button, describe your preferred writing style, name it, and save it. You can then select this tone from a dropdown list when using our tools.",
            },
            {
              title: "Can I personalize the Business Tools in WisdomScribe.ai?",
              subtitle:
                "Yes, WisdomScribe.ai offers the opportunity to customize our tools by uploading your company's knowledge base and defining your preferred tone of voice. This way, you can achieve an experience that truly aligns with your specific business requirements.",
            },
            {
              title:
                "Can I personalize the Content Writing Tools in WisdomScribe.ai?",
              subtitle:
                "Yes, our content writing tools can be customized to reflect your specific style and information. You can upload your knowledge base and/or define your preferred tone of voice to integrate your brand's unique communication style into our tools.",
            },
            {
              title:
                "Can I personalize the Social Media Tools in WisdomScribe.ai?",
              subtitle:
                "Absolutely! You can customize our social media tools by uploading your organization's knowledge base and defining your desired tone of voice. This ensures a unique and tailored social media presence reflecting your brand's voice.",
            },
            {
              title:
                "How can I use my syllabus with WisdomScribe.ai teaching tools?",
              subtitle:
                "You can upload images of your study material to WisdomScribe.ai to generate customized teaching and learning content. Begin by taking a clear snapshot of the text from your book in either .png or .jpg format. Once your syllabus is uploaded, an array of tools becomes available for your use, allowing you to generate materials like question papers, revision notes and more, tailored specifically to your uploaded syllabus.",
            },
            {
              title:
                "Can I personalize the News Writing Tools in WisdomScribe.ai?",
              subtitle:
                "Absolutely! You can define your preferred tone of voice for several of our news writing tools. This might include particular phrasing patterns, sentence structures, or language nuances that reflect your unique journalistic voice. You can then select this tone from a dropdown list when using our tools.",
            },
          ]}
        />
      </Box>

      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Errors"}
          items={[
            {
              title: "Why is WisdomScribe.ai sometimes slow?",
              subtitle:
                "WisdomScribe.ai's performance can occasionally be impacted by server overload due to high traffic. We recommend being patient and trying again after a short while if you experience slow load times.",
            },
            {
              title:
                "Why do the audio to text tools take longer than other tools?",
              subtitle:
                "The process of converting audio to text is complex and can take more time than other tools. We appreciate your patience during this process.",
            },
            {
              title: "What should I do if I don't receive any text?",
              subtitle:
                "If you don't receive any text, please try again. If you still encounter an error, please send us a screenshot so we can help troubleshoot the issue.",
            },
            {
              title:
                "Why did I receive nonsensical output from the speech to text tool?",
              subtitle:
                "If the output from the speech to text tool does not make sense, it's likely that the input language selected does not match the actual language of the audio file. Ensure that the language selected corresponds accurately to the language spoken in the audio file.",
            },
            {
              title:
                "Can I upload an audio file that has music in the background?",
              subtitle:
                "We recommend uploading clear audio files without background noise or music for optimal results. Audio files with music or other noises in the background may result in errors or inaccurate transcriptions.",
            },
            {
              title: "What if my audio file is in a format other than MP3?",
              subtitle:
                "Our tool supports audio uploads in MP3 format. If your audio file is in a different format, such as WAV, we recommend using an online converter to change the file to MP3 format before uploading it.",
            },
            {
              title:
                "What should I do if the text generated from my uploaded syllabus is inaccurate?",
              subtitle:
                "The accuracy of the text generated from an uploaded syllabus depends on the quality of the images provided. If the generated text is inaccurate, it's likely that the images of the syllabus were not clear enough. We recommend uploading well-lit, clear images for optimal results.",
            },
          ]}
        />
      </Box>

      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Account & Billing"}
          items={[
            {
              title: "How can I start using WisdomScribe.ai?",
              subtitle:
                "To begin using WisdomScribe.ai, you will need to subscribe to one of our plans. We offer a range of subscriptions to cater to different user needs.",
            },
            {
              title: "How do I purchase a subscription for WisdomScribe.ai?",
              subtitle:
                "Purchasing a subscription is simple. Select the plan that best suits your needs and proceed to checkout. Payments can be made using your credit or debit card.",
            },
            {
              title: "Do you offer a free trial?",
              subtitle:
                "We do not currently offer a free trial. However, we provide different subscription options that allow users to choose a plan that best meets their needs.",
            },
            {
              title: "Do unused credits roll over to the next month?",
              subtitle:
                "No, unused credits from your current monthly allowance do not roll over into the next month. To get the most out of your subscription, we recommend planning your usage accordingly.",
            },
            {
              title:
                "Can I upgrade my subscription plan if I need more credits?",
              subtitle:
                "Absolutely! If you find that you need more credits than your current plan allows, you can upgrade your subscription at any time.",
            },
            {
              title: "Can I downgrade my subscription plan?",
              subtitle:
                "Yes, you can adjust your subscription plan based on your usage needs, including downgrading if necessary.",
            },
            {
              title: "What happens to my content if I cancel my subscription?",
              subtitle:
                "After canceling your subscription, you will still have access to the content you've created. However, you will not be able to generate new content until you reactivate your subscription.",
            },
          ]}
        />
      </Box>
      <Box>
        <FaqGroupItem
          title={"Security"}
          items={[
            {
              title: "Is my information safe with WisdomScribe.ai?",
              subtitle:
                "Yes! We adhere to stringent data privacy and security measures to ensure your information is protected.",
            },
            {
              title:
                "Does WisdomScribe.ai use any personal data to generate content?",
              subtitle:
                "No, WisdomScribe.ai does not use any personal data to generate content. The AI generates content based solely on the specific inputs provided by the user.",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
