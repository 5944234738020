// client/src/components/screens/KnowledgeDetailScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { authState } from '../../atoms';

const KnowledgeTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontWeight: 'bold',
  flexGrow: 1,
}));

const KnowledgeContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
}));

const KnowledgeDetailCardContent = styled(CardContent)(({ theme }) => ({
  p: 2,
  display: 'flex',
  flexDirection: 'column',
}));

const KnowledgeDetailBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CopyButton = styled(IconButton)(({ theme }) => ({}));

const KnowledgeDetailScreen = () => {
  const navigate = useNavigate();

  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;
  const [knowledgeItem, setKnowledgeItem] = useState(null);
  const { id } = useParams();
  console.log('KnowledgeDetailScreen - Received ID:', id);

  const [copied, setCopied] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const handleEdit = () => {
    setEditing(true);
    setEditedContent(knowledgeItem.text);
    setIsPublic(knowledgeItem.isPublic);
  };

  const handleSave = async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      console.log('Sending edit request with isPublic:', isPublic);

      const response = await instance.put(
        `/api/knowledgeBase/edit-knowledge/${id}`,
        {
          text: editedContent,
          companyName: knowledgeItem.companyName,
          itemName: knowledgeItem.itemName,
          isPublic: isPublic,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log('Edit response:', response.data);
      setKnowledgeItem({
        ...knowledgeItem,
        text: editedContent,
        isPublic: isPublic,
      });
      setEditing(false);
    } catch (error) {
      console.error('Error updating knowledge item:', error);
    }
  };

  const handleDelete = async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      await instance.delete(`/api/knowledgeBase/delete-knowledge/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      // Redirect to the knowledge base list after successful deletion
      navigate('/my-knowledge-base');
    } catch (error) {
      console.error('Error deleting knowledge item:', error);
    }
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const fetchKnowledgeItem = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      return;
    }
    if (!id || id === 'undefined') {
      console.error('Invalid entry ID:', id);
      setKnowledgeItem(null);
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      console.log('Fetching knowledge item with ID:', id);

      const response = await instance.get(
        `/api/knowledgeBase/user-knowledge/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log('Fetched knowledge item:', response.data);
      setKnowledgeItem(response.data);
      setIsPublic(response.data.isPublic);
    } catch (error) {
      console.error('Error fetching knowledge item:', error);
      console.error('Error response:', error.response?.data);
      setKnowledgeItem(null);
    }
  }, [id, isAuthenticated]);

  useEffect(() => {
    fetchKnowledgeItem();
  }, [fetchKnowledgeItem]);

  const handleCopyContent = () => {
    if (knowledgeItem && knowledgeItem.text) {
      try {
        const textContent = knowledgeItem.text
          .replace(/<br\/>/g, '\n')
          .replace(/<[^>]+>/g, '');
        navigator.clipboard.writeText('\n' + textContent + '\n');
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (error) {
        console.error('Error copying content:', error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      {knowledgeItem ? (
        <KnowledgeDetailBox sx={{ p: 4 }}>
          <Card>
            <KnowledgeDetailCardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h6" fontWeight={700}>
                    {knowledgeItem.companyName}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <CopyButton onClick={handleCopyContent} disableRipple>
                    {copied ? (
                      <>
                        <DoneIcon />
                        <Typography variant="body2" component="span">
                          Copied
                        </Typography>
                      </>
                    ) : (
                      <>
                        <DescriptionIcon />
                        <Typography variant="body2" component="span">
                          Copy text
                        </Typography>
                      </>
                    )}
                  </CopyButton>
                  {!editing ? (
                    <>
                      <Button onClick={handleEdit}>Edit</Button>
                      <IconButton
                        onClick={handleDeleteDialogOpen}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Button onClick={handleSave}>Save</Button>
                  )}
                </Box>
              </Box>

              <KnowledgeTitle variant="body2">
                Item: {knowledgeItem.itemName}
              </KnowledgeTitle>

              {editing ? (
                <>
                  <TextField
                    multiline
                    fullWidth
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                      />
                    }
                    label="Make public"
                  />
                </>
              ) : (
                <>
                  <KnowledgeContent
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: knowledgeItem.text }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Status: {knowledgeItem.isPublic ? 'Public' : 'Private'}
                  </Typography>
                </>
              )}
            </KnowledgeDetailCardContent>
          </Card>
        </KnowledgeDetailBox>
      ) : (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Loading...
          </Typography>
        </Box>
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Knowledge Base Item?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this knowledge base item? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default KnowledgeDetailScreen;
