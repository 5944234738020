//client/src/helpers/axiosInstance.js
import axios from 'axios';
import { auth } from '../firebaseConfig'; // Ensure this is correctly importing your Firebase config

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8080',
  baseURL: 'https://wisdom-scribe-service-oasrjw73iq-el.a.run.app',
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
