import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import notVerifiedImage from "../../assets/images/emailNotVerified.svg";

const FailedVerification = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const handleTryAgainClick = () => {
    navigate("/register");
  };

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd ? (
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={"img"}
                src={notVerifiedImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary">
              Email Not Verified
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Your email could not be verified. Please try again with a valid
              email.
            </Typography>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleTryAgainClick}
              >
                Register
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FailedVerification;
