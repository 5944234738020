//client/src/components/screens/FontSelector.js
import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from '@mui/material';

const fontOptions = [
  { name: 'Arial', style: { fontFamily: 'Arial, sans-serif' } },
  {
    name: 'Times New Roman',
    style: { fontFamily: '"Times New Roman", serif' },
  },
  { name: 'Calibri', style: { fontFamily: 'Calibri, sans-serif' } },
  { name: 'Verdana', style: { fontFamily: 'Verdana, sans-serif' } },
  { name: 'Tahoma', style: { fontFamily: 'Tahoma, sans-serif' } },
  { name: 'Trebuchet MS', style: { fontFamily: '"Trebuchet MS", sans-serif' } },
  { name: 'Georgia', style: { fontFamily: 'Georgia, serif' } },
  { name: 'Garamond', style: { fontFamily: 'Garamond, serif' } },
  { name: 'Helvetica', style: { fontFamily: 'Helvetica, sans-serif' } },
  { name: 'Gill Sans', style: { fontFamily: '"Gill Sans", sans-serif' } },
  {
    name: 'Century Gothic',
    style: { fontFamily: '"Century Gothic", sans-serif' },
  },
  { name: 'Lucida Sans', style: { fontFamily: '"Lucida Sans", sans-serif' } },
  { name: 'Open Sans', style: { fontFamily: '"Open Sans", sans-serif' } },
  { name: 'Roboto', style: { fontFamily: 'Roboto, sans-serif' } },
  { name: 'Lato', style: { fontFamily: 'Lato, sans-serif' } },
  { name: 'Montserrat', style: { fontFamily: 'Montserrat, sans-serif' } },
  {
    name: 'Source Sans Pro',
    style: { fontFamily: '"Source Sans Pro", sans-serif' },
  },
];

function FontSelector({ selectedFonts, onFontChange }) {
  const handleFontChange = (type, value) => {
    onFontChange({ ...selectedFonts, [type]: value });
  };

  return (
    <Grid container spacing={2}>
      {['title', 'subtitle', 'body'].map((type) => (
        <Grid item xs={12} sm={4} key={type}>
          <FormControl fullWidth>
            <InputLabel>
              {type.charAt(0).toUpperCase() + type.slice(1)} Font
            </InputLabel>
            <Select
              value={selectedFonts[type] || ''}
              onChange={(e) => handleFontChange(type, e.target.value)}
              renderValue={(value) => {
                const font = fontOptions.find((f) => f.name === value);
                return <Typography style={font?.style}>{value}</Typography>;
              }}
            >
              {fontOptions.map((font) => (
                <MenuItem key={font.name} value={font.name}>
                  <Typography style={font.style}>{font.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
}

export default FontSelector;
