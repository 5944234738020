import instance from './axiosInstance';
import { saveAs } from 'file-saver';

const downloadPdf = async (contentRef) => {
  if (!contentRef || !contentRef.current) {
    alert('No content to download');
    return;
  }

  const logoUrl =
    'https://res.cloudinary.com/dxdpahm3o/image/upload/v1703572789/WisdomScribe-logo_hooaxj.svg';
  const siteUrl = 'https://wisdomscribe.ai';

  let content = `
  <html>
  <head>
    <style>
      body {
        font-family: 'PT Serif', serif;
        color: #1e2022;
        font-size: 12px;
        line-height: 1.6;
        position: relative;
        padding-bottom: 60px; /* Adjust based on footer height */
      }
      .container {
        margin: 30px;
      }
      .logo {
        width: 150px;
        height: 50px;
      }
      .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        height: 50px; /* Set a fixed height for the footer */
      }
      h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
      }
    </style>
  </head>
  <body>
    <div class="container">
      ${contentRef.current.innerHTML}
    </div>
    <div class="footer">
      <a href="${siteUrl}">
        <img class="logo" src="${logoUrl}" alt="Wisdom Scribe">
      </a>
    </div>
  </body>
</html>
  `;

  try {
    const result = await instance.post(
      '/api/pdf/generate-pdf',
      { html: content },
      { responseType: 'blob' }
    );

    const pdfBlob = new Blob([result.data], { type: 'application/pdf' });

    saveAs(pdfBlob, 'eph320.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
    alert('An error occurred while generating the PDF. Please try again.');
  }
};

export default downloadPdf;
