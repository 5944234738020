import createLongAiTextGeneratorTemplate from "../../LongTextAiTextGeneratorTemplate";

const grammarCorrector = createLongAiTextGeneratorTemplate({
  name: "grammarcorrector",
  endpoint: "/api/openai/grammar-corrector",
  aiText: "grammarCorrector",
  aiTextHandler: "grammarCorrectorHandler",
  aiTextPlainText: "grammarCorrectorPlainText",
  title: "Grammar Corrector",
  subText:
    "Ensure your content is error-free with our AI Grammar Checker. This tool reviews your text for grammatical errors, providing suggestions and corrections to enhance readability and maintain a professional tone.",
  label: "Corrected Text",
  placeholderText: "Paste your text here…",
  buttonText: "Correct Grammar",
  aiPlaceholder: "Corrected text will appear here",
});

export default grammarCorrector;
