import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriterAida = createLanguageKeywordTopicTemplate({
  name: "blogwriteraida",
  endpoint: "/api/openai/blog-writer-aida",
  aiText: "blogWriterAida",
  aiTextHandler: "blogWriterAidaHandler",
  aiTextPlainText: "blogWriterAidaHandlerPlainText",
  title: "AIDA-driven Blog",
  subText:
    "This tool harnesses the power of the AIDA (Attention, Interest, Desire, Action) framework to help you craft blogs that attract attention, generate interest, create desire, and inspire action. Just give it a topic, such as 'New skincare range', and it will compose a piece that speaks directly to your audience's needs and wants.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterAida;
