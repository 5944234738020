import React from "react";
import instance from "../../../helpers/axiosInstance";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmailVerificationErrorDialog = ({ open, handleClose, email }) => {
  const navigate = useNavigate();

  const resendVerificationEmail = async () => {
    try {
      const response = await instance.post(
        "/api/auth/resend-verification-email",
        { email }
      );
      console.log("Verification email sent response:", response);
    } catch (err) {
      console.error("Error in sending verification email:", err.response?.data);
    }
  };

  // const resendVerificationEmail = async () => {
  //   try {
  //     const response = await instance.post(
  //       "/api/auth/resend-verification-email",
  //       {
  //         email,
  //       }
  //     );

  //     if (response.data.success) {
  //       console.log("Verification email sent");
  //     } else {
  //       console.error("Error sending verification email");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div">
          Email Verification Required
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please verify your email before logging in.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resendVerificationEmail();
            navigate("/email-verification");
          }}
          color="primary"
          variant="outlined"
        >
          Resend Verification Email
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailVerificationErrorDialog;
