//client/src/components/screens/Login/components/Form/Form.js

import React, { useState, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from '../../../../../atoms';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { auth, googleProvider } from '../../../../../firebaseConfig';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import axiosInstance from '../../../../../helpers/axiosInstance';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Box,
  Grid,
  TextField,
  Button,
  Link,
  Typography,
  IconButton,
  InputAdornment,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import RegisterDialogComponent from '../../../Dialog/RegisterDialog';
import EmailVerificationErrorDialog from '../../../Dialog/EmailVerificationErrorDialog';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Please enter a valid email address'
    )
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have a minimum length of 8'),
  recaptcha: yup.string().required('Please complete the reCAPTCHA'),
});

const RoleSelectionForm = ({
  onSubmit,
  isLoading,
  referralCode,
  setReferralCode,
}) => {
  const formik = useFormik({
    initialValues: {
      role: '',
      referralCode: referralCode,
    },
    validationSchema: yup.object({
      role: yup.string().required('Role selection is required'),
      referralCode: yup.string(),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        fullWidth
        error={formik.touched.role && Boolean(formik.errors.role)}
        sx={{ mb: 2 }}
      >
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          name="role"
          value={formik.values.role}
          label="Role"
          onChange={formik.handleChange}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="journalist">Journalist</MenuItem>
          <MenuItem value="educator">Educator</MenuItem>
          <MenuItem value="business">Business</MenuItem>
          <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
        {formik.touched.role && formik.errors.role && (
          <FormHelperText>{formik.errors.role}</FormHelperText>
        )}
      </FormControl>
      <TextField
        fullWidth
        label="Referral Code (Optional)"
        variant="outlined"
        name="referralCode"
        value={formik.values.referralCode}
        onChange={(e) => {
          formik.handleChange(e);
          setReferralCode(e.target.value);
        }}
        sx={{ mb: 2 }}
      />
      <Button fullWidth variant="contained" type="submit" disabled={isLoading}>
        {isLoading ? (
          <>
            <CircularProgress size={24} sx={{ mr: 1 }} />
            Processing...
          </>
        ) : (
          'Continue to Dashboard'
        )}
      </Button>
    </form>
  );
};

const Form = () => {
  const setAuthState = useSetRecoilState(authState);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openResendVerification, setOpenResendVerification] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState('');
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const recaptchaRef = useRef();

  // const handleEmailPasswordLogin = async (values) => {
  //   setIsLoading(true);
  //   try {
  //     const recaptchaValue = await recaptchaRef.current.executeAsync();
  //     if (!recaptchaValue) {
  //       throw new Error('reCAPTCHA verification failed');
  //     }
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       values.email,
  //       values.password
  //     );
  //     const { user } = userCredential;
  //     if (!user.emailVerified) {
  //       setUnverifiedEmail(values.email);
  //       setOpenResendVerification(true);
  //       setIsLoading(false);
  //       return;
  //     }
  //     const idToken = await user.getIdToken();
  //     // Send the ID token to your backend
  //     const response = await axiosInstance.post('/api/auth/login', {
  //       token: idToken,
  //       recaptcha: recaptchaValue,
  //     });
  //     if (response.data.success) {
  //       // Fetch complete user info
  //       const userInfoResponse = await axiosInstance.get('/api/auth/user');
  //       const userInfo = userInfoResponse.data;

  //       // Update local storage and auth state with complete user info
  //       localStorage.setItem('userRole', userInfo.role);
  //       localStorage.setItem('userInfo', JSON.stringify(userInfo));

  //       const userId = response.data.user._id;
  //       const userRole = response.data.user.role;

  //       setAuthState({
  //         user: {
  //           ...response.data.user,
  //           uid: user.uid,
  //           email: user.email,
  //           displayName: `${response.data.user.firstName} ${response.data.user.lastName}`,
  //           photoURL: user.photoURL,
  //           emailVerified: user.emailVerified,
  //           id: userId,
  //           _id: userId,
  //         },
  //         accessToken: idToken,
  //         isAuthenticated: true,
  //         isLoading: false,
  //         userHMAC: response.data.userHMAC,
  //       });

  //       // Dispatch a custom event after setting the auth state
  //       window.dispatchEvent(
  //         new CustomEvent('loginComplete', { detail: { userId } })
  //       );

  //       localStorage.setItem('justLoggedIn', 'true');

  //       console.log('User role:', userRole);
  //       console.log('Navigating to:', `/my-${userRole}-dashboard`);

  //       navigateBasedOnRole(userRole);
  //     } else {
  //       throw new Error(response.data.error || 'Login failed');
  //     }
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     console.error('Error response:', error.response);
  //     if (error.response) {
  //       console.error('Error status:', error.response.status);
  //       console.error('Error data:', error.response.data);
  //     }
  //     if (error.code === 'auth/user-not-found') {
  //       setError(
  //         'Your email is not registered. Please register before you log in.'
  //       );
  //       setOpenRegister(true);
  //     } else if (error.code === 'auth/wrong-password') {
  //       setError('Incorrect password. Please try again.');
  //     } else {
  //       setError(
  //         `An unexpected error occurred during login. Please try again: ${error.message}`
  //       );
  //     }
  //   }
  // };

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //   },
  //   validationSchema,
  //   onSubmit: handleEmailPasswordLogin,
  // });

  const handleEmailPasswordLogin = async (values) => {
    setIsLoading(true);
    try {
      if (!recaptchaValue) {
        throw new Error('Please complete the reCAPTCHA');
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const { user } = userCredential;
      if (!user.emailVerified) {
        setUnverifiedEmail(values.email);
        setOpenResendVerification(true);
        setIsLoading(false);
        return;
      }
      const idToken = await user.getIdToken();

      const response = await axiosInstance.post('/api/auth/login', {
        token: idToken,
        recaptcha: recaptchaValue,
      });

      if (response.data.success) {
        // Fetch complete user info
        const userInfoResponse = await axiosInstance.get('/api/auth/user');
        const userInfo = userInfoResponse.data;

        // Update local storage and auth state with complete user info
        localStorage.setItem('userRole', userInfo.role);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        const userId = response.data.user._id;
        const userRole = response.data.user.role;

        setAuthState({
          user: {
            ...response.data.user,
            uid: user.uid,
            email: user.email,
            displayName: `${response.data.user.firstName} ${response.data.user.lastName}`,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            id: userId,
            _id: userId,
          },
          accessToken: idToken,
          isAuthenticated: true,
          isLoading: false,
          userHMAC: response.data.userHMAC,
        });

        // Dispatch a custom event after setting the auth state
        window.dispatchEvent(
          new CustomEvent('loginComplete', { detail: { userId } })
        );

        localStorage.setItem('justLoggedIn', 'true');

        console.log('User role:', userRole);
        console.log('Navigating to:', `/my-${userRole}-dashboard`);

        navigateBasedOnRole(userRole);
      } else {
        throw new Error(response.data.error || 'Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error);
      console.error('Error response:', error.response);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      if (error.code === 'auth/user-not-found') {
        setError(
          'Your email is not registered. Please register before you log in.'
        );
        setOpenRegister(true);
      } else if (error.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else {
        setError(
          `An unexpected error occurred during login. Please try again: ${error.message}`
        );
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      recaptcha: '',
    },
    validationSchema,
    onSubmit: handleEmailPasswordLogin,
  });

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
    formik.setFieldValue('recaptcha', value);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleGoogleLogin = async () => {
    setIsLoading(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const googleUser = user.providerData.find(
        (provider) => provider.providerId === 'google.com'
      );
      const googleId = googleUser ? googleUser.uid : null;

      let firstName = 'User';
      let lastName = 'Name';
      if (user.displayName) {
        const nameParts = user.displayName.trim().split(' ');
        firstName = nameParts[0] || 'User';
        lastName = nameParts.slice(1).join(' ') || 'Name';
      }

      const idToken = await user.getIdToken();

      const response = await axiosInstance.post(
        '/api/auth/google-login',
        {
          uid: user.uid,
          email: user.email,
          firstName,
          lastName,
          avatar: user.photoURL,
          googleId,
          emailVerified: user.emailVerified,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        if (response.data.isNewUser || !response.data.user.role) {
          // User needs to select a role
          setGoogleUser(response.data.user);
          setShowRoleSelection(true);
        } else {
          // User already has a role, proceed with login
          setAuthState({
            user: {
              ...response.data.user,
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
              emailVerified: true,
            },
            accessToken: idToken,
            isAuthenticated: true,
            isLoading: false,
            userHMAC: response.data.userHMAC,
          });

          navigateBasedOnRole(response.data.user.role);
        }
      } else {
        throw new Error(response.data.message || 'Google login failed');
      }
    } catch (error) {
      console.error('Google login failed:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      console.log('Submitting role and referral code:', values); // Add this line

      const response = await axiosInstance.post(
        '/api/auth/google-login',
        {
          ...googleUser,
          role: values.role,
          referralCode: values.referralCode,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        setAuthState({
          user: {
            ...response.data.user,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: true,
          },
          accessToken: idToken,
          isAuthenticated: true,
          isLoading: false,
          userHMAC: response.data.userHMAC,
        });

        navigateBasedOnRole(response.data.user.role);
      } else {
        throw new Error(response.data.message || 'Failed to update user role');
      }
    } catch (error) {
      console.error('Role submission failed:', error);
      setError('Failed to set role. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginError = (error) => {
    if (
      error.code === 'auth/user-not-found' ||
      error.code === 'auth/invalid-credential'
    ) {
      setError(
        'Invalid email or password. Please check your credentials and try again.'
      );
    } else if (error.code === 'auth/wrong-password') {
      setError('Incorrect password. Please try again.');
    } else if (error.code === 'auth/too-many-requests') {
      setError(
        'Too many failed login attempts. Please try again later or reset your password.'
      );
    } else if (error.code === 'auth/network-request-failed') {
      setError(
        'Network error. Please check your internet connection and try again.'
      );
    } else {
      setError('An unexpected error occurred. Please try again later.');
    }
  };

  const navigateBasedOnRole = (role) => {
    switch (role) {
      case 'journalist':
        navigate('/my-journalist-dashboard');
        break;
      case 'business':
        navigate('/my-business-dashboard');
        break;
      case 'educator':
        navigate('/my-teaching-dashboard');
        break;
      case 'digital-marketer':
        navigate('/my-digital-marketing-dashboard');
        break;
      case 'writer':
        navigate('/my-writing-dashboard');
        break;
      default:
        navigate('/dashboard');
    }
  };

  // Render method
  if (showRoleSelection) {
    return (
      <RoleSelectionForm
        onSubmit={handleRoleSubmit}
        isLoading={isLoading}
        referralCode={referralCode}
        setReferralCode={setReferralCode}
      />
    );
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Login
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Welcome back
        </Typography>
        <Typography color="text.secondary">
          Login to manage your account.
        </Typography>
      </Box>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleLogin}
        sx={{
          borderColor: '#4285F4',
          color: '#4285F4',
          '&:hover': {
            backgroundColor: '#4285F4',
            color: 'white',
          },
        }}
      >
        Login with Google
      </Button>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ width: '100%', mb: 1 }} />
        <Box
          sx={{
            backgroundColor: 'background.paper',
            px: 1.5,
            position: 'relative',
            top: '-22px',
          }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            or
          </Typography>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            ></Box>

            <TextField
              label="Password"
              variant="outlined"
              name={'password'}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant={'subtitle2'}>
              <Link
                component={'a'}
                color={'primary'}
                href={'/forgot-password'}
                underline={'none'}
              >
                Forgot your password?
              </Link>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button size={'large'} variant={'contained'} type={'submit'}>
              Login
            </Button>
          </Grid> */}

          {/* <Grid item xs={12}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6Lfvf0oqAAAAAOr9ygLKmGkBj5E3e8Lj4E_sSTlv"
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test key
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              size={'large'}
              variant={'contained'}
              type={'submit'}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </Button>
          </Grid>
        </Grid>
      </form> */}

          <Grid item xs={12}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lfvf0oqAAAAAOr9ygLKmGkBj5E3e8Lj4E_sSTlv"
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test key
              onChange={handleCaptchaChange}
            />
            {formik.touched.recaptcha && formik.errors.recaptcha && (
              <Typography color="error" variant="caption">
                {formik.errors.recaptcha}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              size={'large'}
              variant={'contained'}
              type={'submit'}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <RegisterDialogComponent
        open={openRegister}
        handleClose={() => setOpenRegister(false)}
        navigate={navigate}
      />
      <EmailVerificationErrorDialog
        open={openResendVerification}
        handleClose={() => setOpenResendVerification(false)}
        email={unverifiedEmail}
      />
    </Box>
  );
};

export default Form;
