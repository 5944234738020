// import React, { useRef, useState, useEffect } from 'react';
// import { useRecoilValue, useSetRecoilState } from 'recoil';

// import {
//   Box,
//   Container,
//   Typography,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from '@mui/material';

// import instance from '../../helpers/axiosInstance';

// import { authState } from '../../atoms';

// const formatDate = (dateString) => {
//   const options = {
//     year: '2-digit',
//     month: 'short',
//     day: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   };
//   return new Date(dateString).toLocaleDateString(undefined, options);
// };

// const AudioPlayer = ({ src }) => {
//   const handlePlay = () => {
//     // Pause any other playing audio elements
//     document.querySelectorAll('audio').forEach((audioEl) => {
//       if (audioEl.src !== src) {
//         audioEl.pause();
//       }
//     });
//   };

//   return (
//     <audio controls src={src} style={{ width: '100%' }} onPlay={handlePlay}>
//       <source src={src} type="audio/mpeg" />
//       Your browser does not support the audio element.
//     </audio>
//   );
// };

// const UserPlaylistScreen = () => {
//   // Recoil state
//   const auth = useRecoilValue(authState);
//   const setAuth = useSetRecoilState(authState);
//   const { isAuthenticated, accessToken, refreshToken, tokenExpiry } = auth;

//   const [userPlaylist, setUserPlaylist] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);

//   useEffect(() => {
//     const fetchUserPlaylist = async () => {
//       try {
//         const response = await instance.get(
//           `/api/auth/playlist?page=${currentPage}&limit=${pageSize}`,
//           {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           }
//         );

//         const playlistData = response.data.items;
//         if (currentPage === 1) {
//           setUserPlaylist(playlistData);
//         } else {
//           setUserPlaylist((prev) => [...prev, ...playlistData]);
//         }
//       } catch (error) {
//         console.error('Error fetching user playlist:', error);
//       }
//     };

//     fetchUserPlaylist();
//   }, [currentPage, pageSize, isAuthenticated, accessToken]);

//   return (
//     <Container>
//       <Box mt={4} mb={4}>
//         <Typography
//           variant="h5"
//           component="h3"
//           textAlign="center"
//           gutterBottom
//           mb={4}
//         >
//           Your Playlist
//         </Typography>

//         <TableContainer component={Paper} elevation={4}>
//           <Table aria-label="playlist table">
//             <TableHead>
//               <TableRow>
//                 <TableCell style={{ width: '15%' }}>Date</TableCell>
//                 <TableCell style={{ width: '15%' }}>Voice Name</TableCell>
//                 <TableCell style={{ width: '35%' }}>Text</TableCell>
//                 <TableCell style={{ width: '35%' }}>Audio</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {userPlaylist.map((item, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{formatDate(item.createdAt)}</TableCell>
//                   <TableCell>{item.voiceName}</TableCell>
//                   <TableCell>{item.text}</TableCell>
//                   <TableCell>
//                     <AudioPlayer src={item.url} />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <Box textAlign="center" mt={4}>
//           <Button
//             variant="contained"
//             onClick={() => setCurrentPage((prev) => prev + 1)}
//           >
//             Load More
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default UserPlaylistScreen;

import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { authState } from '../../atoms';

const formatDate = (dateString) => {
  const options = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const AudioPlayer = ({ src }) => {
  const handlePlay = () => {
    // Pause any other playing audio elements
    document.querySelectorAll('audio').forEach((audioEl) => {
      if (audioEl.src !== src) {
        audioEl.pause();
      }
    });
  };

  return (
    <audio controls src={src} style={{ width: '100%' }} onPlay={handlePlay}>
      <source src={src} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

const UserPlaylistScreen = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const [userPlaylist, setUserPlaylist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchUserPlaylist = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('Authentication required.');
      navigate('/login'); // Redirect to login page if not authenticated
      return;
    }
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.get(
        `/api/auth/playlist?page=${currentPage}&limit=${pageSize}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const playlistData = response.data.items;
      if (currentPage === 1) {
        setUserPlaylist(playlistData);
      } else {
        setUserPlaylist((prev) => [...prev, ...playlistData]);
      }
    } catch (error) {
      console.error('Error fetching user playlist:', error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access
        navigate('/login');
      }
    }
  }, [currentPage, pageSize, isAuthenticated, navigate]);

  useEffect(() => {
    fetchUserPlaylist();
  }, [fetchUserPlaylist]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your Playlist
        </Typography>

        <TableContainer component={Paper} elevation={4}>
          <Table aria-label="playlist table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '15%' }}>Date</TableCell>
                <TableCell style={{ width: '15%' }}>Voice Name</TableCell>
                <TableCell style={{ width: '35%' }}>Text</TableCell>
                <TableCell style={{ width: '35%' }}>Audio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userPlaylist.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  <TableCell>{item.voiceName}</TableCell>
                  <TableCell>{item.text}</TableCell>
                  <TableCell>
                    <AudioPlayer src={item.url} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserPlaylistScreen;
