import React from "react";
import { colors } from "@mui/material";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { alpha } from "@mui/material";

const narrateToChild = {
  title: "Narrate To Child",
  subtitle:
    "Simplify complex topics for a child's understanding in an engaging way.",
  targetRoute: "/narrate-to-child",
  color: colors.amber[800],
  bgcolor: alpha(colors.amber[800], 0.1),
  icon: <ChildCareIcon fontSize="medium" />,
  tags: [],
};

export default narrateToChild;
