import createBlogFromSpeechTemplate from "../../BlogFromSpeechTemplate";

const translationFromSpeech = createBlogFromSpeechTemplate({
  name: "translationfromspeech",
  endpoint: "/api/openai/translation-from-speech",
  aiText: "translationFromSpeech",
  aiTextHandler: "translationFromSpeechHandler",
  aiTextPlainText: "translationFromSpeechPlainText",
  title: "Translation From Speech",
  subText:
    "Simplify global communication with this precise translation tool. Upload an mp3 file in any of the 22 supported languages, choose your desired output from a list of 21 languages, and the tool will transcribe the speech into clear, written text. Save time, mitigate manual errors, and ensure every spoken word is accurately transcribed, fostering clear understanding across multiple languages.",
  label: "Translated Text",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Translate",
  aiPlaceholder: "Your translation will appear here",
  notes: `
    <strong>Please note:</strong>
    <ul>
    <li>
        Our translation tool is designed to translate audio content from various languages into English.
    </li>
    <li>
        The tool supports translations from the following languages: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.
    </li>
    <li>
        The translation process involves converting the spoken words in the audio file into written English. It's important to ensure the audio quality is clear for the best possible translation.
    </li>
    <li>
        Translations may take some time to complete due to the complexity of language processing. Your patience is appreciated.
    </li>
</ul>
  `,
});

export default translationFromSpeech;
