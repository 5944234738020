import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const ToneOfVoice = ({
  toneOption,
  toneOfVoice,
  setToneOfVoice,
  setToneOfVoiceError,
  toneName,
  selectedTone,
  setSelectedTone,
}) => {
  const handleTextFieldChange = (e) => {
    const wordCount = e.target.value.split(" ").filter((n) => n).length;
    if (wordCount <= 300) {
      setToneOfVoice(e.target.value);
      setToneOfVoiceError("");
    } else {
      setToneOfVoiceError(
        "Tone of Voice should not contain more than 300 words."
      );
    }
  };

  const handleSelectChange = (e) => {
    console.log("Tone input changed:", e.target.value);
    setSelectedTone(e.target.value);
  };

  return (
    <Grid item xs={12}>
      {toneOption === "write" ? (
        <TextField
          multiline
          placeholder="Paste a text/famous writer's name whose tone you want to match here"
          fullWidth
          value={toneOfVoice}
          onChange={handleTextFieldChange}
          sx={{ mb: 1.5 }}
        />
      ) : toneOption === "select" ? (
        <FormControl variant="outlined" fullWidth sx={{ mb: 1.5 }}>
          <InputLabel id="tone-select-label">Tone Name</InputLabel>
          <Select
            labelId="tone-select-label"
            id="tone-select"
            value={selectedTone}
            onChange={handleSelectChange}
            label="Tone Name"
          >
            {toneName.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Grid item xs={12} style={{ height: "auto" }} />
      )}
    </Grid>
  );
};

export default ToneOfVoice;
