import createAudienceToneKeywordsTemplate from "../../AudienceToneKeywordsTemplate";

const landingPageHeadlineGenerator = createAudienceToneKeywordsTemplate({
  name: "landingpageheadlinegenerator",
  endpoint: "/api/openai/landing-page-headline-generator",
  aiText: "landingPageHeadlineGenerator",
  aiTextHandler: "landingPageHeadlineGeneratorHandler",
  aiTextPlainText: "landingPageHeadlineGeneratorPlainText",
  title: "Landing Page Headline Generator",
  subText:
    "Capture attention with the Landing Page Headline Generator. Provide your product details and it will generate compelling headlines that capture your value proposition, drawing visitors in and boosting conversions.",
  label: "Landing Page Headline",
  placeholderText: "Enter your product/service details…",
  buttonText: "Generate Headline",
  aiPlaceholder: "Your landing page headline will appear here",
});

export default landingPageHeadlineGenerator;
