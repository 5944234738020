/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

const mock = [
  {
    title: "A companion for those in ministry",
    subtitle:
      "Church Scribe exists to make your life simpler. It handles content creation, allowing you to focus on your mission.",
    icon: <GroupOutlinedIcon />,
  },
  {
    title: "Rooted in understanding of Scripture",
    subtitle:
      "Church Scribe understands religious texts better than any other AI. It brings a unique, faith-focused approach to content creation.",
    icon: <MenuBookOutlinedIcon />,
  },
  {
    title: "Delivering the highest quality content",
    subtitle:
      "Rely on Church Scribe for outstanding content that helps you convey your message clearly and effectively.",
    icon: <EditNoteOutlinedIcon />,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={"center"}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            Your AI assistant for faith-inspired writing{" "}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={"center"}
          >
            Discover Church Scribe, your AI Writer, ever-ready to serve. It's
            stylish, intuitive, and crafted to meet your needs.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={"center"}
                >
                  {item.title}
                </Typography>
                <Typography align={"center"} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
