import React from "react";
import { colors } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { alpha } from "@mui/material";

const homeworkAssigner = {
  title: "Homework Assigner",
  subtitle: "Easily prepare syllabus-based homework tasks.",
  targetRoute: "/homework-assigner",
  color: colors.lime[800],
  bgcolor: alpha(colors.lime[800], 0.1),
  icon: <EditNoteIcon fontSize="medium" />,
  tags: [],
};

export default homeworkAssigner;
