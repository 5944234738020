// import React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import { useTheme } from '@mui/material/styles';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../../../../../atoms';

// const Banner = () => {
//   const theme = useTheme();
//   const auth = useRecoilValue(authState);

//   return (
//     <Box
//       sx={{
//         color: 'common.white',
//         py: 4,
//       }}
//     >
//       <Typography
//         variant="h4"
//         gutterBottom
//         sx={{
//           fontWeight: 900,
//           color: 'common.white',
//         }}
//       >
//         Coming Soon: AI-Powered PowerPoint Generator
//       </Typography>
//       <Typography
//         variant="h6"
//         component="p"
//         sx={{
//           fontWeight: 500,
//           color: 'common.white',
//           mb: 3,
//         }}
//       >
//         Transform your presentation creation process. Input your requirements,
//         click a button, and watch as your professional-grade PowerPoint
//         presentation materializes in seconds, not hours or days.
//       </Typography>

//       {!auth.isAuthenticated && (
//         <Button
//           variant="contained"
//           color="secondary"
//           size="large"
//           sx={{
//             color: theme.palette.primary.main,
//             backgroundColor: 'common.white',
//             '&:hover': {
//               backgroundColor: theme.palette.grey[100],
//             },
//           }}
//         >
//           Join the Scribe Tribe
//         </Button>
//       )}
//       {auth.isAuthenticated && (
//         <Typography
//           variant="body1"
//           sx={{
//             fontWeight: 500,
//             color: 'common.white',
//           }}
//         >
//           Stay tuned! We'll notify you as soon as this feature is available.
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default Banner;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../../../../atoms';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const theme = useTheme();
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();

  const handleJoinScribeTribe = () => {
    navigate('/register');
  };

  return (
    <Box
      sx={{
        color: 'common.white',
        py: 4,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 900,
          color: 'common.white',
        }}
      >
        Coming Soon: AI-Powered PowerPoint Generator
      </Typography>
      <Typography
        variant="h6"
        component="p"
        sx={{
          fontWeight: 500,
          color: 'common.white',
          mb: 3,
        }}
      >
        Transform your presentation creation process. Input your requirements,
        click a button, and watch as your professional-grade PowerPoint
        presentation materializes in seconds, not hours or days.
      </Typography>

      {!auth.isAuthenticated && (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleJoinScribeTribe}
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          }}
        >
          Join the Scribe Tribe
        </Button>
      )}
      {auth.isAuthenticated && (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            color: 'common.white',
          }}
        >
          Stay tuned! We'll notify you as soon as this feature is available.
        </Typography>
      )}
    </Box>
  );
};

export default Banner;
