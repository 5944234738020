import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const blogWriterBenefits = createLanguageKeywordTopicTemplate({
  name: "blogwriterbenefits",
  endpoint: "/api/openai/blog-writer-benefits",
  aiText: "blogWriterBenefits",
  aiTextHandler: "blogWriterBenefitsHandler",
  aiTextPlainText: "blogWriterBenefitsHandlerPlainText",
  title: "Benefits Blog",
  subText:
    "This tool is specifically designed to craft blogs that lay out the benefits of a product or service. Input a concept, like 'Wireless Earbuds', and watch as our AI writer meticulously lists all the advantages, making your blog a compelling read for potential customers.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterBenefits;
