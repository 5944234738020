import createBusinessToolsTemplate from "../../BusinessToolsTemplate";

const customerEmailResponder = createBusinessToolsTemplate({
  name: "customeremailresponder",
  endpoint: "/api/openai/customer-email-responder",
  aiText: "customerEmailResponder",
  aiTextHandler: "customerEmailResponderHandler",
  aiTextPlainText: "customerEmailResponderPlainText",
  title: "Customer Email Responder",
  subText:
    "Enhance your customer service with this intuitive tool. Simply insert the customer's email, input relevant information or pull it from your Knowledge Base, choose your desired tone of voice, and incorporate specifics about your product or service that you wish to highlight for the customer. This tool seamlessly generates professional, empathetic responses, saving you valuable time while ensuring every customer feels acknowledged and appreciated.",
  label: "Email Response",
  placeholderText: "Enter the customer query…",
  buttonText: "Respond to Email",
  aiPlaceholder: "Email response will appear here",
});

export default customerEmailResponder;
