import React from "react";
import { colors } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { alpha } from "@mui/material";

const lessonPlanner = {
  title: "Lesson Planner",
  subtitle: "Create efficient lesson plans from your syllabus.",
  targetRoute: "/lesson-planner",
  color: colors.blueGrey[700],
  bgcolor: alpha(colors.blueGrey[700], 0.1),
  icon: <DateRangeIcon fontSize="medium" />,
  tags: [],
};

export default lessonPlanner;
