/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '../../Container';
import Fuse from 'fuse.js';
import SearchBar from './SearchBar/SearchBar';

import { authState } from '../../../atoms';

import {
  customerEmailResponder,
  performanceReportCreator,
  pitchDeckMaker,
  proposalWriter,
  websiteContentPlanner,
} from '../cards/BusinessTools';

import {
  analogyMaker,
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterPas,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterStorytelling,
  faqCreator,
  grammarCorrector,
  headlineIdeasWriter,
  introductionsWriter,
  landingPageHeadlineGenerator,
  narrateToChild,
  outlineBasedBlogWriter,
  productDescriptionGenerator,
  quoteGenerator,
  stepByStepProductGuideWriter,
  textReadabilityScanner,
  websitePageContentGenerator,
} from '../cards/ContentWriters';

import {
  breakingNewsAlertGenerator,
  headlineGeneratorBroadcast,
  headlineGeneratorDigital,
  headlineGeneratorPrint,
  interactiveContentGenerator,
  interviewQuestionGenerator,
  newsReportBroadcast,
  newsReportDigital,
  newsReportPrint,
  newsSummaryTool,
  pressReleaseRewriterBroadcast,
  pressReleaseRewriterDigital,
  pressReleaseRewriterPrint,
  socialMediaPostGenerator,
  teaserWriter,
  tickerTextGenerator,
} from '../cards/NewsWriters';

import {
  blogContentPlanner,
  blogTopicIdeas,
  keywordResearchTool,
  relatedTopicsResearch,
  simpleKeywordResearchTool,
  ultimateTopicResearcher,
} from '../cards/SeoTools';

import {
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
} from '../cards/SocialMediaTools';

import {
  analogyCreatorTeaching,
  difficultQuestionPaperMaker,
  flashcardMaker,
  homeworkAssigner,
  individualLearningPlanCreator,
  interactiveActivityDesigner,
  lessonPlanner,
  mixedQuestionPaperMaker,
  quizGeneratorTeaching,
  revisionNotes,
  simpleQuestionPaperMaker,
  topicExplainerTeaching,
} from '../cards/TeachingTools';

const features = {
  'Business Tools': [
    customerEmailResponder,
    performanceReportCreator,
    pitchDeckMaker,
    proposalWriter,
    websiteContentPlanner,
  ],

  'Content Writers': [
    analogyMaker,
    blogOutlineBuilder,
    blogWriter,
    blogWriterAida,
    blogWriterPas,
    blogWriterBenefits,
    blogWriterHowTo,
    blogWriterStorytelling,
    faqCreator,
    grammarCorrector,
    headlineIdeasWriter,
    introductionsWriter,
    landingPageHeadlineGenerator,
    narrateToChild,
    outlineBasedBlogWriter,
    productDescriptionGenerator,
    quoteGenerator,
    stepByStepProductGuideWriter,
    textReadabilityScanner,
    websitePageContentGenerator,
  ],

  'News Writers': [
    breakingNewsAlertGenerator,
    headlineGeneratorBroadcast,
    headlineGeneratorDigital,
    headlineGeneratorPrint,
    interactiveContentGenerator,
    interviewQuestionGenerator,
    newsReportBroadcast,
    newsReportDigital,
    newsReportPrint,
    newsSummaryTool,
    pressReleaseRewriterBroadcast,
    pressReleaseRewriterDigital,
    pressReleaseRewriterPrint,
    socialMediaPostGenerator,
    teaserWriter,
    tickerTextGenerator,
  ],

  'SEO Tools': [
    blogContentPlanner,
    blogTopicIdeas,
    keywordResearchTool,
    relatedTopicsResearch,
    simpleKeywordResearchTool,
    ultimateTopicResearcher,
  ],

  'Social Media Tools': [
    contentIdeasGenerator,
    podcastCreator,
    socialMediaContentGenerator,
    youTubeScriptwriter,
    youTubeTagGenerator,
    youTubeTitleDescriptionKeywordsHashtagsGenerator,
  ],

  'Teaching Tools': [
    analogyCreatorTeaching,
    difficultQuestionPaperMaker,
    flashcardMaker,
    homeworkAssigner,
    individualLearningPlanCreator,
    interactiveActivityDesigner,
    lessonPlanner,
    mixedQuestionPaperMaker,
    quizGeneratorTeaching,
    revisionNotes,
    simpleQuestionPaperMaker,
    topicExplainerTeaching,
  ],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: ['title', 'subtitle', 'tags'],
};

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useRecoilValue(authState);
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = useMemo(() => {
    const allFeatures = Object.values(features).flat();
    return new Fuse(allFeatures, fuseOptions);
  }, []);

  const searchResults = useMemo(() => {
    if (!searchQuery) return features;
    const results = fuse.search(searchQuery);
    return Object.fromEntries(
      Object.entries(features).map(([category, items]) => [
        category,
        items.filter((item) => results.some((result) => result.item === item)),
      ])
    );
  }, [fuse, searchQuery]);

  const handleClickOpen = useCallback(
    (route) => {
      if (!isAuthenticated) {
        navigate('/login');
      } else {
        navigate(route);
      }
    },
    [isAuthenticated, navigate]
  );

  return (
    <>
      <Container sx={{ pt: 4, pb: 2, px: { xs: 2, sm: 4, md: 6 } }}>
        <Box sx={{ mt: 3 }}>
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
      </Container>
      {Object.entries(searchResults).map(([category, cards], index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container sx={{ pt: 2, pb: 6, px: { xs: 2, sm: 4, md: 6 } }}>
            <Typography variant="h5" gutterBottom fontWeight="500" mb={3}>
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <FeatureCard item={item} onClick={handleClickOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
    </>
  );
};

const FeatureCard = React.memo(({ item, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => onClick(item.targetRoute)}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: theme.palette.action.hover,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Card sx={{ p: 4, borderRadius: 2, width: 1, height: 1 }}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mb: 2,
            bgcolor: item.bgcolor,
            color: item.color,
            borderRadius: 2,
          }}
          variant="rounded"
        >
          {item.icon}
        </Avatar>
        <Typography variant="h6" gutterBottom fontWeight="500">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subtitle}</Typography>
      </Card>
    </IconButton>
  );
});

export default Dashboard;
