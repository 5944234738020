import React from "react";
import { colors } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { alpha } from "@mui/material";

const websiteContentPlanner = {
  title: "Website Content Planner",
  subtitle: "Structure your website for optimal SEO and user experience.",
  targetRoute: "/website-content-planner",
  color: colors.lightBlue[300],
  bgcolor: alpha(colors.lightBlue[300], 0.1),
  icon: <DashboardIcon fontSize="medium" />,
  tags: [],
};

export default websiteContentPlanner;
