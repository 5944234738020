import createNewsNoWordCountTemplate from "../../NewsNoWordCountTemplate";

const interactiveContentGenerator = createNewsNoWordCountTemplate({
  name: "interactivecontentgenerator",
  endpoint: "/api/openai/interactive-content-generator",
  aiText: "interactiveContentGenerator",
  aiTextHandler: "interactiveContentGeneratorHandler",
  aiTextPlainText: "interactiveContentGeneratorPlainText",
  title: "Interactive Content Generator",
  subText:
    "Generate intriguing quizzes, polls, or trivia for your digital news platform with our Interactive Content Generator. Provide your topic and news details, and it will create engaging content for any news type.",
  label: "Interactive Content",
  placeholderText: "Enter Interactive Content Topic Here",
  buttonText: "Generate Content",
  aiPlaceholder: "Interactive content will appear here",
});

export default interactiveContentGenerator;
