//client/src/components/screens/Pricing/components/Main/SubscriptionConfirmationPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Stack,
  Typography,
  Avatar,
  CircularProgress,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Lock, CreditCard } from 'lucide-react';
import instance from '../../../../../helpers/axiosInstance';

const MotionBox = motion(Box);

const SubscriptionConfirmationPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const {
    planId,
    planTitle,
    pricingOption,
    currency,
    price,
    features,
    includeApiAccess,
  } = location.state;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatCurrency = (amount, currencyCode) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const handleConfirmSubscription = async () => {
    setLoading(true);
    try {
      const userResponse = await instance.get('/api/auth/user');
      let customerId = userResponse.data.customerId;

      if (!customerId) {
        const customerResponse = await instance.post(
          '/api/razorpay/create-customer',
          {
            name: userResponse.data.username,
            email: userResponse.data.email,
          }
        );
        customerId = customerResponse.data.id;
      }

      const subscriptionResponse = await instance.post(
        '/api/razorpay/create-subscription',
        {
          plan_id: planId,
          customer_id: customerId,
          pricingOption: pricingOption,
          currency: currency,
          total_count: pricingOption === 'monthly' ? 12 : 1,
          quantity: 1,
          notes: {
            plan_name: planTitle,
            pricing_option: pricingOption,
            currency: currency,
            include_api_access: includeApiAccess ? 'yes' : 'no',
          },
        }
      );

      const subscriptionId = subscriptionResponse.data.id;
      if (subscriptionId) {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          subscription_id: subscriptionId,
          name: 'Wisdom Scribe',
          description: `${planTitle} Subscription Plan`,
          handler: async function (response) {
            try {
              const verificationResponse = await instance.post(
                '/api/razorpay/verify-payment',
                {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_subscription_id: response.razorpay_subscription_id,
                  razorpay_signature: response.razorpay_signature,
                }
              );

              if (verificationResponse.data.success) {
                navigate('/dashboard');
              } else {
                setError(
                  'Payment verification failed. Please contact support.'
                );
              }
            } catch (error) {
              console.error('Payment verification failed:', error);
              setError('Payment verification failed. Please contact support.');
            }
          },
          prefill: {
            email: userResponse.data.email,
            contact: userResponse.data.phone,
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        throw new Error('Failed to create subscription');
      }
    } catch (error) {
      console.error('Error in subscription process:', error);
      setError(
        error.response?.data?.error ||
          error.message ||
          'Failed to create subscription. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          paddingTop: 13,
          marginBottom: 3,
        }}
      >
        <Container>
          <Typography
            variant="h3"
            gutterBottom
            align={'center'}
            sx={{ fontWeight: 700, color: theme.palette.primary.main }}
          >
            Confirm Your Subscription
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            align={'center'}
            sx={{ mb: 4 }}
          >
            You're just one step away from unlocking premium features!
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={4} mb={5}>
          <Grid item xs={12} md={7}>
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card variant="outlined" sx={{ mb: 4 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Plan Details
                  </Typography>
                  <Stack spacing={2}>
                    <Typography variant="body1">
                      <strong>Name:</strong> {planTitle}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Billing:</strong> {capitalize(pricingOption)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Price:</strong> {formatCurrency(price, currency)}
                    </Typography>
                    {includeApiAccess && (
                      <Typography variant="body1">
                        <strong>API Access:</strong> Included
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </MotionBox>
          </Grid>

          <Grid item xs={12} md={5}>
            <MotionBox
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Order Summary
                  </Typography>
                  <Stack spacing={3} sx={{ my: 4 }}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body1">{planTitle}</Typography>
                      <Typography variant="body1">
                        {formatCurrency(price, currency)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        mt: 2,
                        pt: 2,
                        borderTop: '1px solid rgba(0,0,0,0.12)',
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        Total
                      </Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {formatCurrency(price, currency)}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
                <CardActions sx={{ px: 2, pb: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<CreditCard size={20} />}
                    onClick={handleConfirmSubscription}
                    sx={{ py: 1.5, fontSize: '1.1rem', fontWeight: 'bold' }}
                  >
                    Confirm and Pay
                  </Button>
                </CardActions>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ pb: 2, opacity: 0.8 }}
                >
                  <Lock
                    size={14}
                    style={{ verticalAlign: 'middle', marginRight: 4 }}
                  />
                  Secure payment powered by Razorpay
                </Typography>
              </Card>

              <Card variant="outlined" sx={{ mt: 4 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Plan Features
                  </Typography>
                  <Grid container spacing={1}>
                    {features && features.length > 0 ? (
                      features.map((feature, index) => (
                        <Grid item xs={12} key={index}>
                          <Box
                            component={ListItem}
                            disableGutters
                            width={'auto'}
                            padding={0}
                          >
                            <Box
                              component={ListItemAvatar}
                              minWidth={'auto !important'}
                              marginRight={2}
                            >
                              <Box
                                component={Avatar}
                                bgcolor={theme.palette.primary.main}
                                width={20}
                                height={20}
                              >
                                <svg
                                  width={12}
                                  height={12}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </Box>
                            </Box>
                            <ListItemText primary={feature} />
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          No features available for this plan.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </MotionBox>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SubscriptionConfirmationPage;
