import createLanguageKeywordTopicTemplate from "../../LanguageKeywordTopicTemplate";

const headlineIdeasWriter = createLanguageKeywordTopicTemplate({
  name: "headlineideaswriter",
  endpoint: "/api/openai/headline-ideas-writer",
  aiText: "headlineIdeasWriter",
  aiTextHandler: "headlineIdeasWriterHandler",
  aiTextPlainText: "headlineIdeasWriterPlainText",
  title: "Headline Ideas Writer",
  subText:
    "Generate compelling headlines with this tool. Enter your topic, and it will generate a list of catchy headlines that pique curiosity and compel clicks, enhancing engagement and readership.",
  label: "Headline Ideas",
  placeholderText: "Enter type of content",
  buttonText: "Generate Headlines",
  aiPlaceholder: "Your headline ideas will appear here",
});

export default headlineIdeasWriter;
