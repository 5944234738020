import createSummaryNewsTemplate from "../../SummaryNewsTemplate";

const teaserWriter = createSummaryNewsTemplate({
  name: "teaserwriter",
  endpoint: "/api/openai/teaser-writer",
  aiText: "teaserWriter",
  aiTextHandler: "teaserWriterHandler",
  aiTextPlainText: "teaserWriterPlainText",
  title: "Teaser Writer",
  subText:
    "Craft intriguing teasers for your upcoming articles or broadcasts with our Teaser Writer. Input your topic, word count, and news details, and it will generate captivating teasers suitable for all news types.",
  label: "News Teaser",
  placeholderText: "Enter Teaser News Here",
  buttonText: "Generate Teaser",
  aiPlaceholder: "News teaser will appear here",
});

export default teaserWriter;
