import React from "react";
import { colors } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { alpha } from "@mui/material";

const performanceReportCreator = {
  title: "Performance Report Creator",
  subtitle: "Transform data into comprehensive performance reports.",
  targetRoute: "/performance-report-creator",
  color: colors.lightGreen[600],
  bgcolor: alpha(colors.lightGreen[600], 0.1),
  icon: <AssessmentIcon fontSize="medium" />,
  tags: [],
};

export default performanceReportCreator;
