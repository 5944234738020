import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // Import Button for clickable items

import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const SimpleNavItem = ({ title, href, colorInvert }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const isActive = activeLink === href;
  const linkColor = colorInvert ? "common.white" : "text.primary";
  const fontWeight = isActive ? 700 : 400;

  const handleNavigation = () => {
    navigate(href);
  };

  return (
    <Button
      onClick={handleNavigation}
      sx={{
        color: linkColor,
        fontWeight: fontWeight,
        textTransform: "none", // If you prefer not to uppercase the text
        "&:hover": {
          bgcolor: "transparent", // Maintain transparent background on hover
        },
      }}
    >
      <Typography color={linkColor} fontWeight={fontWeight}>
        {title}
      </Typography>
    </Button>
  );
};

SimpleNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  colorInvert: PropTypes.bool,
};

export default SimpleNavItem;
