import createSummaryNewsTemplate from "../../SummaryNewsTemplate";

const socialMediaPostGeneratorNews = createSummaryNewsTemplate({
  name: "socialmediapostgeneratornews",
  endpoint: "/api/openai/social-media-post-generator-news",
  aiText: "socialMediaPostGeneratorNews",
  aiTextHandler: "socialMediaPostGeneratorNewsHandler",
  aiTextPlainText: "socialMediaPostGeneratorNewsPlainText",
  title: "Social Media Post Generator - News",
  subText:
    "Transform your news into captivating social media posts with our tool. Perfect for sharing news on any platform and across various news genres.",
  label: "Social Post",
  placeholderText: "Enter News Topic Here",
  buttonText: "Generate Post",
  aiPlaceholder: "Your social media post will appear here",
});

export default socialMediaPostGeneratorNews;
