import React from "react";
import { colors } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { alpha } from "@mui/material";

const productDescriptionGenerator = {
  title: "Product Description Generator",
  subtitle:
    "Transform product descriptions into persuasive narratives that drive conversions.",
  targetRoute: "/product-description-generator",
  color: colors.deepOrange[500],
  bgcolor: alpha(colors.deepOrange[500], 0.1),
  icon: <ShoppingBasketIcon fontSize="medium" />,
  tags: [],
};

export default productDescriptionGenerator;
