import React from "react";
import { colors } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { alpha } from "@mui/material";

const proposalWriter = {
  title: "Proposal Writer",
  subtitle: "Craft winning project proposals effortlessly.",
  targetRoute: "/proposal-writer",
  color: colors.amber[800],
  bgcolor: alpha(colors.amber[800], 0.1),
  icon: <AssignmentIcon fontSize="medium" />,
  tags: [],
};

export default proposalWriter;
