import createLongAiTextGeneratorTemplate from "../../LongTextAiTextGeneratorTemplate";

const textReadabilityScanner = createLongAiTextGeneratorTemplate({
  name: "textreadabilityscanner",
  endpoint: "/api/openai/text-readability-scanner",
  aiText: "textReadabilityScanner",
  aiTextHandler: "textReadabilityScannerHandler",
  aiTextPlainText: "textReadabilityScannerPlainText",
  title: "Text Readability Scanner",
  subText:
    "Analyze text for readability with this tool. It evaluates language complexity, sentence structure, and vocabulary, offering insights to improve comprehension and ensure your content is accessible to your intended audience.",
  label: "Text Readability",
  placeholderText: "Paste your text here…",
  buttonText: "Scan Readability",
  aiPlaceholder: "Your readability score will appear here",
});

export default textReadabilityScanner;
