//client/src/layouts/Main/components/Topbar/components/NavItem/SimpleNavItem.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const SimpleNavItem = ({ title, href, colorInvert, onClose }) => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const isActive = activeLink === href;
  const linkColor = colorInvert ? 'common.white' : 'text.primary';
  const fontWeight = isActive ? 700 : 400;

  const handleNavigation = () => {
    navigate(href);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Button
      onClick={handleNavigation}
      sx={{
        color: linkColor,
        fontWeight: fontWeight,
        textTransform: 'none',
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
    >
      <Typography color={linkColor} fontWeight={fontWeight}>
        {title}
      </Typography>
    </Button>
  );
};

SimpleNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  colorInvert: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SimpleNavItem;
